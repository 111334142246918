// @flow
import { createContext } from 'react';

export type Dispatch<A> = (value: A) => void;

export type AppType = 'STATIC' | 'CONTAINER';

export type GitProvider = 'GITHUB' | 'GITLAB';

export type EnvValue = {| +name: string, +value: string |};

export type GitRepo = {|
  +id: string,
  +name: string,
  +url: string,
  +ownerAvatar?: ?string,
|};

export type CompilationConfig = {|
  +builder: 'DOCKER' | 'BUILDPACKS' | 'KANIKO' | '%future added value',
  +runtime: 'CONTAINER',
  +dockerfile: string,
  +env?: ?Array<EnvValue>,
|};

export type SiteConfig = {|
  +name: string,
  +cloudAccount: string,
  +customErrorCode: string,
  +customErrorPath: string,
  +patternTag: string,
  +continousDeployment: string,
  +source?: ?string,
|};

export type DeployConfig = {|
  +port: string,
  +name: string,
  +environment: string,
  +privacy: 'PUBLIC' | 'PRIVATE' | '%future added value',
  +enableCors: boolean,
  +allowOrigin: Array<string>,
  +allowHeaders: Array<string>,
  +allowMethods: Array<string>,
  +env: Array<EnvValue>,
  +patternTag: string,
  +continousDeployment: string,
  +source?: ?string,
  +replicas: number,
  +dockerImageUrl?: ?string,
|};

export type StateType = {|
  +method?: ?('GIT' | 'DOCKER' | '%future added value'),
  +appName: string,
  +appId?: ?string,
  +gitRef?: ?string,
  +appType?: ?AppType,
  +gitRepo?: ?GitRepo,
  +compilationId?: ?string,
  +gitProvider: GitProvider,
  +appNamespaceValue?: ?string,
  +deploymentConfig?: ?DeployConfig,
  +siteConfig?: ?SiteConfig,
  +compilationConfig?: ?CompilationConfig,
  +deploymentId?: ?string,
  +deploymentName?: ?string,
  +publicEndpoint?: ?string,
  +buildIsSuccess?: ?boolean,
|};

export type ReducerAction =
  | {|
      +type: 'REPLACE_STATE',
      +newState: StateType,
    |}
  | {|
      +type: 'SET_GIT_REPO',
      +repo: GitRepo,
      +gitProvider: GitProvider,
    |}
  | {|
      +type: 'SET_APP_NAME',
      +appName: string,
    |}
  | {|
      +type: 'SET_DOCKER_APP_ID',
      +appName: string,
      +appId: string,
      +appNamespaceValue: string,
    |}
  | {|
      +type: 'SET_COMPILATION_CONFIG',
      +compilationConfig: CompilationConfig,
    |}
  | {|
      +type: 'SET_APP_ID',
      +appId: string,
      +appNamespaceValue: string,
      +compilationConfig: CompilationConfig,
    |}
  | {|
      +type: 'SET_COMPILATION_ID',
      +gitRef: string,
      +compilationId: string,
    |}
  | {|
      +type: 'BACK_STEP',
      +index: number,
    |}
  | {|
      +type: 'SHOW_DEPLOYMENT_FORM',
    |}
  | {|
      +type: 'SET_DEPLOYMENT_CONFIG',
      +deploymentConfig: DeployConfig,
    |}
  | {|
      +type: 'SET_DEPLOYMENT_DATA',
      +deploymentId: string,
      +deploymentName: string,
      +publicEndpoint?: ?string,
    |}
  | {|
      type: 'SET_SITE_CONFIG',
      siteConfig: SiteConfig,
    |}
  | {|
      type: 'SET_METHOD',
      method: 'GIT' | 'DOCKER',
    |}
  | {|
      +type: 'FINISH_PROCESS',
    |};

type ContextType = {|
  state: StateType,
  dispatch: Dispatch<ReducerAction>,
|};

export const initialState: StateType = {
  appName: '',
  appId: null,
  method: null,
  gitRepo: null,
  deploymentId: null,
  compilationId: null,
  deploymentName: null,
  publicEndpoint: null,
  appType: 'CONTAINER',
  gitProvider: 'GITHUB',
  buildIsSuccess: false,
  deploymentConfig: null,
  compilationConfig: null,
};

const initialContext: ContextType = {
  state: initialState,
  dispatch: () => {},
};

export function reducer(state: StateType, action: ReducerAction): StateType {
  if (action.type === 'REPLACE_STATE') {
    return { ...state, ...action.newState };
  }

  if (action.type === 'SET_METHOD') {
    const newState = {
      ...(action.method === state.method ? state : initialState),
      method: action.method,
    };

    return newState;
  }

  if (action.type === 'SET_GIT_REPO') {
    const newState = {
      ...state,
      gitRepo: action.repo,
      appName: action.repo.name?.split('/')?.[1] ?? '',
      gitProvider: action.gitProvider,
    };

    return newState;
  }

  if (action.type === 'SET_APP_NAME') {
    const newState = {
      ...state,

      appName: action.appName,
    };

    return newState;
  }

  if (action.type === 'SET_DOCKER_APP_ID') {
    const newState = {
      ...state,
      appId: action.appId,
      appType: 'CONTAINER',
      appName: action.appName,
      appNamespaceValue: action.appNamespaceValue,
    };

    return newState;
  }

  if (action.type === 'SET_APP_ID') {
    const newState = {
      ...state,
      appId: action.appId,
      appNamespaceValue: action.appNamespaceValue,
      compilationConfig: action.compilationConfig,
    };

    return newState;
  }

  if (action.type === 'SET_COMPILATION_CONFIG') {
    const newState = {
      ...state,
      compilationConfig: action.compilationConfig,
    };

    return newState;
  }

  if (action.type === 'SET_COMPILATION_ID') {
    const newState = {
      ...state,
      gitRef: action.gitRef,
      compilationId: action.compilationId,
      buildIsSuccess: false,
    };

    return newState;
  }

  if (action.type === 'SHOW_DEPLOYMENT_FORM') {
    const newState = {
      ...state,
      buildIsSuccess: true,
    };

    return newState;
  }

  if (action.type === 'SET_DEPLOYMENT_CONFIG') {
    const newState = {
      ...state,
      deploymentConfig: action.deploymentConfig,
    };

    return newState;
  }

  if (action.type === 'SET_SITE_CONFIG') {
    const newState = {
      ...state,
      siteConfig: action.siteConfig,
    };

    return newState;
  }

  if (action.type === 'SET_DEPLOYMENT_DATA') {
    const newState = {
      ...state,
      deploymentId: action.deploymentId,
      deploymentName: action.deploymentName,
      publicEndpoint: action.publicEndpoint ?? null,
    };

    return newState;
  }

  if (action.type === 'FINISH_PROCESS') {
    const newState = {
      ...initialState,
    };

    return newState;
  }

  return { ...state };
}

export const Context: React$Context<{|
  dispatch: Dispatch<ReducerAction>,
  state: StateType,
|}> = createContext(initialContext);
