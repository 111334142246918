// @flow
import stylex from '@serpa-cloud/stylex';
import { useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyLoadQuery, graphql } from 'react-relay';
import * as amplitude from '@amplitude/analytics-browser';

import { Icon, Text, TapIcon, Button, Flexbox, Divider, useDevice } from '../../shared';

import Menu from '../Menu';
import UserBadge from '../UserBadge';

const styles = stylex.create({
  header: {
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 99,
  },
  toolsContainer: {
    background: 'var(--status-bar)',
    '-webkit-backdrop-filter': 'saturate(180%) blur(20px)',
    backdropFilter: 'saturate(180%) blur(20px)',
    paddingLeft: 'env(safe-area-inset-left)',
    paddingRight: 'calc(env(safe-area-inset-right) + 8px)',
  },
  freeTrialBanner: {
    background: 'var(--status-bar)',
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 'calc(env(safe-area-inset-right) + 8px)',
    paddingLeft: 'calc(env(safe-area-inset-left) + 14px)',
    textAlign: 'center',
    borderBottom: '1px solid var(--border-color)',
    '@media (max-width: 680px)': {
      paddingTop: 16,
      paddingBottom: 16,
    },
  },
});

function calculateDaysLeft(freeTrialUntil) {
  if (!freeTrialUntil) return 0;

  const currentDate = new Date();
  const trialEndDate = new Date(freeTrialUntil);
  const timeDifference = trialEndDate - currentDate;

  // Convert the difference from milliseconds to days and ensure it is an integer
  const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return daysLeft;
}

export default function Header(): React$Node {
  const navigate = useNavigate();
  const { width } = useDevice();

  // Fetch paymentProfile using useLazyLoadQuery
  const data = useLazyLoadQuery(
    graphql`
      query HeaderPaymentProfileQuery {
        getCurrentNameSpace {
          id
          freeTrialUntil
        }
        paymentProfile {
          id
        }
      }
    `,
    {},
    {
      fetchPolicy: 'store-or-network',
    },
  );

  const freeTrialUntil = data.getCurrentNameSpace?.freeTrialUntil;
  const daysLeft = useMemo(() => calculateDaysLeft(freeTrialUntil), [freeTrialUntil]);

  const paymentProfile = data?.paymentProfile;

  const handleSubscription = useCallback(() => {
    navigate('/app/subscription/billing');
  }, [navigate]);

  return (
    <>
      <header className={stylex(styles.header)}>
        {paymentProfile === null && (
          <Flexbox
            alignItems={width > 680 ? 'center' : 'flex-start'}
            justifyContent="space-between"
            className={stylex(styles.freeTrialBanner)}
            flexDirection={width > 680 ? 'row' : 'column'}
            rowGap={16}
          >
            <Flexbox alignItems="center" columnGap={12}>
              <Icon icon="redeem" color="--primary-color-1" weight={100} />
              <Flexbox flexDirection="column" rowGap={8}>
                <Text id="freeTrialMessage" type="s0b" color="--neutral-color-800" />
                {width <= 1080 && (
                  <Text
                    id="freeTrialTimer"
                    type="s0b"
                    color="--neutral-color-800"
                    values={{ daysLeft }}
                  />
                )}
              </Flexbox>
            </Flexbox>
            <Flexbox alignItems="center" columnGap={12}>
              {width > 1080 && (
                <Text
                  type="s0b"
                  color="--neutral-color-800"
                  id="freeTrialTimer"
                  values={{ daysLeft }}
                />
              )}
              <Button intlId="subscribeNow" onClick={handleSubscription} />
            </Flexbox>
          </Flexbox>
        )}
        <Flexbox
          columnGap={8}
          className={stylex(styles.toolsContainer)}
          alignItems="center"
          justifyContent="space-between"
        >
          <Menu />
          <Flexbox columnGap={8} alignItems="center">
            <TapIcon
              icon="add"
              onClick={() => {
                amplitude.track('Start App Create');

                navigate('/app/apps/create/catalog');
              }}
              iconColor="--primary-color-1"
            />

            <UserBadge />
          </Flexbox>
        </Flexbox>
        <Divider />
      </header>
    </>
  );
}
