/**
 * @generated SignedSource<<acc82c1d08aebcac64b0f5bca593af11>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type UITheme = "DARK" | "LIGHT" | "%future added value";
export type DeploymentsDetailsUIQuery$variables = {||};
export type DeploymentsDetailsUIQuery$data = {|
  +me: ?{|
    +id: string,
    +uiTheme: ?UITheme,
  |},
|};
export type DeploymentsDetailsUIQuery = {|
  variables: DeploymentsDetailsUIQuery$variables,
  response: DeploymentsDetailsUIQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uiTheme",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeploymentsDetailsUIQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DeploymentsDetailsUIQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "78478bcd103a280846f77ab4522b7f34",
    "id": null,
    "metadata": {},
    "name": "DeploymentsDetailsUIQuery",
    "operationKind": "query",
    "text": "query DeploymentsDetailsUIQuery {\n  me {\n    id\n    uiTheme\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "ec2a65f54cf1810ddb4d742931012fbd";

module.exports = ((node/*: any*/)/*: Query<
  DeploymentsDetailsUIQuery$variables,
  DeploymentsDetailsUIQuery$data,
>*/);
