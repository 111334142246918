/**
 * @generated SignedSource<<de0ef933f7cdd42ec513fa525f75f6b5>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type AppType = "CONTAINER" | "STATIC" | "%future added value";
export type CompilationDetailQuery$variables = {|
  id: string,
|};
export type CompilationDetailQuery$data = {|
  +node: ?{|
    +id: string,
    +app?: {|
      +id: string,
      +name: string,
      +appType: AppType,
      +namespace: string,
    |},
    +artifact?: ?{|
      +id: string,
      +dockerTag: ?string,
      +commitUrl: string,
      +gitTagUrl: ?string,
      +commitShaShort: string,
      +commitDescription: string,
    |},
  |},
|};
export type CompilationDetailQuery = {|
  variables: CompilationDetailQuery$variables,
  response: CompilationDetailQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "App",
      "kind": "LinkedField",
      "name": "app",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "appType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "namespace",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Artifact",
      "kind": "LinkedField",
      "name": "artifact",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dockerTag",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "commitUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gitTagUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "commitShaShort",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "commitDescription",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Build",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompilationDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompilationDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9dfa1b22e5f3a721914a7a2b2d65d0a9",
    "id": null,
    "metadata": {},
    "name": "CompilationDetailQuery",
    "operationKind": "query",
    "text": "query CompilationDetailQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    id\n    ... on Build {\n      id\n      app {\n        id\n        name\n        appType\n        namespace\n      }\n      artifact {\n        id\n        dockerTag\n        commitUrl\n        gitTagUrl\n        commitShaShort\n        commitDescription\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "1a3ad5b729d5afd116c21517709426b7";

module.exports = ((node/*: any*/)/*: Query<
  CompilationDetailQuery$variables,
  CompilationDetailQuery$data,
>*/);
