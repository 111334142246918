// @flow
import stylex from '@serpa-cloud/stylex';

import Icon from './Icon';
import Text from './Text';
import Card from './Card';
import Button from './Button';
import Margin from './Margin';
import Flexbox from './Flexbox';
import Padding from './Padding';

const styles = stylex.create({
  container: {
    maxWidth: 480,
    width: '100%',
  },
});

type Props = {
  link: string,
  icon: string,
  title?: ?string,
  description?: ?string,
  callback: () => void,
  extraDescription: string,
  callToActionLabel: string,
  gradient: [string, string],
};

export default function ComponentDescriptor({
  icon,
  link,
  title,
  gradient,
  callback,
  description,
  extraDescription,
  callToActionLabel,
}: Props): React$Node {
  return (
    <Flexbox className={stylex(styles.container)}>
      <div>
        <Flexbox flexDirection="column" rowGap={16}>
          {title ? <Text id={title} type="h6" component="div" /> : null}
          {description ? (
            <Text
              id={description}
              type="bs"
              values={{
                b: (s) => (
                  <a href={link} target="_blank" rel="noreferrer">
                    <strong>{s}</strong>
                  </a>
                ),
              }}
            />
          ) : null}
        </Flexbox>

        <Margin top={24}>
          <Card>
            <Padding vertical={24} horizontal={24}>
              <Flexbox flexDirection="column" alignItems="center">
                <Padding horizontal={8} bottom={24}>
                  <Icon
                    fill
                    size={56}
                    weight={100}
                    icon={icon}
                    color="--neutral-color-100"
                    gradient={`linear-gradient(265.7deg, var(--${gradient[0]}-solid-color) -2.24%, var(--${gradient[1]}-solid-color) 102.98%)`}
                  />
                </Padding>
                <Text
                  id={extraDescription}
                  type="bs"
                  values={{
                    b: (s) => <strong style={{ color: 'var(--neutral-color-800)' }}>{s}</strong>,
                    g: (s) => <strong style={{ color: 'var(--green-300)' }}>{s}</strong>,
                  }}
                />
              </Flexbox>
              <Margin top={32}>
                <Button intlId={callToActionLabel} onClick={callback} />
              </Margin>
            </Padding>
          </Card>
        </Margin>
      </div>
    </Flexbox>
  );
}

ComponentDescriptor.defaultProps = {
  title: '',
  description: '',
};
