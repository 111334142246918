/* eslint-disable no-nested-ternary */
// @flow
import stylex from '@serpa-cloud/stylex';
import { Suspense, useCallback } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useMatch, useNavigate } from 'react-router-dom';

import BuildStatus from '../AppCreator/BuildStatus';

import { Icon, Flexbox, Padding, ElementModal, TapIcon, Text } from '../../shared';

import useDevice from '../../shared/hooks/useDevice';

const styles = stylex.create({
  container: {
    width: '100%',
  },
  root: {
    width: '100%',
    boxSizing: 'border-box',
  },
  buildProsess: {
    flex: 1,
    overflow: 'hidden',
    borderRadius: '8px',
    height: 'calc(100vh - 334px)',
    border: '1px solid var(--border-color)',
    backgroundColor: 'var(--border-color)',
  },
});

export default function CompilationDetail(): React$Node {
  const { width } = useDevice();
  const navigate = useNavigate();
  const match = useMatch('/app/apps/:nodeId/compilations/:buildId');

  const data = useLazyLoadQuery(
    graphql`
      query CompilationDetailQuery($id: ID!) {
        node(id: $id) {
          id
          ... on Build {
            id
            app {
              id
              name
              appType
              namespace
            }
            artifact {
              id
              dockerTag
              commitUrl
              gitTagUrl
              commitShaShort
              commitDescription
            }
          }
        }
      }
    `,
    {
      id: match?.params?.buildId ?? '',
    },
    {
      fetchPolicy: 'store-or-network',
    },
  );

  const handleOnClose = useCallback(() => {
    navigate('./..');
  }, [navigate]);

  const horizontalPadding = width <= 1000 ? (width <= 400 ? 0 : 16) : 24;

  return (
    <ElementModal
      id={data?.node?.id ?? 'CompilationDetail'}
      header={
        <header>
          <Padding horizontal={width <= 1000 ? (width <= 400 ? 8 : 16) : 24} vertical={24}>
            <Flexbox alignItems="center" justifyContent="space-between">
              <Flexbox alignItems="center" columnGap={8}>
                <Icon
                  fill
                  icon="build_circle"
                  gradient="linear-gradient(265.7deg, var(--yellow-solid-color) -2.24%, var(--red-solid-color) 102.98%)"
                />
                <Text
                  type="h5"
                  id="buildHeader"
                  values={{
                    appName: data?.node?.app?.name ?? '',
                    build:
                      data?.node?.artifact?.dockerTag ??
                      data?.node?.artifact?.commitShaShort ??
                      data?.node?.id ??
                      '',
                  }}
                />
              </Flexbox>
              <TapIcon icon="close" iconColor="--primary-color-1" onClick={handleOnClose} />
            </Flexbox>
          </Padding>
        </header>
      }
    >
      <Padding horizontal={horizontalPadding}>
        <Suspense
          fallback={
            <Flexbox justifyContent="center">
              <Padding bottom={24} className={stylex(styles.root)}>
                <div className={stylex(styles.buildProsess)} />
              </Padding>
            </Flexbox>
          }
        >
          <div className={stylex(styles.container)}>
            <Flexbox justifyContent="center">
              <BuildStatus avoidOpenModal buildId={match?.params?.buildId ?? ''} />
            </Flexbox>
          </div>
        </Suspense>
      </Padding>
    </ElementModal>
  );
}
