// @flow
import { Link } from 'react-router-dom';
import stylex from '@serpa-cloud/stylex';
import { Flexbox, Icon, Text, Padding } from '../../shared';

type Props = {|
  +icon: string,
  +link?: string,
  +url?: string,
  +intlId: string,
|};

const styles = stylex.create({
  root: {
    borderRadius: 8,
    transitionProperty: 'all',
    transitionDuration: 'var(--fds-duration-short-in)',
    transitionTimingFunction: 'var(--fds-animation-fade-in)',
    ':hover': {
      backgroundColor: 'var(--hover-overlay)',
      transitionDuration: 'var(--fds-duration-short-out)',
      transitionTimingFunction: 'var(--fds-animation-fade-out)',
    },
  },
  link: {
    display: 'block',
    position: 'relative',
    textDecoration: 'none!important',
  },
});

export default function MenuElement({ icon, intlId, link, url }: Props): React$Node {
  const element = (
    <Padding horizontal={8} vertical={8} className={stylex(styles.root)}>
      <Flexbox alignItems="center" columnGap={16}>
        <Icon icon={icon} size={20} color="--neutral-color-600" />
        <Text type="s0m" id={intlId} color="--neutral-color-600" />
      </Flexbox>
    </Padding>
  );

  if (link)
    return (
      <Link to={link} className={stylex(styles.link)}>
        {element}
      </Link>
    );

  if (url)
    return (
      <a href={url} target="_blank" rel="noreferrer" className={stylex(styles.link)}>
        {element}
      </a>
    );

  return null;
}

MenuElement.defaultProps = {
  url: '',
  link: '',
};
