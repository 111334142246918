// @flow
import stylex from '@serpa-cloud/stylex';

import type { LayoutSize } from './Flexbox';

import { ReactComponent as SerpaLogo } from './images/logo.svg';

type Props = {
  width?: LayoutSize,
  className?: string,
  height?: LayoutSize,
};
export default function Logo({ width, height, className }: Props): React$Node {
  return <SerpaLogo width={width} height={height} className={stylex(className)} />;
}

Logo.defaultProps = {
  width: 96,
  height: 96,
  className: '',
};
