/**
 * @generated SignedSource<<bf32ab9987b01c4d55a04e44734a0b5e>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type UITheme = "DARK" | "LIGHT" | "%future added value";
export type MicroappsDetailUIQuery$variables = {||};
export type MicroappsDetailUIQuery$data = {|
  +me: ?{|
    +id: string,
    +uiTheme: ?UITheme,
  |},
|};
export type MicroappsDetailUIQuery = {|
  variables: MicroappsDetailUIQuery$variables,
  response: MicroappsDetailUIQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uiTheme",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MicroappsDetailUIQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MicroappsDetailUIQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "fe9f963e0419eded45b6bfde45272d37",
    "id": null,
    "metadata": {},
    "name": "MicroappsDetailUIQuery",
    "operationKind": "query",
    "text": "query MicroappsDetailUIQuery {\n  me {\n    id\n    uiTheme\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "bdfcb3eced618bcaa74f4fab3644a28c";

module.exports = ((node/*: any*/)/*: Query<
  MicroappsDetailUIQuery$variables,
  MicroappsDetailUIQuery$data,
>*/);
