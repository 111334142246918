/* eslint-disable no-unused-vars */
// @flow
import { useEffect } from 'react';
import { $getRoot, $createTextNode, PASTE_COMMAND } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createCodeNode, $createCodeHighlightNode, $isCodeNode } from '@lexical/code';

type Props = {|
  +content: string,
|};

export default function DockerEditionPlugin(): React$Node {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const removeUpdateListener = editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const root = $getRoot();
        const firstChild = root.getFirstChild();

        if (firstChild && !$isCodeNode(firstChild)) {
          editor.update(() => {
            root.clear();
            const code = $createCodeNode('dockerfile');
            root.append(code);
            const highlight = $createCodeHighlightNode('');
            code.append(highlight);

            highlight.select();
          });
        }
      });
    });

    return () => removeUpdateListener();
  }, [editor]);

  return null;
}
