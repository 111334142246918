// @flow
import stylex from '@serpa-cloud/stylex';
import { Flexbox, Button, Padding, Divider, Logo, Text, Link } from '../../shared';

const styles = stylex.create({
  footerDivider: {
    height: 4,
    backgroundColor: 'var(--primary-color-1)',
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media (max-width: 480px)': {
      rowGap: 16,
      flexDirection: 'column',
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 480px)': {
      flexDirection: 'column',
    },
  },
  links: {
    display: 'flex',
    columnGap: 56,
    '@media (max-width: 980px)': {
      rowGap: 48,
      marginTop: 48,
      flexDirection: 'column',
    },
  },
});

export default function Footer(): React$Node {
  return (
    <footer>
      <Divider />
      <Padding horizontal={16} vertical={16}>
        <div className={stylex(styles.head)}>
          <Logo width={184} height={48} />
          <Text
            type="s0r"
            color="--neutral-color-600"
            id="footer.copyright"
            values={{ year: new Date().getFullYear() }}
          />
        </div>
      </Padding>
      <Divider />
      <Padding horizontal={16} vertical={40}>
        <div className={stylex(styles.content)}>
          <Flexbox flexDirection="column" rowGap={24}>
            <Text type="s1b" color="--neutral-color-700" id="footer.build" />
            <Flexbox>
              <Button
                size="hero"
                intlId="footer.early"
                onClick={() => {
                  window.location.href = '/session/signup';
                }}
              />
            </Flexbox>
          </Flexbox>
          <div className={stylex(styles.links)}>
            <Flexbox flexDirection="column" rowGap={16}>
              <Padding bottom={8}>
                <Text type="s1b" color="--neutral-color-700">
                  Serpa Cloud
                </Text>
              </Padding>

              <a href="https://es.serpa.cloud">
                <Text type="s1m" color="--primary-color-1">
                  Español - México
                </Text>
              </a>
              <a href="https://en.serpa.cloud">
                <Text type="s1m" color="--primary-color-1">
                  English - US
                </Text>
              </a>
            </Flexbox>
            <Flexbox flexDirection="column" rowGap={16}>
              <Padding bottom={8}>
                <Text type="s1b" color="--neutral-color-700" id="footer.social" />
              </Padding>

              <a
                href="https://www.linkedin.com/company/serpa-cloud/"
                target="_blank"
                rel="noreferrer"
              >
                <Text type="s1m" color="--primary-color-1">
                  LinkedIn
                </Text>
              </a>
              <a
                href="https://app.bluerabbit.co/WLlMSFDtrBJunBmrxCXu"
                target="_blank"
                rel="noreferrer"
              >
                <Text type="s1m" color="--primary-color-1" id="landing.footer.ourCommunity" />
              </a>
            </Flexbox>
            <Flexbox flexDirection="column" rowGap={16}>
              <Padding bottom={8}>
                <Text type="s1b" color="--neutral-color-700" id="footer.legal" />
              </Padding>
              <Link to="/legal/privacy">
                <Text type="s1m" color="--primary-color-1" id="footer.privacy" />
              </Link>
              <Link to="/legal/terms">
                <Text type="s1m" color="--primary-color-1" id="footer.terms" />
              </Link>
            </Flexbox>
          </div>
        </div>
      </Padding>
      <Padding bottom={0}>
        <div className={stylex(styles.footerDivider)} />
      </Padding>
    </footer>
  );
}
