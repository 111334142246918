// @flow
import { useIntl } from 'react-intl';
import { useReducer, useCallback } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';
import { useMatch, useSearchParams } from 'react-router-dom';

import DeploymentConfigurator from '../../AppCreator/DeploymentConfigurator';

import { reducer, Context, initialState } from '../../AppCreator/Provider';
import type { StateType, Dispatch, ReducerAction } from '../../AppCreator/Provider';

import { useFetchPolicy, useNotification } from '../../../shared';

export default function CompilationSettings(): React$Node {
  const intl = useIntl();
  const fetchPolicy = useFetchPolicy();
  const [searchParam] = useSearchParams();
  const [, , setNotification] = useNotification();
  const match = useMatch('/app/apps/:appId/deployments/:id/settings');

  const defaultArtifact = decodeURIComponent(searchParam.get('artifact') ?? '');

  const deploymentData = useLazyLoadQuery(
    graphql`
      query DeploymentSettingsQuery($id: ID!) {
        node(id: $id) {
          ... on Deployment {
            id
            name
            port
            source {
              ... on Artifact {
                id
              }
            }
            dockerImageUrl
            privacy
            enableCors
            patternTag
            allowOrigin
            allowHeaders
            allowMethods
            replicas
            continousDeployment
            environment {
              id
              name
              defaultEnvironment
            }
            environmentVariables {
              name
              value
            }
            app {
              id
              appMethod
              namespace
            }
            ...DeploymentElementData
          }
        }
      }
    `,
    {
      id: match?.params?.id ?? '',
    },
    {
      fetchPolicy,
    },
  );

  const data = deploymentData?.node;

  if (!data) throw new Error('Unable to get data');

  const [state, dispatch]: [StateType, Dispatch<ReducerAction>] = useReducer(reducer, {
    ...initialState,
    appId: data?.app?.id ?? '',
    method: data?.app?.appMethod ?? 'GIT',
    deploymentConfig: {
      name: data?.name ?? '',
      port: Number(data?.port ?? 80).toString(),
      privacy: data?.privacy ?? 'PRIVATE',
      patternTag: data?.patternTag ?? '/.*/',
      enableCors: data?.enableCors ?? false,
      dockerImageUrl: data?.dockerImageUrl ?? '',
      allowOrigin: [...(data?.allowOrigin ?? ['*'])],
      allowHeaders: [...(data?.allowHeaders ?? ['content-type', 'accept-type'])],
      allowMethods: [...(data?.allowMethods ?? ['OPTIONS, GET'])],
      env: [...(data?.environmentVariables ?? [])],
      environment: data?.environment?.defaultEnvironment
        ? 'DEFAULT'
        : data?.environment?.id ?? 'DEFAULT',
      continousDeployment: data?.continousDeployment ?? 'MANUAL',
      source: defaultArtifact || (data?.source?.id ?? null),
      replicas: data?.replicas ?? 1,
    },
  });

  const handleOnUpdate = useCallback(() => {
    setNotification({
      id: new Date().getTime().toString(),
      type: 'SUCCESS',
      // $FlowIgnore
      message: intl.formatMessage({ id: 'app.succesUpdate' }),
    });
  }, [intl, setNotification]);

  return (
    <Context.Provider value={{ state, dispatch }}>
      <DeploymentConfigurator
        editable
        disableEnvironment
        disableDeploymentName
        avoidConvertToPrivate
        callToActionLabel="save"
        onUpdate={handleOnUpdate}
        deploymentId={data?.id ?? ''}
      />
    </Context.Provider>
  );
}
