/**
 * @generated SignedSource<<efbdfebf626db58e1755a19faa2c4b4b>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type SubscriptionPlan = "HOBBY" | "BASIC" | "PRO" | "%future added value";
export type SubscriptionInput = {|
  name: string,
  goal: string,
  market: string,
  orgSize: string,
  plan: SubscriptionPlan,
|};
export type OnboardingSubscriptionMutation$variables = {|
  input: SubscriptionInput,
|};
export type OnboardingSubscriptionMutation$data = {|
  +subscribe: string,
|};
export type OnboardingSubscriptionMutation = {|
  variables: OnboardingSubscriptionMutation$variables,
  response: OnboardingSubscriptionMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "subscribe",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OnboardingSubscriptionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OnboardingSubscriptionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8599620916fa9f2429e22e2555298447",
    "id": null,
    "metadata": {},
    "name": "OnboardingSubscriptionMutation",
    "operationKind": "mutation",
    "text": "mutation OnboardingSubscriptionMutation(\n  $input: SubscriptionInput!\n) {\n  subscribe(input: $input)\n}\n"
  }
};
})();

(node/*: any*/).hash = "925ccdefddd8c376e3485e1476ba3dd0";

module.exports = ((node/*: any*/)/*: Mutation<
  OnboardingSubscriptionMutation$variables,
  OnboardingSubscriptionMutation$data,
>*/);
