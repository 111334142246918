// @flow
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { graphql, useFragment } from 'react-relay';

import Routes from './Routes';

import { usePageView } from '../../../shared';

import type { Networking$key } from './__generated__/Networking.graphql';

type Props = {|
  +node?: ?Networking$key,
|};

export default function Networking({ node }: Props): React$Node {
  const intl = useIntl();
  usePageView(intl.formatMessage({ id: 'pageView.ilb' }));

  const data = useFragment(
    graphql`
      fragment Networking on Environment {
        id
        routes {
          match {
            parameter
            parameterName
            matchType
            matchValue
          }
          route {
            weight
            destination {
              id
              name
            }
          }
        }
      }
    `,
    node,
  );

  const { envId } = useParams();

  if (!data) return null;

  return (
    /* $FlowIgnore */
    <Routes routes={data.routes} target={envId} type="Environment" />
  );
}

Networking.defaultProps = {
  node: null,
};
