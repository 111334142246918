// @flow
import { graphql, useLazyLoadQuery } from 'react-relay';

import Icon from './Icon';
import Avatar from './Avatar';

type Props = {
  size: number,
};

export default function SelfAvatar({ size }: Props): React$Node {
  const data = useLazyLoadQuery(
    graphql`
      query SelfAvatarQuery($size: Int) {
        me {
          media(width: $size, height: $size) {
            ...Avatar
          }
        }
      }
    `,
    { size },
    { fetchPolicy: 'store-or-network' },
  );

  return (
    <Avatar
      circle
      node={data.me?.media ?? null}
      fixedHeight={size / 2}
      fixedWidth={size / 2}
      fallbackIcon={<Icon icon="person" />}
    />
  );
}
