/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
// @flow
import { useIntl } from 'react-intl';
import stylex from '@serpa-cloud/stylex';
import { useMatch } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { ConnectionHandler } from 'relay-runtime';
import { useSpring, animated } from 'react-spring';
import { useLazyLoadQuery, useMutation, graphql } from 'react-relay';

import {
  Text,
  Card,
  Button,
  Flexbox,
  Padding,
  TapIcon,
  Spinner,
  useNotification,
} from '../../shared';

import cursorClose from '../../shared/images/cursorClose.png';

import type { DeleteDeploymentModalMutation } from './__generated__/DeleteDeploymentModalMutation.graphql';
import type { DeleteDeploymentModalPauseMutation } from './__generated__/DeleteDeploymentModalPauseMutation.graphql';

const styles = stylex.create({
  modal: {
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 99,
  },
  contentOverlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.8,
    position: 'absolute',
    backgroundColor: 'var(--neutral-color-800)',
  },
  card: {
    maxHeight: 'calc(100vh - 40px)',
    overflow: 'auto',
    width: '560px',
  },
  strong: {
    color: 'var(--primary-color-1)',
    fontWeight: 'bold',
  },
  fullWidth: {
    width: '100%',
  },
  codeBox: {
    width: '100%',
    borderRadius: 8,
    boxSizing: 'border-box',
    backgroundColor: 'var(--neutral-color-300)',
  },
});

type Props = {|
  +mode: string,
  +onClose: () => void,
  +deploymentId: string,
|};

export default function DeleteDeploymentModal({ mode, onClose, deploymentId }: Props): React$Node {
  const intl = useIntl();
  const match = useMatch('/app/:action/:id/:section');
  const [, , setNotification] = useNotification();

  const userData = useLazyLoadQuery(
    graphql`
      query DeleteDeploymentModalQuery {
        me {
          pronoun
        }
      }
    `,
    {},
    {
      fetchPolicy: 'store-or-network',
    },
  );

  const [modalSpringProps, modalSpringAPI] = useSpring(
    () => ({
      from: { opacity: 0 },
      to: { opacity: 1 },
      onRest({ value }) {
        if (value.opacity === 0) onClose();
      },
    }),
    [],
  );

  const handleClose = useCallback(() => {
    modalSpringAPI.stop();
    modalSpringAPI.start({ opacity: 0 });
  }, [modalSpringAPI]);

  const [deleteDeployment, deletePending] = useMutation<DeleteDeploymentModalMutation>(graphql`
    mutation DeleteDeploymentModalMutation($id: ID!) {
      deleteDeployment(id: $id)
    }
  `);

  const [pauseDeployment, pausePending] = useMutation<DeleteDeploymentModalPauseMutation>(graphql`
    mutation DeleteDeploymentModalPauseMutation($id: ID!) {
      pauseDeployment(id: $id) {
        id
        pause
        status {
          replicas
          readyReplicas
          updatedReplicas
          availableReplicas
          unavailableReplicas
          observedGeneration
        }
        pods {
          status {
            phase
          }
        }
      }
    }
  `);

  const handleOnDelete = useCallback(() => {
    if (mode === 'DELETE' && !deletePending)
      deleteDeployment({
        variables: {
          id: deploymentId,
        },
        updater(store) {
          const root = store.getRoot();

          const serviceRecords = ConnectionHandler.getConnection(
            root,
            'ScrolledList_root_entities',
            {
              index: 'DEPLOYMENTS',
              filterMatrix: [
                match?.params?.action
                  ? [
                      {
                        property:
                          match?.params?.action === 'apps' ? 'app.keyword' : 'environment.keyword',
                        type: 'term',
                        value: match?.params?.id ?? '',
                      },
                    ]
                  : null,
                [
                  {
                    property: 'deleted',
                    type: 'term',
                    valueBoolean: false,
                  },
                ],
              ].filter(Boolean),
            },
          );

          if (serviceRecords) ConnectionHandler.deleteNode(serviceRecords, deploymentId);
        },
        onCompleted(response) {
          if (response?.deleteDeployment) {
            setNotification({
              id: new Date().getTime().toString(),
              type: 'SUCCESS',
              message: intl.formatMessage({ id: 'deleteDeployment.success' }),
            });

            setTimeout(() => {
              handleClose();
            }, 10);
          }
        },
        onError(error) {
          // eslint-disable-next-line no-console
          console.log(error);

          setNotification({
            id: new Date().getTime().toString(),
            type: 'ERROR',
            // $FlowIgnore
            message: error.source.errors[0].message,
          });
        },
      });

    if (mode === 'PAUSE' && !deletePending)
      pauseDeployment({
        variables: {
          id: deploymentId,
        },

        onCompleted(response) {
          if (response?.pauseDeployment) {
            setNotification({
              id: new Date().getTime().toString(),
              type: 'SUCCESS',
              message: intl.formatMessage({ id: 'pauseDeployment.success' }),
            });

            setTimeout(() => {
              handleClose();
            }, 1);
          }
        },
        onError(error) {
          // eslint-disable-next-line no-console
          console.log(error);

          setNotification({
            id: new Date().getTime().toString(),
            type: 'ERROR',
            // $FlowIgnore
            message: error.source.errors[0].message,
          });
        },
      });
  }, [
    intl,
    mode,
    handleClose,
    deploymentId,
    deletePending,
    pauseDeployment,
    setNotification,
    deleteDeployment,
    match?.params?.id,
    match?.params?.action,
  ]);

  useEffect(() => {
    modalSpringAPI.start({ opacity: 1 });

    return () => {
      modalSpringAPI.stop();
    };
  });

  return (
    <animated.div style={modalSpringProps} className={stylex(styles.modal)}>
      <Flexbox className={stylex(styles.modal)} alignItems="center" justifyContent="center">
        <div
          onClick={handleClose}
          className={stylex(styles.contentOverlay)}
          style={{ cursor: `url(${cursorClose}) 12 12, auto` }}
        />
        <Card className={stylex(styles.card)}>
          <Padding horizontal={24} vertical={16}>
            <Flexbox justifyContent="space-between" alignItems="center">
              <Text
                type="h4"
                id={
                  mode === 'DELETE'
                    ? `deleteDeployment.Title.${userData.me?.pronoun ?? ''}`
                    : `pauseDeployment.Title.${userData.me?.pronoun ?? ''}`
                }
              />
              <TapIcon icon="close" onClick={handleClose} />
            </Flexbox>
            <Padding top={24}>
              <Text
                type="bd"
                id={
                  mode === 'DELETE' ? 'deleteDeployment.description' : 'pauseDeployment.description'
                }
              />

              <Padding top={40}>
                <Flexbox>
                  <Flexbox columnGap={24} alignItems="center">
                    <Button
                      intlId={mode === 'DELETE' ? 'delete' : 'pause'}
                      onClick={handleOnDelete}
                      disabled={deletePending || pausePending}
                      icon={mode === 'DELETE' ? 'delete' : 'pause'}
                      buttonType="danger"
                    />
                    {(deletePending || pausePending) && <Spinner size={24} />}
                  </Flexbox>
                </Flexbox>
              </Padding>
            </Padding>
          </Padding>
        </Card>
      </Flexbox>
    </animated.div>
  );
}
