// @flow

import { useReducer } from 'react';
import stylex from '@serpa-cloud/stylex';
import { Routes, Route, useSearchParams } from 'react-router-dom';

import { Padding } from '../../shared';

import AppName from './AppName';
import GitSelector from './GitSelector';
import AppCompilation from './AppCompilation';
import DeploymentCatalog from '../Deployments/DeploymentCatalog';

import { reducer, Context, initialState } from './Provider';
import type { StateType, Dispatch, ReducerAction } from './Provider';

const styles = stylex.create({
  container: {
    width: 760,
    paddingTop: 24,
    marginLeft: 'auto',
    marginRight: 'auto',
    '@media (max-width: 815px) and (min-width: 541px)': {
      width: '100%',
      paddingRight: 16,
      paddingLeft: 16,
      boxSizing: 'border-box',
    },
    '@media (max-width: 540px)': {
      width: '100%',
      paddingRight: 8,
      paddingLeft: 8,
      boxSizing: 'border-box',
    },
  },
});

export default function AppCreator(): React$Node {
  const [searchParams] = useSearchParams();
  const method = searchParams.get('method');

  const [state, dispatch]: [StateType, Dispatch<ReducerAction>] = useReducer(reducer, {
    ...initialState,
    // eslint-disable-next-line no-nested-ternary
    method: method ? (method === 'DOCKER' ? 'DOCKER' : 'GIT') : null,
  });

  return (
    <div>
      <Context.Provider value={{ state, dispatch }}>
        <Padding top={24} bottom={40}>
          <div className={stylex(styles.container)}>
            <Routes>
              <Route path="/catalog" element={<DeploymentCatalog />} />
              <Route path="/git" element={<GitSelector />} />
              <Route path="/name" element={<AppName />} />
              <Route path="/setup" element={<AppCompilation />} />
            </Routes>
          </div>
        </Padding>
      </Context.Provider>
    </div>
  );
}
