/**
 * @generated SignedSource<<346bd4ff2c6c2b050886c3b1d53ea101>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type BuildStatus = "UNKNOWN" | "QUEUED" | "WORKING" | "SUCCESS" | "FAILURE" | "ERROR" | "TIMEOUT" | "CANCELLED" | "%future added value";
export type BuildStatusQuery$variables = {|
  id: string,
|};
export type BuildStatusQuery$data = {|
  +node: ?{|
    +id: string,
    +createdAt?: any,
    +buildTime?: ?number,
    +done?: boolean,
    +app?: {|
      +id: string,
      +name: string,
    |},
    +status?: BuildStatus,
    +logs?: ?{|
      +id: string,
      +payloads: $ReadOnlyArray<string>,
    |},
    +artifact?: ?{|
      +id: string,
    |},
  |},
|};
export type BuildStatusQuery = {|
  variables: BuildStatusQuery$variables,
  response: BuildStatusQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buildTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "done",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "App",
      "kind": "LinkedField",
      "name": "app",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BuildLogs",
      "kind": "LinkedField",
      "name": "logs",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payloads",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Artifact",
      "kind": "LinkedField",
      "name": "artifact",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Build",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BuildStatusQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BuildStatusQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f01dd4010e3e14215ae79f1ef23ad602",
    "id": null,
    "metadata": {},
    "name": "BuildStatusQuery",
    "operationKind": "query",
    "text": "query BuildStatusQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    id\n    ... on Build {\n      createdAt\n      buildTime\n      done\n      app {\n        id\n        name\n      }\n      status\n      logs {\n        id\n        payloads\n      }\n      artifact {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "fac3a81a861771a942a624ab76990c38";

module.exports = ((node/*: any*/)/*: Query<
  BuildStatusQuery$variables,
  BuildStatusQuery$data,
>*/);
