/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
// @flow
import YAML from 'yaml';
import { forwardRef, useRef, useState, useImperativeHandle, useCallback } from 'react';

import Text from './Text';
import Margin from './Margin';
import Padding from './Padding';
import Flexbox from './Flexbox';
import Divider from './Divider';
import LiteButton from './LiteButton';
import InteractiveElement from './InteractiveElement';
import Icon from './Icon';
import useDevice from './hooks/useDevice';

import EnvironmentVariablesFields from './EnvironmentVariablesFields';
import EnvironmentVariablesEditor from './EnvironmentVariablesEditor';

type VarElement = {| +name: string, +value: string |};

export type EnvironmentVariablesFormRef = {|
  +submit: () => Array<{ index: number, data: VarElement, errors: any }>,
|};

type Props = {|
  +defaultValues?: ?$ReadOnlyArray<VarElement>,
|};

function EnvironmentVariablesFormComponent({ defaultValues }: Props, ref) {
  const { width } = useDevice();
  const fieldsRef = useRef([]);
  const [showEditor, setShowEditor] = useState(true);
  const [rows, setRows] = useState<Array<[number, VarElement]>>(
    defaultValues ? defaultValues.map((el, index) => [index, el]) : [],
  );

  useImperativeHandle(ref, () => ({
    submit() {
      if (showEditor) {
        const fields = rows.map((val) => ({
          index: val[0],
          data: {
            name: val[1].name?.toString() ?? '',
            value: YAML.parse(val[1].value)?.toString() ?? '',
          },
          errors: undefined,
        }));
        return fields;
      }
      return fieldsRef?.current?.map((el) => el?.submit?.() ?? null).filter(Boolean);
    },
  }));

  const handleOnAddVariable = useCallback(() => {
    setRows((o) => [...o, [(o?.[o.length - 1]?.[0] ?? 0) + 1, { name: '', value: '' }]]);
  }, [setRows]);

  const switchMode = useCallback(() => {
    if (!showEditor) {
      const dataInputs = fieldsRef?.current?.map((el) => el?.submit?.() ?? null).filter(Boolean);

      setRows(
        dataInputs.map((val) => [
          val.index,
          {
            name: val.data.name,
            value: YAML.stringify(val.data.value),
          },
        ]),
      );
      setShowEditor((x) => !x);
    } else {
      setRows((o) => {
        const newRow = o.map((val) => [
          val[0],
          {
            name: val[1].name?.toString() ?? '',
            value: YAML.parse(val[1].value)?.toString() ?? '',
          },
        ]);

        return newRow;
      });
      setShowEditor((x) => !x);
    }
  }, [showEditor]);

  return (
    <>
      <Padding bottom={8}>
        <Flexbox alignItems="center" justifyContent="space-between">
          <Text type="s1b" id="appCreator.environmentVariables" color="--neutral-color-800" />

          <InteractiveElement onClick={switchMode}>
            <Flexbox columnGap={8}>
              <Icon icon="360" color="--secondary-color-1" />
              {width > 480 && (
                <Text
                  id={!showEditor ? 'appCreator.swipeenv' : 'appCreator.swipeinputs'}
                  type="s0m"
                  color="--secondary-color-1"
                />
              )}
            </Flexbox>
          </InteractiveElement>
        </Flexbox>
      </Padding>
      <Divider />
      {(showEditor && <EnvironmentVariablesEditor environmentsRow={rows} setRows={setRows} />) || (
        <>
          {rows.length > 0 && (
            <Margin top={24}>
              <Flexbox flexDirection="column" rowGap={16}>
                {rows.map((row, index) => (
                  <EnvironmentVariablesFields
                    key={row[0]}
                    index={index}
                    setRows={setRows}
                    defaultName={row[1].name}
                    defaultValue={row[1].value}
                    ref={(el) => {
                      fieldsRef.current[index] = el;
                    }}
                  />
                ))}
              </Flexbox>
            </Margin>
          )}
          <Margin top={24}>
            <LiteButton
              icon="add"
              onClick={handleOnAddVariable}
              intlId="environmentVariables.add"
            />
          </Margin>
        </>
      )}
    </>
  );
}

const EnvironmentVariablesForm: React$AbstractComponent<
  Props,
  EnvironmentVariablesFormRef,
> = forwardRef(EnvironmentVariablesFormComponent);

export default EnvironmentVariablesForm;
