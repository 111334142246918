// @flow
import { useEffect } from 'react';
import stylex from '@serpa-cloud/stylex';
import { createPortal } from 'react-dom';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useTransition, animated, config } from 'react-spring';

import Card from './Card';

import useDevice from './hooks/useDevice';

type Props = {|
  +open: boolean,
  +onClose: (SyntheticMouseEvent<HTMLElement>) => void,
  +className?: ?string,
  +children: React$Node,
  +containerHeight: number,
  +anchor?: ?('LEFT' | 'RIGHT'),
  +forceInlineRender?: ?boolean,
|};

const styles = stylex.create({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'var(--neutral-gradient-traslucent)',
    zIndex: 9999998,
    backdropFilter: 'saturate(180%) blur(40px)',
  },
  containerLeft: {
    position: 'absolute',
    left: 0,
    zIndex: 9999999,
    transformOrigin: 'left top',
  },
  containerRight: {
    position: 'absolute',
    right: 0,
    zIndex: 9999999,
    transformOrigin: 'right top',
  },
  container: { position: 'fixed', left: 0, right: 0, bottom: 0, zIndex: 9999999 },
});

export default function ContextualMenu({
  open,
  anchor,
  onClose,
  children,
  className,
  containerHeight,
  forceInlineRender,
}: Props): React$Node {
  const { width } = useDevice();
  const isMobile = width <= 680;

  const transitions = useTransition(open, {
    from: { opacity: 0, y: isMobile ? 96 : 0, scale: isMobile ? 1 : 0.95 },
    enter: { opacity: 1, y: 0, scale: isMobile ? 1 : 1 },
    leave: { opacity: 0, y: isMobile ? 96 : 0, scale: isMobile ? 1 : 0.99 },
    config: config.stiff,
  });

  useEffect(() => {
    const handler = function handler(e) {
      if (onClose && open) onClose(e);
    };
    if (open) window.addEventListener('click', handler);

    return () => window.removeEventListener('click', handler);
  }, [onClose, open]);

  const userData = useLazyLoadQuery(
    graphql`
      query ContextualMenuQuery {
        me {
          id
          uiTheme
        }
      }
    `,
    {},
    {
      fetchPolicy: 'store-or-network',
    },
  );

  if (isMobile && !forceInlineRender)
    return createPortal(
      <div className={userData?.me?.uiTheme}>
        {transitions(
          (animatedStyles, item) =>
            item && (
              <>
                <animated.div
                  className={stylex(styles.overlay)}
                  style={{ ...animatedStyles, y: 0 }}
                />
                <animated.div
                  className={stylex(styles.container)}
                  style={{ ...animatedStyles }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Card
                    className={className ?? ''}
                    style={{
                      width: 'calc(100vw + 0px)',
                      transform: 'translateX(-1px)',
                      paddingBottom: 'env(safe-area-inset-bottom)',
                      paddingLeft: 'env(safe-area-inset-left)',
                      paddingRight: 'env(safe-area-inset-right)',
                    }}
                  >
                    {children}
                  </Card>
                </animated.div>
              </>
            ),
        )}
      </div>,
      document.body,
    );

  return transitions(
    (animatedStyles, item) =>
      item && (
        <animated.div
          className={stylex(anchor === 'RIGHT' ? styles.containerRight : styles.containerLeft)}
          style={{ ...animatedStyles, top: containerHeight }}
          onClick={(e) => e.stopPropagation()}
        >
          <Card className={className ?? ''}>{children}</Card>
        </animated.div>
      ),
  );
}

ContextualMenu.defaultProps = {
  className: '',
  anchor: 'LEFT',
  forceInlineRender: false,
};
