/**
 * @generated SignedSource<<7c36baa00d43e30e2e3b9ee216bdc0b7>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type LogsViewerQuery$variables = {|
  deploymentId: string,
  name: string,
|};
export type LogsViewerQuery$data = {|
  +getPodById: ?{|
    +id: string,
    +name: string,
    +status: {|
      +phase: string,
      +conditions: $ReadOnlyArray<{|
        +type: string,
        +status: boolean,
      |}>,
    |},
  |},
  +podLog: ?string,
|};
export type LogsViewerQuery = {|
  variables: LogsViewerQuery$variables,
  response: LogsViewerQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deploymentId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "deploymentId",
    "variableName": "deploymentId"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  }
],
v2 = [
  {
    "alias": null,
    "args": (v1/*: any*/),
    "concreteType": "Pod",
    "kind": "LinkedField",
    "name": "getPodById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PodStatus",
        "kind": "LinkedField",
        "name": "status",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phase",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PodStatusCondition",
            "kind": "LinkedField",
            "name": "conditions",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v1/*: any*/),
    "kind": "ScalarField",
    "name": "podLog",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LogsViewerQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LogsViewerQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "68f8604e55b0685e49109634380e3efc",
    "id": null,
    "metadata": {},
    "name": "LogsViewerQuery",
    "operationKind": "query",
    "text": "query LogsViewerQuery(\n  $deploymentId: ID!\n  $name: String!\n) {\n  getPodById(deploymentId: $deploymentId, name: $name) {\n    id\n    name\n    status {\n      phase\n      conditions {\n        type\n        status\n      }\n    }\n  }\n  podLog(deploymentId: $deploymentId, name: $name)\n}\n"
  }
};
})();

(node/*: any*/).hash = "d7438ddde3f50722ec2f142fd6bdb2f4";

module.exports = ((node/*: any*/)/*: Query<
  LogsViewerQuery$variables,
  LogsViewerQuery$data,
>*/);
