// @flow
import { useNavigate } from 'react-router-dom';
import stylex from '@serpa-cloud/stylex';
import { Suspense, lazy, useCallback, useState } from 'react';

import {
  Text,
  Card,
  Divider,
  Flexbox,
  InteractiveElement,
  LiteButton,
  Margin,
  Padding,
  SelfAvatar,
} from '../../shared';

const PostComposer = lazy(() => import(/* webpackPrefetch: true */ '../PostComposer'));

const styles = stylex.create({
  avatarContainer: {
    width: 40,
    height: 40,
    borderRadius: 20,
    background: 'var(--neutral-color-200)',
  },
  placeholderContainer: {
    height: 40,
    flex: 1,
  },
  placeholder: {
    height: 40,
    widdth: '100%',
    background: 'var(--neutral-color-200)',
    borderRadius: 24,
  },
  buttonContainer: {
    flex: 1,
  },
});

export default function Composer(): React$Node {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const onShowModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const onCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <>
      <Card>
        <Padding horizontal={12} vertical={12}>
          <Flexbox columnGap={8}>
            <Suspense fallback={<div className={styles.avatarContainer} />}>
              <div className={styles.avatarContainer}>
                <SelfAvatar size={80} />
              </div>
            </Suspense>

            <InteractiveElement
              className={stylex(styles.placeholderContainer)}
              onClick={onShowModal}
            >
              <Flexbox className={stylex(styles.placeholder)} alignItems="center">
                <Padding horizontal={16}>
                  <Text type="s1r" id="composer.placeholder" color="--neutral-color-600" />
                </Padding>
              </Flexbox>
            </InteractiveElement>
          </Flexbox>
          <Margin vertical={8}>
            <Divider />
          </Margin>
          <Flexbox columnGap={8}>
            <div className={stylex(styles.buttonContainer)}>
              <LiteButton
                intlId="composer.createApp"
                icon="cloud_upload"
                iconColor="--orange-300"
                onClick={() => navigate('/app/apps/create/catalog')}
              />
            </div>
            <div className={stylex(styles.buttonContainer)}>
              <LiteButton
                intlId="composer.createPost"
                icon="draft"
                iconColor="--blue-300"
                onClick={onShowModal}
              />
            </div>
          </Flexbox>
        </Padding>
      </Card>
      {showModal && (
        <Suspense fallback={<div />}>
          <PostComposer onClose={onCloseModal} />
        </Suspense>
      )}
    </>
  );
}
