// @flow
import stylex from '@serpa-cloud/stylex';
import { forwardRef, useImperativeHandle, useCallback } from 'react';
import { useIntl } from 'react-intl';

import Icon from './Icon';
import Flexbox from './Flexbox';
import InteractiveElement from './InteractiveElement';
import Input, { useInput, validateData } from './Input';

import useDevice from './hooks/useDevice';

export type EnvironmentVariablesFieldsRef = {|
  +submit: () => { index: number, data: {| +name: string, +value: string |}, errors: any },
|};

const styles = stylex.create({
  container: {
    position: 'relative',
    width: 'calc(100% - 40px)',
  },
  partialWidth: {
    width: 'calc(50% - 8px)',
  },
  fullWidth: {
    width: '100%',
  },
  iconContainer: {
    top: 16,
    width: 24,
    height: 24,
    right: -40,
    cursor: 'pointer',
    position: 'absolute',
  },
});

type Props = {|
  +index: number,
  +setRows: (any) => void,
  +defaultName?: ?string,
  +defaultValue?: ?string,
|};

function EnvironmentVariablesFieldsComponent(
  { index, setRows, defaultName, defaultValue }: Props,
  ref,
) {
  const { screenSize } = useDevice();
  const intl = useIntl();

  const name = useInput({
    name: 'name',
    value: defaultName ?? '',
    // eslint-disable-next-line no-useless-escape
    regexpOverwrite: /^[a-zA-Z0-9\-\_]*/,
    label: intl.formatMessage({ id: 'appCreator.variableName' }),
    required: true,
    errors: {
      requiredError: 'This field is required',
    },
  });

  const value = useInput({
    name: 'value',
    value: defaultValue ?? '',
    label: intl.formatMessage({ id: 'appCreator.variableValue' }),
    required: true,
    errors: {
      requiredError: 'This field is required',
    },
  });

  const handleOnDelete = useCallback(() => {
    setRows((o) => {
      const n = [...o];
      n.splice(index, 1);
      return n;
    });
  }, [index, setRows]);

  const handleOnSubmit = useCallback(() => {
    return validateData([name, value]);
  }, [name, value]);

  useImperativeHandle(ref, () => ({
    submit() {
      const { data, errors } = handleOnSubmit();
      return { index, data, errors };
    },
  }));

  return (
    <div className={stylex(styles.container)}>
      <Flexbox
        columnGap={16}
        rowGap={8}
        justifyContent="space-between"
        flexDirection={screenSize === 'phone' ? 'column' : 'row'}
      >
        <div className={stylex(screenSize === 'phone' ? styles.fullWidth : styles.partialWidth)}>
          <Input input={name.input} />
        </div>
        <div className={stylex(screenSize === 'phone' ? styles.fullWidth : styles.partialWidth)}>
          <Input input={value.input} />
        </div>
      </Flexbox>
      <InteractiveElement className={stylex(styles.iconContainer)} onClick={handleOnDelete}>
        <Icon icon="delete" size={24} opticalSize={24} color="--red-400" />
      </InteractiveElement>
    </div>
  );
}

const EnvironmentVariablesFields: React$AbstractComponent<
  Props,
  EnvironmentVariablesFieldsRef,
> = forwardRef(EnvironmentVariablesFieldsComponent);

EnvironmentVariablesFieldsComponent.defaultProps = {
  defaultName: '',
  defaultValue: '',
};

export default EnvironmentVariablesFields;
