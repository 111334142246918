// @flow
import stylex from '@serpa-cloud/stylex';
import { graphql, useMutation } from 'react-relay';
import * as amplitude from '@amplitude/analytics-browser';
import { Routes, Route, useMatch } from 'react-router-dom';

import { lazy, Suspense, useCallback, useEffect } from 'react';

import { Spinner, Padding, Flexbox } from '../../shared';

import Waitlist from './Waitlist';

import type { SignGithubOauthMutation } from './__generated__/SignGithubOauthMutation.graphql';

const Login = lazy(() => import('./Login'));
const Signup = lazy(() => import('./Signup'));
const ResetPassword = lazy(() => import('./ResetPassword'));
const RecoveryPassword = lazy(() => import('./RecoveryPassword'));

const styles = stylex.create({
  container: {
    minHeight: '100vh',
    position: 'relative',
    boxSizing: 'border-box',
    background: 'var(--neutral-color-200)',
  },
  homeContainer: {
    minHeight: 760,
    height: 'calc(100vh - 56px)',
    '@media (max-width: 960px)': {
      height: 'auto',
    },
  },
  fallbackContainer: {
    position: 'fixed',
    width: '100vw',
    minHeight: '100%',
  },
  blueCircle: {
    position: 'absolute',
    top: '0px',
    left: '0px',
  },
  serpaMountains: {
    position: 'absolute',
    bottom: '0px',
    right: '0px',
  },
  centerContainer: {
    position: 'relative',
  },
  preregisterContainer: {
    '@media (max-width: 760px': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  signContainer: {
    width: '100%',
    '@media (max-width: 960px)': {
      paddingTop: 60,
    },
  },
});

export default function Sign(): React$Node {
  const sessionMatch = useMatch('/session/*');

  const handleGithubLogin = useCallback(() => {
    window.location.assign(
      `https://github.com/login/oauth/authorize?client_id=${process.env
        .REACT_APP_GITHUB_API_CLIENT ??
        ''}&scope=user,user:email,repo,read:org&redirect_uri=${encodeURIComponent(
        `${window.location.origin}${window.location.pathname}?method=github`,
      )}`,
    );
  }, []);

  const [connectToGithub, pendingConnectionToGithub] = useMutation<SignGithubOauthMutation>(graphql`
    mutation SignGithubOauthMutation($code: String!) {
      githubOauth(code: $code)
    }
  `);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const method = params.get('method');

    if (code) {
      if (method === 'github')
        connectToGithub({
          variables: { code },
          onCompleted(response) {
            if (response.githubOauth) {
              if (window.location.pathname.includes('signup')) {
                amplitude.track('Sign Up');
                window.location.href = '/app/apps/create/catalog';
              } else {
                amplitude.track('Sign In');
                window.location.href = '/app';
              }
            }
          },
          onError(error) {
            // eslint-disable-next-line no-console
            console.trace(error);
          },
        });
    }
  }, [connectToGithub]);

  return (
    <Flexbox
      justifyContent="center"
      alignItems="center"
      className={stylex(styles.container, !sessionMatch ? styles.homeContainer : null)}
    >
      <Padding className={stylex(styles.signContainer)}>
        <Routes>
          <Route
            path="signup"
            element={
              <Suspense
                fallback={
                  <Flexbox className={stylex(styles.fallbackContainer)}>
                    <Spinner size={24} />
                  </Flexbox>
                }
              >
                <Signup
                  customGoogleLogin={() => {}}
                  handleGithubLogin={handleGithubLogin}
                  pendingConnectionToGithub={pendingConnectionToGithub}
                />
              </Suspense>
            }
          />
          <Route
            path="signin"
            element={
              <Suspense
                fallback={
                  <Flexbox className={stylex(styles.fallbackContainer)}>
                    <Spinner size={24} />
                  </Flexbox>
                }
              >
                <Login
                  customGoogleLogin={() => {}}
                  handleGithubLogin={handleGithubLogin}
                  pendingConnectionToGithub={pendingConnectionToGithub}
                />
              </Suspense>
            }
          />

          <Route
            path="recovery-password"
            element={
              <Suspense
                fallback={
                  <Flexbox className={stylex(styles.fallbackContainer)}>
                    <Spinner size={24} />
                  </Flexbox>
                }
              >
                <RecoveryPassword />
              </Suspense>
            }
          />

          <Route
            path="reset-password"
            element={
              <Suspense
                fallback={
                  <Flexbox className={stylex(styles.fallbackContainer)}>
                    <Spinner size={24} />
                  </Flexbox>
                }
              >
                <ResetPassword />
              </Suspense>
            }
          />

          <Route
            index
            element={
              <Suspense
                fallback={
                  <Flexbox className={stylex(styles.fallbackContainer)}>
                    <Spinner size={24} />
                  </Flexbox>
                }
              >
                <Waitlist />
              </Suspense>
            }
          />
        </Routes>
      </Padding>
    </Flexbox>
  );
}
