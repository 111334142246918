/**
 * @generated SignedSource<<35934a1090a6f15bb90bcc29dc493f44>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type CompilationSourceGitListQuery$variables = {|
  appId: string,
|};
export type CompilationSourceGitListQuery$data = {|
  +gitBranchesByApp: ?$ReadOnlyArray<{|
    +id: string,
    +name: string,
    +sha: string,
  |}>,
  +gitTagsByApp: ?$ReadOnlyArray<{|
    +id: string,
    +name: string,
    +sha: string,
  |}>,
|};
export type CompilationSourceGitListQuery = {|
  variables: CompilationSourceGitListQuery$variables,
  response: CompilationSourceGitListQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "appId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "appId",
    "variableName": "appId"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sha",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": (v1/*: any*/),
    "concreteType": "GitBranch",
    "kind": "LinkedField",
    "name": "gitBranchesByApp",
    "plural": true,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v1/*: any*/),
    "concreteType": "GitTag",
    "kind": "LinkedField",
    "name": "gitTagsByApp",
    "plural": true,
    "selections": (v2/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompilationSourceGitListQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompilationSourceGitListQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "b744a0a29409aab591335fdbd0c3440e",
    "id": null,
    "metadata": {},
    "name": "CompilationSourceGitListQuery",
    "operationKind": "query",
    "text": "query CompilationSourceGitListQuery(\n  $appId: String!\n) {\n  gitBranchesByApp(appId: $appId) {\n    id\n    name\n    sha\n  }\n  gitTagsByApp(appId: $appId) {\n    id\n    name\n    sha\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "32f0ef615f854ad03989a43583e06262";

module.exports = ((node/*: any*/)/*: Query<
  CompilationSourceGitListQuery$variables,
  CompilationSourceGitListQuery$data,
>*/);
