/**
 * @generated SignedSource<<9f4dff8410e680ad663a728b35caa83d>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type DeploymentElementStatusQuery$variables = {|
  id: string,
|};
export type DeploymentElementStatusQuery$data = {|
  +node: ?{|
    +id: string,
    +status?: ?{|
      +replicas: number,
      +readyReplicas: number,
      +updatedReplicas: number,
      +availableReplicas: number,
      +unavailableReplicas: number,
      +observedGeneration: number,
    |},
    +pods?: $ReadOnlyArray<{|
      +status: {|
        +phase: string,
      |},
    |}>,
  |},
|};
export type DeploymentElementStatusQuery = {|
  variables: DeploymentElementStatusQuery$variables,
  response: DeploymentElementStatusQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DeploymentStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "replicas",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "readyReplicas",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updatedReplicas",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "availableReplicas",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unavailableReplicas",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "observedGeneration",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Pod",
      "kind": "LinkedField",
      "name": "pods",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PodStatus",
          "kind": "LinkedField",
          "name": "status",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "phase",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Deployment",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeploymentElementStatusQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeploymentElementStatusQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "84e8b51c340df9c266d077066e7effca",
    "id": null,
    "metadata": {},
    "name": "DeploymentElementStatusQuery",
    "operationKind": "query",
    "text": "query DeploymentElementStatusQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    id\n    ... on Deployment {\n      id\n      status {\n        replicas\n        readyReplicas\n        updatedReplicas\n        availableReplicas\n        unavailableReplicas\n        observedGeneration\n      }\n      pods {\n        status {\n          phase\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "655068cfc88561abaeba5223b389b66a";

module.exports = ((node/*: any*/)/*: Query<
  DeploymentElementStatusQuery$variables,
  DeploymentElementStatusQuery$data,
>*/);
