// @flow
import { useIntl } from 'react-intl';
import { useMatch } from 'react-router-dom';
import { useReducer, useCallback } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';

import AppCompilation from '../../AppCreator/AppCompilation';

import { reducer, Context, initialState } from '../../AppCreator/Provider';
import type { StateType, Dispatch, ReducerAction } from '../../AppCreator/Provider';

import { useFetchPolicy, useNotification } from '../../../shared';

export default function CompilationSettings(): React$Node {
  const intl = useIntl();
  const fetchPolicy = useFetchPolicy();
  const [, , setNotification] = useNotification();
  const match = useMatch('/app/apps/:id/settings');

  const appData = useLazyLoadQuery(
    graphql`
      query CompilationSettingsQuery($id: ID!) {
        node(id: $id) {
          ... on App {
            id
            builder
            appType
            dockerfileContent
            runtime
            preInstallCommand
            buildCommand
            buildPath
            nodeVersion
            environmentVariables {
              name
              value
            }
          }
        }
      }
    `,
    {
      id: match?.params?.id ?? '',
    },
    {
      fetchPolicy,
    },
  );

  const data = appData?.node;

  if (!data) throw new Error('Unable to get data');

  const [state, dispatch]: [StateType, Dispatch<ReducerAction>] = useReducer(reducer, {
    ...initialState,
    appId: data.id,
    compilationConfig: {
      builder: data?.builder ?? 'DOCKER',
      runtime: 'CONTAINER',
      dockerfile: data.dockerfileContent ?? '',
      env: [...(data?.environmentVariables ?? [])],
    },
  });

  const handleOnUpdate = useCallback(() => {
    setNotification({
      id: new Date().getTime().toString(),
      type: 'SUCCESS',
      // $FlowIgnore
      message: intl.formatMessage({ id: 'app.succesUpdate' }),
    });
  }, [intl, setNotification]);

  return (
    <Context.Provider value={{ state, dispatch }}>
      <AppCompilation callToActionLabel="save" onUpdate={handleOnUpdate} />
    </Context.Provider>
  );
}
