// @flow
import { useMemo } from 'react';
import stylex from '@serpa-cloud/stylex';
import { useNavigate } from 'react-router-dom';
import { graphql, useFragment } from 'react-relay';

import useDevice from '../../../shared/hooks/useDevice';

import type { Registers$key } from './__generated__/Registers.graphql';

import Register from './Register';
import DomainForm from './DomainForm';
import DomainElement from './DomainElement';

import { Margin, Flexbox, Padding, Button } from '../../../shared';

type Props = {|
  +node?: ?Registers$key,
|};

const ipExpression = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;

const styles = stylex.create({
  component: {
    minHeight: 'calc(100vh - 155px)',
  },
  container: {
    width: '100%',
    position: 'relative',
    '@media (max-width: 1280px)': {
      boxSizing: 'border-box',
    },
  },

  aside: {
    backgroundColor: 'var(--neutral-color-200)',
  },
  descriptorContainer: {
    top: 91,
    position: 'sticky',
  },
});

export default function Registers({ node }: Props): React$Node {
  const { width } = useDevice();
  const navigate = useNavigate();

  const data = useFragment(
    graphql`
      fragment Registers on Environment {
        id
        kubernetesCluster {
          id
          managedResource
          ingress {
            id
            ingressHostName
          }
        }
        domainsMapping {
          id
          ...Register
        }
        domainsRecords {
          id
          ...DomainElement
        }
      }
    `,
    node,
  );

  const isManagedResource = !data?.kubernetesCluster?.managedResource;
  const ingresssHostName = data?.kubernetesCluster?.ingress?.ingressHostName ?? '';

  const ingressRecordType = useMemo(() => {
    const ingressIsIP = ipExpression.test(ingresssHostName);
    return ingressIsIP ? 'A' : 'CNAME';
  }, [ingresssHostName]);

  if (!data) return null;

  const domains = data.domainsRecords;
  const mappings = data.domainsMapping;

  return (
    <div className={stylex(styles.component)}>
      <Flexbox justifyContent="center">
        <Padding bottom={40} className={stylex(styles.container)}>
          {!isManagedResource && (
            <Flexbox flexDirection={width <= 1024 ? 'column' : 'row'}>
              <Button intlId="dns.addDomain" onClick={() => navigate('./create')} />
            </Flexbox>
          )}

          {isManagedResource && <DomainForm />}

          <Margin top={24}>
            <Flexbox flexDirection="column" rowGap={24}>
              {domains?.map((el) => (
                <DomainElement key={el.id} node={el} />
              ))}

              {mappings?.map((el, index) => (
                <Register
                  key={el.id}
                  node={el}
                  ingressHost={ingresssHostName}
                  defaultDomain={index === 0}
                  ingressRecordType={ingressRecordType}
                />
              ))}
            </Flexbox>
          </Margin>
        </Padding>
      </Flexbox>
    </div>
  );
}

Registers.defaultProps = {
  node: null,
};
