// @flow
import { graphql, useLazyLoadQuery } from 'react-relay';

import { Text, Input, CascaderOption } from '../../shared';
import type { inputRef } from '../../shared/Input';

type Props = {|
  +input: inputRef,
|};

export default function DeploymentAppList({ input }: Props): React$Node {
  const data = useLazyLoadQuery(
    graphql`
      query DeploymentAppListQuery($filterMatrix: [[FilterInput]]) {
        entities(first: 99, index: APPS, filterMatrix: $filterMatrix) {
          pageInfo {
            hasNextPage
            endCursor
            finalCursor
          }
          edges {
            id
            cursor
            node {
              __typename
              ... on App {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      filterMatrix: [
        [
          {
            property: 'deleted',
            type: 'term',
            valueBoolean: false,
          },
        ],
      ],
    },
    { fetchPolicy: 'network-only', networkCacheConfig: { force: false } },
  );

  const edges = data?.entities?.edges;

  return (
    <Input input={input}>
      {edges?.map((edge) =>
        // eslint-disable-next-line no-underscore-dangle
        edge?.node?.__typename === 'App' ? (
          <CascaderOption
            key={edge.id}
            value={edge.node.id}
            label={
              <Text type="s0m" color="--neutral-color-800">
                {edge.node.name}
              </Text>
            }
          />
        ) : null,
      ) ?? []}
    </Input>
  );
}
