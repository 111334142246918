/**
 * @generated SignedSource<<bd0cc38fa0551b3f5d90c6ba070cf4b4>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteAppModalMutation$variables = {|
  id: string,
|};
export type DeleteAppModalMutation$data = {|
  +deleteApp: boolean,
|};
export type DeleteAppModalMutation = {|
  variables: DeleteAppModalMutation$variables,
  response: DeleteAppModalMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ScalarField",
    "name": "deleteApp",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteAppModalMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteAppModalMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "349d74389955b489f56f32179c7f6468",
    "id": null,
    "metadata": {},
    "name": "DeleteAppModalMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteAppModalMutation(\n  $id: ID!\n) {\n  deleteApp(id: $id)\n}\n"
  }
};
})();

(node/*: any*/).hash = "3bc8e8de3cab95dc006dcff8b631a38a";

module.exports = ((node/*: any*/)/*: Mutation<
  DeleteAppModalMutation$variables,
  DeleteAppModalMutation$data,
>*/);
