/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// @flow
import { useIntl } from 'react-intl';
import stylex from '@serpa-cloud/stylex';
import { useCallback, Suspense } from 'react';
import { ConnectionHandler } from 'relay-runtime';
import { graphql, useMutation } from 'react-relay';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ClusterSelector from './ClusterSelector';
import { ReactComponent as Logo } from './sierra-negra.svg';

import {
  Text,
  Input,
  Margin,
  Button,
  Spinner,
  Flexbox,
  Padding,
  Breadcrumb,
  SectionTitle,
  CascaderOption,
  useInput,
  usePageView,
  validateData,
  useNotification,
} from '../../../shared';

import type { FormEnvironmentMutation } from './__generated__/FormEnvironmentMutation.graphql';

const styles = stylex.create({
  title: {
    flex: 1,
  },
  inputContainer: {
    flex: 1,
  },
  form: {
    maxWidth: 800,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 24,
    boxSizing: 'border-box',
    paddingTop: 'var(--content-top-padding)',
  },
});

export default function EnvironmentForm(): React$Node {
  const intl = useIntl();
  usePageView(intl.formatMessage({ id: 'pageView.createEnvironment' }));

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const successRedirect = searchParams.get('success_redirect');

  const [, , setNotification] = useNotification();

  const name = useInput({
    name: 'name',
    required: true,
    toLowerCase: true,
    // eslint-disable-next-line no-useless-escape
    regexpOverwrite: /^[a-z\-]*/,
    label: intl.formatMessage({ id: 'environments.formNameLabel' }),
    value: '',
    errors: {
      requiredError: intl.formatMessage({ id: 'input.requiredError' }),
    },
  });

  const kubernetesResource = useInput({
    name: 'kubernetesResource',
    required: true,
    label: intl.formatMessage({ id: 'environments.formClusterLabel' }),
    value: 'DEFAULT',
    autoCloseSelect: true,
    errors: {
      requiredError: intl.formatMessage({ id: 'input.requiredError' }),
    },
  });

  const fakeCluster = useInput({
    name: 'fakeCluster',
    required: true,
    label: intl.formatMessage({ id: 'environments.formClusterLabel' }),
    value: 'DEFAULT',
    autoCloseSelect: true,
    disabled: true,
    errors: {
      requiredError: intl.formatMessage({ id: 'input.requiredError' }),
    },
  });

  const [createEnvironment, commitPending] = useMutation<FormEnvironmentMutation>(graphql`
    mutation FormEnvironmentMutation($input: EnvironmentInput!) {
      createEnvironment(input: $input) {
        id
        ...EnvironmentElementDetail
      }
    }
  `);

  const handleOnSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (!commitPending) {
        const { data, errors } = validateData<{
          name: string,
          kubernetesResource: string,
        }>([name, kubernetesResource]);

        if (!errors) {
          createEnvironment({
            variables: {
              input: {
                ...data,
              },
            },
            updater(store) {
              const root = store.getRoot();

              const edges = ConnectionHandler.getConnection(root, 'ScrolledList_root_entities', {
                index: 'ENVIRONMENTS',
              });

              const payload = store.getRootField('createEnvironment');

              if (!edges || !payload) return;

              const newEdge = ConnectionHandler.createEdge(store, edges, payload, 'Edge');

              ConnectionHandler.insertEdgeBefore(edges, newEdge);
            },
            onCompleted(response) {
              if (response.createEnvironment) {
                setNotification({
                  id: response.createEnvironment.id,
                  type: 'SUCCESS',
                  message: intl.formatMessage({ id: 'environments.formSuccessNotification' }),
                });

                setTimeout(() => {
                  if (successRedirect) navigate(successRedirect.toString(), { replace: true });
                  else navigate(-1);
                }, 1);
              }
            },
            onError(error) {
              // eslint-disable-next-line no-console
              console.log(error);

              setNotification({
                id: `Environment${new Date().getTime()}`,
                type: 'ERROR',
                // $FlowIgnore
                message: intl.formatMessage({ id: error.source.errors[0].message }),
              });
            },
          });
        }
      }
    },
    [
      intl,
      name,
      navigate,
      commitPending,
      successRedirect,
      setNotification,
      createEnvironment,
      kubernetesResource,
    ],
  );

  return (
    <>
      <Breadcrumb
        sections={[
          { link: '/app/environments', textId: 'home.environment', icon: 'dynamic_form' },
          {
            link: '/app/environments/create',
            textId: 'emptyState.environmentsTextButton',
            icon: 'dynamic_form',
          },
        ]}
      />
      <form className={stylex(styles.form)} onSubmit={handleOnSubmit} autoComplete="nope">
        <input type="submit" style={{ display: 'none' }} />

        <SectionTitle title="environments.formTitle" />

        <Padding top={16}>
          <Margin bottom={24}>
            <Text id="environments.formDescription" type="bd" />
          </Margin>
          <Flexbox flexDirection="column" rowGap={16}>
            <Flexbox columnGap={16}>
              <div className={stylex(styles.inputContainer)}>
                <Suspense
                  fallback={
                    <Input input={fakeCluster.input}>
                      <CascaderOption
                        value="DEFAULT"
                        placeholderLabel={
                          <Flexbox alignItems="center" columnGap={8}>
                            <div className={stylex(styles.providerContainer)}>
                              <Logo width={24} height={24} />
                            </div>
                            <Text type="s0m" color="--neutral-color-800" id="sierraNegraManaged" />
                          </Flexbox>
                        }
                        label={
                          <Flexbox alignItems="center" columnGap={8}>
                            <div className={stylex(styles.providerContainer)}>
                              <Logo width={24} height={24} />
                            </div>
                            <Text type="s0m" color="--neutral-color-800" id="sierraNegraManaged" />
                          </Flexbox>
                        }
                      />
                    </Input>
                  }
                >
                  <ClusterSelector input={kubernetesResource.input} />
                </Suspense>
              </div>
            </Flexbox>

            <Input input={name.input} />
          </Flexbox>
        </Padding>

        <Padding top={32} bottom={24}>
          <Flexbox columnGap={24} alignItems="center">
            <Button intlId="create" onClick={handleOnSubmit} disabled={commitPending} />
            {commitPending && <Spinner size={24} />}
          </Flexbox>
        </Padding>
      </form>
    </>
  );
}
