/**
 * @generated SignedSource<<663f5b51a2ebdad5285d35f64a9ba2cd>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
type Avatar$fragmentType = any;
export type AppMethod = "GIT" | "DOCKER" | "%future added value";
export type AppType = "CONTAINER" | "STATIC" | "%future added value";
export type ContainerBuilder = "DOCKER" | "BUILDPACKS" | "KANIKO" | "%future added value";
export type EventTypeTrigger = "BRANCH" | "TAG" | "%future added value";
export type GitProvider = "GITHUB" | "GITLAB" | "%future added value";
export type StaticRuntime = "NODEJS" | "%future added value";
export type AppInput = {|
  name?: ?string,
  media?: ?string,
  source?: ?string,
  gitProvider?: ?GitProvider,
  useDockerfileTemplate?: ?boolean,
  dockerfile?: ?string,
  dockerfileContent?: ?string,
  continousIntegrationEnabled?: ?boolean,
  eventTypeTrigger?: ?EventTypeTrigger,
  eventNameTrigger?: ?string,
  env?: ?$ReadOnlyArray<EnvInput>,
  runtime?: ?StaticRuntime,
  buildCommand?: ?string,
  preInstallCommand?: ?string,
  appType?: ?AppType,
  appMethod: AppMethod,
  buildPath?: ?string,
  builder?: ?ContainerBuilder,
|};
export type EnvInput = {|
  name: string,
  value: string,
|};
export type GitSelectorCreateAppMutation$variables = {|
  input: AppInput,
  triggerCompilation?: ?boolean,
|};
export type GitSelectorCreateAppMutation$data = {|
  +createApp: {|
    +id: string,
    +namespace: string,
    +name: string,
    +createdAtFormatted: string,
    +lastCompilation: ?string,
    +media: ?{|
      +$fragmentSpreads: Avatar$fragmentType,
    |},
    +createdBy: {|
      +fullname: ?string,
    |},
  |},
|};
export type GitSelectorCreateAppMutation = {|
  variables: GitSelectorCreateAppMutation$variables,
  response: GitSelectorCreateAppMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "triggerCompilation"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  },
  {
    "kind": "Variable",
    "name": "triggerCompilation",
    "variableName": "triggerCompilation"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "namespace",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAtFormatted",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastCompilation",
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "height",
    "value": 80
  },
  {
    "kind": "Literal",
    "name": "width",
    "value": 80
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullname",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GitSelectorCreateAppMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "App",
        "kind": "LinkedField",
        "name": "createApp",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "media",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Avatar"
              }
            ],
            "storageKey": "media(height:80,width:80)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": [
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GitSelectorCreateAppMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "App",
        "kind": "LinkedField",
        "name": "createApp",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "media",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "width",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "height",
                "storageKey": null
              }
            ],
            "storageKey": "media(height:80,width:80)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bc46b67c10752ef5b3cd116ff73f440b",
    "id": null,
    "metadata": {},
    "name": "GitSelectorCreateAppMutation",
    "operationKind": "mutation",
    "text": "mutation GitSelectorCreateAppMutation(\n  $input: AppInput!\n  $triggerCompilation: Boolean\n) {\n  createApp(input: $input, triggerCompilation: $triggerCompilation) {\n    id\n    namespace\n    name\n    createdAtFormatted\n    lastCompilation\n    media(width: 80, height: 80) {\n      ...Avatar\n      id\n    }\n    createdBy {\n      fullname\n      id\n    }\n  }\n}\n\nfragment Avatar on Image {\n  id\n  url\n  alt\n  width\n  height\n}\n"
  }
};
})();

(node/*: any*/).hash = "41e4c626b9cb29a51510a112076da9c4";

module.exports = ((node/*: any*/)/*: Mutation<
  GitSelectorCreateAppMutation$variables,
  GitSelectorCreateAppMutation$data,
>*/);
