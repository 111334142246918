// @flow
import stylex from '@serpa-cloud/stylex';
import { Suspense, useCallback } from 'react';

import { Logo, Flexbox, Card, Margin, Text, Button, SuspensableImage } from '../../../shared';

import overviewImageURL from './overview.png';

const styles = stylex.create({
  root: {
    position: 'relative',
    paddingLeft: 24,
    paddingRight: 24,
  },
  container: {
    position: 'relative',
  },
  logo: {
    width: 320,
    height: 80,
    '@media (max-width: 960px) and (min-width: 480px)': {
      width: 280,
      maxWidth: '60%',
      height: 56,
    },
    '@media (max-width: 480px)': {
      width: 296,
      height: 60,
    },
  },
  contentContainer: {
    maxWidth: 1200,
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
  },
  blueCircle: {
    position: 'absolute',
    top: -1,
    left: -1,
  },
  bulletContainer: {
    width: 480,
    maxWidth: 'calc(100vw - 48px)',
  },
  cardContainer: {
    width: 'calc(40vw - 104px)',
    position: 'absolute',
    right: -60,
    top: 'calc(((max(calc(40vw - 104px), 660px) / 1.5) - 640px) / -2)',
    '@media (max-width: 960px)': {
      width: 'calc(100vw - 200px)',
      position: 'relative',
      right: 0,
    },
  },
  cardContainerOverflow: {
    minWidth: 660,
    '@media (max-width: 960px)': {
      minWidth: 440,
    },
  },
  card: {
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'hidden',
    aspectRatio: '1.5',
  },
  imageFallback: {
    width: '100%',
    aspectRatio: '1.5',
    backgroundColor: 'var(--neutral-color-200)',
  },
  image: {
    width: '100%',
    aspectRatio: '1.5',
  },
  fullWidth: {
    width: '100%',
  },
  copyContainer: {
    width: 480,
  },
});

export default function Waitlist(): React$Node {
  const onTypeform = useCallback(() => {
    window.location.href = '/session/signup';
  }, []);

  return (
    <div className={stylex(styles.root)}>
      <div className={stylex(styles.contentContainer)}>
        <Flexbox
          justifyContent="space-between"
          alignItems="center"
          className={stylex(styles.container)}
        >
          <div className={stylex(styles.copyContainer)}>
            <a href="/" target="_blank">
              <Logo className={styles.logo} />
            </a>

            <Margin top={56} bottom={48}>
              <Flexbox alignItems="center" columnGap={24}>
                <div>
                  <Text type="d3" component="h1" color="--neutral-color-800" id="sign.oneLiner" />
                </div>
              </Flexbox>
            </Margin>

            <Flexbox flexDirection="column" rowGap={32}>
              <Flexbox
                alignItems="center"
                columnGap={24}
                className={stylex(styles.bulletContainer)}
              >
                <Text type="d3">🏗️</Text>
                <div>
                  <Text color="--neutral-color-600" type="bd" id="sign.infraestructure" />
                </div>
              </Flexbox>

              <Flexbox
                alignItems="center"
                columnGap={24}
                className={stylex(styles.bulletContainer)}
              >
                <Text type="d3">🤖</Text>
                <div>
                  <Text
                    color="--neutral-color-600"
                    type="bd"
                    id="sign.deploymentText"
                    values={{ strong: (str) => <strong>{str}</strong> }}
                  />
                </div>
              </Flexbox>

              <Flexbox
                alignItems="center"
                columnGap={24}
                className={stylex(styles.bulletContainer)}
              >
                <Text type="d3">💵</Text>
                <div>
                  <Text color="--neutral-color-600" type="bd" id="sign.moneyText" />
                </div>
              </Flexbox>
            </Flexbox>

            <Margin top={72}>
              <Flexbox>
                <Button intlId="joinWaitlist" size="hero" onClick={onTypeform} />
              </Flexbox>
            </Margin>
          </div>
        </Flexbox>
      </div>
      <div className={stylex(styles.cardContainer)}>
        <div className={stylex(styles.cardContainerOverflow)}>
          <Card className={stylex(styles.card)}>
            <Suspense fallback={<div className={stylex(styles.imageFallback)} />}>
              <SuspensableImage
                src={overviewImageURL}
                alt="Serpa Cloud Dashboard"
                className={stylex(styles.image)}
              />
            </Suspense>
          </Card>
        </div>
      </div>
    </div>
  );
}
