// @flow
import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';
import {
  Text,
  Card,
  Button,
  Margin,
  Flexbox,
  Padding,
  useInterval,
  useDevice,
} from '../../../shared'; // Componente interno de UI

function calculateTimeLeft(discountAvailableUntil) {
  if (!discountAvailableUntil) return { minutes: '00', seconds: '00', totalTimeLeftInSeconds: 0 };

  const currentDate = new Date();
  const discountEndDate = new Date(discountAvailableUntil);
  const timeDifference = discountEndDate - currentDate;

  const totalTimeLeftInSeconds = Math.floor(timeDifference / 1000); // Total time in seconds
  const minutes = Math.floor(totalTimeLeftInSeconds / 60);
  const seconds = totalTimeLeftInSeconds % 60;

  // Asegurar que los minutos y segundos tengan dos dígitos
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  return { minutes: formattedMinutes, seconds: formattedSeconds, totalTimeLeftInSeconds };
}

function calculateDaysLeft(freeTrialUntil) {
  if (!freeTrialUntil) return 0;

  const currentDate = new Date();
  const trialEndDate = new Date(freeTrialUntil);
  const timeDifference = trialEndDate - currentDate;

  // Convert the difference from milliseconds to days and ensure it is an integer
  const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return daysLeft;
}

export default function DiscountAvailableUntil(): React$Node {
  const { width } = useDevice();
  const navigate = useNavigate(); // Para manejar la navegación

  const data = useLazyLoadQuery(
    graphql`
      query DiscountAvailableUntilQuery {
        getCurrentNameSpace {
          id
          freeTrialUntil
          discountAvailableUntil
        }
        paymentProfile {
          id
        }
      }
    `,
    {},
  );

  // Extraer la información relevante
  const { getCurrentNameSpace, paymentProfile } = data;
  const { discountAvailableUntil } = getCurrentNameSpace ?? {};
  const freeTrialUntil = data.getCurrentNameSpace?.freeTrialUntil;
  const daysLeft = useMemo(() => calculateDaysLeft(freeTrialUntil), [freeTrialUntil]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(discountAvailableUntil));

  // Hook de intervalo para actualizar el tiempo restante cada minuto
  useInterval(() => {
    const updatedTimeLeft = calculateTimeLeft(discountAvailableUntil);
    setTimeLeft(updatedTimeLeft);
  }, 1000);

  // Si no hay perfil de pago, y hay un descuento disponible
  if (!paymentProfile && discountAvailableUntil) {
    const currentDate = new Date();
    const discountDate = new Date(discountAvailableUntil);

    // Verificar si la fecha de descuento es en el futuro
    if (currentDate < discountDate) {
      // Cambiar el color si faltan 5 minutos o menos (300 segundos)
      const timerColor = timeLeft.totalTimeLeftInSeconds <= 300 ? '--red-300' : '--green-300';

      return (
        <Margin top={24}>
          <Card>
            <Padding vertical={16} horizontal={16}>
              <Flexbox
                alignItems={width > 640 ? 'center' : 'flex-start'}
                justifyContent="space-between"
                rowGap={16}
                flexDirection={width > 640 ? 'row' : 'column'}
              >
                <Flexbox flexDirection="column" rowGap={12}>
                  <Text type="s0b" id="discountMessage" color="--neutral-color-800" />
                  <Text
                    type="s1r"
                    id="discountTimer"
                    values={{
                      seconds: timeLeft.seconds,
                      minutes: timeLeft.minutes,
                      b: (str) => <strong style={{ color: `var(${timerColor})` }}>{str}</strong>,
                    }}
                    color="--neutral-color-600"
                  />
                </Flexbox>
                <Button
                  buttonType="primary"
                  intlId="callToActionDisscount"
                  onClick={() => navigate('/app/subscription/billing')}
                />
              </Flexbox>
            </Padding>
          </Card>
        </Margin>
      );
    }
  }

  // Si no hay descuento disponible
  if (!paymentProfile)
    return (
      <Margin top={24}>
        <Card>
          <Padding vertical={16} horizontal={16}>
            <Flexbox
              alignItems={width > 800 ? 'center' : 'flex-start'}
              justifyContent="space-between"
              rowGap={16}
              flexDirection={width > 800 ? 'row' : 'column'}
            >
              <Text
                type="s0b"
                id="freeTrialTimer"
                values={{ daysLeft }}
                color="--neutral-color-800"
              />
              <Button
                buttonType="primary"
                intlId="subscribeNow"
                onClick={() => navigate('/app/subscription/billing')}
              />
            </Flexbox>
          </Padding>
        </Card>
      </Margin>
    );

  return null;
}
