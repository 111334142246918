// @flow
import { useTransition } from 'react-spring';
import { useContext, useCallback } from 'react';

import { AppContext } from '../AppProvider';
import type { Notification } from '../AppProvider';

const config = { tension: 125, friction: 20, precision: 0.1 };

export default function useNotification(): [
  Notification[],
  any,
  (Notification) => void,
  (string) => void,
] {
  const { state, dispatch } = useContext(AppContext);
  const { notifications } = state;

  const setNotification = useCallback(
    (newNotification: Notification) => {
      dispatch({
        type: 'SET_NOTIFICATION',
        notification: newNotification,
      });
    },
    [dispatch],
  );

  const disposeNotification = useCallback(
    (id: string) => {
      dispatch({ type: 'REMOVE_NOTIFICATION', notificationId: id });
    },
    [dispatch],
  );

  const transitions = useTransition(notifications, {
    from: { opacity: -10, height: 0, y: -60, life: 100, marginBottom: 0 },
    keys: (item: Notification) => item.id,
    enter: () => async (next) => {
      await next({ opacity: 1, height: 60, y: 0, marginBottom: 16 });
      await next({ life: 0 });
    },
    leave: (_, index) => {
      if (index === notifications.length - 1) return { opacity: 0, height: 0, y: -40 };
      return { opacity: 0, height: 0, y: -60, marginBottom: 0 };
    },
    onRest: (result, _, item) => {
      if (result.finished) disposeNotification(item.id);
    },
    config: (item, index, phase) => (key) =>
      phase === 'enter' && key === 'life' ? { duration: 10000 } : config,
  });

  return [notifications, transitions, setNotification, disposeNotification];
}
