/**
 * @generated SignedSource<<a2c088f5294db27cf6c4eff054819251>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
export type HTTPMatchRequestMatchType = "EXACT" | "PREFIX" | "REGEX" | "%future added value";
export type HTTPMatchRequestParameter = "URI" | "SCHEME" | "METHOD" | "AUTHORITY" | "HEADERS" | "QUERY_PARAMS" | "%future added value";
import type { FragmentType } from "relay-runtime";
declare export opaque type Networking$fragmentType: FragmentType;
export type Networking$data = {|
  +id: string,
  +routes: $ReadOnlyArray<{|
    +match: $ReadOnlyArray<{|
      +parameter: HTTPMatchRequestParameter,
      +parameterName: ?string,
      +matchType: HTTPMatchRequestMatchType,
      +matchValue: string,
    |}>,
    +route: $ReadOnlyArray<{|
      +weight: number,
      +destination: ?{|
        +id: string,
        +name: string,
      |},
    |}>,
  |}>,
  +$fragmentType: Networking$fragmentType,
|};
export type Networking$key = {
  +$data?: Networking$data,
  +$fragmentSpreads: Networking$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Networking",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "HTTPRoute",
      "kind": "LinkedField",
      "name": "routes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "HTTPMatchRequest",
          "kind": "LinkedField",
          "name": "match",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "parameter",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "parameterName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "matchType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "matchValue",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HTTPRouteDestination",
          "kind": "LinkedField",
          "name": "route",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "weight",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Deployment",
              "kind": "LinkedField",
              "name": "destination",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Environment",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "1ee0fd42e37b3b972324f949e8d9e231";

module.exports = ((node/*: any*/)/*: Fragment<
  Networking$fragmentType,
  Networking$data,
>*/);
