// @flow
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Text from './Text';
import Flexbox from './Flexbox';
import LiteButton from './LiteButton';

import useDevice from './hooks/useDevice';

type Props = {|
  +to?: ?string,
  +title: string,
  +text?: ?string,
  +linkText?: ?string,
  +linkHref?: ?string,
|};

export default function SectionTitle({ to, title, text, linkText, linkHref }: Props): React$Node {
  const { screenSize, width } = useDevice();
  const navigate = useNavigate();
  const onClick = useCallback(() => {
    if (to) window.open(to);
    if (linkHref) navigate(linkHref ?? '');
  }, [to, navigate, linkHref]);

  return (
    <Flexbox
      justifyContent="space-between"
      flexDirection={screenSize === 'phone' ? 'column' : 'row'}
      columnGap={16}
      rowGap={24}
    >
      <Flexbox flexDirection="column" rowGap={16}>
        <Text type={width <= 1000 ? 'h5' : 'h4'} id={title} />
        {text ? <Text type={width <= 1000 ? 'bs' : 'bd'} id={text} /> : null}
      </Flexbox>
      {linkText ? <LiteButton icon="add" intlId={linkText} onClick={onClick} showShadow /> : null}
    </Flexbox>
  );
}

SectionTitle.defaultProps = {
  to: '',
  text: '',
  linkText: '',
  linkHref: '',
};
