// @flow
import swap from 'lodash-move';
import clamp from 'lodash.clamp';
import stylex from '@serpa-cloud/stylex';
import { useDrag } from 'react-use-gesture';
import { useSprings, animated } from 'react-spring';
import { useCallback, useRef, forwardRef, useImperativeHandle, useState, useEffect } from 'react';

import HTTPRoute from './HTTPRoute';
import type { HTTPRoute$data } from './HTTPRoute';

import useDevice from '../../../shared/hooks/useDevice';

type DraggableListProps = {
  target: string,
  items: Array<HTTPRoute$data>,
  onChangeOrder: (((Array<number>) => Array<number>) | Array<number>) => void,
  setRoutes: (((Array<HTTPRoute$data>) => Array<HTTPRoute$data>) | Array<HTTPRoute$data>) => void,
};

type DraggableListRef = {|
  +addRoute: (position: string) => void,
  +reOrder: () => void,
|};

// const itemHeight = 112 + 24;

const styles = stylex.create({
  listItem: {
    width: '100%',
    position: 'absolute',
    transformOrigin: '50% 50% 0px',
    touchAction: 'none',
    boxSizing: 'border-box',
    height: 112,
    '@media (max-width: 815px)': {
      height: 184,
    },
  },
});

const DraggableList: React$AbstractComponent<DraggableListProps, DraggableListRef> = forwardRef(
  function DraggableList({ items, target, setRoutes, onChangeOrder }: DraggableListProps, ref) {
    const { screenSize } = useDevice();
    const [itemHeight, setItemHeight] = useState(screenSize === 'phone' ? 208 : 136); // 168 + 24 ... 112 + 24
    const order = useRef<Array<number>>(items.map((_, index) => index));
    const itemsLength = items.length;

    const fn = useCallback(
      (
        newOrder,
        active = false,
        originalIndex = 0,
        curIndex = 0,
        y = 0,
        forceImmediate = false,
      ) => {
        return (index) => {
          if (active && index === originalIndex) {
            return {
              y: curIndex * itemHeight + y,
              scale: 1.02,
              zIndex: 1,
              shadow: 15,
              immediate: (key) => key === 'y' || key === 'zIndex',
            };
          }

          return {
            y: newOrder.indexOf(index) * itemHeight,
            scale: 1,
            zIndex: 0,
            shadow: 0,
            immediate: forceImmediate || false,
          };
        };
      },
      [itemHeight],
    );

    const [springs, api] = useSprings(items.length, fn(order.current));

    useEffect(() => {
      if (screenSize === 'phone') {
        setItemHeight(208);
        api.start(fn(order.current, false, 0, 0, 0, true));
      } else {
        setItemHeight(136);
        api.start(fn(order.current, false, 0, 0, 0, true));
      }
    }, [api, fn, screenSize]);

    useImperativeHandle(ref, () => ({
      addRoute(position) {
        if (position === 'top') {
          const newOrder: Array<number> = [0, ...order.current.map((i) => i + 1)];
          order.current = newOrder;
          onChangeOrder(newOrder);
        }

        if (position === 'bottom') {
          const newOrder: Array<number> = [...order.current, order.current.length];

          order.current = newOrder;
          onChangeOrder(newOrder);
        }
      },
      reOrder() {
        api.start(fn(order.current, false, 0, 0, 0, true));
      },
    }));

    const bind = useDrag(({ args: [originalIndex], active, movement: [, y] }) => {
      const curIndex = order.current.indexOf(originalIndex);
      const curRow = clamp(
        Math.round((curIndex * (itemHeight * 2) + y) / (itemHeight * 2)),
        0,
        items.length - 1,
      );
      const newOrder: Array<number> = swap(order.current, curIndex, curRow);

      api.start(fn(newOrder, active, originalIndex, curIndex, y));

      if (!active) {
        order.current = newOrder;
        onChangeOrder(newOrder);
      }
    });

    const handleOnChangeRoute = useCallback(
      ({ index, data }) => {
        if (!data) {
          order.current = [...order.current]
            .filter((i) => index !== i)
            .map((i) => {
              if (i > index) return i - 1;
              return i;
            });

          onChangeOrder(order.current);
        }

        setRoutes((r) => {
          const newRoutes = [...r];
          newRoutes[index] = data;
          return newRoutes.filter(Boolean);
        });

        api.start(fn(order.current, false, 0, 0, 0, true));
      },
      [api, fn, onChangeOrder, setRoutes],
    );

    return (
      <div style={{ height: itemsLength * itemHeight }}>
        {springs.map(({ zIndex, shadow, y, scale }, i) => {
          return (
            <animated.div
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              className={stylex(styles.listItem)}
              style={{
                zIndex,
                boxShadow: shadow.to((s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`),
                y,
                scale,
              }}
            >
              <HTTPRoute
                index={i}
                bind={bind}
                target={target}
                data={items[i]}
                onChange={handleOnChangeRoute}
                ruleNumber={order.current.indexOf(i) ?? 0}
              />
            </animated.div>
          );
        })}
      </div>
    );
  },
);

export default DraggableList;
