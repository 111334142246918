// @flow
import { Link } from 'react-router-dom';
import stylex from '@serpa-cloud/stylex';
import { graphql, useLazyLoadQuery } from 'react-relay';

import { Padding, Text, Margin, Flexbox } from '../../../shared';

import useDevice from '../../../shared/hooks/useDevice';

type Props = {|
  +id: string,
  +appId: string,
|};

const styles = stylex.create({
  cardRecomendation: {
    borderRadius: 3,
    boxShadow: 'var(--shadow-1)',
    border: '1px solid var(--border-color)',
    backgroundColor: 'var(--neutral-color-200)',
  },
});

export default function BuildRemediations({ id, appId }: Props): React$Node {
  const { width } = useDevice();
  const data = useLazyLoadQuery(
    graphql`
      query BuildRemediationsQuery($buildId: ID!) {
        getBuildRemediations(buildId: $buildId)
      }
    `,
    {
      buildId: id,
    },
    {
      fetchPolicy: 'store-or-network',
    },
  );

  return (
    <Margin top={width <= 1000 ? 24 : 48}>
      <Flexbox flexDirection="column" rowGap={20}>
        <Text
          type={width <= 1000 ? 's1b' : 's2b'}
          id="build.remediationTitle"
          color="--neutral-color-800"
        />
        <Text
          type={width <= 1000 ? 'bs' : 'bd'}
          id="build.remediationDescription"
          color="--neutral-color-600"
          values={{
            l(s) {
              return <Link to={`/app/apps/${appId}/settings`}>{s}</Link>;
            },
          }}
        />
      </Flexbox>
      <Margin top={24}>
        <Padding className={stylex(styles.cardRecomendation)} horizontal={16} vertical={24}>
          <Text type="bs" color="--neutral-color-800">
            {data.getBuildRemediations}
          </Text>
        </Padding>
      </Margin>
    </Margin>
  );
}
