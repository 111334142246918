/**
 * @generated SignedSource<<6887ded3a3366c95dcfcc0ce02db96f5>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type GitProvider = "GITHUB" | "GITLAB" | "%future added value";
export type GitSelectorSubscription$variables = {||};
export type GitSelectorSubscription$data = {|
  +gitValidation: {|
    +provider: GitProvider,
    +available: boolean,
  |},
|};
export type GitSelectorSubscription = {|
  variables: GitSelectorSubscription$variables,
  response: GitSelectorSubscription$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GitValidation",
    "kind": "LinkedField",
    "name": "gitValidation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "provider",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "available",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GitSelectorSubscription",
    "selections": (v0/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GitSelectorSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "1af39a46860dff524e82722c450e711a",
    "id": null,
    "metadata": {},
    "name": "GitSelectorSubscription",
    "operationKind": "subscription",
    "text": "subscription GitSelectorSubscription {\n  gitValidation {\n    provider\n    available\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "859c93a0e8ec11129da583244be85794";

module.exports = ((node/*: any*/)/*: GraphQLSubscription<
  GitSelectorSubscription$variables,
  GitSelectorSubscription$data,
>*/);
