/**
 * @generated SignedSource<<6bdb6e6cb9485a12f1140ffea490ec6f>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type DNSProvider = "AWS" | "%future added value";
export type DomainStatus = "PENDING" | "COMPLETE" | "%future added value";
export type DomainFormMutation$variables = {|
  domain: string,
  environment: string,
  provider: DNSProvider,
|};
export type DomainFormMutation$data = {|
  +validateNSRecords: {|
    +id: string,
    +domain: string,
    +status: DomainStatus,
    +records: {|
      +A: $ReadOnlyArray<string>,
      +CNAME: $ReadOnlyArray<string>,
    |},
  |},
|};
export type DomainFormMutation = {|
  variables: DomainFormMutation$variables,
  response: DomainFormMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "domain"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "environment"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "provider"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "domain",
        "variableName": "domain"
      },
      {
        "kind": "Variable",
        "name": "environment",
        "variableName": "environment"
      },
      {
        "kind": "Variable",
        "name": "provider",
        "variableName": "provider"
      }
    ],
    "concreteType": "Domain",
    "kind": "LinkedField",
    "name": "validateNSRecords",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "domain",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DNSRecordProvider",
        "kind": "LinkedField",
        "name": "records",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "A",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "CNAME",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DomainFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DomainFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "05fa11de3ef5419708e3a33567a78722",
    "id": null,
    "metadata": {},
    "name": "DomainFormMutation",
    "operationKind": "mutation",
    "text": "mutation DomainFormMutation(\n  $domain: String!\n  $environment: ID!\n  $provider: DNSProvider!\n) {\n  validateNSRecords(domain: $domain, environment: $environment, provider: $provider) {\n    id\n    domain\n    status\n    records {\n      A\n      CNAME\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "cfbd73588026f8d33e1923ef15f1cfbf";

module.exports = ((node/*: any*/)/*: Mutation<
  DomainFormMutation$variables,
  DomainFormMutation$data,
>*/);
