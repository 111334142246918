/**
 * @generated SignedSource<<5a9bf763564a368ac828a9370950ee1a>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
type DNS$fragmentType = any;
type Networking$fragmentType = any;
export type EnvironmentDetailQuery$variables = {|
  id: string,
|};
export type EnvironmentDetailQuery$data = {|
  +node: ?{|
    +id: string,
    +name?: string,
    +namespace?: string,
    +$fragmentSpreads: DNS$fragmentType & Networking$fragmentType,
  |},
|};
export type EnvironmentDetailQuery = {|
  variables: EnvironmentDetailQuery$variables,
  response: EnvironmentDetailQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "namespace",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domain",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domainName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EnvironmentDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DNS"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Networking"
              }
            ],
            "type": "Environment",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EnvironmentDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "KubernetesCluster",
                "kind": "LinkedField",
                "name": "kubernetesCluster",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "managedResource",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Ingress",
                    "kind": "LinkedField",
                    "name": "ingress",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ingressHostName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DomainMapping",
                "kind": "LinkedField",
                "name": "domainsMapping",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Certificate",
                    "kind": "LinkedField",
                    "name": "cert",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "provider",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "arn",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DNSRecord",
                        "kind": "LinkedField",
                        "name": "dnsRecords",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Domain",
                "kind": "LinkedField",
                "name": "domainsRecords",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DNSRecordProvider",
                    "kind": "LinkedField",
                    "name": "records",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "A",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "CNAME",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "validationId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "HTTPRoute",
                "kind": "LinkedField",
                "name": "routes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HTTPMatchRequest",
                    "kind": "LinkedField",
                    "name": "match",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "parameter",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "parameterName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "matchType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "matchValue",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HTTPRouteDestination",
                    "kind": "LinkedField",
                    "name": "route",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "weight",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Deployment",
                        "kind": "LinkedField",
                        "name": "destination",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Environment",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a61cebd3341fa12b5580de20602bc61c",
    "id": null,
    "metadata": {},
    "name": "EnvironmentDetailQuery",
    "operationKind": "query",
    "text": "query EnvironmentDetailQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    id\n    ... on Environment {\n      id\n      name\n      namespace\n      ...DNS\n      ...Networking\n    }\n  }\n}\n\nfragment DNS on Environment {\n  id\n  ...Registers\n}\n\nfragment DomainElement on Domain {\n  id\n  domain\n  records {\n    A\n    CNAME\n  }\n  status\n  validationId\n}\n\nfragment Networking on Environment {\n  id\n  routes {\n    match {\n      parameter\n      parameterName\n      matchType\n      matchValue\n    }\n    route {\n      weight\n      destination {\n        id\n        name\n      }\n    }\n  }\n}\n\nfragment Register on DomainMapping {\n  id\n  domain\n  cert {\n    id\n    domainName\n    provider\n    arn\n    dnsRecords {\n      domainName\n      name\n      type\n      value\n    }\n  }\n}\n\nfragment Registers on Environment {\n  id\n  kubernetesCluster {\n    id\n    managedResource\n    ingress {\n      id\n      ingressHostName\n    }\n  }\n  domainsMapping {\n    id\n    ...Register\n  }\n  domainsRecords {\n    id\n    ...DomainElement\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "f963dd7275f8ce6a8e9a255b0fd8dc3f";

module.exports = ((node/*: any*/)/*: Query<
  EnvironmentDetailQuery$variables,
  EnvironmentDetailQuery$data,
>*/);
