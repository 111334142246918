// @flow
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import stylex from '@serpa-cloud/stylex';
import { useParams } from 'react-router-dom';
import { useMutation, graphql } from 'react-relay';

import { Input, Button, Flexbox, useInput, usePageView, validateData } from '../../../shared';

import type { DomainFormMutation } from './__generated__/DomainFormMutation.graphql';

const styles = stylex.create({
  input: {
    flex: '1',
  },
});

export default function DomainForm(): React$Node {
  usePageView({ id: 'addDomain.title' });

  const intl = useIntl();
  const { nodeId } = useParams();

  const [validateNS, commitNSPending] = useMutation<DomainFormMutation>(graphql`
    mutation DomainFormMutation($domain: String!, $environment: ID!, $provider: DNSProvider!) {
      validateNSRecords(domain: $domain, environment: $environment, provider: $provider) {
        id
        domain
        status
        records {
          A
          CNAME
        }
      }
    }
  `);

  const domain = useInput({
    name: 'domain',
    required: true,
    label: { id: 'addDomain.inputLabel' },
    regexp: /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/g,
    value: '',
    autoComplete: false,
    errors: {
      regexpError: { id: 'input.dns.validDomainRequired' },
      requiredError: { id: 'input.requiredError' },
    },
  });

  const handleOnSubmit = useCallback(
    (e) => {
      if (!commitNSPending) {
        e.preventDefault();

        const { errors, data } = validateData<{ domain: string }>([domain]);

        if (!errors) {
          validateNS({
            variables: {
              provider: 'AWS',
              environment: nodeId,
              domain: data.domain.toLowerCase(),
            },
            updater(store) {
              const environment = store.get(nodeId);
              const domains = environment?.getLinkedRecords('domainsRecords') ?? [];

              const payload = store.getRootField('validateNSRecords');
              const payloadId = payload?.getDataID();

              if (domains.some((domainRecord) => payloadId === domainRecord?.getDataID())) {
                return;
              }

              if (payload) {
                domains.unshift(payload);
                environment?.setLinkedRecords(domains, 'domainsRecords');
              }
            },
            onError(error) {
              // eslint-disable-next-line no-console
              console.trace(error);

              domain.setData((x) => ({
                ...x,
                error: intl.formatMessage({
                  id:
                    // $FlowIgnore
                    error?.source?.errors?.[0]?.message ??
                    'backend.domains.dnsLookup.notAuthorityFound',
                }),
              }));
            },
          });
        }
      }
    },
    [commitNSPending, domain, validateNS, nodeId, intl],
  );

  return (
    <div>
      <form onSubmit={handleOnSubmit} autoComplete="nope">
        <input type="submit" style={{ display: 'none' }} />

        <Flexbox alignItems="flex-start" columnGap={16}>
          <div className={stylex(styles.input)}>
            <Input input={domain.input} />
          </div>

          <Button
            iconRight="east"
            intlId="add"
            onClick={handleOnSubmit}
            disabled={commitNSPending}
            loading={commitNSPending}
            size="hero"
          />
        </Flexbox>
      </form>
    </div>
  );
}
