// @flow
import ReactDOM from 'react-dom';
import stylex from '@serpa-cloud/stylex';
import { graphql, useMutation } from 'react-relay';
import { useRef, useCallback, useState, lazy, Suspense } from 'react';

import { Icon, Avatar, TapIcon, useImageLoad } from '../../../shared';

import type { Avatar$key } from '../../../shared';

import type { ProfilePictureMutation } from './__generated__/ProfilePictureMutation.graphql';

const ImageEditor = lazy(() => import(/* webpackPrefetch: true */ '../ImageEditor'));

const styles = stylex.create({
  inputFile: {
    width: 0,
    height: 0,
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  pictureContainer: {
    backgroundColor: 'var(--neutral-color-200)',
    width: 124,
    height: 124,
    position: 'relative',
    boxSizing: 'border-box',
  },
  picturePreview: {
    objectFit: 'cover',
    width: 124,
    height: 124,
  },
  avatarContainer: {
    width: 124,
    height: 124,
    transitionDuration: 'var(--fds-duration-medium-in)',
    transitionTimingFunction: 'var(--fds-animation-fade-in)',
  },
  addPictureContainer: {
    width: 36,
    height: 36,
    position: 'absolute',
    top: -8,
    right: -8,
  },
});

type Props = {|
  +owned: boolean,
  +fullname: string,
  +node?: ?Avatar$key,
|};

export default function ProfilePicture({ fullname, node, owned }: Props): React$Node {
  const mediaRef = useRef<?HTMLInputElement>();
  const [updateMedia] = useMutation<ProfilePictureMutation>(graphql`
    mutation ProfilePictureMutation($image: ID!) {
      updateUserMedia(image: $image) {
        id
        media(width: 352, height: 352) {
          ...Avatar
        }
        thumb: media(width: 64, height: 64) {
          ...Avatar
        }
        thumbComposer: media(width: 80, height: 80) {
          ...Avatar
        }
      }
    }
  `);

  const [croppedImage, setCroppedImage] = useState(null);

  const [
    ,
    mediaIsPending,
    ,
    mediaPreviewUrl,
    mediaOnChange,
    disposeMedia,
    uploadImage,
  ] = useImageLoad(
    fullname,
    (image) => {
      updateMedia({
        variables: { image },
        onCompleted() {
          disposeMedia();
          setCroppedImage(null);
        },
      });
    },
    {
      upload: false,
    },
  );

  const onCrop = useCallback(
    ({ dataURL, blob }) => {
      setCroppedImage(dataURL);
      uploadImage(blob);
    },
    [uploadImage],
  );

  return (
    <>
      {owned && (
        <input
          ref={mediaRef}
          type="file"
          accept="image/*"
          onChange={mediaOnChange}
          className={stylex(styles.inputFile)}
        />
      )}

      {mediaPreviewUrl
        ? ReactDOM.createPortal(
            // eslint-disable-next-line react/jsx-indent
            <Suspense fallback={<div />}>
              <ImageEditor onClose={disposeMedia} image={mediaPreviewUrl} onSave={onCrop} />
            </Suspense>,
            document.getElementById('modal-portal'),
          )
        : null}

      <div className={stylex(styles.pictureContainer)}>
        {croppedImage && (
          <img
            alt={fullname}
            src={croppedImage}
            className={stylex(styles.picturePreview)}
            style={{ opacity: 0.4 }}
          />
        )}
        {!croppedImage && (
          <div
            className={stylex(styles.avatarContainer)}
            style={{ opacity: mediaIsPending ? 0.4 : 1 }}
          >
            <Avatar
              fixedWidth={124}
              fixedHeight={124}
              node={node ?? null}
              fallbackIcon={<Icon icon="person" fill size={80} opticalSize={48} />}
            />
          </div>
        )}
        {owned && (
          <div className={stylex(styles.addPictureContainer)}>
            <TapIcon icon="add_a_photo" onClick={() => mediaRef?.current?.click()} />
          </div>
        )}
      </div>
    </>
  );
}

ProfilePicture.defaultProps = {
  node: null,
};
