/**
 * @generated SignedSource<<10fc32298fa4b3ebbb020bcca5878b2a>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type ElasticIndex = "ALL" | "APPS" | "BUILDS" | "RELEASES" | "ORGS" | "ARTIFACTS" | "ACCOUNTS" | "DOCKERFILES" | "DEPLOYMENTS" | "CHANNELS" | "ENVIRONMENTS" | "IMPLEMENTATIONS" | "KUBERNETES" | "SITES" | "POSTS" | "%future added value";
export type SortInput = {|
  value: string,
  property: string,
|};
export type FilterInput = {|
  property: string,
  type: string,
  value?: ?string,
  valueNumber?: ?number,
  valueBoolean?: ?boolean,
  gt?: ?number,
  lte?: ?number,
|};
export type ClusterSelectorQuery$variables = {|
  first?: ?number,
  after?: ?any,
  sort?: ?SortInput,
  index?: ?ElasticIndex,
  filterMatrix?: ?$ReadOnlyArray<?$ReadOnlyArray<?FilterInput>>,
|};
export type ClusterSelectorQuery$data = {|
  +entities: ?{|
    +pageInfo: ?{|
      +hasNextPage: ?boolean,
      +endCursor: ?any,
      +finalCursor: ?any,
    |},
    +edges: ?$ReadOnlyArray<?{|
      +id: string,
      +cursor: any,
      +node: {|
        +__typename: "KubernetesCluster",
        +id: string,
        +provider: string,
        +namespace: string,
        +clusterId: string,
        +clusterStatus: ?string,
      |} | {|
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        +__typename: "%other",
      |},
    |}>,
  |},
|};
export type ClusterSelectorQuery = {|
  variables: ClusterSelectorQuery$variables,
  response: ClusterSelectorQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterMatrix"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "index"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filterMatrix",
    "variableName": "filterMatrix"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "index",
    "variableName": "index"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalCursor",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "provider",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "namespace",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clusterId",
      "storageKey": null
    },
    {
      "alias": "clusterStatus",
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "KubernetesCluster",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClusterSelectorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "EntitiesConnection",
        "kind": "LinkedField",
        "name": "entities",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Edge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ClusterSelectorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "EntitiesConnection",
        "kind": "LinkedField",
        "name": "entities",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Edge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e9f4bf18522d8556710583a500a1e139",
    "id": null,
    "metadata": {},
    "name": "ClusterSelectorQuery",
    "operationKind": "query",
    "text": "query ClusterSelectorQuery(\n  $first: Int\n  $after: Cursor\n  $sort: SortInput\n  $index: ElasticIndex\n  $filterMatrix: [[FilterInput]]\n) {\n  entities(sort: $sort, first: $first, after: $after, index: $index, filterMatrix: $filterMatrix) {\n    pageInfo {\n      hasNextPage\n      endCursor\n      finalCursor\n    }\n    edges {\n      id\n      cursor\n      node {\n        __typename\n        ... on KubernetesCluster {\n          id\n          provider\n          namespace\n          clusterId\n          clusterStatus: status\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "4ceb743cf0464fa454c1f5228208ef4d";

module.exports = ((node/*: any*/)/*: Query<
  ClusterSelectorQuery$variables,
  ClusterSelectorQuery$data,
>*/);
