/**
 * @generated SignedSource<<103e9b24e9aa5cd7b541d6ae748a27fb>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
type Registers$fragmentType = any;
import type { FragmentType } from "relay-runtime";
declare export opaque type DNS$fragmentType: FragmentType;
export type DNS$data = {|
  +id: string,
  +$fragmentSpreads: Registers$fragmentType,
  +$fragmentType: DNS$fragmentType,
|};
export type DNS$key = {
  +$data?: DNS$data,
  +$fragmentSpreads: DNS$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DNS",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Registers"
    }
  ],
  "type": "Environment",
  "abstractKey": null
};

(node/*: any*/).hash = "95e1474175087834ce9e112816564137";

module.exports = ((node/*: any*/)/*: Fragment<
  DNS$fragmentType,
  DNS$data,
>*/);
