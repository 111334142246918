// @flow
import { createContext, useContext, useState, useCallback } from 'react';

type Props = {
  children: React$Node,
};

type ModalType = {|
  id: any,
  label?: ?string,
  ref: any,
|};

const ModalContext = createContext({
  modals: [],
  registerModal: () => {},
  unregisterModal: () => {},
});

export function ModalProvider({ children }: Props): React$Node {
  const [modals, setModals] = useState([]);

  const registerModal = useCallback((modal) => {
    setModals((prevModals) => [...prevModals, modal]);
  }, []);

  const unregisterModal = useCallback((modal) => {
    setModals((prevModals) => prevModals.filter((m) => m !== modal));
  }, []);

  const contextValue = {
    modals,
    registerModal,
    unregisterModal,
  };

  return <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>;
}

export function useModals(): {|
  modals: Array<ModalType>,
  registerModal: (modal: ModalType) => void,
  unregisterModal: (modal: ModalType) => void,
|} {
  return useContext(ModalContext);
}
