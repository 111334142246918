/**
 * @generated SignedSource<<e4aec261c640b33cd9097fadf7d40783>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type UITheme = "DARK" | "LIGHT" | "%future added value";
export type LayoutQuery$variables = {||};
export type LayoutQuery$data = {|
  +me: ?{|
    +id: string,
    +uiTheme: ?UITheme,
    +username: string,
    +email: string,
    +hasBasicProfile: boolean,
  |},
  +paymentProfile: ?{|
    +id: string,
  |},
|};
export type LayoutQuery = {|
  variables: LayoutQuery$variables,
  response: LayoutQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "me",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uiTheme",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasBasicProfile",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PaymentProfile",
    "kind": "LinkedField",
    "name": "paymentProfile",
    "plural": false,
    "selections": [
      (v0/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LayoutQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LayoutQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "419903344676a82af91041de635429e2",
    "id": null,
    "metadata": {},
    "name": "LayoutQuery",
    "operationKind": "query",
    "text": "query LayoutQuery {\n  me {\n    id\n    uiTheme\n    username\n    email\n    hasBasicProfile\n  }\n  paymentProfile {\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "9069c730cced0a128bbfcc1802742ff6";

module.exports = ((node/*: any*/)/*: Query<
  LayoutQuery$variables,
  LayoutQuery$data,
>*/);
