// @flow
import stylex from '@serpa-cloud/stylex';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';

import { Text, ServiceCard } from '../../shared';

import GithubLogo from '../../shared/images/github-logo.png';
import GithubLogoWhite from '../../shared/images/github-logo-white.png';
import GitlabLogo from '../../shared/images/gitlab-logo.png';

import type { MicroAppElement$key } from './__generated__/MicroAppElement.graphql';

type Props = {|
  +node: MicroAppElement$key,
|};

const styles = stylex.create({
  repoAvatar: {
    width: 18,
    height: 18,
    borderRadius: 12,
  },
});

const githubImage = {
  LIGHT: GithubLogo,
  DARK: GithubLogoWhite,
};

export default function MicroAppElement({ node }: Props): React$Node {
  const data = useFragment(
    graphql`
      fragment MicroAppElement on App {
        id
        name
        appType
        createdAt
        siteCount
        gitProvider
        deploymentCount
        compilationCount
        createdAtFormatted
        staticGitSourceName
      }
    `,
    node,
  );

  const userData = useLazyLoadQuery(
    graphql`
      query MicroAppElementQuery {
        me {
          id
          uiTheme
        }
      }
    `,
    {},
    {
      fetchPolicy: 'store-or-network',
    },
  );

  return (
    <ServiceCard
      name={data.name}
      createdAtFormatted={data.createdAtFormatted}
      firstCount={data?.compilationCount ?? 0}
      firstCountLabel="microapp.compilations"
      secondCount={(data?.appType === 'CONTAINER' ? data?.deploymentCount : data?.siteCount) ?? 0}
      secondCountLabel={data?.appType === 'CONTAINER' ? 'microapp.deployments' : 'microapp.sites'}
      footer={
        data.gitProvider ? (
          <>
            <img
              alt={data.gitProvider === 'GITHUB' ? 'Github logo' : 'Gitlab logo'}
              src={
                data.gitProvider === 'GITHUB'
                  ? githubImage[userData?.me?.uiTheme === 'LIGHT' ? 'LIGHT' : 'DARK']
                  : GitlabLogo
              }
              className={stylex(styles.repoAvatar)}
            />
            <a
              href={`https://${
                data.gitProvider === 'GITHUB' ? 'github' : 'gitlab'
              }.com/${data.staticGitSourceName ?? ''}`}
              target="_blank"
              rel="noreferrer"
            >
              <Text type="s0r" color="--primary-color-1">
                {data?.staticGitSourceName}
              </Text>
            </a>
          </>
        ) : null
      }
      serviceURL={`/app/apps/${data?.id}`}
    />
  );
}
