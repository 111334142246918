/**
 * @generated SignedSource<<a01eaa4162186528a0adf306041e4261>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type BuildModalQuery$variables = {|
  appId: string,
|};
export type BuildModalQuery$data = {|
  +gitBranchesByApp: ?$ReadOnlyArray<{|
    +id: string,
    +name: string,
    +sha: string,
  |}>,
  +gitTagsByApp: ?$ReadOnlyArray<{|
    +id: string,
    +name: string,
    +sha: string,
  |}>,
|};
export type BuildModalQuery = {|
  variables: BuildModalQuery$variables,
  response: BuildModalQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "appId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "appId",
    "variableName": "appId"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sha",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": (v1/*: any*/),
    "concreteType": "GitBranch",
    "kind": "LinkedField",
    "name": "gitBranchesByApp",
    "plural": true,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v1/*: any*/),
    "concreteType": "GitTag",
    "kind": "LinkedField",
    "name": "gitTagsByApp",
    "plural": true,
    "selections": (v2/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BuildModalQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BuildModalQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "1ae42ff6f23053d6e852c7d85832c009",
    "id": null,
    "metadata": {},
    "name": "BuildModalQuery",
    "operationKind": "query",
    "text": "query BuildModalQuery(\n  $appId: String!\n) {\n  gitBranchesByApp(appId: $appId) {\n    id\n    name\n    sha\n  }\n  gitTagsByApp(appId: $appId) {\n    id\n    name\n    sha\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "6c29e9a05702e6fcf82966889d467641";

module.exports = ((node/*: any*/)/*: Query<
  BuildModalQuery$variables,
  BuildModalQuery$data,
>*/);
