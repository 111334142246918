/**
 * @generated SignedSource<<bfae6cb16a82449d056e2f535ff35095>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type Pronoun = "HE" | "SHE" | "THEY" | "OTHER" | "%future added value";
export type DeleteDeploymentModalQuery$variables = {||};
export type DeleteDeploymentModalQuery$data = {|
  +me: ?{|
    +pronoun: ?Pronoun,
  |},
|};
export type DeleteDeploymentModalQuery = {|
  variables: DeleteDeploymentModalQuery$variables,
  response: DeleteDeploymentModalQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pronoun",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteDeploymentModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DeleteDeploymentModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "578efca1377da8a8126e0d3a49c1fff3",
    "id": null,
    "metadata": {},
    "name": "DeleteDeploymentModalQuery",
    "operationKind": "query",
    "text": "query DeleteDeploymentModalQuery {\n  me {\n    pronoun\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "95dac22e3dd0a7cc6220ffeddaa35e0f";

module.exports = ((node/*: any*/)/*: Query<
  DeleteDeploymentModalQuery$variables,
  DeleteDeploymentModalQuery$data,
>*/);
