/**
 * @generated SignedSource<<9f1a5576aec4fc5f7533a3fb8044eea4>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type AppType = "CONTAINER" | "STATIC" | "%future added value";
export type ContainerBuilder = "DOCKER" | "BUILDPACKS" | "KANIKO" | "%future added value";
export type StaticRuntime = "NODEJS" | "%future added value";
export type CompilationSettingsQuery$variables = {|
  id: string,
|};
export type CompilationSettingsQuery$data = {|
  +node: ?{|
    +id?: string,
    +builder?: ?ContainerBuilder,
    +appType?: AppType,
    +dockerfileContent?: ?string,
    +runtime?: ?StaticRuntime,
    +preInstallCommand?: ?string,
    +buildCommand?: ?string,
    +buildPath?: ?string,
    +nodeVersion?: ?string,
    +environmentVariables?: $ReadOnlyArray<{|
      +name: string,
      +value: string,
    |}>,
  |},
|};
export type CompilationSettingsQuery = {|
  variables: CompilationSettingsQuery$variables,
  response: CompilationSettingsQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "builder",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dockerfileContent",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "runtime",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "preInstallCommand",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "buildCommand",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "buildPath",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeVersion",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Env",
  "kind": "LinkedField",
  "name": "environmentVariables",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompilationSettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "type": "App",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompilationSettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "type": "App",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "234c153e81e9a8397d220dedf1748643",
    "id": null,
    "metadata": {},
    "name": "CompilationSettingsQuery",
    "operationKind": "query",
    "text": "query CompilationSettingsQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on App {\n      id\n      builder\n      appType\n      dockerfileContent\n      runtime\n      preInstallCommand\n      buildCommand\n      buildPath\n      nodeVersion\n      environmentVariables {\n        name\n        value\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "20e8234cfd905b4212fc9a3d6c012a1a";

module.exports = ((node/*: any*/)/*: Query<
  CompilationSettingsQuery$variables,
  CompilationSettingsQuery$data,
>*/);
