/**
 * @generated SignedSource<<f7280ac00e26e9bb2dccc54b87eb74f4>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
type DomainElement$fragmentType = any;
type Register$fragmentType = any;
import type { FragmentType } from "relay-runtime";
declare export opaque type Registers$fragmentType: FragmentType;
export type Registers$data = {|
  +id: string,
  +kubernetesCluster: ?{|
    +id: string,
    +managedResource: boolean,
    +ingress: {|
      +id: string,
      +ingressHostName: string,
    |},
  |},
  +domainsMapping: $ReadOnlyArray<{|
    +id: string,
    +$fragmentSpreads: Register$fragmentType,
  |}>,
  +domainsRecords: $ReadOnlyArray<{|
    +id: string,
    +$fragmentSpreads: DomainElement$fragmentType,
  |}>,
  +$fragmentType: Registers$fragmentType,
|};
export type Registers$key = {
  +$data?: Registers$data,
  +$fragmentSpreads: Registers$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Registers",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "KubernetesCluster",
      "kind": "LinkedField",
      "name": "kubernetesCluster",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "managedResource",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Ingress",
          "kind": "LinkedField",
          "name": "ingress",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ingressHostName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DomainMapping",
      "kind": "LinkedField",
      "name": "domainsMapping",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Register"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Domain",
      "kind": "LinkedField",
      "name": "domainsRecords",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DomainElement"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Environment",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "36b32bb48409a9caae0442500d5ec963";

module.exports = ((node/*: any*/)/*: Fragment<
  Registers$fragmentType,
  Registers$data,
>*/);
