// @flow
import { useCallback } from 'react';
import stylex from '@serpa-cloud/stylex';
import { useMutation, graphql } from 'react-relay';
import { Link, useParams, useNavigate } from 'react-router-dom';

import {
  Text,
  Card,
  Input,
  Button,
  Margin,
  Spinner,
  Padding,
  Flexbox,
  useInput,
  usePageView,
  validateData,
  useNotification,
} from '../../../shared';

import useDevice from '../../../shared/hooks/useDevice';

import type { DomainFormPageMutation } from './__generated__/DomainFormPageMutation.graphql';

const styles = stylex.create({
  container: {
    width: '100%',
    position: 'relative',
    boxSizing: 'border-box',
  },
  margin: {
    position: 'relative',
  },
  singleDescriptorContainer: {
    maxWidth: 480,
  },
  aside: {
    backgroundColor: 'var(--neutral-color-200)',
  },
  descriptorContainer: {
    top: 91,
    position: 'sticky',
  },
});

export default function DomainFormPage(): React$Node {
  const { width } = useDevice();
  usePageView({ id: 'addDomain.title' });

  const [, , setNotification] = useNotification();
  const navigate = useNavigate();

  const { nodeId } = useParams();

  const [addDomain, commitPending] = useMutation<DomainFormPageMutation>(graphql`
    mutation DomainFormPageMutation($environment: ID!, $domain: String!) {
      addAlternateDomain(environment: $environment, domain: $domain) {
        id
        name
        namespace
        ...DNS
        ...Networking
      }
    }
  `);

  const domain = useInput({
    name: 'domain',
    required: true,
    label: { id: 'addDomain.inputLabel' },
    regexp: /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/g,
    value: '',
    autoComplete: false,
    errors: {
      regexpError: { id: 'input.dns.validDomainRequired' },
      requiredError: { id: 'input.requiredError' },
    },
  });

  const handleOnSubmit = useCallback(
    (e) => {
      e.preventDefault();

      const { errors, data } = validateData<{ domain: string }>([domain]);

      if (!errors) {
        addDomain({
          variables: {
            domain: data.domain.toLowerCase(),
            environment: nodeId,
          },
          onCompleted(response) {
            if (response?.addAlternateDomain) {
              setNotification({
                id: new Date().getTime().toString(),
                type: 'SUCCESS',
                message: { id: 'addDomain.success' },
              });
              navigate('..');
            }
          },
          onError(error) {
            // eslint-disable-next-line no-console
            console.log(error);

            setNotification({
              id: new Date().getTime().toString(),
              type: 'ERROR',
              // $FlowIgnore
              message: error.source.errors[0].message,
            });
          },
        });
      }
    },
    [addDomain, domain, navigate, nodeId, setNotification],
  );

  return (
    <Card>
      <Padding top={32} horizontal={width <= 540 ? 8 : 16} className={stylex(styles.container)}>
        <Margin bottom={24} className={stylex(styles.margin)}>
          <form onSubmit={handleOnSubmit} autoComplete="nope">
            <input type="submit" style={{ display: 'none' }} />
            <Flexbox flexDirection="column" rowGap={24}>
              <Text id="addDomain.title" type={width <= 1000 ? 'h6' : 'h5'} component="h1" />
              <Text
                id="addDomain.description"
                type={width <= 1000 ? 'bs' : 'bd'}
                values={{
                  b: (string) => <Link to="../../router">{string}</Link>,
                }}
              />
            </Flexbox>

            <Margin top={32} bottom={24}>
              <Input input={domain.input} />
            </Margin>

            <Flexbox alignItems="center" columnGap={24}>
              <Button
                iconRight="east"
                intlId="dns.addDomain"
                onClick={handleOnSubmit}
                disabled={commitPending}
              />
              {commitPending && <Spinner size={24} />}
            </Flexbox>
          </form>
        </Margin>
      </Padding>
    </Card>
  );
}
