// @flow
import stylex from '@serpa-cloud/stylex';
import { useCallback, useReducer } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import {
  Icon,
  Text,
  Flexbox,
  TapIcon,
  Padding,
  ElementModal,
  useDevice,
  useFetchPolicy,
} from '../../shared';
import DeploymentConfigurator from '../AppCreator/DeploymentConfigurator';

import { reducer, Context, initialState } from '../AppCreator/Provider';
import type { StateType, Dispatch, ReducerAction } from '../AppCreator/Provider';

const styles = stylex.create({
  container: {
    boxSizing: 'border-box',
  },
});

function DeploymentFormInterface(): React$Node {
  const fetchPolicy = useFetchPolicy();

  const [searchParams] = useSearchParams();
  const appId = searchParams.get('appId');
  const artifactId = searchParams.get('source');
  const componentParam = searchParams.get('component');
  const componentIdParam = searchParams.get('componentId');

  const data = useLazyLoadQuery(
    graphql`
      query DeploymentFormQuery($id: ID!) {
        node(id: $id) {
          ... on App {
            id
            name
            appMethod
            namespace
          }
        }
      }
    `,
    {
      id: appId ?? '',
    },
    {
      fetchPolicy,
    },
  );

  const app = data?.node;

  const [state, dispatch]: [StateType, Dispatch<ReducerAction>] = useReducer(reducer, {
    ...initialState,
    appId: app?.id ?? '',
    appName: app?.name ?? '',
    method: app?.appMethod ?? 'GIT',
    deploymentConfig: {
      name: `${app?.name ?? ''}-default`,
      port: '8080',
      privacy: 'PUBLIC',
      patternTag: '/.*/',
      enableCors: false,
      dockerImageUrl: '',
      allowOrigin: ['*'],
      allowHeaders: ['content-type', 'accept-type'],
      allowMethods: ['OPTIONS, GET'],
      env: [{ name: 'PORT', value: '8080' }],
      environment:
        componentParam === 'environments' && componentIdParam ? componentIdParam : 'DEFAULT',
      continousDeployment: 'MANUAL',
      source: artifactId ?? null,
      replicas: 1,
    },
  });

  if (!app) return <Navigate to="/app/deployments/select-source?component=none&componentId=none" />;

  return (
    <Context.Provider value={{ state, dispatch }}>
      <DeploymentConfigurator editable />
    </Context.Provider>
  );
}

type Props = {
  mode?: 'CREATE' | 'UPDATE',
};

export default function DeploymentForm({ mode }: Props): React$Node {
  const { width } = useDevice();
  const navigate = useNavigate();

  const handleOnClose = useCallback(() => {
    navigate('./..');
  }, [navigate]);

  return (
    <ElementModal
      id="DeploymentFormCreate"
      header={
        <header>
          <Padding horizontal={width <= 1000 ? 16 : 24} vertical={16}>
            <Flexbox alignItems="center" justifyContent="space-between">
              <Flexbox columnGap={8} alignItems="center">
                <Icon
                  icon="rocket_launch"
                  fill
                  gradient="linear-gradient(265.7deg, var(--green-solid-color) -2.24%, var(--cyan-solid-color) 102.98%)"
                />

                <Text type="h5" id={mode === 'CREATE' ? 'createDeployment' : 'updateDeployment'} />
              </Flexbox>
              <TapIcon icon="close" iconColor="--primary-color-1" onClick={handleOnClose} />
            </Flexbox>
          </Padding>
        </header>
      }
    >
      <Padding
        className={stylex(styles.container)}
        bottom={40}
        // eslint-disable-next-line no-nested-ternary
        horizontal={width <= 1000 ? (width <= 480 ? 0 : 16) : 24}
      >
        <DeploymentFormInterface />
      </Padding>
    </ElementModal>
  );
}

DeploymentForm.defaultProps = {
  mode: 'UPDATE',
};
