/**
 * @generated SignedSource<<6e7a730b3a07f914c0ef31555b3f8ca1>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type OnboardingSubscriptionQuery$variables = {||};
export type OnboardingSubscriptionQuery$data = {|
  +getCurrentNameSpace: ?{|
    +id: string,
    +__typename: string,
  |},
  +paymentProfile: ?{|
    +id: string,
  |},
|};
export type OnboardingSubscriptionQuery = {|
  variables: OnboardingSubscriptionQuery$variables,
  response: OnboardingSubscriptionQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "getCurrentNameSpace",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PaymentProfile",
    "kind": "LinkedField",
    "name": "paymentProfile",
    "plural": false,
    "selections": [
      (v0/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OnboardingSubscriptionQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OnboardingSubscriptionQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9c50c5a5abd5873dde4d2ab0439dfb99",
    "id": null,
    "metadata": {},
    "name": "OnboardingSubscriptionQuery",
    "operationKind": "query",
    "text": "query OnboardingSubscriptionQuery {\n  getCurrentNameSpace {\n    id\n    __typename\n  }\n  paymentProfile {\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "9189e4bee9f55860965daf9857193368";

module.exports = ((node/*: any*/)/*: Query<
  OnboardingSubscriptionQuery$variables,
  OnboardingSubscriptionQuery$data,
>*/);
