// @flow
import { useIntl } from 'react-intl';
import { graphql, useLazyLoadQuery } from 'react-relay';

import { Text, FastSelect, CascaderOption } from '../../shared';

type Props = {|
  +appId: string,
  +handleOnSubmit: (source: [string]) => void,
|};

export default function CompilationSourceGitList({ appId, handleOnSubmit }: Props): React$Node {
  const intl = useIntl();

  const data = useLazyLoadQuery(
    graphql`
      query CompilationSourceGitListQuery($appId: String!) {
        gitBranchesByApp(appId: $appId) {
          id
          name
          sha
        }
        gitTagsByApp(appId: $appId) {
          id
          name
          sha
        }
      }
    `,
    {
      appId,
    },
    {
      fetchPolicy: 'store-and-network',
    },
  );

  return (
    <FastSelect
      placeholder={intl.formatMessage({
        id: 'compilationSource.gitPlaceholder',
      })}
      value={[]}
      onChange={() => {}}
      label={null}
    >
      <CascaderOption
        selectable={false}
        value="BRANCH"
        label={<Text type="s0m" color="--neutral-color-600" id="buildModal.input.BRANCH" />}
      >
        {data.gitBranchesByApp?.map((el) => {
          const [, name] = el.id.split(':');
          const [, , ...alias] = name.split('/');

          return (
            <CascaderOption
              key={el.id}
              value={`Branch@${name}@${el.sha}`}
              callback={() => {
                handleOnSubmit([`Branch@${name}@${el.sha}`]);
              }}
              label={
                <Text type="s0m" color="--neutral-color-600">
                  {alias.join('/')}
                </Text>
              }
            />
          );
        })}
      </CascaderOption>
      <CascaderOption
        selectable={false}
        value="TAG"
        label={<Text type="s0m" color="--neutral-color-600" id="buildModal.input.TAG" />}
      >
        {data.gitTagsByApp?.map((el) => {
          const [, name] = el.id.split(':');
          const [, , ...alias] = name.split('/');

          return (
            <CascaderOption
              key={el.id}
              value={`Tag@${name}@${el.sha}`}
              selectable={false}
              callback={() => {
                handleOnSubmit([`Tag@${name}@${el.sha}`]);
              }}
              label={
                <Text type="s0m" color="--neutral-color-600">
                  {alias.join('/')}
                </Text>
              }
            />
          );
        })}
      </CascaderOption>
    </FastSelect>
  );
}
