// @flow
import stylex from '@serpa-cloud/stylex';
import { useMutation, graphql } from 'react-relay';
import { useState, useCallback, useRef } from 'react';

import {
  Button,
  Margin,
  Spinner,
  Flexbox,
  Padding,
  Divider,
  LiteButton,
  ComponentDescriptor,
  useNotification,
} from '../../../shared';

import DraggableList from './DraggableList';

import useDevice from '../../../shared/hooks/useDevice';

import type { HTTPRoute$data } from './HTTPRoute';
import type { RoutesMutation } from './__generated__/RoutesMutation.graphql';

const styles = stylex.create({
  rulesComponent: {
    minHeight: 'calc(100vh - 155px)',
  },
  container: {
    position: 'relative',
  },
  routesMargin: {
    position: 'relative',
  },
  footer: {
    bottom: 0,
    width: '100%',
    position: 'sticky',
    transform: 'translateX(-16px)',
    zIndex: 99,
    '-webkit-backdrop-filter': 'saturate(180%) blur(40px)',
    backdropFilter: 'saturate(180%) blur(40px)',
    '@media (max-width: 1280px)': {
      width: '100%',
    },
  },
  aside: {
    backgroundColor: 'var(--neutral-color-200)',
  },
  descriptorContainer: {
    top: 91,
    position: 'sticky',
  },
  singleDescriptorContainer: {
    maxWidth: 560,
  },
});

type Props = {|
  +target: string,
  +routes: $ReadOnlyArray<HTTPRoute$data>,
  +type: 'Implementation' | 'Environment',
|};

export default function Routes({ target, routes: defaultRoutes, type }: Props): React$Node {
  const { width } = useDevice();
  const listRef = useRef();
  const [order, setOrder] = useState<Array<number>>([...defaultRoutes].map((_, index) => index));
  const [routes, setRoutes] = useState<Array<HTTPRoute$data>>([...defaultRoutes]);
  const [, , setNotification] = useNotification();

  const addRoute = useCallback((position) => {
    const newRoute = {
      match: [
        {
          matchType: '',
          matchValue: '',
          parameter: '',
          parameterName: '',
        },
      ],
      route: [
        {
          destination: { id: '', name: '' },
          weight: 0,
        },
      ],
    };

    if (!listRef.current) {
      setOrder([0]);
    }

    listRef.current?.addRoute(position);

    setRoutes((r) => {
      if (position === 'top') {
        // $FlowFixMe
        return [newRoute, ...r];
      }
      return [...r, newRoute];
    });

    listRef.current?.reOrder();
  }, []);

  const [commit, commitPending] = useMutation<RoutesMutation>(graphql`
    mutation RoutesMutation($input: DestinationInput!) {
      updateNetworking(input: $input) {
        __typename
        ... on Environment {
          id
          name
          routes {
            match {
              parameter
              parameterName
              matchType
              matchValue
            }
            route {
              weight
              destination {
                id
                name
              }
            }
          }
        }
        ... on Implementation {
          id
          name
          routes {
            match {
              parameter
              parameterName
              matchType
              matchValue
            }
            route {
              weight
              destination {
                id
                name
              }
            }
          }
        }
      }
    }
  `);

  const handleOnSubmit = useCallback(() => {
    const result = routes.map((r) => ({
      match: r.match,
      route: r.route.map((d) => ({
        ...d,
        destination: d.destination.id,
      })),
    }));

    commit({
      variables: {
        input: {
          target,
          routes: order.map((i) => result[i]),
        },
      },
      onCompleted(response) {
        if (response?.updateNetworking) {
          setNotification({
            id: new Date().getTime().toString(),
            type: 'SUCCESS',
            message: { id: 'networkUpdate.success' },
          });
        }
      },
      onError(error) {
        setNotification({
          id: new Date().getTime().toString(),
          type: 'ERROR',
          // $FlowIgnore
          message: error.source.errors[0].message,
        });
      },
    });
  }, [commit, order, routes, setNotification, target]);

  if (routes.length === 0)
    return (
      <Flexbox justifyContent="center">
        <ComponentDescriptor
          link="/"
          icon="router"
          callback={() => addRoute('top')}
          extraDescription="ilb.description"
          callToActionLabel="router.addNew"
          gradient={['cyan', 'blue']}
        />
      </Flexbox>
    );

  return (
    <div className={stylex(styles.rulesComponent)}>
      <Padding className={stylex(styles.container)}>
        <Margin bottom={20} className={stylex(styles.routesMargin)}>
          {routes.length > 1 ? (
            <Margin bottom={24}>
              <Flexbox flexDirection={width <= 1024 ? 'column' : 'row'}>
                <Button
                  intlId="router.addNew"
                  icon="add"
                  onClick={() => addRoute('top')}
                  buttonType="secondary"
                />
              </Flexbox>
            </Margin>
          ) : null}

          <Flexbox flexDirection="column" rowGap={24}>
            <DraggableList
              type={type}
              ref={listRef}
              items={routes}
              target={target}
              setRoutes={setRoutes}
              onChangeOrder={setOrder}
            />
          </Flexbox>

          <LiteButton intlId="router.addNew" icon="add" onClick={() => addRoute('bottom')} />
        </Margin>

        {routes.length > 0 ? (
          <Padding className={stylex(styles.footer)} bottom={16} horizontal={16}>
            <Divider />
            <Padding top={16}>
              <Flexbox alignItems="center" justifyContent="space-between">
                <Flexbox alignItems="center" columnGap={24}>
                  <Button
                    intlId="save"
                    buttonType="primary"
                    onClick={handleOnSubmit}
                    disabled={commitPending || !routes.length}
                  />
                  {commitPending && <Spinner size={24} />}
                </Flexbox>
                <Button
                  intlId="cancel"
                  buttonType="secondary"
                  onClick={() => {
                    setRoutes([...defaultRoutes]);
                  }}
                >
                  Cancel
                </Button>
              </Flexbox>
            </Padding>
          </Padding>
        ) : null}
      </Padding>
    </div>
  );
}
