// @flow
import stylex from '@serpa-cloud/stylex';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { graphql, useFragment, fetchQuery, useRelayEnvironment } from 'react-relay';

import {
  Icon,
  Card,
  Text,
  Margin,
  Spinner,
  Padding,
  Flexbox,
  TapIcon,
  Divider,
  LiteButton,
} from '../../../shared';

import useDevice from '../../../shared/hooks/useDevice';

import RegisterDNSValidation from './RegisterDNSValidation';
import type { Register$key } from './__generated__/Register.graphql';
import RegisterDNSValidationQuery from './__generated__/RegisterDNSValidationQuery.graphql';

type Props = {|
  +ingressHost: string,
  +node?: ?Register$key,
  +defaultDomain: boolean,
  +ingressRecordType: string,
|};

const styles = stylex.create({
  activeTab: {
    borderBottom: '2px solid var(--neutral-color-800)',
  },
  inactiveTab: {
    borderBottom: '1px solid transparent',
  },
  spinnerContainer: {
    width: 16,
    height: 16,
  },
  pre: {
    overflow: 'auto',
    marginTop: 0,
    marginBottom: 4,
  },
  noShrink: {
    minWidth: 1,
    maxWidth: '100%',
    flex: '0 0 auto',
  },
  overflow: {
    overflow: 'auto',
  },
  table: {
    flex: 1,
    minWidth: 1,
    overflow: 'auto',
    maxWidth: '100%',
  },
  valueCell: {
    maxWidth: '20%',
  },
});

export default function Register({
  node,
  ingressHost,
  defaultDomain,
  ingressRecordType,
}: Props): React$Node {
  const { width } = useDevice();
  const environment = useRelayEnvironment();
  const [dnsValidationPending, setDNSValidationPending] = useState(false);

  const data = useFragment(
    graphql`
      fragment Register on DomainMapping {
        id
        domain
        cert {
          id
          domainName
          provider
          arn
          dnsRecords {
            domainName
            name
            type
            value
          }
        }
      }
    `,
    node,
  );

  const domainName = useMemo(() => {
    return (
      (data?.domain
        ?.split('.')
        ?.slice(0, -2)
        ?.join('.') ??
        '') ||
      '@'
    );
  }, [data?.domain]);

  const recordsToCheck = useMemo(() => {
    const recordsToCheckRaw = [
      {
        type: ingressRecordType,
        name: data?.domain ?? '',
        value: ingressHost,
      },
    ];

    data?.cert.dnsRecords.forEach((record) => {
      recordsToCheckRaw.push({
        type: record.type,
        name: record.name,
        value: record.value,
      });
    });

    return recordsToCheckRaw
      .filter((r) => r.type && r.name && r.value)
      .map((r) => {
        return {
          type: r.type,
          name: r.name.endsWith('.') ? r.name.slice(0, -1) : r.name,
          value: r.value.endsWith('.') ? r.value.slice(0, -1) : r.value,
        };
      });
  }, [data?.cert.dnsRecords, data?.domain, ingressHost, ingressRecordType]);

  const checkDNSValidation = useCallback(() => {
    setDNSValidationPending(true);

    fetchQuery(environment, RegisterDNSValidationQuery, {
      records: recordsToCheck,
    }).subscribe({
      complete() {
        setDNSValidationPending(false);
      },
    });
  }, [environment, recordsToCheck]);

  const pendingValidationElement = useMemo(
    () => (
      <Flexbox alignItems="center" columnGap={8}>
        <Padding vertical={4} horizontal={4} className={stylex(styles.spinnerContainer)}>
          <Spinner size={16} />
        </Padding>
        <Text id="domains.validatingRecords" type="s0r" color="--primary-color-600" />
      </Flexbox>
    ),
    [],
  );

  if (!data) return null;

  return (
    <Card>
      <Padding horizontal={20} top={20}>
        <Flexbox justifyContent="space-between" alignItems="center">
          <a href={`https://${data.domain}`} target="_blank" rel="noreferrer">
            <Text type="s1b" color="--neutral-color-800">
              {data.domain}
            </Text>
          </a>

          <Flexbox>
            {width > 480 ? (
              <LiteButton intlId="validate" icon="sync" showShadow onClick={checkDNSValidation} />
            ) : (
              <TapIcon icon="sync" iconColor="--primary-color-1" onClick={checkDNSValidation} />
            )}
          </Flexbox>
        </Flexbox>
        <Margin top={20} bottom={24}>
          {defaultDomain && (
            <Flexbox alignItems="center" columnGap={8}>
              <Icon icon="check_circle" fill color="--green-300" size={24} />
              <Text id="domains.validRecords" type="s0r" color="--neutral-color-600" />
            </Flexbox>
          )}
          {!defaultDomain &&
            (dnsValidationPending ? (
              pendingValidationElement
            ) : (
              <Suspense fallback={pendingValidationElement}>
                <RegisterDNSValidation records={recordsToCheck} />
              </Suspense>
            ))}
        </Margin>
      </Padding>

      {!defaultDomain && (
        <>
          <Divider />
          <Padding horizontal={20} top={24}>
            <Flexbox columnGap={16}>
              <Padding horizontal={12} bottom={16} className={stylex(styles.activeTab)}>
                <Text type="s0m" color="--neutral-color-800" id="domains.CNAMERecords" />
              </Padding>
            </Flexbox>
            <Divider />
          </Padding>
          <Padding horizontal={20} bottom={20}>
            <Padding top={24} bottom={24}>
              <Text id="domains.instructions" type="s0r" color="--neutral-color-800" />
            </Padding>
            <Flexbox flexDirection="column" rowGap={20}>
              <Card>
                <Padding horizontal={12} top={16} bottom={12}>
                  <Flexbox
                    alignItems="flex-start"
                    columnGap={20}
                    flexWrap="wrap"
                    className={stylex(styles.table)}
                  >
                    <Flexbox flexDirection="column" rowGap={16} className={stylex(styles.noShrink)}>
                      <Text id="domain.type" type="s0r" />
                      <Text type="s0m" color="--neutral-color-800">
                        <pre className={stylex(styles.pre)}>{ingressRecordType}</pre>
                      </Text>
                    </Flexbox>

                    <Flexbox
                      flexDirection="column"
                      rowGap={16}
                      className={stylex(styles.noShrink, styles.valueCell)}
                    >
                      <Text id="domain.name" type="s0r" />
                      <Text type="s0m" color="--neutral-color-800">
                        <pre className={stylex(styles.pre)}>{domainName}</pre>
                      </Text>
                    </Flexbox>

                    <Flexbox
                      flexDirection="column"
                      rowGap={16}
                      className={stylex(styles.noShrink, styles.overflow)}
                    >
                      <Text id="domain.value" type="s0r" />
                      <Text type="s0m" color="--neutral-color-800">
                        <pre className={stylex(styles.pre)}>{ingressHost}</pre>
                      </Text>
                    </Flexbox>
                  </Flexbox>
                </Padding>
              </Card>

              {data.cert.dnsRecords.map((record, index) => {
                const recordName =
                  record.name
                    ?.split('.')
                    ?.slice(0, -2)
                    ?.join('.') ?? '';

                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Card key={`${record.name}-${index}`}>
                    <Padding horizontal={12} top={16} bottom={12}>
                      <Flexbox
                        alignItems="flex-start"
                        columnGap={20}
                        flexWrap="wrap"
                        className={stylex(styles.table)}
                      >
                        <Flexbox
                          flexDirection="column"
                          rowGap={16}
                          className={stylex(styles.noShrink)}
                        >
                          <Text id="domain.type" type="s0r" />
                          <Text type="s0m" color="--neutral-color-800">
                            <pre className={stylex(styles.pre)}>{record.type}</pre>
                          </Text>
                        </Flexbox>

                        <Flexbox
                          flexDirection="column"
                          rowGap={16}
                          className={stylex(styles.noShrink, styles.valueCell)}
                        >
                          <Text id="domain.name" type="s0r" />
                          <Text type="s0m" color="--neutral-color-800">
                            <pre className={stylex(styles.pre)}>{recordName || '@'}</pre>
                          </Text>
                        </Flexbox>

                        <Flexbox
                          flexDirection="column"
                          rowGap={16}
                          className={stylex(styles.noShrink, styles.overflow)}
                        >
                          <Text id="domain.value" type="s0r" />
                          <Text type="s0m" color="--neutral-color-800">
                            <pre className={stylex(styles.pre)}>{record.value}</pre>
                          </Text>
                        </Flexbox>
                      </Flexbox>
                    </Padding>
                  </Card>
                );
              })}
            </Flexbox>
            <Padding top={32}>
              <Flexbox alignItems="center" columnGap={8}>
                <Icon icon="info" size={16} fill color="--neutral-color-400" weight={700} />
                <Text
                  type="bs"
                  id="domains.info"
                  color="--neutral-color-600"
                  values={{
                    l: (s) => (
                      <a href="https://docs.serpa.cloud" target="_blank" rel="noreferrer">
                        {s}
                      </a>
                    ),
                  }}
                />
              </Flexbox>
            </Padding>
          </Padding>
        </>
      )}
    </Card>
  );

  /* return (
    <div>
      <Card>
        <Padding vertical={8} horizontal={16}>
          <Flexbox
            alignItems={screenSize === 'phone' ? 'flex-start' : 'center'}
            justifyContent="space-between"
            rowGap={8}
            className={stylex(screenSize !== 'phone' ? styles.head : null)}
            flexDirection={screenSize === 'phone' ? 'column' : 'row'}
          >
            <a href={`https://${data.domain}`} target="_blank" rel="noreferrer">
              <Flexbox alignItems="center" columnGap={8}>
                <Icon icon="open_in_new" size={20} color="--neutral-color-400" />
                <Text type="s0b" color="--primary-color-1">
                  {data.domain}
                </Text>
              </Flexbox>
            </a>

            {!defaultDomain && (
              <InteractiveElement onClick={handleOnToggle}>
                <Padding left={12} right={8} vertical={4}>
                  <Flexbox alignItems="center" columnGap={4}>
                    <Text id={show ? 'dns.hideRegisters' : 'dns.showRegisters'} type="s0m" />
                    <Icon icon={show ? 'expand_less' : 'expand_more'} />
                  </Flexbox>
                </Padding>
              </InteractiveElement>
            )}
            {defaultDomain && (
              <div className={stylex(styles.defaultDomain)}>
                <Padding horizontal={16} vertical={8}>
                  <Text id="dns.defaultDomain" type="s0m" color="--neutral-color-600" />
                </Padding>
              </div>
            )}
          </Flexbox>
        </Padding>
      </Card>

      <Accordeon show={show} onChange={handleOnToggle}>
        <Padding top={16} bottom={16}>
          <Padding bottom={16} top={16} right={16} className={stylex(styles.instructions)}>
            <Flexbox alignItems="center">
              <Padding left={16} right={8}>
                <Icon fill size={24} icon="integration_instructions" color="--orange-300" />
              </Padding>
              <Flexbox flexDirection="column">
                <Text id="dns.records.description" type="bs" />
              </Flexbox>
            </Flexbox>
          </Padding>
        </Padding>
        <Padding bottom={24}>
          <Flexbox flexDirection="column" rowGap={16}>
            <Record
              title="dns.domainRecordTitle"
              type={recordType}
              value={ingressHost}
              prop={data.domain}
            />
            {data.cert.dnsRecords.map((record) => (
              <Record
                key={record.domainName}
                title="dns.certRecordTitle"
                type={record.type}
                value={record.value}
                prop={record.name}
              />
            ))}
          </Flexbox>
        </Padding>
      </Accordeon>
    </div>
  ); */
}

Register.defaultProps = {
  node: null,
};
