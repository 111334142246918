// @flow

import Logs from '../Logs';
import DeploymentResume from '../Settings/DeploymentResume';

type Props = {|
  +deploymentId: string,
|};

export default function Dashboard({ deploymentId }: Props): React$Node {
  return (
    <div>
      <DeploymentResume deploymentId={deploymentId} />
      <Logs deploymentId={deploymentId} />
    </div>
  );
}
