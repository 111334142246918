/**
 * @generated SignedSource<<8e134a31419f72265e2f3930a06b28bf>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type Pronoun = "HE" | "SHE" | "THEY" | "OTHER" | "%future added value";
export type DeleteAppModalQuery$variables = {||};
export type DeleteAppModalQuery$data = {|
  +me: ?{|
    +pronoun: ?Pronoun,
  |},
|};
export type DeleteAppModalQuery = {|
  variables: DeleteAppModalQuery$variables,
  response: DeleteAppModalQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pronoun",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteAppModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DeleteAppModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "57894606071c795a31b56bd1971459ac",
    "id": null,
    "metadata": {},
    "name": "DeleteAppModalQuery",
    "operationKind": "query",
    "text": "query DeleteAppModalQuery {\n  me {\n    pronoun\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "81ea80fd8ece0805a2775dd76b12879d";

module.exports = ((node/*: any*/)/*: Query<
  DeleteAppModalQuery$variables,
  DeleteAppModalQuery$data,
>*/);
