// @flow
import stylex from '@serpa-cloud/stylex';

import { Card, Padding, Text, Margin, Flexbox, useInterval } from '../../shared';

import { ReactComponent as Logo } from './iconLogo.svg';

const styles = stylex.create({
  container: {
    maxWidth: 680,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    width: '100%',
    paddingTop: 24,
    paddingBottom: 40,
    paddingLeft: 16,
    paddingRight: 16,
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    boxSizing: 'border-box',
    flexDirection: 'column',
  },
  header: {
    width: '100%',
  },
  section: {
    width: '100%',
  },
  list: {
    margin: 0,
  },
  elementList: {
    marginTop: 24,
    marginBottom: 24,
  },
});

export default function DeploymentError(): React$Node {
  useInterval(() => {
    // eslint-disable-next-line no-self-assign
    window.location.href = window.location.href;
  }, 30000);

  return (
    <div className={stylex(styles.container)}>
      <header className={stylex(styles.header)}>
        <Logo width={56} height={56} />
      </header>

      <Margin top={24} className={stylex(styles.section)}>
        <section>
          <Flexbox flexDirection="column" rowGap={16}>
            <Text id="networkingDeploymentError" type="h4" />
            <Text id="networkingDeploymentErrorDescription" type="bd" />
          </Flexbox>

          <Margin top={40}>
            <ul className={stylex(styles.list)}>
              <li className={stylex(styles.elementList)}>
                <Text
                  id="networkingDeploymentError3"
                  type="bs"
                  color="--neutral-color-700"
                  values={{
                    b: (str) => <strong style={{ color: 'var(--primary-color-1)' }}>{str}</strong>,
                    d: (str) => (
                      <strong style={{ color: 'var(--neutral-color-800)' }}>{str}</strong>
                    ),
                  }}
                />
              </li>
              <li className={stylex(styles.elementList)}>
                <Text
                  id="networkingDeploymentError1"
                  type="bs"
                  color="--neutral-color-700"
                  values={{
                    b: (str) => <strong style={{ color: 'var(--primary-color-1)' }}>{str}</strong>,
                    d: (str) => (
                      <strong style={{ color: 'var(--neutral-color-800)' }}>{str}</strong>
                    ),
                  }}
                />
              </li>

              <li className={stylex(styles.elementList)}>
                <Text
                  id="networkingDeploymentError4"
                  type="bs"
                  color="--neutral-color-700"
                  values={{
                    b: (str) => <strong style={{ color: 'var(--primary-color-1)' }}>{str}</strong>,
                    d: (str) => (
                      <strong style={{ color: 'var(--neutral-color-800)' }}>{str}</strong>
                    ),
                  }}
                />
              </li>

              <li className={stylex(styles.elementList)}>
                <Text
                  id="networkingDeploymentError2"
                  type="bs"
                  color="--neutral-color-700"
                  values={{
                    b: (str) => <strong style={{ color: 'var(--primary-color-1)' }}>{str}</strong>,
                  }}
                />
              </li>
            </ul>
          </Margin>

          <Margin top={40}>
            <Card>
              <Padding vertical={16} horizontal={16}>
                <Text
                  type="bs"
                  id="networkingDeploymentErrorSupport"
                  values={{
                    a: (str) => (
                      <a
                        target="_blank"
                        href="https://join.slack.com/t/serpacloudtechsupport/shared_invite/zt-2mtkez0tw-Yxy6bTOILZypZXDs2_jwCg"
                        style={{ color: 'var(--primary-color-1)' }}
                        rel="noreferrer"
                      >
                        {str}
                      </a>
                    ),
                  }}
                />
              </Padding>
            </Card>
          </Margin>
        </section>
      </Margin>
    </div>
  );
}
