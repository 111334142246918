// @flow
import { Link } from 'react-router-dom';
import stylex from '@serpa-cloud/stylex';
import { Icon, Flexbox, Text, Padding } from '../../shared';

type Props = {|
  +icon: string,
  +intlId: string,
  +link: string,
|};

const styles = stylex.create({
  root: {
    height: 36,
    display: 'flex',
    borderRadius: 8,
    alignItems: 'center',
    boxSizing: 'border-box',
    transitionProperty: 'all',
    transitionDuration: 'var(--fds-duration-short-in)',
    transitionTimingFunction: 'var(--fds-animation-fade-in)',
    ':hover': {
      backgroundColor: 'var(--hover-overlay)',
      transitionDuration: 'var(--fds-duration-short-out)',
      transitionTimingFunction: 'var(--fds-animation-fade-out)',
    },
  },
  link: {
    width: '100%',
    display: 'block',
    position: 'relative',
    textDecoration: 'none!important',
  },
  emojiContainer: {
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default function MenuSection({ icon, intlId, link }: Props): React$Node {
  return (
    <Link to={link} className={stylex(styles.link)}>
      <Padding horizontal={8} vertical={8} className={stylex(styles.root)}>
        <Flexbox alignItems="center" columnGap={12}>
          <div className={stylex(styles.emojiContainer)}>
            <Icon icon={icon} size={20} />
          </div>
          <Text type="s0m" id={intlId} color="--neutral-color-600" />
        </Flexbox>
      </Padding>
    </Link>
  );
}
