// @flow
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, matchPath } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';

import spanishTranslations from './translations/spanish.json';
import englishTranslations from './translations/english.json';

type Props = {|
  +children: React$Node,
|};

const patterns = [
  {
    path: '/',
    title: 'pageView.landingPage',
  },
  {
    path: '/session/signin',
    title: 'pageView.login',
  },
  {
    path: '/session/signup',
    title: 'pageView.signUp',
  },
  {
    path: '/session/signup',
    title: 'pageView.signUp',
  },
  {
    path: '/session/recovery-password',
    title: 'pageView.recoveryPassword',
  },
  {
    path: '/app',
    title: 'pageView.dashboard',
  },
  {
    path: '/app/create-profile',
    title: 'pageView.profile',
  },
  {
    path: '/app/deploy',
    title: 'pageView.deploymentCatalog',
  },
  {
    path: '/app/apps',
    title: 'pageView.microapps',
  },
  {
    path: '/app/apps/:id/deployments',
    title: 'pageView.deploymentsInApp',
  },
  {
    path: '/app/apps/:id/compilations',
    title: 'pageView.buildsInApp',
  },
  {
    path: '/app/apps/:id/compilations/create',
    title: 'pageView.buildSelectSource',
  },
  {
    path: '/app/apps/:id/settings',
    title: 'pageView.microappsSettings',
  },
  {
    path: '/app/cloud-accounts',
    title: 'pageView.cloudAccount',
  },
  {
    path: '/app/cloud-accounts/create',
    title: 'pageView.connectCloudAccount',
  },
  {
    path: '/app/clusters',
    title: 'pageView.clusters',
  },
  {
    path: '/app/clusters/create',
    title: 'pageView.createClusters',
  },
  {
    path: '/app/environments',
    title: 'pageView.environment',
  },
  {
    path: '/app/environments/:id/deployments',
    title: 'pageView.deploymentsInEnv',
  },
  {
    path: '/app/environments/:id/router',
    title: 'pageView.ilb',
  },
  {
    path: '/app/environments/:id/dns',
    title: 'pageView.domainManager',
  },
  {
    path: '/app/environments/:id/dns/create',
    title: 'pageView.addDomain',
  },
  {
    path: '/app/builds',
    title: 'pageView.builds',
  },
  {
    path: '/app/build/:id',
    title: 'pageView.buildLogs',
  },
  {
    path: '/app/deployments/:id/settings',
    title: 'pageView.deploymentSettings',
  },
  {
    path: '/app/deployments/:id/logs',
    title: 'pageView.deploymentsLogs',
  },
  {
    path: '/app/apps/create/catalog',
    title: 'appCreator.deploymentCatalogTitle',
  },
  {
    path: '/app/apps/create/git',
    title: 'appCreator.gitSelectorTitle',
  },
  {
    path: '/app/apps/create/name',
    title: 'appCreator.appNameTitle',
  },
  {
    path: '/app/apps/create/setup',
    title: 'appCreator.setup',
  },
  {
    path: '/app/deployments',
    title: 'menu.deployments',
  },
  {
    path: '/app/deployments/select-source',
    title: 'pageview.selectDeploymentSource',
  },
  {
    path: '/app/deployments/create',
    title: 'pageview.createDeployment',
  },
  {
    path: '/app/onboarding',
    title: 'home.onboarding',
  },
];

export function getDocumentTitle(pathname: string, locale: string): string {
  const matchResult = patterns.find((route) => matchPath(route.path, pathname));

  let translations = englishTranslations;

  if (locale === 'es') {
    translations = spanishTranslations;
  }

  if (matchResult) {
    const message: string = translations[matchResult.title];

    if (!message) return 'Serpa Cloud';

    return `${message} | Serpa Cloud`;
  }

  return 'Serpa Cloud';
}

export default function Analytics({ children }: Props): React$Node {
  const intl = useIntl();
  const { pathname } = useLocation();

  useEffect(() => {
    const matchResult = patterns.find((route) => matchPath(route.path, pathname));

    if (matchResult) {
      const message = intl.formatMessage({ id: matchResult.title });

      document.title = `${message} | Serpa Cloud`;

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'page_view' });

      amplitude.track('[Amplitude] Page Viewed', {
        '[Amplitude] Page Domain': window.location.hostname,
        '[Amplitude] Page Location': window.location.href,
        '[Amplitude] Page Path': window.location.path,
        '[Amplitude] Page Title': `${message} | Serpa Cloud`,
        '[Amplitude] Page URL': window.location.href.split('?')[0],
      });

      window.flowlog?.track('$pageview');
    }
  }, [intl, pathname]);

  return children;
}
