// @flow
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useContext, useCallback, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';

import { Context } from '../AppCreator/Provider';

import {
  Icon,
  Card,
  Text,
  Button,
  Margin,
  Padding,
  Flexbox,
  RadioButton,
  usePageView,
} from '../../shared';

import useDevice from '../../shared/hooks/useDevice';

import { ReactComponent as GitIcon } from './git.svg';
import { ReactComponent as DockerIcon } from './docker.svg';

export default function DeploymentCatalog(): React$Node {
  const { screenSize } = useDevice();
  const intl = useIntl();
  usePageView(intl.formatMessage({ id: 'pageView.deploymentCatalog' }));

  const navigate = useNavigate();
  const [method, setMethod] = useState('GIT');

  const { dispatch } = useContext(Context);

  const handleOnContinue = useCallback(() => {
    amplitude.track('App Method Selected');

    amplitude.track(`${method} App Selected`);

    dispatch({ type: 'SET_METHOD', method });

    if (method === 'GIT') {
      navigate('/app/apps/create/git');
    } else if (method === 'DOCKER') {
      navigate('/app/apps/create/name');
    }
  }, [dispatch, method, navigate]);

  return (
    <section>
      <Padding bottom={40}>
        <Flexbox flexDirection="column" rowGap={24}>
          <Flexbox alignItems="center" columnGap={8}>
            <Icon
              fill
              icon="token"
              gradient="linear-gradient(265.7deg, var(--orange-solid-color) -2.24%, var(--red-solid-color) 102.98%)"
            />
            <Flexbox flexDirection="column">
              <Text
                type={screenSize === 'phone' ? 'h6' : 'h5'}
                component="h1"
                id="appCreator.deploymentCatalogTitle"
              />
            </Flexbox>
          </Flexbox>
          <Text
            id="appCreator.deploymentCatalogDescription"
            type={screenSize === 'phone' ? 'bs' : 'bd'}
          />
        </Flexbox>
      </Padding>

      <Flexbox flexDirection="column" rowGap={16}>
        <Card>
          <Padding vertical={24} horizontal={16}>
            <Flexbox alignItems="center" justifyContent="space-between">
              <Flexbox alignItems="center" columnGap={16}>
                <GitIcon width={24} height={24} />
                <Flexbox flexDirection="column" rowGap={12}>
                  <Text type="s1b" id="gitRepo" color="--neutral-color-800" />
                  <Text type="s1r" id="gitRepoDescription" color="--neutral-color-600" />
                </Flexbox>
              </Flexbox>
              <div style={{ minWidth: 24 }}>
                <RadioButton checked={method === 'GIT'} onChange={() => setMethod('GIT')} />
              </div>
            </Flexbox>
          </Padding>
        </Card>

        <Card>
          <Padding vertical={24} horizontal={16}>
            <Flexbox alignItems="center" justifyContent="space-between">
              <Flexbox alignItems="center" columnGap={16}>
                <DockerIcon width={24} height={24} />
                <Flexbox flexDirection="column" rowGap={12}>
                  <Text type="s1b" id="dockerImage" color="--neutral-color-800" />
                  <Text type="s1r" id="dockerImageDescription" color="--neutral-color-600" />
                </Flexbox>
              </Flexbox>
              <div style={{ minWidth: 24 }}>
                <RadioButton checked={method === 'DOCKER'} onChange={() => setMethod('DOCKER')} />
              </div>
            </Flexbox>
          </Padding>
        </Card>
      </Flexbox>

      <Margin top={32}>
        <Flexbox flexDirection={screenSize === 'phone' ? 'column' : 'row'}>
          <Button intlId="continue" iconRight="arrow_forward" onClick={handleOnContinue} />
        </Flexbox>
      </Margin>
    </section>
  );
}
