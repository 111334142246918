// @flow
import stylex from '@serpa-cloud/stylex';
import { graphql, useLazyLoadQuery } from 'react-relay';

import { Text, Input, Flexbox, CascaderOption } from '../../../shared';

import awsLogo from './icon_aws.png';
import gcloudLogo from './icon_gcloud.png';

import { ReactComponent as Logo } from './sierra-negra.svg';

import type { inputRef } from '../../../shared/Input';

const styles = stylex.create({
  providerContainer: {
    width: 24,
    height: 24,
    flexShrink: 0,
    objectFit: 'contain',
    objectPosition: 'center center',
  },
  dot: {
    width: 8,
    height: 8,
    flexShrink: 0,
    borderRadius: 8,
  },
  PENDING: {
    backgroundColor: 'var(--orange-300)',
  },
  DELETE_PENDING: {
    backgroundColor: 'var(--orange-300)',
  },
  CONNECTED: {
    backgroundColor: 'var(--green-300)',
  },
  DELETE: {
    backgroundColor: 'var(--red-300)',
  },
});

type Props = {|
  +input: inputRef,
|};

export default function ClusterSelector({ input }: Props): React$Node {
  const data = useLazyLoadQuery(
    graphql`
      query ClusterSelectorQuery(
        $first: Int
        $after: Cursor
        $sort: SortInput
        $index: ElasticIndex
        $filterMatrix: [[FilterInput]]
      ) {
        entities(
          sort: $sort
          first: $first
          after: $after
          index: $index
          filterMatrix: $filterMatrix
        ) {
          pageInfo {
            hasNextPage
            endCursor
            finalCursor
          }
          edges {
            id
            cursor
            node {
              __typename
              ... on KubernetesCluster {
                id
                provider
                namespace
                clusterId
                clusterStatus: status
              }
            }
          }
        }
      }
    `,
    {
      first: 99,
      index: 'KUBERNETES',
      filterMatrix: [
        [
          {
            property: 'status.keyword',
            type: 'term',
            value: 'CONNECTED',
          },
          {
            property: 'status.keyword',
            type: 'term',
            value: 'PENDING',
          },
        ],
      ],
    },
    {
      fetchPolicy: 'store-and-network',
    },
  );

  return (
    <Input input={input}>
      <CascaderOption
        value="DEFAULT"
        placeholderLabel={
          <Flexbox alignItems="center" columnGap={8}>
            <div className={stylex(styles.providerContainer)}>
              <Logo width={24} height={24} />
            </div>
            <Text type="s0m" color="--neutral-color-800" id="sierraNegraManaged" />
          </Flexbox>
        }
        label={
          <Flexbox alignItems="center" columnGap={8}>
            <div className={stylex(styles.dot, styles.CONNECTED)} />

            <div className={stylex(styles.providerContainer)}>
              <Logo width={24} height={24} />
            </div>
            <Text type="s0m" color="--neutral-color-800" id="sierraNegraManaged" />
          </Flexbox>
        }
      />
      {data?.entities?.edges?.map((edge) => {
        if (!edge?.node) return null;

        const { node } = edge;

        // eslint-disable-next-line no-underscore-dangle
        if (node.__typename === 'KubernetesCluster')
          return (
            <CascaderOption
              key={node.id}
              value={node.id}
              selectable={node?.clusterStatus === 'CONNECTED'}
              placeholderLabel={
                <Flexbox alignItems="center" columnGap={8}>
                  <img
                    width={20}
                    className={stylex(styles.providerContainer)}
                    alt={node.provider === 'AWS' ? 'AWS' : 'Google Cloud'}
                    src={node.provider === 'AWS' ? awsLogo : gcloudLogo}
                  />

                  <Text type="s0m" color="--neutral-color-800">
                    {`@${node.namespace}/${node.clusterId}`}
                  </Text>
                </Flexbox>
              }
              label={
                <Flexbox alignItems="center" columnGap={8}>
                  <div className={stylex(styles.dot, styles[node?.clusterStatus ?? 'PENDING'])} />

                  <img
                    width={20}
                    className={stylex(styles.providerContainer)}
                    alt={node.provider === 'AWS' ? 'AWS' : 'Google Cloud'}
                    src={node.provider === 'AWS' ? awsLogo : gcloudLogo}
                  />

                  <Text type="s0m" color="--neutral-color-800">
                    {`@${node.namespace}/${node.clusterId}`}
                  </Text>
                </Flexbox>
              }
            />
          );

        return null;
      })}
    </Input>
  );
}
