/**
 * @generated SignedSource<<2a23b123c812a9808c49e285b5b9e4fa>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
type EnvironmentElementDetail$fragmentType = any;
export type EnvironmentInput = {|
  name: string,
  env?: ?$ReadOnlyArray<EnvInput>,
  kubernetesResource: string,
|};
export type EnvInput = {|
  name: string,
  value: string,
|};
export type FormEnvironmentMutation$variables = {|
  input: EnvironmentInput,
|};
export type FormEnvironmentMutation$data = {|
  +createEnvironment: {|
    +id: string,
    +$fragmentSpreads: EnvironmentElementDetail$fragmentType,
  |},
|};
export type FormEnvironmentMutation = {|
  variables: FormEnvironmentMutation$variables,
  response: FormEnvironmentMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FormEnvironmentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Environment",
        "kind": "LinkedField",
        "name": "createEnvironment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EnvironmentElementDetail"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FormEnvironmentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Environment",
        "kind": "LinkedField",
        "name": "createEnvironment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "namespace",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "host",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAtFormatted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deploymentCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "secretsCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c249633fb95373dbfe4b6e1939c1ccd6",
    "id": null,
    "metadata": {},
    "name": "FormEnvironmentMutation",
    "operationKind": "mutation",
    "text": "mutation FormEnvironmentMutation(\n  $input: EnvironmentInput!\n) {\n  createEnvironment(input: $input) {\n    id\n    ...EnvironmentElementDetail\n  }\n}\n\nfragment EnvironmentElementDetail on Environment {\n  id\n  name\n  namespace\n  host\n  createdAtFormatted\n  deploymentCount\n  secretsCount\n}\n"
  }
};
})();

(node/*: any*/).hash = "df12ecd98a8e1e87a2c2e3aaf6ee0aad";

module.exports = ((node/*: any*/)/*: Mutation<
  FormEnvironmentMutation$variables,
  FormEnvironmentMutation$data,
>*/);
