/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// @flow

import stylex from '@serpa-cloud/stylex';
import { useCallback, useEffect, useRef } from 'react';

import { useModals } from './ElementModalContext';

const styles = stylex.create({
  content: {
    top: 40,
    right: 0,
    bottom: 0,
    position: 'fixed',
    zIndex: 999999,

    boxShadow: 'var(--shadow-1)',
    borderRadius: 8,
    border: '1px solid var(--border-color)',
    '@media (min-width: 1900px)': {
      width: 1200,
    },
    '@media (min-width: 1536px) and (max-width: 1900px)': {
      width: 1024,
    },
    '@media (min-width: 1280px)  and (max-width: 1536px)': {
      width: 920,
    },
    '@media (min-width: 1024px)  and (max-width: 1280px)': {
      width: '70%',
    },
    '@media (max-width: 1024px)': {
      width: '100%',
      top: 0,
      borderRadius: 0,
    },
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 8,
    backgroundColor: 'var(--surface-background)',
    backdropFilter: 'saturate(180%) blur(40px)',
    '@media (max-width: 1024px)': {
      borderRadius: 0,
    },
  },
  innerContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
  },
  children: {
    overflow: 'auto',
    overscrollBehavior: 'contain',
    position: 'relative',
  },
});

type Props = {|
  +id?: ?string,
  +header?: ?React$Node,
  +children: React$Node,
|};

export default function ElementModal({ id, header, children }: Props): React$Node {
  const headerRef = useRef();
  const containerRef = useRef();
  const childrenRef = useRef(null);
  const { modals, registerModal, unregisterModal } = useModals();

  console.log({ modals });

  const handleLayout = useCallback(() => {
    if (containerRef.current && headerRef.current) {
      const containerHeight = containerRef.current.offsetHeight;
      const headerHeight = headerRef.current.offsetHeight;
      const contentHeight = containerHeight - headerHeight;

      // Ajusta la altura del div que contiene a children
      if (childrenRef.current) {
        childrenRef.current.style.height = `${contentHeight - 2}px`;
      }
    }
  }, []);

  useEffect(() => {
    handleLayout();

    window.addEventListener('resize', handleLayout); // Ajusta en el redimensionamiento de la ventana

    return () => {
      window.removeEventListener('resize', handleLayout); // Limpieza al desmontar
    };
  }, [header, children, handleLayout]);

  useEffect(() => {
    const modal = { id: id || containerRef.current, label: id, ref: containerRef };

    registerModal(modal);

    return () => {
      unregisterModal(modal);
    };
  }, [registerModal, unregisterModal, id]);

  const modalIndex = modals.findIndex((modal) => modal.id === (id || containerRef.current));

  console.log({ modalIndex, id });

  const isLastModal = modalIndex === modals.length - 1;
  const zIndex = 999999 + modalIndex;
  const transformStyle = isLastModal
    ? ''
    : `translate(${(modals.length - (modalIndex + 1)) * -24}px, ${(modals.length -
        (modalIndex + 1)) *
        24}px)`;

  return (
    <div
      className={stylex(styles.content)}
      ref={containerRef}
      style={{
        zIndex,
        transform: transformStyle,
        pointerEvents: isLastModal ? 'auto' : 'none',
      }}
    >
      {modalIndex < modals.length - 2 ? null : (
        <>
          <div className={stylex(styles.overlay)} />
          <div className={stylex(styles.innerContent)}>
            <div ref={headerRef}>{header}</div>
            <div ref={childrenRef} className={stylex(styles.children)}>
              {children}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

ElementModal.defaultProps = {
  id: null,
  header: null,
};
