/**
 * @generated SignedSource<<ebf4ee9b421e14d15c0b6e4f7d5bb342>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type GitProvider = "GITHUB" | "GITLAB" | "%future added value";
export type CompilationSetupQuery$variables = {|
  repo: string,
  provider: GitProvider,
|};
export type CompilationSetupQuery$data = {|
  +dockerFileByRepo: ?string,
|};
export type CompilationSetupQuery = {|
  variables: CompilationSetupQuery$variables,
  response: CompilationSetupQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "provider"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "repo"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "provider",
        "variableName": "provider"
      },
      {
        "kind": "Variable",
        "name": "repo",
        "variableName": "repo"
      }
    ],
    "kind": "ScalarField",
    "name": "dockerFileByRepo",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CompilationSetupQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CompilationSetupQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "1c5306f96428a9e16845249e4339b008",
    "id": null,
    "metadata": {},
    "name": "CompilationSetupQuery",
    "operationKind": "query",
    "text": "query CompilationSetupQuery(\n  $repo: String!\n  $provider: GitProvider!\n) {\n  dockerFileByRepo(repo: $repo, provider: $provider)\n}\n"
  }
};
})();

(node/*: any*/).hash = "6fdb96f9c8116fc09d526371985bb4a4";

module.exports = ((node/*: any*/)/*: Query<
  CompilationSetupQuery$variables,
  CompilationSetupQuery$data,
>*/);
