// @flow
import stylex from '@serpa-cloud/stylex';
import { useCallback, Suspense } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';

import {
  Button,
  Margin,
  Padding,
  Flexbox,
  ScrolledList,
  ComponentDescriptor,
  ServiceCardPlaceholder,
  useFetchPolicy,
  useDevice,
} from '../../shared';

import DeploymentElement from './DeploymentElement';

const styles = stylex.create({
  component: {
    minHeight: 'calc(100vh - 137px)',
  },

  container: {
    position: 'relative',
  },
  deploymentList: {
    position: 'relative',
  },
  repoAvatar: {
    width: 18,
    height: 18,
    borderRadius: 12,
  },
  aside: {
    backgroundColor: 'var(--neutral-color-200)',
  },
  descriptorContainer: {
    top: 91,
    position: 'sticky',
  },
});

type ContainerProps = { +children: React$Node, +empty: boolean };

function DeploymentsContainer({ children, empty }: ContainerProps): React$Node {
  return (
    <div className={stylex(styles.component)}>
      {empty ? (
        <Padding bottom={40} className={stylex(styles.container)}>
          {children}
        </Padding>
      ) : null}
      {!empty ? (
        <Padding bottom={40} className={stylex(styles.deploymentList)}>
          {children}
        </Padding>
      ) : null}
    </div>
  );
}

export default function Deployments(): React$Node {
  const { width } = useDevice();
  const navigate = useNavigate();
  const baseFetchPolicy = useFetchPolicy();
  const match = useMatch({ path: '/app/:component/:id/:section/*', end: true });

  const component = match?.params?.component;
  const componentId = match?.params?.id;

  const renderElement = useCallback((node, key) => <DeploymentElement node={node} key={key} />, []);
  const fallbackElement = useCallback((key) => <ServiceCardPlaceholder key={key} />, []);

  return (
    <Suspense fallback={<div />}>
      <ScrolledList
        first={4}
        index="DEPLOYMENTS"
        fetchPolicy={baseFetchPolicy}
        renderElement={renderElement}
        queryFields={['name', 'deploymentName', 'serviceName']}
        header={
          <Margin bottom={24}>
            <Flexbox flexDirection={width <= 1024 ? 'column' : 'row'}>
              <Button
                intlId={
                  component === 'apps'
                    ? 'deployments.addDeploymentFromApp'
                    : 'deployments.addDeployment'
                }
                onClick={() => {
                  if (component === 'apps') {
                    amplitude.track('Go to Deployment Form');
                    navigate(
                      `/app/apps/${componentId ?? ''}/deployments/create?appId=${componentId ??
                        ''}&recreate=true&enable_deployment=true`,
                    );
                  } else {
                    amplitude.track('Go to Deployment Source Selector');
                    navigate(
                      `/app/deployments/select-source?component=${component ??
                        'none'}&componentId=${componentId ?? 'none'}`,
                    );
                  }
                }}
              />
            </Flexbox>
          </Margin>
        }
        emptyElement={
          <ComponentDescriptor
            link="https://docs.serpa.cloud/introduction"
            icon="rocket_launch"
            gradient={['green', 'cyan']}
            callToActionLabel={
              component === 'apps'
                ? 'deployments.addDeploymentFromApp'
                : 'deployments.addDeployment'
            }
            extraDescription="deployment.description"
            callback={() => {
              if (component === 'apps') {
                amplitude.track('Go to Deployment Form');
                navigate(`create?appId=${componentId ?? ''}&recreate=true&enable_deployment=true`);
              } else {
                amplitude.track('Go to Deployment Source Selector');
                navigate(
                  `/app/deployments/select-source?component=${component ??
                    'none'}&componentId=${componentId ?? 'none'}`,
                );
              }
            }}
          />
        }
        container={<Flexbox flexDirection="column" rowGap={24} />}
        filterMatrix={[
          component
            ? [
                {
                  property: component === 'apps' ? 'app.keyword' : 'environment.keyword',
                  type: 'term',
                  value: componentId ?? '',
                },
              ]
            : null,
          [
            {
              property: 'deleted',
              type: 'term',
              valueBoolean: false,
            },
          ],
        ].filter(Boolean)}
        fallbackElement={fallbackElement}
        parent={DeploymentsContainer}
      />
    </Suspense>
  );
}
