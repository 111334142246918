/* eslint-disable no-nested-ternary */
// @flow
import { Suspense, useCallback } from 'react';
import stylex from '@serpa-cloud/stylex';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Navigate, NavLink, Routes, Route, useMatch, useNavigate } from 'react-router-dom';

import { Text, Flexbox, Padding, ElementModal, LoaderForSuspense, TapIcon } from '../../../shared';

import Settings from '../Settings';
import BuildModal from '../BuildModal';
import Compilations from '../../Compilations';
import Deployments from '../../Deployments/DeploymentList';

import DeploymentForm from '../../Deployments/DeploymentForm';
import DeploymentsDetail from '../../Deployments/DeploymentsDetail';
import CompilationDetail from '../../Compilations/CompilationDetail';

import useDevice from '../../../shared/hooks/useDevice';

import { ReactComponent as Docker } from './docker.svg';
import { ReactComponent as Gitlab } from './gitlab.svg';
import { ReactComponent as Octomark } from './octomark.svg';
import { ReactComponent as OctomarkDark } from './octomark_white.svg';

const styles = stylex.create({
  tabsContainer: {
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
  },
  navElement: {
    height: 44,
    boxSizing: 'border-box',
  },
  navLink: {
    flexShrink: 0,
  },
  activeElement: {
    borderBottomColor: 'var(--primary-color-1)',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
  },
});

export default function MicroappsDetail(): React$Node {
  const { width } = useDevice();
  const navigate = useNavigate();
  const mainMatch = useMatch('/app/apps/:appId/*');
  const match = useMatch('/app/apps/:appId/:section/*');

  const appId = mainMatch?.params?.appId ?? '';
  const section = match?.params?.section ?? '';

  const userData = useLazyLoadQuery(
    graphql`
      query MicroappsDetailUIQuery {
        me {
          id
          uiTheme
        }
      }
    `,
    {},
    {
      fetchPolicy: 'store-or-network',
    },
  );

  const data = useLazyLoadQuery(
    graphql`
      query MicroappsDetailQuery($id: ID!) {
        node(id: $id) {
          id
          ... on App {
            id
            name
            appType
            appMethod
            namespace
            gitProvider
          }
        }
      }
    `,
    {
      id: appId,
    },
    {
      fetchPolicy: 'store-and-network',
    },
  );

  const handleOnBuildSubmit = useCallback(
    (buildId) => {
      navigate(`/app/build/${buildId}`);
    },
    [navigate],
  );

  const handleOnClose = useCallback(() => {
    navigate('/app');
  }, [navigate]);

  if (!section) {
    if (data?.node?.appMethod === 'GIT')
      return <Navigate replace to={`/app/apps/${appId}/compilations`} />;
    return <Navigate replace to={`/app/apps/${appId}/deployments`} />;
  }

  if (!data.node) return <Navigate replace to="/app" />;

  const node = data?.node;
  const horizontalPadding = width <= 1000 ? (width <= 400 ? 0 : 16) : 24;

  return (
    <>
      <ElementModal
        id={appId ?? 'AppDetail'}
        header={
          <header>
            <Padding horizontal={width <= 1000 ? (width <= 400 ? 8 : 16) : 24} top={24} bottom={24}>
              <Flexbox alignItems="center" justifyContent="space-between">
                <Flexbox alignItems="center" columnGap={16}>
                  {node.appMethod === 'GIT' && node.gitProvider === 'GITLAB' ? (
                    <Gitlab width={24} height={24} />
                  ) : null}
                  {node.appMethod === 'GIT' &&
                  node.gitProvider === 'GITHUB' &&
                  userData?.me?.uiTheme === 'LIGHT' ? (
                    <Octomark width={24} height={24} />
                  ) : null}

                  {node.appMethod === 'GIT' &&
                  node.gitProvider === 'GITHUB' &&
                  userData?.me?.uiTheme === 'DARK' ? (
                    <OctomarkDark width={24} height={24} />
                  ) : null}

                  {node.appMethod === 'DOCKER' ? <Docker width={24} height={24} /> : null}
                  <Text type="h5">{node?.name}</Text>
                </Flexbox>
                <TapIcon icon="close" iconColor="--primary-color-1" onClick={handleOnClose} />
              </Flexbox>
            </Padding>
            <Padding horizontal={horizontalPadding} className={stylex(styles.tabsContainer)}>
              <Flexbox columnGap={16}>
                {data?.node?.appMethod === 'GIT' ? (
                  <NavLink
                    to="compilations"
                    style={{ textDecoration: 'none' }}
                    className={stylex(styles.navLink)}
                  >
                    {/* $FlowIssue */}
                    {({ isActive }) => (
                      <Padding
                        top={12}
                        bottom={12}
                        horizontal={12}
                        className={stylex(
                          styles.navElement,
                          isActive ? styles.activeElement : styles.inactiveElement,
                        )}
                      >
                        <Text
                          type="s0b"
                          color={isActive ? '--primary-color-1' : '--neutral-color-800'}
                          id="deployment.menuCompilations"
                        />
                      </Padding>
                    )}
                  </NavLink>
                ) : null}

                {data?.node?.appType === 'CONTAINER' && (
                  <NavLink
                    to="deployments"
                    style={{ textDecoration: 'none' }}
                    className={stylex(styles.navLink)}
                  >
                    {/* $FlowIssue */}
                    {({ isActive }) => (
                      <Padding
                        top={12}
                        bottom={12}
                        horizontal={12}
                        className={stylex(
                          styles.navElement,
                          isActive ? styles.activeElement : styles.inactiveElement,
                        )}
                      >
                        <Text
                          type="s0b"
                          color={isActive ? '--primary-color-1' : '--neutral-color-800'}
                          id="deployment.menuDeployment"
                        />
                      </Padding>
                    )}
                  </NavLink>
                )}

                <NavLink
                  to="settings"
                  style={{ textDecoration: 'none' }}
                  className={stylex(styles.navLink)}
                >
                  {/* $FlowIssue */}
                  {({ isActive }) => (
                    <Padding
                      top={12}
                      bottom={12}
                      horizontal={12}
                      className={stylex(
                        styles.navElement,
                        isActive ? styles.activeElement : styles.inactiveElement,
                      )}
                    >
                      <Text
                        type="s0b"
                        color={isActive ? '--primary-color-1' : '--neutral-color-800'}
                        id="deployment.menuSettings"
                      />
                    </Padding>
                  )}
                </NavLink>
              </Flexbox>
            </Padding>
          </header>
        }
      >
        <div className={stylex(styles.container)}>
          <Padding top={24} horizontal={horizontalPadding} bottom={80}>
            <Routes>
              <Route
                path="compilations/create"
                element={
                  <Suspense fallback={<LoaderForSuspense />}>
                    <BuildModal appId={appId} onBuild={handleOnBuildSubmit} />
                  </Suspense>
                }
              />

              <Route
                path="compilations/*"
                element={
                  <Suspense fallback={<LoaderForSuspense />}>
                    <Compilations />
                  </Suspense>
                }
              />

              <Route
                path="deployments/*"
                element={
                  <Suspense fallback={<LoaderForSuspense />}>
                    <Deployments />
                  </Suspense>
                }
              />

              <Route
                path="settings"
                element={
                  <Suspense fallback={<LoaderForSuspense />}>
                    <Settings showCompilationSettings={data?.node?.appMethod === 'GIT'} />
                  </Suspense>
                }
              />
            </Routes>
          </Padding>
        </div>
      </ElementModal>

      <Routes>
        <Route
          path="deployments/create"
          element={
            <div>
              <Suspense fallback={<div />}>
                <DeploymentForm mode="CREATE" />
              </Suspense>
            </div>
          }
        />
        <Route
          path="deployments/:nodeId/*"
          element={
            <Suspense fallback={<LoaderForSuspense spinnerSize={40} />}>
              <DeploymentsDetail />
            </Suspense>
          }
        />
        <Route
          path="compilations/:buildId"
          element={
            <div>
              <Suspense fallback={<div />}>
                <CompilationDetail />
              </Suspense>
            </div>
          }
        />
      </Routes>
    </>
  );
}
