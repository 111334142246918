/**
 * @generated SignedSource<<94a8d1b767b1298b420a9f195935bb4d>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
export type DomainStatus = "PENDING" | "COMPLETE" | "%future added value";
import type { FragmentType } from "relay-runtime";
declare export opaque type DomainElement$fragmentType: FragmentType;
export type DomainElement$data = {|
  +id: string,
  +domain: string,
  +records: {|
    +A: $ReadOnlyArray<string>,
    +CNAME: $ReadOnlyArray<string>,
  |},
  +status: DomainStatus,
  +validationId: string,
  +$fragmentType: DomainElement$fragmentType,
|};
export type DomainElement$key = {
  +$data?: DomainElement$data,
  +$fragmentSpreads: DomainElement$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DomainElement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domain",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DNSRecordProvider",
      "kind": "LinkedField",
      "name": "records",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "A",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "CNAME",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "validationId",
      "storageKey": null
    }
  ],
  "type": "Domain",
  "abstractKey": null
};

(node/*: any*/).hash = "fd77e77f4bcf4cafff143842742bd3d7";

module.exports = ((node/*: any*/)/*: Fragment<
  DomainElement$fragmentType,
  DomainElement$data,
>*/);
