// @flow
import { Suspense } from 'react';
import stylex from '@serpa-cloud/stylex';
import { IntlProvider } from 'react-intl';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ErrorBoundary, Notifications, Spinner, SessionController, Flexbox } from './shared';

import spanishTranslations from './translations/spanish.json';
import englishTranslations from './translations/english.json';

import Analytics from './Analytics';

import Legal from './v2/Legal';
import Sign from './v2/Sign';
import Layout from './v2/Layout';
import GitValidation from './v2/GitValidation';
import DeploymentError from './v2/DeploymentError';

const styles = stylex.create({
  loading: {
    width: '100vw',
    height: '100vh',
    backgroundColor: 'var(--surface-background)',
  },
});

type Props = {
  locale: 'es' | 'en',
};

export default function App({ locale }: Props): React$Node {
  const loadingElement = (
    <Flexbox className={stylex(styles.loading)} alignItems="center" justifyContent="center">
      <Spinner size={40} />
    </Flexbox>
  );

  let translations = englishTranslations;

  if (locale === 'es') {
    translations = spanishTranslations;
  }

  return (
    <>
      <IntlProvider messages={translations} locale={locale}>
        <Analytics>
          <ErrorBoundary>
            <Routes>
              <Route path="app/deployment-error" element={<DeploymentError />} />
              <Route
                path="session/*"
                element={
                  <Suspense fallback={loadingElement}>
                    <SessionController publicRoute redirectTo="/app" fallback={loadingElement}>
                      <Sign />
                    </SessionController>
                  </Suspense>
                }
              />
              <Route
                path="/app/*"
                element={
                  <Suspense fallback={loadingElement}>
                    <SessionController redirectTo="/session/signin" fallback={loadingElement}>
                      <Layout />
                    </SessionController>
                  </Suspense>
                }
              />
              <Route
                path="/git/:provider/validation"
                element={
                  <Suspense fallback={loadingElement}>
                    <SessionController redirectTo="/session/signin" fallback={loadingElement}>
                      <GitValidation />
                    </SessionController>
                  </Suspense>
                }
              />
              <Route
                path="/legal/*"
                element={
                  <Suspense fallback={loadingElement}>
                    <Legal />
                  </Suspense>
                }
              />
              <Route index element={<Navigate to="/app" />} />
            </Routes>
            <Notifications />
          </ErrorBoundary>
        </Analytics>
      </IntlProvider>

      <div id="modal-portal" />
      <div id="notification-portal" />
      <div id="cascader-portal" />
    </>
  );
}
