/**
 * @generated SignedSource<<12ae48bb001e543f1f035bd96919b11d>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
export type AppMethod = "GIT" | "DOCKER" | "%future added value";
import type { FragmentType } from "relay-runtime";
declare export opaque type DeploymentElementData$fragmentType: FragmentType;
export type DeploymentElementData$data = {|
  +id: string,
  +name: string,
  +pause: ?boolean,
  +createdAtFormatted: string,
  +dockerImageUrl: ?string,
  +app: {|
    +id: string,
    +name: string,
    +namespace: string,
    +appMethod: AppMethod,
  |},
  +implementation: {|
    +serviceName: string,
  |},
  +environment: {|
    +id: string,
    +name: string,
  |},
  +status: ?{|
    +replicas: number,
    +readyReplicas: number,
    +updatedReplicas: number,
    +availableReplicas: number,
    +unavailableReplicas: number,
    +observedGeneration: number,
  |},
  +publicEndpoint: ?string,
  +pods: $ReadOnlyArray<{|
    +status: {|
      +phase: string,
    |},
  |}>,
  +source: ?{|
    +id?: string,
    +commitShaShort?: string,
    +commitUrl?: string,
    +gitBaseIsTag?: boolean,
    +gitTag?: ?string,
    +gitTagUrl?: ?string,
    +commitDescription?: string,
    +artifact?: {|
      +id: string,
      +commitShaShort: string,
      +commitUrl: string,
      +gitBaseIsTag: boolean,
      +gitTag: ?string,
      +gitTagUrl: ?string,
      +commitDescription: string,
    |},
  |},
  +$fragmentType: DeploymentElementData$fragmentType,
|};
export type DeploymentElementData$key = {
  +$data?: DeploymentElementData$data,
  +$fragmentSpreads: DeploymentElementData$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "commitShaShort",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "commitUrl",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "gitBaseIsTag",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "gitTag",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "gitTagUrl",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "commitDescription",
    "storageKey": null
  }
],
v3 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Artifact",
    "kind": "LinkedField",
    "name": "artifact",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeploymentElementData",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pause",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAtFormatted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dockerImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "App",
      "kind": "LinkedField",
      "name": "app",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "namespace",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "appMethod",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Implementation",
      "kind": "LinkedField",
      "name": "implementation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "serviceName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Environment",
      "kind": "LinkedField",
      "name": "environment",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeploymentStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "replicas",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "readyReplicas",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updatedReplicas",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "availableReplicas",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unavailableReplicas",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "observedGeneration",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicEndpoint",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Pod",
      "kind": "LinkedField",
      "name": "pods",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PodStatus",
          "kind": "LinkedField",
          "name": "status",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "phase",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "source",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "Artifact",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v3/*: any*/),
          "type": "Release",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v3/*: any*/),
          "type": "ReleaseChannel",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Deployment",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "c8670016df64bcd7201367febd2779d8";

module.exports = ((node/*: any*/)/*: Fragment<
  DeploymentElementData$fragmentType,
  DeploymentElementData$data,
>*/);
