// @flow
import stylex from '@serpa-cloud/stylex';
import { useState, startTransition } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import {
  Text,
  Card,
  Padding,
  Flexbox,
  Spinner,
  useInterval,
  InteractiveElement,
  Icon,
} from '../../../shared';

const styles = stylex.create({
  container: {
    height: 'calc(100vh - (var(--header-height) + var(--content-top-padding) + 197px))',
  },
  header: {
    height: 48,
  },
  height100: {
    height: '100%',
  },
  replicaState: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: 'var(--green-300)',
  },
  error: {
    backgroundColor: 'var(--red-300)',
  },
  pending: {
    backgroundColor: 'var(--orange-300)',
  },
  logsContainer: {
    backgroundColor: 'var(--logs-background)',
    flex: 1,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    overflow: 'auto',
  },
  logContent: {
    fontSize: 16,
    flexShrink: 0,
    color: 'var(--logs-text-color)',
    fontFamily: 'var(--font-family-code)',
  },
  timestamp: {
    fontSize: 16,
    flexShrink: 0,
    color: 'var(--done)',
    fontFamily: 'var(--font-family-code)',
  },
});

type Props = {|
  +name: string,
  +podsLenght: number,
  +podIndex: number,
  +deploymentId: string,
  +onChangePod: (direction: 1 | -1) => void,
|};

export default function LogsViewer({
  name,
  deploymentId,
  podIndex,
  podsLenght,
  onChangePod,
}: Props): React$Node {
  const [fetchKey, setFetchKey] = useState<number>(0);

  const data = useLazyLoadQuery(
    graphql`
      query LogsViewerQuery($deploymentId: ID!, $name: String!) {
        getPodById(deploymentId: $deploymentId, name: $name) {
          id
          name
          status {
            phase
            conditions {
              type
              status
            }
          }
        }
        podLog(deploymentId: $deploymentId, name: $name)
      }
    `,
    {
      name,
      deploymentId,
    },
    {
      fetchKey,
      fetchPolicy: 'store-and-network',
    },
  );

  useInterval(() => {
    startTransition(() => {
      setFetchKey((k) => k + 1);
    });
  }, 5000);

  const pod = data.getPodById;
  const podStatus = pod?.status;
  const logs: string = data?.podLog ?? '';
  const logsArray = logs.split('\n');
  const logsElement = logsArray.map((el) => {
    const m = el.match(new RegExp(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{2,}Z/g));
    if (!m) return ['', el];
    return [m[0], el.replace(m[0], '')];
  });

  const isPending = podStatus?.phase === 'PENDING' || podStatus?.phase === 'Pending';
  const isSuccess = podStatus?.conditions?.every((c) => c.status === true);

  let status = 'success';

  if (isPending) status = 'pending';
  else if (!isSuccess) status = 'error';

  return (
    <Card className={stylex(styles.container)}>
      <Flexbox flexDirection="column" className={stylex(styles.height100)}>
        <Padding horizontal={16}>
          <Flexbox
            alignItems="center"
            justifyContent="space-between"
            className={stylex(styles.header)}
          >
            <Flexbox columnGap={8} alignItems="center">
              <div
                className={stylex(
                  styles.replicaState,
                  !isSuccess ? styles.error : null,
                  isPending ? styles.pending : null,
                )}
              />
              <Text
                id={`logs.${status}`}
                type="s0b"
                color="--neutral-color-800"
                values={{ podIndex: podIndex + 1, podsLenght }}
              />
            </Flexbox>
            <Flexbox alignItems="center" columnGap={16}>
              {podsLenght > 1 && (
                <Flexbox alignItems="center" columnGap={8}>
                  <InteractiveElement onClick={() => onChangePod(-1)}>
                    <Icon
                      icon="chevron_left"
                      hoverColor="--primary-color-1"
                      color="--neutral-color-500"
                    />
                  </InteractiveElement>

                  <InteractiveElement onClick={() => onChangePod(1)}>
                    <Icon
                      icon="chevron_right"
                      hoverColor="--primary-color-1"
                      color="--neutral-color-500"
                    />
                  </InteractiveElement>
                </Flexbox>
              )}
              <Spinner size={16} />
            </Flexbox>
          </Flexbox>
        </Padding>
        <div className={stylex(styles.logsContainer)}>
          <Padding vertical={16} horizontal={16}>
            <Flexbox flexDirection="column" rowGap={8}>
              {logsElement.map((log, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Flexbox key={i} columnGap={24} alignItems="center">
                  <div className={stylex(styles.timestamp)}>{log[0]}</div>
                  <div className={stylex(styles.logContent)}>{log[1]}</div>
                </Flexbox>
              ))}
            </Flexbox>
          </Padding>
        </div>
      </Flexbox>
    </Card>
  );
}
