// @flow
import { useIntl } from 'react-intl';
import { animated } from 'react-spring';
import stylex from '@serpa-cloud/stylex';

import Card from './Card';
import Icon from './Icon';
import Text from './Text';
import Padding from './Padding';
import Flexbox from './Flexbox';
import TapIcon from './TapIcon';

import useNotification from './hooks/useNotification';

import sendCustomEvent from './utils/sendCustomEvent';

import type { Notification as NotificationType } from './AppProvider';

const styles = stylex.create({
  card: {
    width: 600,
    overflow: 'hidden',
    boxSizing: 'border-box',
    position: 'relative',
    '@media (max-width: 600px)': {
      width: '100%',
    },
  },
  progressBar: {
    left: 0,
    height: 4,
    bottom: 0,
    width: '100%',
    position: 'absolute',
    transformOrigin: 'left center',
  },
});

type NotificationProps = {|
  ...NotificationType,
  +life: any,
|};

export default function Notification({
  id,
  type,
  life,
  cancel,
  message,
}: NotificationProps): React$Node {
  const intl = useIntl();

  let icon = '';
  let color = 'success';

  if (type === 'SUCCESS') {
    icon = 'check_circle';
    color = '--green-300';
  } else if (type === 'WARNING') {
    icon = 'warning';
    color = '--orange-300';
  } else if (type === 'ERROR') {
    icon = 'error';
    color = '--red-300';
  }

  const [, , , dispose] = useNotification();

  return (
    <Card className={stylex(styles.card)}>
      <Padding top={8} horizontal={16} bottom={12}>
        <Flexbox alignItems="center" justifyContent="space-between">
          <Padding right={24}>
            <Flexbox alignItems="center" columnGap={16}>
              <Icon fill icon={icon} color={color} />
              <Text type="s1m" color="--neutral-color-800">
                {typeof message === 'string' ? message : intl.formatMessage({ id: message.id })}
              </Text>
            </Flexbox>
          </Padding>
          <TapIcon
            icon="close"
            onClick={() => {
              sendCustomEvent('component', 'notification', 'click', 'close');
              cancel?.();
              dispose(id);
            }}
          />
        </Flexbox>
      </Padding>
      <animated.div
        style={{
          backgroundColor: `var(${color})`,
          transform: life.to([0, 100], [0, 1]).to((value) => `scaleX(${value})`),
        }}
        className={stylex(styles.progressBar)}
      />
    </Card>
  );
}
