// @flow
import stylex from '@serpa-cloud/stylex';
import { Routes, Route } from 'react-router-dom';

import Footer from '../Footer';
import TermsOfUse from './TermsOfUse';
import PrivacyNotice from './PrivacyNotice';

const styles = stylex.create({
  container: {
    backgroundColor: 'var(--neutral-color-200)',
    fontFamily: 'var(--font-family-default)',
    color: 'var(--neutral-color-800)',
    lineHeight: 1.5,
  },
});

export default function Legal(): React$Node {
  return (
    <div className={stylex(styles.container)}>
      <Routes>
        <Route path="privacy/" element={<PrivacyNotice />} />
        <Route path="terms/" element={<TermsOfUse />} />
      </Routes>
      <Footer />
    </div>
  );
}
