/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
// @flow
import { useIntl } from 'react-intl';
import stylex from '@serpa-cloud/stylex';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConnectionHandler } from 'relay-runtime';
import { useSpring, animated } from 'react-spring';
import { useLazyLoadQuery, useMutation, graphql } from 'react-relay';

import {
  Text,
  Card,
  Button,
  Flexbox,
  Padding,
  Spinner,
  LiteButton,
  useNotification,
} from '../../../shared';

import useDevice from '../../../shared/hooks/useDevice';

import cursorClose from '../../../shared/images/cursorClose.png';

import type { DeleteAppModalMutation } from './__generated__/DeleteAppModalMutation.graphql';

const styles = stylex.create({
  modal: {
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 99,
  },
  contentOverlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.8,
    position: 'absolute',
    backgroundColor: 'var(--neutral-color-800)',
  },
  card: {
    overflow: 'auto',
    '@media (max-width: 680px)': {
      width: '100%',
      height: '100%',
    },
    '@media (min-width: 680px)': {
      width: 680,
      maxWidth: 'calc(100vw - 24px)',
      height: 'auto',
      maxHeight: 'calc(100vh - 40px)',
    },
  },
  strong: {
    color: 'var(--primary-color-1)',
    fontWeight: 'bold',
  },
  fullWidth: {
    width: '100%',
  },
  codeBox: {
    width: '100%',
    borderRadius: 8,
    boxSizing: 'border-box',
    backgroundColor: 'var(--neutral-color-300)',
  },
});

type Props = {|
  +onClose: () => void,
  +appId: string,
|};

export default function DeleteAppModal({ onClose, appId }: Props): React$Node {
  const intl = useIntl();
  const { width } = useDevice();
  const navigate = useNavigate();
  const [, , setNotification] = useNotification();

  const userData = useLazyLoadQuery(
    graphql`
      query DeleteAppModalQuery {
        me {
          pronoun
        }
      }
    `,
    {},
    {
      fetchPolicy: 'store-or-network',
    },
  );

  const [modalSpringProps, modalSpringAPI] = useSpring(
    () => ({
      from: { opacity: 0 },
      to: { opacity: 1 },
      onRest({ value }) {
        if (value.opacity === 0) onClose();
      },
    }),
    [],
  );

  const handleClose = useCallback(() => {
    modalSpringAPI.stop();
    modalSpringAPI.start({ opacity: 0 });
  }, [modalSpringAPI]);

  const [deleteDeployment, deletePending] = useMutation<DeleteAppModalMutation>(graphql`
    mutation DeleteAppModalMutation($id: ID!) {
      deleteApp(id: $id)
    }
  `);

  const handleOnDelete = useCallback(() => {
    if (!deletePending)
      deleteDeployment({
        variables: {
          id: appId,
        },
        updater(store) {
          const root = store.getRoot();

          const serviceRecords = ConnectionHandler.getConnection(
            root,
            'ScrolledList_root_entities',
            {
              index: 'APPS',
              filterMatrix: [
                [
                  {
                    property: 'deleted',
                    type: 'term',
                    valueBoolean: false,
                  },
                ],
              ],
            },
          );

          if (serviceRecords) ConnectionHandler.deleteNode(serviceRecords, appId);
        },
        onCompleted(response) {
          if (response?.deleteApp) {
            navigate('/app/apps', { replace: true });

            setNotification({
              id: new Date().getTime().toString(),
              type: 'SUCCESS',
              message: intl.formatMessage({ id: 'deleteApp.success' }),
            });
          }
        },
        onError(error) {
          // eslint-disable-next-line no-console
          console.log(error);

          setNotification({
            id: new Date().getTime().toString(),
            type: 'ERROR',
            // $FlowIgnore
            message: error.source.errors[0].message,
          });
        },
      });
  }, [deletePending, deleteDeployment, appId, navigate, setNotification, intl]);

  useEffect(() => {
    modalSpringAPI.start({ opacity: 1 });

    return () => {
      modalSpringAPI.stop();
    };
  });

  const isMobile = width <= 680;

  return (
    <animated.div style={modalSpringProps} className={stylex(styles.modal)}>
      <Flexbox className={stylex(styles.modal)} alignItems="center" justifyContent="center">
        <div
          onClick={handleClose}
          className={stylex(styles.contentOverlay)}
          style={{ cursor: `url(${cursorClose}) 12 12, auto` }}
        />
        <Card className={stylex(styles.card)}>
          <Padding horizontal={24} bottom={16} top={24}>
            <Text type="h4" id={`app.dangerZone.Title.${userData.me?.pronoun ?? ''}`} />

            <Padding top={24}>
              <Text type={width <= 815 ? 'bs' : 'bd'} id="app.dangerZone.description" />

              <Padding top={40}>
                <Flexbox
                  justifyContent="space-between"
                  flexDirection={isMobile ? 'column' : 'row'}
                  rowGap={16}
                >
                  <Flexbox
                    columnGap={24}
                    alignItems={isMobile ? 'normal' : 'center'}
                    flexDirection={isMobile ? 'column' : 'row'}
                  >
                    <Button
                      intlId="delete"
                      onClick={handleOnDelete}
                      disabled={deletePending}
                      icon="delete"
                      buttonType="danger"
                    />
                    {deletePending && <Spinner size={24} />}
                  </Flexbox>
                  <LiteButton intlId="cancel" onClick={handleClose} />
                </Flexbox>
              </Padding>
            </Padding>
          </Padding>
        </Card>
      </Flexbox>
    </animated.div>
  );
}
