// @flow
import stylex from '@serpa-cloud/stylex';
import { useParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { graphql, useMutation, useFragment } from 'react-relay';

import {
  Text,
  Card,
  Icon,
  Margin,
  Spinner,
  Divider,
  Flexbox,
  Padding,
  TapIcon,
  LiteButton,
  InteractiveElement,
} from '../../../shared';

import useDevice from '../../../shared/hooks/useDevice';

import type { DomainElement$key } from './__generated__/DomainElement.graphql';
import type { DomainElementRequestCertificateMutation } from './__generated__/DomainElementRequestCertificateMutation.graphql';
import useInterval from '../../../shared/hooks/useInterval';

type Props = {|
  +node?: ?DomainElement$key,
|};

const styles = stylex.create({
  activeTab: {
    borderBottom: '2px solid var(--neutral-color-800)',
  },
  inactiveTab: {
    borderBottom: '1px solid transparent',
  },
  spinnerContainer: {
    width: 16,
    height: 16,
  },
  pre: {
    overflow: 'auto',
    marginTop: 0,
    marginBottom: 4,
  },
  noShrink: {
    minWidth: 1,
    maxWidth: '100%',
    flex: '0 0 auto',
  },
  overflow: {
    overflow: 'auto',
  },
  table: {
    flex: 1,
    minWidth: 1,
    overflow: 'auto',
    maxWidth: '100%',
  },
  valueCell: {
    maxWidth: '20%',
  },
});

export default function DomainElement({ node }: Props): React$Node {
  const { width } = useDevice();
  const { nodeId } = useParams();
  const [recordType, setRecordType] = useState('CNAME');
  const data = useFragment(
    graphql`
      fragment DomainElement on Domain {
        id
        domain
        records {
          A
          CNAME
        }
        status
        validationId
      }
    `,
    node,
  );

  const [
    requestCertificate,
    commitCertificatePending,
  ] = useMutation<DomainElementRequestCertificateMutation>(graphql`
    mutation DomainElementRequestCertificateMutation(
      $domain: String!
      $environment: ID!
      $provider: DNSProvider!
      $recordType: DNSRecordType!
    ) {
      requestCertificate(
        domain: $domain
        environment: $environment
        provider: $provider
        recordType: $recordType
      ) {
        id
        domain
        status
      }
    }
  `);

  const validateDomain = useCallback(() => {
    if (!commitCertificatePending && data) {
      requestCertificate({
        variables: {
          recordType,
          provider: 'AWS',
          environment: nodeId,
          domain: data.domain.toLowerCase(),
        },
        onCompleted(response) {
          console.log(response);
        },
        onError(error) {
          // eslint-disable-next-line no-console
          console.trace(error);
        },
      });
    }
  }, [commitCertificatePending, data, nodeId, recordType, requestCertificate]);

  const isPending = data?.status === 'PENDING';
  const isComplete = data?.status === 'COMPLETE';

  useInterval(
    () => {
      validateDomain();
    },
    isPending ? 30000 : null,
  );

  if (!data) return null;

  const domainName =
    data.domain
      ?.split('.')
      ?.slice(0, -2)
      ?.join('.') ?? '';

  const selectedRecords = data.records?.[recordType];

  return (
    <Card>
      <Padding horizontal={20} top={20}>
        <Flexbox justifyContent="space-between" alignItems="center">
          <a href={`https://${data.domain}`} target="_blank" rel="noreferrer">
            <Text type="s1b" color="--neutral-color-800">
              {data.domain}
            </Text>
          </a>

          <Flexbox>
            {width > 480 ? (
              <LiteButton
                intlId="validate"
                icon="sync"
                showShadow
                onClick={validateDomain}
                pending={commitCertificatePending}
              />
            ) : (
              <TapIcon icon="sync" iconColor="--primary-color-1" onClick={validateDomain} />
            )}
          </Flexbox>
        </Flexbox>
        <Margin top={20} bottom={24}>
          {!commitCertificatePending && isPending && (
            <Flexbox alignItems="center" columnGap={8}>
              <Icon icon="error" fill color="--red-300" size={24} />
              <Text id="domains.invalidRecords" type="s0b" color="--red-300" />
            </Flexbox>
          )}
          {!commitCertificatePending && isComplete && (
            <Flexbox alignItems="center" columnGap={8}>
              <Icon icon="check_circle" fill color="--green-300" size={24} />
              <Text id="domains.validRecords" type="s0r" color="--neutral-color-600" />
            </Flexbox>
          )}
          {commitCertificatePending && (
            <Flexbox alignItems="center" columnGap={8}>
              <Padding vertical={4} horizontal={4} className={stylex(styles.spinnerContainer)}>
                <Spinner size={16} />
              </Padding>
              <Text id="domains.validatingRecords" type="s0r" color="--primary-color-600" />
            </Flexbox>
          )}
        </Margin>
      </Padding>
      {isPending && (
        <>
          <Divider />
          <Padding horizontal={20} top={24}>
            <Flexbox columnGap={16}>
              <InteractiveElement onClick={() => setRecordType('CNAME')}>
                <Padding
                  horizontal={8}
                  bottom={16}
                  className={stylex(recordType === 'CNAME' ? styles.activeTab : styles.inactiveTab)}
                >
                  <Text
                    type="s0m"
                    color={recordType === 'CNAME' ? '--neutral-color-800' : '--neutral-color-600'}
                    id="domains.CNAMERecords"
                  />
                </Padding>
              </InteractiveElement>

              <InteractiveElement onClick={() => setRecordType('A')}>
                <Padding
                  horizontal={8}
                  bottom={16}
                  className={stylex(recordType !== 'CNAME' ? styles.activeTab : styles.inactiveTab)}
                >
                  <Text
                    type="s0m"
                    color={recordType !== 'CNAME' ? '--neutral-color-800' : '--neutral-color-600'}
                    id="domains.ARecords"
                  />
                </Padding>
              </InteractiveElement>
            </Flexbox>
            <Divider />
          </Padding>
          <Padding horizontal={20} bottom={20}>
            <Padding top={24} bottom={16}>
              <Text id="domains.instructions" type="s0r" color="--neutral-color-800" />
            </Padding>
            <Card>
              <Padding horizontal={12} top={16} bottom={12}>
                <Flexbox
                  alignItems="flex-start"
                  columnGap={20}
                  flexWrap="wrap"
                  className={stylex(styles.table)}
                >
                  <Flexbox flexDirection="column" rowGap={16} className={stylex(styles.noShrink)}>
                    <Text id="domain.type" type="s0r" />
                    <Text type="s0m" color="--neutral-color-800">
                      <pre className={stylex(styles.pre)}>{recordType}</pre>
                    </Text>
                  </Flexbox>

                  <Flexbox
                    flexDirection="column"
                    rowGap={16}
                    className={stylex(styles.noShrink, styles.valueCell)}
                  >
                    <Text id="domain.name" type="s0r" />
                    <Text type="s0m" color="--neutral-color-800">
                      <pre className={stylex(styles.pre)}>{domainName || '@'}</pre>
                    </Text>
                  </Flexbox>

                  <Flexbox
                    flexDirection="column"
                    rowGap={16}
                    className={stylex(styles.noShrink, styles.overflow)}
                  >
                    <Text id="domain.value" type="s0r" />
                    <Text type="s0m" color="--neutral-color-800">
                      <pre className={stylex(styles.pre)}>{selectedRecords?.join(', ') ?? ''}</pre>
                    </Text>
                  </Flexbox>
                </Flexbox>
              </Padding>
            </Card>
            <Padding top={32}>
              <Flexbox alignItems="center" columnGap={8}>
                <Icon icon="info" size={16} fill color="--neutral-color-400" weight={700} />
                <Text
                  type="bs"
                  id="domains.info"
                  color="--neutral-color-600"
                  values={{
                    l: (s) => (
                      <a href="https://docs.serpa.cloud" target="_blank" rel="noreferrer">
                        {s}
                      </a>
                    ),
                  }}
                />
              </Flexbox>
            </Padding>
          </Padding>
        </>
      )}
    </Card>
  );
}

DomainElement.defaultProps = {
  node: null,
};
