// @flow
import { graphql, useFragment } from 'react-relay';
import { Route, Routes } from 'react-router-dom';

import Registers from './Registers';
import DomainFormPage from './DomainFormPage';

import type { DNS$key } from './__generated__/DNS.graphql';

type Props = {|
  +node?: ?DNS$key,
|};

export default function DNS({ node }: Props): React$Node {
  const data = useFragment(
    graphql`
      fragment DNS on Environment {
        id
        ...Registers
      }
    `,
    node,
  );

  return (
    <Routes>
      <Route path="create" element={<DomainFormPage />} />
      <Route index element={<Registers node={data} />} />
    </Routes>
  );
}

DNS.defaultProps = {
  node: null,
};
