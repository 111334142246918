// @flow
import stylex from '@serpa-cloud/stylex';
import { useState, useCallback, useRef } from 'react';
import { useTransition, animated } from 'react-spring';
import { useLazyLoadQuery, graphql, loadQuery, useRelayEnvironment } from 'react-relay';

import {
  Icon,
  Text,
  Margin,
  Padding,
  TapIcon,
  Flexbox,
  InteractiveElement,
  ContextualMenu,
  Divider,
} from '../../shared';

import MenuSection from './MenuSection';
import MenuElement from './MenuElement';
import NamespaceSelector from './NamespaceSelector';

import NamespaceSelectorQuery from './__generated__/NamespaceSelectorQuery.graphql';

const styles = stylex.create({
  menu: {
    top: 0,
    left: 0,
    height: '100vh',
    width: 280,
    opacity: 1,
    zIndex: 9,
    position: 'fixed',
    willChange: 'transform',
    boxShadow: 'var(--shadow-1)',
    backgroundColor: 'var(--neutral-color-100)',
    borderRight: '1px solid var(--border-color)',
    '@media (max-width: 540px)': {
      width: 'calc(100vw - 80px)',
      maxWidth: 480,
    },
  },

  arrowContainer: {
    position: 'relative',
  },
  namespaceSelector: {
    backgroundColor: 'var(--neutral-color-100)',
  },
  namespaceSelectorContainer: {
    position: 'relative',
  },
  contextualMenu: {
    minWidth: 264,
  },
  menuOverlay: {
    zIndex: 8,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.8,
    height: '100vh',
    backgroundColor: 'var(--neutral-color-800)',
  },
});

export default function Menu(): React$Node {
  const timer = useRef();
  const [menuOpen, setMenuOpen] = useState(false);

  const [transitions] = useTransition(menuOpen, () => ({
    from: { opacity: 0, left: -280 },
    enter: { opacity: 1, left: 0 },
    leave: { opacity: 0, left: -280 },
  }));

  const data = useLazyLoadQuery(
    graphql`
      query MenuQuery {
        getCurrentNameSpace {
          id
          ... on User {
            id
            username
            media(width: 48, height: 48) {
              ...Avatar
            }
          }
          ... on Org {
            id
            key
            media(width: 48, height: 48) {
              ...Avatar
            }
          }
        }
      }
    `,
    {},
    {
      fetchPolicy: 'store-and-network',
    },
  );

  const openMenu = useCallback(() => {
    setMenuOpen(true);
  }, []);

  const closeMenu = useCallback(() => {
    timer.current = setTimeout(() => {
      setMenuOpen(false);
    }, 500);
  }, []);

  const cancelTimer = useCallback(() => {
    clearTimeout(timer.current);
    timer.current = null;
  }, []);

  const [orgMenuIsOpen, setOrgMenuIsOpen] = useState<boolean>(false);

  const handleOnToggleOrgMenu = useCallback(
    (e) => {
      e.stopPropagation();

      setTimeout(() => {
        setOrgMenuIsOpen((x) => !x);
      }, 1);
    },
    [setOrgMenuIsOpen],
  );

  const relayEnvironment = useRelayEnvironment();

  const preloadOrganizations = useCallback(() => {
    loadQuery(
      relayEnvironment,
      NamespaceSelectorQuery,
      {},
      {
        fetchPolicy: 'store-or-network',
      },
    );
  }, [relayEnvironment]);

  const namespace = data?.getCurrentNameSpace;

  return (
    <Flexbox alignItems="center" columnGap={0}>
      <Flexbox alignItems="center">
        <Padding horizontal={8} vertical={4}>
          <TapIcon icon="menu" onClick={openMenu} iconColor="--primary-color-1" />
        </Padding>

        {transitions(
          (style, item) =>
            item && (
              <div>
                <animated.nav
                  style={style}
                  onMouseLeave={closeMenu}
                  onMouseEnter={cancelTimer}
                  className={stylex(styles.menu)}
                >
                  <Padding horizontal={8} top={16} bottom={8}>
                    <Margin bottom={12}>
                      <Padding horizontal={8}>
                        <Text id="menu.resources" type="s0b" />
                      </Padding>
                    </Margin>

                    <MenuSection icon="token" intlId="menu.apps" link="/app" />
                  </Padding>

                  <Divider />

                  <Padding top={8} horizontal={8}>
                    <MenuElement
                      icon="note"
                      intlId="menu.documentation"
                      url="https://docs.serpa.cloud"
                    />

                    <MenuElement
                      icon="animated_images"
                      intlId="onboarding.videos"
                      url="https://www.youtube.com/@SerpaCloud/videos"
                    />

                    <MenuElement
                      icon="support_agent"
                      intlId="onboarding.support"
                      url="https://join.slack.com/t/serpacloudtechsupport/shared_invite/zt-2mtkez0tw-Yxy6bTOILZypZXDs2_jwCg"
                    />
                  </Padding>
                </animated.nav>
                <animated.div
                  onClick={() => {
                    setMenuOpen(false);
                  }}
                  className={stylex(styles.menuOverlay)}
                  style={{
                    opacity: style.opacity.to([0, 1], [0, 0.8]).to((value) => value),
                  }}
                />
              </div>
            ),
        )}
      </Flexbox>

      <div className={stylex(styles.namespaceSelectorContainer)}>
        <InteractiveElement onClick={handleOnToggleOrgMenu} onMouseEnter={preloadOrganizations}>
          <div className={stylex(styles.namespaceSelector)}>
            <Flexbox alignItems="center" columnGap={8}>
              <Flexbox alignItems="center" columnGap={8}>
                <Text type="s0b" color="--neutral-color-800">
                  {`@${namespace?.username ?? namespace?.key ?? ''}`}
                </Text>
                <div className={stylex(styles.arrowContainer)}>
                  <Icon
                    icon="unfold_more"
                    size={16}
                    weight={400}
                    opticalSize={20}
                    color="--neutral-color-800"
                  />
                </div>
              </Flexbox>
            </Flexbox>
          </div>
        </InteractiveElement>
        <ContextualMenu
          open={orgMenuIsOpen}
          containerHeight={32}
          anchor="LEFT"
          onClose={() => {
            setOrgMenuIsOpen(false);
          }}
          className={stylex(styles.contextualMenu)}
        >
          <NamespaceSelector />
        </ContextualMenu>
      </div>
    </Flexbox>
  );
}
