// @flow
import stylex from '@serpa-cloud/stylex';

import { useCallback } from 'react';

import { $getRoot } from 'lexical';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';

import SerpaAI from './SerpaAI';
import DockerEditionPlugin from './DockerEditionPlugin';

import CodeHighlightPlugin from '../../../beta/BlockNote/plugins/CodeHighlightPlugin';

// eslint-disable-next-line import/no-extraneous-dependencies
require('prismjs/components/prism-docker');

const styles = stylex.create({
  container: {
    position: 'relative',
    backgroundColor: 'var(--dockerfile-editor-background)',
    border: '1px solid var(--border-color)',
    paddingBottom: 56,
    boxShadow: 'var(--shadow-1)',
  },
  code: {
    tabSize: 2,
    paddingTop: 20,
    paddingRight: 20,
    paddingBottom: 20,
    paddingLeft: 48,
    fontSize: 16,
    lineHeight: '20px',
    display: 'block',
    minHeight: '100%',
    position: 'relative',
    color: 'var(--neutral-color-600)',
    fontFamily: 'var(--font-family-code)',
    ':before': {
      fontSize: 12,
      lineHeight: '20px',
      content: 'attr(data-gutter)',
      position: 'absolute',
      backgroundColor: 'var(--dockerfile-editor-background)',
      left: 0,
      top: 0,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 20,
      paddingBottom: 20,
      whiteSpace: 'pre-wrap',
      textAlign: 'right',
      minWidth: 16,
    },
    '@media (max-width: 1000px)': {
      fontSize: 14,
    },
  },
  keyword: {
    color: 'var(--primary-color-1)',
  },
  string: {
    color: 'var(--red-300)',
  },
  comment: {
    color: 'var(--neutral-color-500)',
  },
  punctuation: {
    color: 'var(--neutral-color-500)',
  },
  variable: {
    color: 'var(--neutral-color-500)',
  },
  operator: {
    color: 'var(--neutral-color-500)',
  },
  options: {
    color: 'var(--neutral-color-500)',
  },
  property: {
    color: 'var(--neutral-color-500)',
  },
  instruction: {
    color: 'var(--neutral-color-500)',
  },
  editable: {
    outline: 0,
    minHeight: '280px',
  },
});

type Props = {|
  +content: string,
  +initialContent: string,
  +onChange: (string) => void,
|};

export default function DockerfileEditor({ content, onChange, initialContent }: Props): React$Node {
  const handleOnChange = useCallback(
    (editorState) => {
      editorState.read(() => {
        const root = $getRoot();
        const text = root.getTextContent();
        onChange(text);
      });
    },
    [onChange],
  );

  return (
    <div className={stylex(styles.container)}>
      <LexicalComposer
        initialConfig={{
          editorState: JSON.stringify({
            root: {
              children: [
                {
                  children: [
                    {
                      detail: 0,
                      format: 0,
                      mode: 'normal',
                      style: '',
                      text:
                        content ||
                        '#Please edit this Dockerfile\n\n#First define your base image\nFROM alpine\n\n#End with your final script\nCMD [ "echo", "HELLO_WORLD" ]',
                      type: 'code-highlight',
                      version: 1,
                    },
                  ],
                  direction: 'ltr',
                  format: '',
                  indent: 0,
                  type: 'code',
                  version: 1,
                  language: 'dockerfile',
                },
              ],
              direction: 'ltr',
              format: '',
              indent: 0,
              type: 'root',
              version: 1,
            },
          }),
          namespace: 'DockerfileEditor',
          onError(editorError) {
            throw editorError;
          },
          nodes: [CodeHighlightNode, CodeNode],
          theme: {
            code: stylex(styles.code),
            codeHighlight: {
              keyword: stylex(styles.keyword),
              string: stylex(styles.string),
              comment: stylex(styles.comment),
              punctuation: stylex(styles.punctuation),
              variable: stylex(styles.variable),
              operator: stylex(styles.operator),
              options: stylex(styles.options),
              property: stylex(styles.property),
              instruction: stylex(styles.instruction),
            },
          },
        }}
      >
        <RichTextPlugin
          ErrorBoundary={LexicalErrorBoundary}
          contentEditable={<ContentEditable className={stylex(styles.editable)} />}
          placeholder={null}
        />
        <CodeHighlightPlugin />
        <DockerEditionPlugin />
        <OnChangePlugin onChange={handleOnChange} />
        {false && <SerpaAI initialContent={initialContent || ''} />}
      </LexicalComposer>
    </div>
  );
}
