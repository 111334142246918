// @flow
import stylex from '@serpa-cloud/stylex';

import { Icon, Text, Flexbox, Padding, Margin, Divider, Grid, Card } from '../../shared';

import useDevice from '../../shared/hooks/useDevice';

const styles = stylex.create({
  rootBreadcrumb: {
    top: 0,
    zIndex: 9,
    position: 'sticky',
    backgroundColor: 'var(--neutral-color-100)',
  },
  breadcrumbFake: {
    width: 160,
    height: 12,
    borderRadius: 4,
    backgroundColor: 'var(--neutral-color-200)',
  },
  titleFake: {
    width: 240,
    height: 18,
    borderRadius: 4,
    backgroundColor: 'var(--neutral-color-400)',
  },
  subtitleFake: {
    width: 760,
    height: 16,
    borderRadius: 4,
    marginTop: 25,
    marginBottom: 5,
    backgroundColor: 'var(--neutral-color-300)',
    '@media (max-width: 840px)': {
      width: '100%',
    },
  },
  cardFake: {
    height: 240,
  },
  cardtitleFake: {
    width: 120,
    height: 12,
    borderRadius: 4,
    backgroundColor: 'var(--neutral-color-400)',
  },
  cardsubtitleFake: {
    width: 80,
    height: 12,
    borderRadius: 4,
    marginTop: 8,
    marginBottom: 5,
    backgroundColor: 'var(--neutral-color-300)',
  },
});

export default function LayoutPlaceholder(): React$Node {
  const { width } = useDevice();
  return (
    <div className={stylex(styles.root)}>
      <Padding vertical={12} horizontal={16}>
        <Flexbox alignItems="center" columnGap={12}>
          <Flexbox alignItems="center" columnGap={4}>
            <Icon icon="home" size={20} fill color="--neutral-color-400" />
            <Text id="home" type="s0m" />
          </Flexbox>

          <Flexbox alignItems="center" columnGap={12}>
            <Text type="s0m" color="--neutral-color-400">
              /
            </Text>

            <div className={stylex(styles.breadcrumbFake)} />
          </Flexbox>
        </Flexbox>
      </Padding>
      <Divider />
      <Padding top={32} horizontal={24} bottom={24}>
        <div className={stylex(styles.titleFake)} />
        <div className={stylex(styles.subtitleFake)} />
        <Margin top={24}>
          <Grid
            columns={
              (width <= 815 && '1fr') ||
              (width > 815 && width <= 1000 && '1fr 1fr') ||
              '1fr 1fr 1fr 1fr 1fr'
            }
            columnGap={24}
            rowGap={32}
          >
            <Card className={stylex(styles.cardFake)}>
              <Padding vertical={16} horizontal={16}>
                <div className={stylex(styles.cardtitleFake)} />
                <div className={stylex(styles.cardsubtitleFake)} />
              </Padding>
            </Card>
            <Card className={stylex(styles.cardFake)}>
              <Padding vertical={16} horizontal={16}>
                <div className={stylex(styles.cardtitleFake)} />
                <div className={stylex(styles.cardsubtitleFake)} />
              </Padding>
            </Card>
            <Card className={stylex(styles.cardFake)}>
              <Padding vertical={16} horizontal={16}>
                <div className={stylex(styles.cardtitleFake)} />
                <div className={stylex(styles.cardsubtitleFake)} />
              </Padding>
            </Card>
            <Card className={stylex(styles.cardFake)}>
              <Padding vertical={16} horizontal={16}>
                <div className={stylex(styles.cardtitleFake)} />
                <div className={stylex(styles.cardsubtitleFake)} />
              </Padding>
            </Card>
            <Card className={stylex(styles.cardFake)}>
              <Padding vertical={16} horizontal={16}>
                <div className={stylex(styles.cardtitleFake)} />
                <div className={stylex(styles.cardsubtitleFake)} />
              </Padding>
            </Card>

            <Card className={stylex(styles.cardFake)}>
              <Padding vertical={16} horizontal={16}>
                <div className={stylex(styles.cardtitleFake)} />
                <div className={stylex(styles.cardsubtitleFake)} />
              </Padding>
            </Card>
            <Card className={stylex(styles.cardFake)}>
              <Padding vertical={16} horizontal={16}>
                <div className={stylex(styles.cardtitleFake)} />
                <div className={stylex(styles.cardsubtitleFake)} />
              </Padding>
            </Card>
            <Card className={stylex(styles.cardFake)}>
              <Padding vertical={16} horizontal={16}>
                <div className={stylex(styles.cardtitleFake)} />
                <div className={stylex(styles.cardsubtitleFake)} />
              </Padding>
            </Card>
            <Card className={stylex(styles.cardFake)}>
              <Padding vertical={16} horizontal={16}>
                <div className={stylex(styles.cardtitleFake)} />
                <div className={stylex(styles.cardsubtitleFake)} />
              </Padding>
            </Card>
            <Card className={stylex(styles.cardFake)}>
              <Padding vertical={16} horizontal={16}>
                <div className={stylex(styles.cardtitleFake)} />
                <div className={stylex(styles.cardsubtitleFake)} />
              </Padding>
            </Card>

            <Card className={stylex(styles.cardFake)}>
              <Padding vertical={16} horizontal={16}>
                <div className={stylex(styles.cardtitleFake)} />
                <div className={stylex(styles.cardsubtitleFake)} />
              </Padding>
            </Card>
            <Card className={stylex(styles.cardFake)}>
              <Padding vertical={16} horizontal={16}>
                <div className={stylex(styles.cardtitleFake)} />
                <div className={stylex(styles.cardsubtitleFake)} />
              </Padding>
            </Card>
            <Card className={stylex(styles.cardFake)}>
              <Padding vertical={16} horizontal={16}>
                <div className={stylex(styles.cardtitleFake)} />
                <div className={stylex(styles.cardsubtitleFake)} />
              </Padding>
            </Card>
            <Card className={stylex(styles.cardFake)}>
              <Padding vertical={16} horizontal={16}>
                <div className={stylex(styles.cardtitleFake)} />
                <div className={stylex(styles.cardsubtitleFake)} />
              </Padding>
            </Card>
            <Card className={stylex(styles.cardFake)}>
              <Padding vertical={16} horizontal={16}>
                <div className={stylex(styles.cardtitleFake)} />
                <div className={stylex(styles.cardsubtitleFake)} />
              </Padding>
            </Card>
          </Grid>
        </Margin>
      </Padding>
    </div>
  );
}
