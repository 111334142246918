/**
 * @generated SignedSource<<15532b56358014bba03eb6d619228990>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type Gender = "MALE" | "FEMALE" | "NONBINARY" | "TRANSGENDERMALE" | "TRANSGENDERFEMALE" | "QUEER" | "OTHER" | "%future added value";
export type Pronoun = "HE" | "SHE" | "THEY" | "OTHER" | "%future added value";
export type CreateProfileMutation$variables = {|
  name: string,
  lastname: string,
  gender?: ?Gender,
  pronoun?: ?Pronoun,
  description?: ?string,
|};
export type CreateProfileMutation$data = {|
  +updateUserProfile: {|
    +id: string,
    +name: ?string,
    +lastname: ?string,
    +fullname: ?string,
    +gender: ?Gender,
    +pronoun: ?Pronoun,
    +description: string,
  |},
|};
export type CreateProfileMutation = {|
  variables: CreateProfileMutation$variables,
  response: CreateProfileMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "gender"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lastname"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pronoun"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "gender",
        "variableName": "gender"
      },
      {
        "kind": "Variable",
        "name": "lastname",
        "variableName": "lastname"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "pronoun",
        "variableName": "pronoun"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "updateUserProfile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastname",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fullname",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gender",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pronoun",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateProfileMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateProfileMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "5c332cd3cd7c1d9e260eb5c97e5d8296",
    "id": null,
    "metadata": {},
    "name": "CreateProfileMutation",
    "operationKind": "mutation",
    "text": "mutation CreateProfileMutation(\n  $name: String!\n  $lastname: String!\n  $gender: Gender\n  $pronoun: Pronoun\n  $description: String\n) {\n  updateUserProfile(name: $name, lastname: $lastname, gender: $gender, pronoun: $pronoun, description: $description) {\n    id\n    name\n    lastname\n    fullname\n    gender\n    pronoun\n    description\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "d657f30b7e4f49b22e1bf6b39e7cedc4";

module.exports = ((node/*: any*/)/*: Mutation<
  CreateProfileMutation$variables,
  CreateProfileMutation$data,
>*/);
