// @flow
import { useIntl } from 'react-intl';
import stylex from '@serpa-cloud/stylex';
import * as amplitude from '@amplitude/analytics-browser';
import { useCallback, useState, useContext, useRef } from 'react';
import { useMutation, useLazyLoadQuery, graphql } from 'react-relay';

import {
  Text,
  Input,
  Margin,
  Button,
  Flexbox,
  Spinner,
  CascaderOption,
  EnvironmentVariablesForm,
  useInput,
  useNotification,
} from '../../../shared';

import DockerfileEditor from './DockerfileEditor';

import { Context } from '../Provider';

import useDevice from '../../../shared/hooks/useDevice';

import type { CompilationSetupUpdateMutation } from './__generated__/CompilationSetupUpdateMutation.graphql';

type Props = {|
  +onUpdate?: ?() => void,
  +callToActionLabel: string,
|};

const styles = stylex.create({
  inputContainer: {
    flex: 1,
  },
});

export default function CompilationSetup({ onUpdate, callToActionLabel }: Props): React$Node {
  const { width } = useDevice();
  const intl = useIntl();

  const envsRef = useRef<any>();
  const [, , setNotification] = useNotification();
  const { state, dispatch } = useContext(Context);

  const dockerData = useLazyLoadQuery(
    graphql`
      query CompilationSetupQuery($repo: String!, $provider: GitProvider!) {
        dockerFileByRepo(repo: $repo, provider: $provider)
      }
    `,
    {
      provider: state?.gitProvider,
      repo: state?.gitRepo?.name ?? '',
    },
    {
      fetchPolicy: 'store-or-network',
    },
  );

  const dockerFileAtGit = dockerData?.dockerFileByRepo ?? '';

  const hasDockerFile = !!dockerData?.dockerFileByRepo;

  const builderInput = useInput({
    required: true,
    name: 'builder',
    autoCloseSelect: true,
    validateEvent: 'blur',
    label: intl.formatMessage({ id: 'input.builder' }),
    value: state?.compilationConfig?.builder ?? (hasDockerFile ? 'DOCKER' : 'BUILDPACKS'),
    replacer(value) {
      if (!value) return builderInput.input.value;
      return value;
    },
  });

  // $FlowFixMe
  const builder: 'DOCKER' | 'BUILDPACKS' = builderInput.input.value;

  const [dockerfile, setDockerfile] = useState(
    state?.compilationConfig?.dockerfile || dockerFileAtGit || '',
  );

  const handleOnUpdateDockerfile = useCallback((text) => {
    setDockerfile(text);
  }, []);

  const [updateApp, updatePending] = useMutation<CompilationSetupUpdateMutation>(graphql`
    mutation CompilationSetupUpdateMutation($input: PatchAppInput!) {
      updateApp(input: $input) {
        id
        namespace
        appType
        dockerfileContent
        runtime
        preInstallCommand
        buildCommand
        buildPath
        nodeVersion
      }
    }
  `);

  const handleOnSubmit = useCallback(() => {
    const envs = envsRef?.current?.submit?.();
    const envsData = (envs ?? []).map((el) => el.data);
    const envsError = (envs ?? []).reduce((old, el) => [...old, ...(el?.errors ?? [])], []);

    if (!envsError?.length) {
      amplitude.track('Set Update Compilation App');

      updateApp({
        variables: {
          input: {
            builder,
            env: envsData,
            // $FlowIssue
            id: state.appId,
          },
        },
        onCompleted(response) {
          if (onUpdate) onUpdate();
          else {
            const appResponse = response?.updateApp;

            if (appResponse) {
              amplitude.track('App Updated');

              setNotification({
                id: new Date().getTime().toString(),
                type: 'SUCCESS',
                // $FlowIgnore
                message: intl.formatMessage({ id: 'app.SucessUpdate' }),
              });

              dispatch({
                type: 'SET_APP_ID',
                appId: appResponse?.id,
                appNamespaceValue: appResponse.namespace,
                compilationConfig: {
                  builder,
                  env: envsData,
                  runtime: 'CONTAINER',
                  dockerfile: dockerfile ?? '',
                },
              });
            }
          }
        },
        onError(error) {
          amplitude.track('Update App Error');

          // eslint-disable-next-line no-console
          console.trace(error);

          setNotification({
            id: new Date().getTime().toString(),
            type: 'ERROR',
            // $FlowIgnore
            message: intl.formatMessage({ id: 'app.SucessCreated' }),
          });
        },
      });
    } else {
      amplitude.track('Set Compilation App Error');
    }
  }, [state.appId, updateApp, builder, dockerfile, onUpdate, dispatch, setNotification, intl]);

  return (
    <div>
      <Flexbox columnGap={16}>
        <div className={stylex(styles.inputContainer)}>
          <Input input={builderInput.input}>
            <CascaderOption
              value="BUILDPACKS"
              label={
                <Text
                  type="s0m"
                  color="--neutral-color-800"
                  id="input.buildpacks"
                  values={{
                    g(s) {
                      return (
                        <span
                          style={{
                            color: 'var(--green-300)',
                            fontWeight: 'bold',
                          }}
                        >
                          {s}
                        </span>
                      );
                    },
                  }}
                />
              }
            />

            <CascaderOption
              value="DOCKER"
              label={<Text type="s0m" color="--neutral-color-800" id="input.docker" />}
            />
          </Input>
        </div>
      </Flexbox>

      <Margin top={width <= 1000 ? 24 : 40}>
        {builder === 'DOCKER' && !!dockerfile && (
          <>
            <Text type="s1b" color="--neutral-color-800">
              Dockerfile
            </Text>
            <Margin top={16}>
              <Text
                type="bs"
                id="appCreator.compilationDockerDescription"
                color="--neutral-color-600"
              />
            </Margin>
            <Margin top={24} bottom={24}>
              <DockerfileEditor
                content={dockerfile}
                onChange={handleOnUpdateDockerfile}
                initialContent={dockerFileAtGit || ''}
              />
            </Margin>
          </>
        )}

        {false && (
          <Margin bottom={24}>
            <EnvironmentVariablesForm
              ref={envsRef}
              defaultValues={state?.compilationConfig?.env ?? []}
            />
          </Margin>
        )}

        <Flexbox columnGap={24} alignItems="center">
          <Button
            intlId={callToActionLabel || 'createApp'}
            icon="save"
            onClick={handleOnSubmit}
            disabled={updatePending}
          />

          {updatePending && <Spinner size={24} />}
        </Flexbox>
      </Margin>
    </div>
  );
}

CompilationSetup.defaultProps = {
  onUpdate: null,
};
