// @flow
import stylex from '@serpa-cloud/stylex';

import Flexbox from './Flexbox';
import Spinner from './Spinner';

import type { LayoutSize } from './Flexbox';

const styles = stylex.create({
  loadingContainer: {
    width: '100%',
    padding: '16px',
  },
});

type Props = {|
  spinnerSize?: ?LayoutSize,
|};

export default function LoaderForSuspense({ spinnerSize = 24 }: Props): React$Node {
  return (
    <Flexbox
      className={stylex(styles.loadingContainer)}
      justifyContent="center"
      alignItems="center"
    >
      <Spinner size={spinnerSize} />
    </Flexbox>
  );
}

LoaderForSuspense.defaultProps = {
  spinnerSize: 24,
};
