/**
 * @generated SignedSource<<a373594049165ad85074b5f28a792571>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type HTTPMatchRequestMatchType = "EXACT" | "PREFIX" | "REGEX" | "%future added value";
export type HTTPMatchRequestParameter = "URI" | "SCHEME" | "METHOD" | "AUTHORITY" | "HEADERS" | "QUERY_PARAMS" | "%future added value";
export type DestinationInput = {|
  target: string,
  routes: $ReadOnlyArray<HTTPRouteInput>,
|};
export type HTTPRouteInput = {|
  match: $ReadOnlyArray<HTTPMatchRequestInput>,
  route: $ReadOnlyArray<HTTPRouteDestinationInput>,
|};
export type HTTPMatchRequestInput = {|
  parameter: HTTPMatchRequestParameter,
  parameterName?: ?string,
  matchType: HTTPMatchRequestMatchType,
  matchValue: string,
|};
export type HTTPRouteDestinationInput = {|
  destination: string,
  weight: number,
|};
export type RoutesMutation$variables = {|
  input: DestinationInput,
|};
export type RoutesMutation$data = {|
  +updateNetworking: {|
    +__typename: "Environment",
    +id: string,
    +name: string,
    +routes: $ReadOnlyArray<{|
      +match: $ReadOnlyArray<{|
        +parameter: HTTPMatchRequestParameter,
        +parameterName: ?string,
        +matchType: HTTPMatchRequestMatchType,
        +matchValue: string,
      |}>,
      +route: $ReadOnlyArray<{|
        +weight: number,
        +destination: ?{|
          +id: string,
          +name: string,
        |},
      |}>,
    |}>,
  |} | {|
    +__typename: "Implementation",
    +id: string,
    +name: string,
    +routes: $ReadOnlyArray<{|
      +match: $ReadOnlyArray<{|
        +parameter: HTTPMatchRequestParameter,
        +parameterName: ?string,
        +matchType: HTTPMatchRequestMatchType,
        +matchValue: string,
      |}>,
      +route: $ReadOnlyArray<{|
        +weight: number,
        +destination: ?{|
          +id: string,
          +name: string,
        |},
      |}>,
    |}>,
  |} | {|
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    +__typename: "%other",
  |},
|};
export type RoutesMutation = {|
  variables: RoutesMutation$variables,
  response: RoutesMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "HTTPRoute",
    "kind": "LinkedField",
    "name": "routes",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "HTTPMatchRequest",
        "kind": "LinkedField",
        "name": "match",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parameter",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parameterName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "matchType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "matchValue",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "HTTPRouteDestination",
        "kind": "LinkedField",
        "name": "route",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "weight",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Deployment",
            "kind": "LinkedField",
            "name": "destination",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v6 = {
  "kind": "InlineFragment",
  "selections": (v5/*: any*/),
  "type": "Environment",
  "abstractKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": (v5/*: any*/),
  "type": "Implementation",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RoutesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateNetworking",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RoutesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateNetworking",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5b900e965ff0e431d6f7a037b80ba242",
    "id": null,
    "metadata": {},
    "name": "RoutesMutation",
    "operationKind": "mutation",
    "text": "mutation RoutesMutation(\n  $input: DestinationInput!\n) {\n  updateNetworking(input: $input) {\n    __typename\n    ... on Environment {\n      id\n      name\n      routes {\n        match {\n          parameter\n          parameterName\n          matchType\n          matchValue\n        }\n        route {\n          weight\n          destination {\n            id\n            name\n          }\n        }\n      }\n    }\n    ... on Implementation {\n      id\n      name\n      routes {\n        match {\n          parameter\n          parameterName\n          matchType\n          matchValue\n        }\n        route {\n          weight\n          destination {\n            id\n            name\n          }\n        }\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "b82ea4cfe74d1c1f30399c141bc36215";

module.exports = ((node/*: any*/)/*: Mutation<
  RoutesMutation$variables,
  RoutesMutation$data,
>*/);
