// @flow
import stylex from '@serpa-cloud/stylex';
import { useNavigate } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { useState, useCallback, Suspense } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { graphql, useMutation, useLazyLoadQuery } from 'react-relay';

import { Icon, Avatar, Padding, ContextualMenu, InteractiveElement } from '../../shared';

import ContextualMenuItem from './ContextualMenuItem';

import type { Avatar$key } from '../../shared';

import type { UITheme } from './__generated__/UserBadgeQuery.graphql';
import type { UserBadgeLogoutMutation } from './__generated__/UserBadgeLogoutMutation.graphql';
import type { UserBadgeChangeUIThemeMutation } from './__generated__/UserBadgeChangeUIThemeMutation.graphql';

const styles = stylex.create({
  container: {
    height: 36,
    position: 'relative',
    boxSizing: 'border-box',
  },
  containerInteractive: {
    height: 34,
    paddingLeft: 4,
    paddingRight: 4,
    cursor: 'pointer',
  },
  content: {
    width: '100%',
    height: '100%',
  },
  placeholder: {
    height: 16,
    width: 120,
    borderRadius: 8,
    backgroundColor: 'var(--neutral-color-300)',
  },
  contextualMenu: {
    width: 320,
  },
});

type UIProps = {|
  +label?: ?string,
  +userId?: ?string,
  +media?: ?Avatar$key,
  +uiTheme?: ?UITheme,
|};

function UserBadgeUI({ userId, label, media, uiTheme }: UIProps): React$Node {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const handleOnAction = useCallback(() => {
    setTimeout(() => {
      setMenuOpen((x) => !x);
    }, 1);
  }, []);

  const [logout] = useMutation<UserBadgeLogoutMutation>(graphql`
    mutation UserBadgeLogoutMutation {
      logout
    }
  `);

  const [changeUITheme, uiThemePending] = useMutation<UserBadgeChangeUIThemeMutation>(graphql`
    mutation UserBadgeChangeUIThemeMutation($uiTheme: UITheme!) {
      setUITheme(uiTheme: $uiTheme) {
        id
        uiTheme
      }
    }
  `);

  return (
    <div className={stylex(styles.container)}>
      <InteractiveElement
        label={label ?? ''}
        onClick={handleOnAction}
        onKeyPress={handleOnAction}
        className={stylex(styles.containerInteractive)}
      >
        <Avatar node={media ?? null} circle fixedWidth={36} fallbackIcon={<Icon icon="person" />} />
      </InteractiveElement>

      <ContextualMenu
        open={menuOpen}
        containerHeight={48}
        anchor="RIGHT"
        onClose={() => {
          setMenuOpen(false);
        }}
        className={stylex(styles.contextualMenu)}
      >
        <Padding horizontal={4} vertical={8}>
          <ContextualMenuItem
            icon={uiTheme === 'LIGHT' ? 'dark_mode' : 'light_mode'}
            title={uiTheme === 'LIGHT' ? 'theme.changeToDarkMode' : 'theme.changeToLightMode'}
            callback={() => {
              if (!uiThemePending)
                changeUITheme({
                  variables: {
                    uiTheme: uiTheme === 'LIGHT' ? 'DARK' : 'LIGHT',
                  },
                  optimisticUpdater(store) {
                    if (userId) {
                      const record = store.get(userId);
                      record?.setValue(uiTheme === 'LIGHT' ? 'DARK' : 'LIGHT', 'uiTheme');
                    }
                  },
                });
            }}
            pending={uiThemePending}
          />
          <ContextualMenuItem
            icon="face"
            title="editProfile"
            callback={() => {
              setMenuOpen(false);
              navigate('/app/create-profile');
            }}
          />
          <ContextualMenuItem
            icon="logout"
            title="logout"
            callback={() => {
              amplitude.reset();

              logout({
                variables: {},
                onCompleted() {
                  window.location.href = 'https://serpa.cloud';

                  if (process.env.NODE_ENV === 'production') {
                    datadogRum.clearUser();
                  }
                },
              });
            }}
          />
        </Padding>
      </ContextualMenu>
    </div>
  );
}

UserBadgeUI.defaultProps = {
  label: '',
  media: null,
  userId: null,
  uiTheme: 'LIGHT',
};

function UserBadgeData(): React$Node {
  const userData = useLazyLoadQuery(
    graphql`
      query UserBadgeQuery {
        me {
          id
          username
          name
          uiTheme
          media(width: 72, height: 72) {
            ...Avatar
          }
        }
      }
    `,
    {},
    {
      fetchPolicy: 'store-or-network',
    },
  );

  if (!userData?.me) return null;

  const { id, name, media, username, uiTheme } = userData.me;

  return <UserBadgeUI userId={id} label={name ?? username} media={media} uiTheme={uiTheme} />;
}

export default function UserBadgeInterface(): React$Node {
  return (
    <Suspense fallback={<UserBadgeUI />}>
      <UserBadgeData />
    </Suspense>
  );
}
