/**
 * @generated SignedSource<<cf7d186e3c6f34a1c379ee1bd58815e5>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
type Avatar$fragmentType = any;
export type ProfilePictureMutation$variables = {|
  image: string,
|};
export type ProfilePictureMutation$data = {|
  +updateUserMedia: {|
    +id: string,
    +media: ?{|
      +$fragmentSpreads: Avatar$fragmentType,
    |},
    +thumb: ?{|
      +$fragmentSpreads: Avatar$fragmentType,
    |},
    +thumbComposer: ?{|
      +$fragmentSpreads: Avatar$fragmentType,
    |},
  |},
|};
export type ProfilePictureMutation = {|
  variables: ProfilePictureMutation$variables,
  response: ProfilePictureMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "image"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "image",
    "variableName": "image"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "height",
    "value": 352
  },
  {
    "kind": "Literal",
    "name": "width",
    "value": 352
  }
],
v4 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "Avatar"
  }
],
v5 = [
  {
    "kind": "Literal",
    "name": "height",
    "value": 64
  },
  {
    "kind": "Literal",
    "name": "width",
    "value": 64
  }
],
v6 = [
  {
    "kind": "Literal",
    "name": "height",
    "value": 80
  },
  {
    "kind": "Literal",
    "name": "width",
    "value": 80
  }
],
v7 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "alt",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "width",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "height",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfilePictureMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "updateUserMedia",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "media",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": "media(height:352,width:352)"
          },
          {
            "alias": "thumb",
            "args": (v5/*: any*/),
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "media",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": "media(height:64,width:64)"
          },
          {
            "alias": "thumbComposer",
            "args": (v6/*: any*/),
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "media",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": "media(height:80,width:80)"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProfilePictureMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "updateUserMedia",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "media",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": "media(height:352,width:352)"
          },
          {
            "alias": "thumb",
            "args": (v5/*: any*/),
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "media",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": "media(height:64,width:64)"
          },
          {
            "alias": "thumbComposer",
            "args": (v6/*: any*/),
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "media",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": "media(height:80,width:80)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ae7f1bbacd989165c21302848776e8e4",
    "id": null,
    "metadata": {},
    "name": "ProfilePictureMutation",
    "operationKind": "mutation",
    "text": "mutation ProfilePictureMutation(\n  $image: ID!\n) {\n  updateUserMedia(image: $image) {\n    id\n    media(width: 352, height: 352) {\n      ...Avatar\n      id\n    }\n    thumb: media(width: 64, height: 64) {\n      ...Avatar\n      id\n    }\n    thumbComposer: media(width: 80, height: 80) {\n      ...Avatar\n      id\n    }\n  }\n}\n\nfragment Avatar on Image {\n  id\n  url\n  alt\n  width\n  height\n}\n"
  }
};
})();

(node/*: any*/).hash = "e47c60964fc678b525d2d38a07c19d44";

module.exports = ((node/*: any*/)/*: Mutation<
  ProfilePictureMutation$variables,
  ProfilePictureMutation$data,
>*/);
