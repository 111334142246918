// @flow
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useMatch, useNavigate } from 'react-router-dom';
import { Text, Padding, Flexbox, Button } from '../../../shared';

import useDevice from '../../../shared/hooks/useDevice';

import DeleteDeploymentModal from '../DeleteDeploymentModal';

export default function DeploymentDangerZone(): React$Node {
  const navigate = useNavigate();
  const match = useMatch('/app/:action/:id/:section');
  const [showModal, setShowModal] = useState(false);
  const { width } = useDevice();

  return (
    <>
      {showModal
        ? createPortal(
            // eslint-disable-next-line react/jsx-indent
            <DeleteDeploymentModal
              mode="DELETE"
              deploymentId={match?.params?.id ?? ''}
              onClose={() => {
                setShowModal(false);
                navigate('/app', { replace: true });
              }}
            />,
            document.getElementById('modal-portal'),
          )
        : null}
      <Padding vertical={24} horizontal={16}>
        <Flexbox flexDirection="column" rowGap={24}>
          <Text type="h5" color="--red-300" id="deployment.dangerZone.title" />
          <Text type={width <= 815 ? 'bs' : 'bd'} id="deployment.dangerZone.shortDescription" />
        </Flexbox>
        <Padding top={32}>
          <Flexbox>
            <Button
              intlId="delete"
              icon="delete"
              buttonType="danger"
              onClick={() => setShowModal(true)}
            />
          </Flexbox>
        </Padding>
      </Padding>
    </>
  );
}
