// @flow
import { useState, useRef } from 'react';
import stylex from '@serpa-cloud/stylex';
import { useTransition, animated } from 'react-spring';
import { graphql, useMutation } from 'react-relay/hooks';

import { LiteButton, Avatar, useImageLoad } from '../../../shared';
import type { Avatar$key } from '../../../shared';

import type { ProfileCoverMutation } from './__generated__/ProfileCoverMutation.graphql';

const styles = stylex.create({
  coverContainer: {
    height: 240,
    position: 'relative',
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    backgroundColor: 'var(--neutral-color-300)',
  },
  editCoverContainer: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
  inputFile: {
    width: 0,
    height: 0,
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  picturePreview: {
    objectFit: 'cover',
    width: 1200,
    height: 240,
  },
});

type Props = {|
  +owned: boolean,
  +fullname: string,
  +node?: ?Avatar$key,
|};

export default function ProfileCover({ fullname, node, owned }: Props): React$Node {
  const coverRef = useRef<?HTMLInputElement>();

  const [updateMedia] = useMutation<ProfileCoverMutation>(graphql`
    mutation ProfileCoverMutation($image: ID!) {
      updateUserCover(image: $image) {
        id
        cover(width: 2400, height: 480) {
          ...Avatar
        }
        bluredCover: cover(width: 30, height: 15, blur: 2) {
          id
          url
        }
      }
    }
  `);

  const [showCoverEditButton, setShowCoverEditButton] = useState(false);
  const [transitions] = useTransition(showCoverEditButton, () => ({
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  }));

  const [, , , coverPreviewUrl, coverOnChange, disposeMedia] = useImageLoad(fullname, (image) => {
    updateMedia({
      variables: { image },
      onCompleted() {
        disposeMedia();
      },
    });
  });

  return (
    <>
      {owned && (
        <input
          ref={coverRef}
          type="file"
          accept="image/*"
          onChange={coverOnChange}
          className={stylex(styles.inputFile)}
        />
      )}
      <div
        className={stylex(styles.coverContainer)}
        onMouseEnter={() => setShowCoverEditButton(true)}
        onMouseLeave={() => setShowCoverEditButton(false)}
      >
        {owned &&
          transitions(
            (style, item) =>
              item && (
                <animated.div style={style} className={stylex(styles.editCoverContainer)}>
                  <LiteButton
                    intlId="profile.addCover"
                    icon="upload"
                    onClick={() => coverRef?.current?.click()}
                  />
                </animated.div>
              ),
          )}
        {coverPreviewUrl && (
          <img
            className={stylex(styles.picturePreview)}
            width="1200"
            height="240"
            alt=""
            src={coverPreviewUrl}
            style={{ opacity: 0.4 }}
          />
        )}
        {!coverPreviewUrl && (
          <Avatar
            fixedWidth={1200}
            fixedHeight={240}
            node={node ?? null}
            circle={false}
            shadow={false}
          />
        )}
      </div>
    </>
  );
}

ProfileCover.defaultProps = {
  node: null,
};
