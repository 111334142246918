// @flow
import { useIntl } from 'react-intl';
import stylex from '@serpa-cloud/stylex';
import { graphql, useLazyLoadQuery } from 'react-relay';
import * as amplitude from '@amplitude/analytics-browser';
import { useSearchParams, useNavigate } from 'react-router-dom';

import {
  lazy,
  useRef,
  useState,
  Suspense,
  useEffect,
  useContext,
  useLayoutEffect,
  startTransition,
} from 'react';

import {
  Text,
  Margin,
  Button,
  Padding,
  Flexbox,
  SideModal,
  useInterval,
  usePageView,
  Spinner,
} from '../../../shared';

import { Context } from '../Provider';
import BuildRemediations from './BuildRemediations';

import useDevice from '../../../shared/hooks/useDevice';

const BuildStatusModal = lazy(() => import('./BuildStatusModal'));

const keywordRegexp = /^(Starting|Finished) Step #[0-9]*$|^FETCHSOURCE|^BUILD$|^PUSH$|^DONE$/g;

const styles = stylex.create({
  root: {
    width: '100%',

    boxSizing: 'border-box',
    '@media (max-width: 1000px) and (min-width: 541px)': {
      paddingLeft: 16,
      paddingRight: 16,
    },
    '@media (max-width: 540px)': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  buildProsess: {
    flex: 1,
    overflow: 'hidden',
    borderRadius: '8px',
    border: '1px solid var(--border-color)',
    transitionProperty: 'height',
    transitionDuration: 'var(--fds-duration-short-in)',
    transitionTimingFunction: 'var(--fds-animation-expand-collapse-out)',
  },
  buildProcessWithoutError: {
    height: 'calc(100vh - 304px)',
  },
  buildProcessWithError: {
    height: 'calc((100vh - 304px) / 2)',
  },
  buildHeader: {
    borderBottom: '1px solid var(--neutral-color-300)',
    backgroundColor: 'var(--neutral-color-100)',
  },
  buildTerminal: {
    flex: 1,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 16,
    paddingRight: 16,
    overflowY: 'auto',
    overscrollBehavior: 'contain',
    backgroundColor: 'var(--logs-background)',
  },
  doneIcon: {
    width: '66px',
    height: '66px',
    backgroundColor: 'var(--done)',
    borderRadius: '33px',
  },
  bullet: {
    width: 16,
    height: 16,
    borderRadius: '8px',
    backgroundColor: 'var(--neutral-color-400)',
    marginRight: '8px',
  },
  line: {
    marginTop: 12,
    marginBottom: 12,
    color: 'var(--always-white)',
    fontFamily: 'var(--font-family-code)',
    '@media (max-width: 1000px)': {
      fontSize: 14,
    },
  },
  lineActive: {
    color: 'var(--secondary-color-4)',
  },
});

const statusMapping: { [string]: { icon: string, color: string } } = {
  UNKNOWN: { color: '--neutral-color-600', icon: 'help' },
  QUEUED: { color: '--neutral-color-600', icon: '' },
  WORKING: { color: '--neutral-color-600', icon: '' },
  SUCCESS: { color: '--green-300', icon: 'check_circle' },
  FAILURE: { color: '--red-300', icon: 'error' },
  ERROR: { color: '--red-300', icon: 'error' },
  TIMEOUT: { color: '--red-300', icon: 'timelapse' },
  CANCELLED: { color: '--red-300', icon: 'stop_circle' },
};

type Props = {|
  +buildId?: ?string,
  +avoidOpenModal?: ?boolean,
|};

export default function BuildStatus({ buildId, avoidOpenModal }: Props): React$Node {
  const intl = useIntl();
  const { screenSize, width } = useDevice();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  usePageView(intl.formatMessage({ id: 'pageView.buildLogs' }));

  const isAutoDeploy = searchParams.get('autodeploy') === 'true';

  const containerRef = useRef();
  const [fetchKey, setFetchKey] = useState(0);
  const [showDoneModal, setShowDoneModal] = useState<boolean>(false);

  const scheduledAnimationFrame = useRef<boolean>(false);

  const previousScrollDelta = useRef<number>(0);
  const previousScrollPosition = useRef<number>(Infinity);

  const { state } = useContext(Context);

  const data = useLazyLoadQuery(
    graphql`
      query BuildStatusQuery($id: ID!) {
        node(id: $id) {
          id
          ... on Build {
            createdAt
            buildTime
            done
            app {
              id
              name
            }
            status
            logs {
              id
              payloads
            }
            artifact {
              id
            }
          }
        }
      }
    `,
    {
      id: buildId ?? state.compilationId ?? '',
    },
    {
      fetchKey,
      fetchPolicy: 'store-and-network',
    },
  );

  const status = data?.node?.status ?? 'UNKNOW';
  const isPolling = status === 'QUEUED' || status === 'WORKING';

  const payloads = data?.node?.logs?.payloads ?? [];
  const payloadsLength = payloads?.length ?? 0;
  const initialPayloadsRef = useRef(payloadsLength);

  useEffect(() => {
    const element = containerRef.current;

    function handler() {
      const height = element?.clientHeight ?? 0;
      const scrollTop = element?.scrollTop ?? 0;
      const scrollHeight = element?.scrollHeight ?? 0;

      previousScrollDelta.current = scrollHeight - (height + scrollTop);
      previousScrollPosition.current = scrollTop;
      scheduledAnimationFrame.current = false;
    }

    function onScroll() {
      if (!scheduledAnimationFrame.current) {
        scheduledAnimationFrame.current = true;
        requestAnimationFrame(handler);
      }
    }

    element?.addEventListener('scroll', onScroll);
    return () => {
      element?.removeEventListener('scroll', onScroll);
    };
  });

  useLayoutEffect(() => {
    const element = containerRef.current;
    if (payloadsLength !== initialPayloadsRef.current && element) {
      element.scrollTop =
        element.scrollHeight - (element.clientHeight + previousScrollDelta.current);
    }
  }, [payloadsLength]);

  useInterval(
    () => {
      startTransition(() => {
        setFetchKey((k) => k + 1);
      });
    },
    isPolling ? 5000 : null,
  );

  const buildIsDone = Boolean(data?.node?.done);

  useEffect(() => {
    if (buildIsDone) {
      amplitude.track('Build Done');

      amplitude.track(`Build Status ${status}`);

      if (!avoidOpenModal) {
        setShowDoneModal(true);
      }

      if (isAutoDeploy && data.node) {
        navigate(
          `/app/apps/${data.node?.app?.id ?? ''}/deployments/create?appId=${data.node?.app?.id ??
            ''}&source=${data.node?.artifact?.id ?? ''}&recreate=true&enable_deployment=true`,
        );
      }
    }
  }, [buildIsDone, avoidOpenModal, status, isAutoDeploy, data.node, navigate]);

  const isUserError = status === 'ERROR' || status === 'FAILURE';

  if (!data.node) return null;

  const { logs, done, buildTime } = data.node;

  return (
    <>
      <SideModal
        open={showDoneModal}
        onClose={() => {
          amplitude.track('Build Modal Close');
          setShowDoneModal(false);
        }}
      >
        <Suspense
          fallback={
            <Padding vertical={80}>
              <Flexbox alignItems="center" justifyContent="center">
                <Spinner />
              </Flexbox>
            </Padding>
          }
        >
          <BuildStatusModal
            appId={data?.node?.app?.id ?? ''}
            onClose={() => {
              amplitude.track('Build Modal Close');
              setShowDoneModal(false);
            }}
          />
        </Suspense>
      </SideModal>
      <div className={stylex(styles.root)}>
        <Padding bottom={24}>
          <Flexbox flexDirection="column" rowGap={16}>
            <Text
              type={width <= 1000 ? 'bs' : 'bd'}
              id={isPolling ? 'appCreator.buildDescription' : 'buildDetail.complete'}
              color="--neutral-color-700"
            />
            <Text
              type="bs"
              id="appCreator.buildStartedAt"
              color="--neutral-color-600"
              values={{ date: new Date(data.node?.createdAt ?? '').toLocaleString(intl.locale) }}
            />
          </Flexbox>
        </Padding>

        <Flexbox
          className={stylex(
            styles.buildProsess,
            isUserError ? styles.buildProcessWithError : styles.buildProcessWithoutError,
          )}
          flexDirection="column"
        >
          <div className={stylex(styles.buildHeader)}>
            <Padding horizontal={16} vertical={16}>
              <Flexbox
                justifyContent="space-between"
                rowGap={16}
                flexDirection={screenSize === 'phone' ? 'column' : 'row'}
              >
                <Flexbox alignItems="center" columnGap={8}>
                  <Text
                    type="s0m"
                    color="--secondary-color-600"
                    id="appCreator.buildLogsDuration"
                  />
                  <Text type="s0b" color="--primary-color-1">{`${buildTime ?? 0}s`}</Text>
                </Flexbox>
                <Text
                  type="s0b"
                  color={statusMapping[status ?? 'UNKNOW']?.color}
                  id={`appCreator.buildStatus.${status ?? 'UNKNOW'}`}
                />
              </Flexbox>
            </Padding>
          </div>
          <div className={stylex(styles.buildTerminal)} ref={containerRef}>
            {logs?.payloads.map((line, lineIndex) => {
              const isKeyword = new RegExp(keywordRegexp).test(line);
              return (
                <p
                  // eslint-disable-next-line react/no-array-index-key
                  key={lineIndex}
                  className={stylex(styles.line, isKeyword ? styles.lineActive : null)}
                >
                  {line || ' '}
                </p>
              );
            })}
          </div>
        </Flexbox>
        {!isUserError && (
          <Margin top={24}>
            <Flexbox>
              <Button
                intlId="deploy"
                disabled={!done}
                onClick={() => setShowDoneModal(true)}
                iconRight={done ? 'east' : null}
              />
            </Flexbox>
          </Margin>
        )}
        {isUserError && (
          <Suspense
            fallback={
              <Margin top={24}>
                <Flexbox alignItems="center" columnGap={16}>
                  <Spinner size={20} />
                  <Text id="build.gettingRecomendations" type="s1m" color="--red-300" />
                </Flexbox>
              </Margin>
            }
          >
            <BuildRemediations id={data?.node?.id ?? ''} appId={data?.node?.app?.id ?? ''} />
          </Suspense>
        )}
      </div>
    </>
  );
}

BuildStatus.defaultProps = {
  buildId: null,
  avoidOpenModal: false,
};
