// @flow
import { useIntl } from 'react-intl';
import stylex from '@serpa-cloud/stylex';
import { useCallback, Suspense } from 'react';

import {
  Grid,
  Padding,
  Flexbox,
  Breadcrumb,
  ScrolledList,
  useFetchPolicy,
  usePageView,
} from '../../shared';

// import Form from './Form';
import CompilationSource from './CompilationSource';
import CompilationElement from './CompilationElement';

import useDevice from '../../shared/hooks/useDevice';

const styles = stylex.create({
  toolContainer: {
    zIndex: 9,
    position: 'relative',
  },
});

export default function CompilationsList(): React$Node {
  const { width } = useDevice();
  const intl = useIntl();
  usePageView(intl.formatMessage({ id: 'pageView.compilation' }));
  const baseFetchPolicy = useFetchPolicy();
  const renderElement = useCallback(
    (node, key) => <CompilationElement node={node} key={key} />,
    [],
  );

  return (
    <div>
      <Breadcrumb
        sections={[{ link: '/app/builds', textId: 'home.builds', icon: 'construction' }]}
      />
      <Grid
        columnGap={24}
        columns={width <= 1000 ? '1fr' : '400px 1fr'}
        className={stylex(styles.content)}
      >
        <Padding left={16} vertical={24} right={16} className={stylex(styles.toolContainer)}>
          <CompilationSource />
        </Padding>
        <Padding
          right={16}
          left={16}
          top={width <= 1000 ? 0 : 24}
          bottom={24}
          className={stylex(styles.list)}
        >
          <Suspense fallback={<div />}>
            <ScrolledList
              first={10}
              index="BUILDS"
              fetchPolicy={baseFetchPolicy}
              renderElement={renderElement}
              container={<Flexbox rowGap={24} flexDirection="column" />}
            />
          </Suspense>
        </Padding>
      </Grid>
    </div>
  );
}
