/**
 * @generated SignedSource<<aa9ae91860df75f4fcb942160b4cbebd>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
type DNS$fragmentType = any;
type Networking$fragmentType = any;
export type DomainFormPageMutation$variables = {|
  environment: string,
  domain: string,
|};
export type DomainFormPageMutation$data = {|
  +addAlternateDomain: {|
    +id: string,
    +name: string,
    +namespace: string,
    +$fragmentSpreads: DNS$fragmentType & Networking$fragmentType,
  |},
|};
export type DomainFormPageMutation = {|
  variables: DomainFormPageMutation$variables,
  response: DomainFormPageMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "domain"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "environment"
},
v2 = [
  {
    "kind": "Variable",
    "name": "domain",
    "variableName": "domain"
  },
  {
    "kind": "Variable",
    "name": "environment",
    "variableName": "environment"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "namespace",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domain",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domainName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DomainFormPageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Environment",
        "kind": "LinkedField",
        "name": "addAlternateDomain",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DNS"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Networking"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DomainFormPageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Environment",
        "kind": "LinkedField",
        "name": "addAlternateDomain",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "KubernetesCluster",
            "kind": "LinkedField",
            "name": "kubernetesCluster",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "managedResource",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Ingress",
                "kind": "LinkedField",
                "name": "ingress",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ingressHostName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainMapping",
            "kind": "LinkedField",
            "name": "domainsMapping",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Certificate",
                "kind": "LinkedField",
                "name": "cert",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "provider",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "arn",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DNSRecord",
                    "kind": "LinkedField",
                    "name": "dnsRecords",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domainsRecords",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DNSRecordProvider",
                "kind": "LinkedField",
                "name": "records",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "A",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "CNAME",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "validationId",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "HTTPRoute",
            "kind": "LinkedField",
            "name": "routes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "HTTPMatchRequest",
                "kind": "LinkedField",
                "name": "match",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "parameter",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "parameterName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "matchType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "matchValue",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "HTTPRouteDestination",
                "kind": "LinkedField",
                "name": "route",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "weight",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Deployment",
                    "kind": "LinkedField",
                    "name": "destination",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f7f2edb35db4c23a3c3e5d96c15cf648",
    "id": null,
    "metadata": {},
    "name": "DomainFormPageMutation",
    "operationKind": "mutation",
    "text": "mutation DomainFormPageMutation(\n  $environment: ID!\n  $domain: String!\n) {\n  addAlternateDomain(environment: $environment, domain: $domain) {\n    id\n    name\n    namespace\n    ...DNS\n    ...Networking\n  }\n}\n\nfragment DNS on Environment {\n  id\n  ...Registers\n}\n\nfragment DomainElement on Domain {\n  id\n  domain\n  records {\n    A\n    CNAME\n  }\n  status\n  validationId\n}\n\nfragment Networking on Environment {\n  id\n  routes {\n    match {\n      parameter\n      parameterName\n      matchType\n      matchValue\n    }\n    route {\n      weight\n      destination {\n        id\n        name\n      }\n    }\n  }\n}\n\nfragment Register on DomainMapping {\n  id\n  domain\n  cert {\n    id\n    domainName\n    provider\n    arn\n    dnsRecords {\n      domainName\n      name\n      type\n      value\n    }\n  }\n}\n\nfragment Registers on Environment {\n  id\n  kubernetesCluster {\n    id\n    managedResource\n    ingress {\n      id\n      ingressHostName\n    }\n  }\n  domainsMapping {\n    id\n    ...Register\n  }\n  domainsRecords {\n    id\n    ...DomainElement\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "6991ce38fcbfbbf388d8b775e58713f6";

module.exports = ((node/*: any*/)/*: Mutation<
  DomainFormPageMutation$variables,
  DomainFormPageMutation$data,
>*/);
