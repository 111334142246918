/**
 * @generated SignedSource<<4191754c3c86d0cc759316645de5105b>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
type Avatar$fragmentType = any;
export type ProfileCoverMutation$variables = {|
  image: string,
|};
export type ProfileCoverMutation$data = {|
  +updateUserCover: {|
    +id: string,
    +cover: ?{|
      +$fragmentSpreads: Avatar$fragmentType,
    |},
    +bluredCover: ?{|
      +id: string,
      +url: string,
    |},
  |},
|};
export type ProfileCoverMutation = {|
  variables: ProfileCoverMutation$variables,
  response: ProfileCoverMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "image"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "image",
    "variableName": "image"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "height",
    "value": 480
  },
  {
    "kind": "Literal",
    "name": "width",
    "value": 2400
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v5 = {
  "alias": "bluredCover",
  "args": [
    {
      "kind": "Literal",
      "name": "blur",
      "value": 2
    },
    {
      "kind": "Literal",
      "name": "height",
      "value": 15
    },
    {
      "kind": "Literal",
      "name": "width",
      "value": 30
    }
  ],
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "cover",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": "cover(blur:2,height:15,width:30)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileCoverMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "updateUserCover",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "cover",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Avatar"
              }
            ],
            "storageKey": "cover(height:480,width:2400)"
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProfileCoverMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "updateUserCover",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "cover",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "width",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "height",
                "storageKey": null
              }
            ],
            "storageKey": "cover(height:480,width:2400)"
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6dbd9a7529daec9ebc5e81314ef62041",
    "id": null,
    "metadata": {},
    "name": "ProfileCoverMutation",
    "operationKind": "mutation",
    "text": "mutation ProfileCoverMutation(\n  $image: ID!\n) {\n  updateUserCover(image: $image) {\n    id\n    cover(width: 2400, height: 480) {\n      ...Avatar\n      id\n    }\n    bluredCover: cover(width: 30, height: 15, blur: 2) {\n      id\n      url\n    }\n  }\n}\n\nfragment Avatar on Image {\n  id\n  url\n  alt\n  width\n  height\n}\n"
  }
};
})();

(node/*: any*/).hash = "6a94117df10bba1d42821dd91905c5e5";

module.exports = ((node/*: any*/)/*: Mutation<
  ProfileCoverMutation$variables,
  ProfileCoverMutation$data,
>*/);
