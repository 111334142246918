// @flow
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import stylex from '@serpa-cloud/stylex';
import { useNavigate } from 'react-router-dom';
import { graphql, useMutation, useLazyLoadQuery } from 'react-relay';

import {
  Text,
  Input,
  Margin,
  Button,
  Spinner,
  Padding,
  Flexbox,
  Breadcrumb,
  CascaderOption,
  useInput,
  usePageView,
  validateData,
} from '../../shared';

import ProfilePicture from '../Profile/ProfilePicture';

import SessionQuery from '../../queries/Session';

import type { CreateProfileMutation } from './__generated__/CreateProfileMutation.graphql';

const styles = stylex.create({
  container: {
    paddingTop: 'var(--content-top-padding)',
  },
  mainContent: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  cardContainer: {
    width: 560,
  },
  inputContainer: {
    flex: 1,
  },
  bioContainer: {
    height: 96,
  },
});

export default function CreateProfile(): React$Node {
  const intl = useIntl();
  usePageView(intl.formatMessage({ id: 'pageView.profile' }));

  const navigate = useNavigate();

  const data = useLazyLoadQuery(
    SessionQuery,
    {},
    {
      fetchPolicy: 'store-or-network',
    },
  );

  const name = useInput({
    name: 'name',
    value: data?.me?.name ?? '',
    label: intl.formatMessage({ id: 'input.firstNameLabel' }),
    validateEvent: 'blur',
    errors: {
      requiredError: intl.formatMessage({ id: 'input.requiredError' }),
    },
    required: true,
  });

  const lastname = useInput({
    name: 'lastname',
    value: data?.me?.lastname ?? '',
    label: intl.formatMessage({ id: 'input.lastNameLabel' }),
    validateEvent: 'blur',
    errors: {
      requiredError: intl.formatMessage({ id: 'input.requiredError' }),
    },
    required: true,
  });

  const gender = useInput({
    name: 'gender',
    value: data?.me?.gender ?? '',
    label: intl.formatMessage({ id: 'input.genderLabel' }),
    autoCloseSelect: true,
  });

  const pronoun = useInput({
    name: 'pronoun',
    value: data?.me?.pronoun ?? '',
    label: intl.formatMessage({ id: 'input.pronounLabel' }),
    autoCloseSelect: true,
  });

  const description = useInput({
    name: 'description',
    value: data?.me?.description ?? '',
    label: intl.formatMessage({ id: 'input.biographyLabel' }),
    maxlength: 150,
    type: 'textarea',
  });

  const [updateProfile, updateProfilePending] = useMutation<CreateProfileMutation>(graphql`
    mutation CreateProfileMutation(
      $name: String!
      $lastname: String!
      $gender: Gender
      $pronoun: Pronoun
      $description: String
    ) {
      updateUserProfile(
        name: $name
        lastname: $lastname
        gender: $gender
        pronoun: $pronoun
        description: $description
      ) {
        id
        name
        lastname
        fullname
        gender
        pronoun
        description
      }
    }
  `);

  const handleOnSubmit = useCallback(() => {
    const { data: formData, errors } = validateData([name, lastname, gender, pronoun, description]);

    if (!errors) {
      updateProfile({
        variables: {
          name: formData.name,
          lastname: formData.lastname,
          gender: formData.gender,
          pronoun: formData.pronoun,
          description: formData.description,
        },
        onCompleted() {
          navigate('/app/apps/create/catalog');
        },
      });
    }
  }, [description, gender, lastname, name, pronoun, updateProfile, navigate]);

  return (
    <div>
      <Breadcrumb
        sections={[
          { link: '/app/create-profile', textId: 'profile.updateProfile', icon: 'person' },
        ]}
      />
      <Padding bottom={40}>
        <div className={stylex(styles.container)}>
          <div className={stylex(styles.mainContent)}>
            <Margin>
              <Flexbox justifyContent="center">
                <div className={stylex(styles.cardContainer)}>
                  <Padding horizontal={16} vertical={24}>
                    <Flexbox justifyContent="center" alignItems="center">
                      <ProfilePicture
                        fullname={data?.me?.fullname ?? ''}
                        node={data?.me?.profileAvatar ?? null}
                        owned
                      />
                    </Flexbox>
                    <Padding top={32}>
                      <Text
                        id="profileEditor.personalData"
                        type="s1b"
                        color="--neutral-color-700"
                      />
                      <Margin top={16}>
                        <Flexbox columnGap={16}>
                          <div className={stylex(styles.inputContainer)}>
                            <Input input={name.input} />
                          </div>
                          <div className={stylex(styles.inputContainer)}>
                            <Input input={lastname.input} />
                          </div>
                        </Flexbox>
                      </Margin>
                    </Padding>

                    <Padding top={32}>
                      <Text
                        id="profileEditor.diversityData"
                        type="s1b"
                        color="--neutral-color-700"
                      />

                      <Margin top={16}>
                        <Flexbox columnGap={16}>
                          <div className={stylex(styles.inputContainer)}>
                            <Input input={gender.input}>
                              <CascaderOption
                                value=""
                                label={
                                  <Text
                                    type="s0m"
                                    color="--neutral-color-800"
                                    id="input.selectAnOption"
                                  />
                                }
                              />
                              <CascaderOption
                                value="MALE"
                                label={
                                  <Text
                                    type="s0m"
                                    color="--neutral-color-800"
                                    id="input.gender.MALE"
                                  />
                                }
                              />
                              <CascaderOption
                                value="FEMALE"
                                label={
                                  <Text
                                    type="s0m"
                                    color="--neutral-color-800"
                                    id="input.gender.FEMALE"
                                  />
                                }
                              />
                              <CascaderOption
                                value="NONBINARY"
                                label={
                                  <Text
                                    type="s0m"
                                    color="--neutral-color-800"
                                    id="input.gender.NONBINARY"
                                  />
                                }
                              />
                              <CascaderOption
                                value="OTHER"
                                label={
                                  <Text
                                    type="s0m"
                                    color="--neutral-color-800"
                                    id="input.gender.OTHER"
                                  />
                                }
                              />
                            </Input>
                          </div>
                          <div className={stylex(styles.inputContainer)}>
                            <Input input={pronoun.input}>
                              <CascaderOption
                                value=""
                                label={
                                  <Text
                                    type="s0m"
                                    color="--neutral-color-800"
                                    id="input.selectAnOption"
                                  />
                                }
                              />
                              <CascaderOption
                                value="HE"
                                label={
                                  <Text
                                    type="s0m"
                                    color="--neutral-color-800"
                                    id="input.pronoun.HE"
                                  />
                                }
                              />
                              <CascaderOption
                                value="SHE"
                                label={
                                  <Text
                                    type="s0m"
                                    color="--neutral-color-800"
                                    id="input.pronoun.SHE"
                                  />
                                }
                              />
                              <CascaderOption
                                value="THEY"
                                label={
                                  <Text
                                    type="s0m"
                                    color="--neutral-color-800"
                                    id="input.pronoun.THEY"
                                  />
                                }
                              />
                              <CascaderOption
                                value="OTHER"
                                label={
                                  <Text
                                    type="s0m"
                                    color="--neutral-color-800"
                                    id="input.pronoun.OTHER"
                                  />
                                }
                              />
                            </Input>
                          </div>
                        </Flexbox>
                      </Margin>
                    </Padding>

                    <Padding top={32}>
                      <Text id="profileEditor.biography" type="s1b" color="--neutral-color-700" />

                      <Margin top={16}>
                        <div className={stylex(styles.bioContainer)}>
                          <Input input={description.input} />
                        </div>

                        <Margin top={8}>
                          <Flexbox justifyContent="flex-end">
                            <Text
                              type="s0b"
                              color={
                                description.input.value.length > 140
                                  ? '--red-300'
                                  : '--primary-color-1'
                              }
                            >
                              {`${description.input.value.length} / 150`}
                            </Text>
                          </Flexbox>
                        </Margin>
                      </Margin>
                    </Padding>
                    <Padding top={24}>
                      <Flexbox columnGap={24} alignItems="center">
                        <Button
                          intlId="save"
                          onClick={handleOnSubmit}
                          disabled={updateProfilePending}
                        />
                        {updateProfilePending && <Spinner size={24} />}
                      </Flexbox>
                    </Padding>
                  </Padding>
                </div>
              </Flexbox>
            </Margin>
          </div>
        </div>
      </Padding>
    </div>
  );
}
