/**
 * @generated SignedSource<<a941409ceeb4d19b166e2b9a441e5c78>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type HeaderPaymentProfileQuery$variables = {||};
export type HeaderPaymentProfileQuery$data = {|
  +getCurrentNameSpace: ?{|
    +id: string,
    +freeTrialUntil: any,
  |},
  +paymentProfile: ?{|
    +id: string,
  |},
|};
export type HeaderPaymentProfileQuery = {|
  variables: HeaderPaymentProfileQuery$variables,
  response: HeaderPaymentProfileQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "freeTrialUntil",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "PaymentProfile",
  "kind": "LinkedField",
  "name": "paymentProfile",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HeaderPaymentProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "getCurrentNameSpace",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HeaderPaymentProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "getCurrentNameSpace",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ]
  },
  "params": {
    "cacheID": "c56ea3cab4b8997e949a4d5794440880",
    "id": null,
    "metadata": {},
    "name": "HeaderPaymentProfileQuery",
    "operationKind": "query",
    "text": "query HeaderPaymentProfileQuery {\n  getCurrentNameSpace {\n    __typename\n    id\n    freeTrialUntil\n  }\n  paymentProfile {\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "899778fc81c067f6f1b64bcbb02c6e47";

module.exports = ((node/*: any*/)/*: Query<
  HeaderPaymentProfileQuery$variables,
  HeaderPaymentProfileQuery$data,
>*/);
