// @flow
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import stylex from '@serpa-cloud/stylex';
import { graphql, useLazyLoadQuery } from 'react-relay';

import {
  Grid,
  Text,
  Input,
  Margin,
  Button,
  Padding,
  Flexbox,
  Divider,
  useInput,
  validateData,
  CascaderOption,
} from '../../../shared';

import useDevice from '../../../shared/hooks/useDevice';

type Route = {|
  weight: number,
  destination: {|
    id: string,
    name: string,
  |},
|};

export type HTTPMatchRequestMatchType = 'EXACT' | 'PREFIX' | 'REGEX' | '%future added value';
export type HTTPMatchRequestParameter =
  | 'URI'
  | 'SCHEME'
  | 'METHOD'
  | 'AUTHORITY'
  | 'HEADERS'
  | 'QUERY_PARAMS'
  | '%future added value';

const styles = stylex.create({
  scroller: {
    height: '100%',
    overflowY: 'auto',
    boxSizing: 'border-box',
    overscrollBehavior: 'contain',
    maxHeight: 'calc(100vh - 40px)',
  },
});

type Props = {|
  +target: string,
  destinationId: string,
  +onClose: () => void,
  +onSave: ({
    conditions: Array<{|
      parameter: HTTPMatchRequestParameter,
      parameterName: ?string,
      matchType: HTTPMatchRequestMatchType,
      matchValue: string,
    |}>,
    routes: Array<Route>,
  }) => void,
  +conditions: Array<{|
    parameter: HTTPMatchRequestParameter,
    parameterName: ?string,
    matchType: HTTPMatchRequestMatchType,
    matchValue: string,
  |}>,
|};

export default function ConditionsEditor({
  target,
  onSave,
  onClose,
  conditions,
  destinationId,
}: Props): React$Node {
  const { width } = useDevice();
  const intl = useIntl();
  const condition = conditions[0];

  const parameter = useInput({
    name: 'parameter',
    hideSearch: true,
    required: true,
    autoCloseSelect: true,
    value: condition?.parameter ?? 'URI',
    label: intl.formatMessage({ id: 'router.parameterLabel' }),
    errors: {
      requiredError: intl.formatMessage({ id: 'input.requiredError' }),
    },
    forceInlineRender: true,
  });

  const parameterValue = parameter.input.value;
  const parameterIsAObject = parameterValue === 'HEADERS' || parameterValue === 'QUERY_PARAMS';

  const parameterName = useInput({
    name: 'parameterName',
    label: intl.formatMessage({
      id: `router.parameterNameLabel.${parameterIsAObject ? parameterValue : 'DEFAULT'}`,
    }),
    required: parameterIsAObject,
    autoCloseSelect: true,
    value: condition?.parameterName ?? '',
    disabled: !parameterIsAObject,
    errors: {
      requiredError: intl.formatMessage({ id: 'input.requiredError' }),
    },
    forceInlineRender: true,
  });

  const matchType = useInput({
    name: 'matchType',
    hideSearch: true,
    label: intl.formatMessage({ id: 'router.matchTypeLabel' }),
    required: true,
    autoCloseSelect: true,
    value: condition?.matchType ?? 'EXACT',
    errors: {
      requiredError: intl.formatMessage({ id: 'input.requiredError' }),
    },
    forceInlineRender: true,
  });

  const matchValue = useInput({
    name: 'matchValue',
    label: `
    ${intl.formatMessage({ id: 'router.if' })}${intl.formatMessage({
      id: `router.parameterLabel.${parameter.input.value || 'UNDEFINED'}`,
    })}${parameterName.input.value ?? ''} ${intl.formatMessage({
      id: `router.relationLabel.${matchType.input.value || 'UNDEFINED'}`,
    })}
    `,
    required: true,
    autoCloseSelect: true,
    value: condition?.matchValue ?? '',
    errors: {
      requiredError: intl.formatMessage({ id: 'input.requiredError' }),
    },
    forceInlineRender: true,
  });

  const destination = useInput({
    name: 'destination',
    label: intl.formatMessage({ id: 'router.destinationLabel' }),
    required: true,
    autoCloseSelect: true,
    value: destinationId ?? '',
    errors: {
      requiredError: intl.formatMessage({ id: 'input.requiredError' }),
    },
    forceInlineRender: true,
  });

  const deploymentData = useLazyLoadQuery(
    graphql`
      query ConditionsEditorQuery($filterMatrix: [[FilterInput]]) {
        entities(first: 200, index: DEPLOYMENTS, filterMatrix: $filterMatrix) {
          edges {
            id
            cursor
            node {
              __typename
              ... on Deployment {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      filterMatrix: [
        [
          {
            type: 'term',
            value: target,
            property: 'environment.keyword',
          },
        ],
        [
          {
            property: 'deleted',
            type: 'term',
            valueBoolean: false,
          },
        ],
      ],
    },
    {
      fetchPolicy: 'store-and-network',
    },
  );

  const handleOnSave = useCallback(
    (e) => {
      e?.preventDefault();

      const {
        data: { destination: destinationData, ...conditionsData },
        errors,
      } = validateData([parameter, parameterName, matchType, matchValue, destination]);

      const [id, name] = destinationData.split('@');

      if (!errors) {
        onSave({
          conditions: [conditionsData],
          routes: [{ weight: 100, destination: { id, name } }],
        });

        onClose();
      }
    },
    [destination, matchType, matchValue, onSave, parameter, parameterName, onClose],
  );

  const showDestination = !!parameter.input.value && !!matchType.input.value;

  return (
    <div
      role="dialog"
      aria-modal="true"
      aria-labelledby="modalRuleTitle"
      className={stylex(styles.scroller)}
      aria-describedby="modalRuleDescription"
    >
      <Padding top={24} bottom={16} horizontal={16}>
        <Flexbox flexDirection="column" rowGap={24}>
          <Text
            type="s2b"
            ariaId="modalRuleTitle"
            id="router.defineRouterRule"
            color="--neutral-color-800"
          />
          <Text
            id="router.defineRouterRuleDescription"
            type={width <= 815 ? 'bs' : 'bd'}
            ariaId="modalRuleDescription"
          />
        </Flexbox>

        <form onSubmit={handleOnSave} autoComplete="nope">
          <input type="submit" style={{ display: 'none' }} />

          <Padding top={40}>
            <Text id="router.routeRule.conditions" type="s0b" color="--neutral-color-800" />
          </Padding>

          <Padding top={16}>
            <Divider />
          </Padding>

          <Margin top={16}>
            <Grid
              columnGap={16}
              rowGap={16}
              columns={width <= 815 ? '1fr' : '1fr 1fr'}
              className={stylex(styles.grid)}
            >
              <div>
                <Input input={parameter.input}>
                  <CascaderOption
                    value="URI"
                    label={
                      <Text type="s0m" color="--neutral-color-800" id="router.condition.Path" />
                    }
                  />
                  <CascaderOption
                    value="SCHEME"
                    label={
                      <Text type="s0m" color="--neutral-color-800" id="router.condition.Scheme" />
                    }
                  />
                  <CascaderOption
                    value="METHOD"
                    label={
                      <Text type="s0m" color="--neutral-color-800" id="router.condition.Method" />
                    }
                  />
                  <CascaderOption
                    value="AUTHORITY"
                    label={
                      <Text
                        type="s0m"
                        color="--neutral-color-800"
                        id="router.condition.Authority"
                      />
                    }
                  />
                  <CascaderOption
                    value="HEADERS"
                    label={
                      <Text type="s0m" color="--neutral-color-800" id="router.condition.Header" />
                    }
                  />
                  <CascaderOption
                    value="QUERY_PARAMS"
                    label={
                      <Text
                        type="s0m"
                        color="--neutral-color-800"
                        id="router.condition.QueryParameter"
                      />
                    }
                  />
                </Input>
              </div>
              <div>
                <Input input={parameterName.input} />
              </div>
              <div>
                <Input input={matchType.input}>
                  <CascaderOption
                    value="EXACT"
                    label={
                      <Text type="s0m" color="--neutral-color-800" id="router.condition.EXACT" />
                    }
                  />
                  <CascaderOption
                    value="PREFIX"
                    label={
                      <Text type="s0m" color="--neutral-color-800" id="router.condition.PREFIX" />
                    }
                  />
                  <CascaderOption
                    value="REGEX"
                    label={
                      <Text type="s0m" color="--neutral-color-800" id="router.condition.REGEX" />
                    }
                  />
                </Input>
              </div>
              <div />
            </Grid>
          </Margin>

          {showDestination ? (
            <>
              <Padding top={40}>
                <Text id="router.routeRule.destination" type="s0b" color="--neutral-color-800" />
              </Padding>

              <Padding top={16}>
                <Divider />
              </Padding>

              <Margin top={16}>
                <Grid columnGap={16} rowGap={16} columns="1fr" className={stylex(styles.grid)}>
                  <div>
                    <Input input={matchValue.input} />
                  </div>
                  <div>
                    <Input input={destination.input}>
                      {(deploymentData?.entities?.edges ?? []).map((el) => (
                        <CascaderOption
                          key={el?.node?.id}
                          value={el?.node?.id ?? ''}
                          label={
                            <Text type="s0m" color="--neutral-color-800">
                              {el?.node?.name ?? ''}
                            </Text>
                          }
                        />
                      ))}
                    </Input>
                  </div>
                </Grid>
              </Margin>
            </>
          ) : null}

          <Padding top={24}>
            <Flexbox
              rowGap={16}
              flexDirection={width <= 815 ? 'column' : 'row'}
              justifyContent="space-between"
            >
              <Button onClick={handleOnSave} intlId="save" disabled={!showDestination} />
              <Button buttonType="secondary" onClick={onClose} intlId="cancel" autoFocus />
            </Flexbox>
          </Padding>
        </form>
      </Padding>
    </div>
  );
}
