/**
 * @generated SignedSource<<342fe5d40e8f645619c8b823407af598>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type AppType = "CONTAINER" | "STATIC" | "%future added value";
export type ContainerBuilder = "DOCKER" | "BUILDPACKS" | "KANIKO" | "%future added value";
export type EventTypeTrigger = "BRANCH" | "TAG" | "%future added value";
export type StaticRuntime = "NODEJS" | "%future added value";
export type PatchAppInput = {|
  id: string,
  media?: ?string,
  useDockerfileTemplate?: ?boolean,
  dockerfile?: ?string,
  dockerfileContent?: ?string,
  continousIntegrationEnabled?: ?boolean,
  eventTypeTrigger?: ?EventTypeTrigger,
  eventNameTrigger?: ?string,
  env?: ?$ReadOnlyArray<EnvInput>,
  preInstallCommand?: ?string,
  buildCommand?: ?string,
  buildPath?: ?string,
  nodeVersion?: ?string,
  builder?: ?ContainerBuilder,
|};
export type EnvInput = {|
  name: string,
  value: string,
|};
export type CompilationSetupUpdateMutation$variables = {|
  input: PatchAppInput,
|};
export type CompilationSetupUpdateMutation$data = {|
  +updateApp: {|
    +id: string,
    +namespace: string,
    +appType: AppType,
    +dockerfileContent: ?string,
    +runtime: ?StaticRuntime,
    +preInstallCommand: ?string,
    +buildCommand: ?string,
    +buildPath: ?string,
    +nodeVersion: ?string,
  |},
|};
export type CompilationSetupUpdateMutation = {|
  variables: CompilationSetupUpdateMutation$variables,
  response: CompilationSetupUpdateMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "App",
    "kind": "LinkedField",
    "name": "updateApp",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "namespace",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "appType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dockerfileContent",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "runtime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "preInstallCommand",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "buildCommand",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "buildPath",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nodeVersion",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompilationSetupUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompilationSetupUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "75133222b4c12942188a76330486a048",
    "id": null,
    "metadata": {},
    "name": "CompilationSetupUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation CompilationSetupUpdateMutation(\n  $input: PatchAppInput!\n) {\n  updateApp(input: $input) {\n    id\n    namespace\n    appType\n    dockerfileContent\n    runtime\n    preInstallCommand\n    buildCommand\n    buildPath\n    nodeVersion\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "a1133282149ada4ed30d6f9eed185d79";

module.exports = ((node/*: any*/)/*: Mutation<
  CompilationSetupUpdateMutation$variables,
  CompilationSetupUpdateMutation$data,
>*/);
