/**
 * @generated SignedSource<<12168387b5f6758cee311ec3d3d4d999>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeploymentElementPauseMutation$variables = {|
  id: string,
|};
export type DeploymentElementPauseMutation$data = {|
  +restartDeployment: {|
    +id: string,
    +pause: ?boolean,
    +status: ?{|
      +replicas: number,
      +readyReplicas: number,
      +updatedReplicas: number,
      +availableReplicas: number,
      +unavailableReplicas: number,
      +observedGeneration: number,
    |},
    +pods: $ReadOnlyArray<{|
      +status: {|
        +phase: string,
      |},
    |}>,
  |},
|};
export type DeploymentElementPauseMutation = {|
  variables: DeploymentElementPauseMutation$variables,
  response: DeploymentElementPauseMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Deployment",
    "kind": "LinkedField",
    "name": "restartDeployment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pause",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DeploymentStatus",
        "kind": "LinkedField",
        "name": "status",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "replicas",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "readyReplicas",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedReplicas",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "availableReplicas",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unavailableReplicas",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "observedGeneration",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Pod",
        "kind": "LinkedField",
        "name": "pods",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PodStatus",
            "kind": "LinkedField",
            "name": "status",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phase",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeploymentElementPauseMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeploymentElementPauseMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9afcfe8f6e97cd2ae2b21f998a652120",
    "id": null,
    "metadata": {},
    "name": "DeploymentElementPauseMutation",
    "operationKind": "mutation",
    "text": "mutation DeploymentElementPauseMutation(\n  $id: ID!\n) {\n  restartDeployment(id: $id) {\n    id\n    pause\n    status {\n      replicas\n      readyReplicas\n      updatedReplicas\n      availableReplicas\n      unavailableReplicas\n      observedGeneration\n    }\n    pods {\n      status {\n        phase\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "f8008eabd0769ed63af63de773831d19";

module.exports = ((node/*: any*/)/*: Mutation<
  DeploymentElementPauseMutation$variables,
  DeploymentElementPauseMutation$data,
>*/);
