/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
// @flow
import stylex from '@serpa-cloud/stylex';
import { Flexbox, Padding, Text } from '../../../shared';

const styles = stylex.create({
  li: {
    marginBottom: 8,
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: 12,
  },
  tableColumn: {
    width: '33.33%',
  },
  columnCenter: {
    textAlign: 'center',
  },
  columnJustify: {
    textAlign: 'justify',
  },
});

export default function PrivacyNotice(): React$Node {
  return (
    <div>
      <Padding horizontal={32} top={80} bottom={32}>
        <Flexbox rowGap={24} flexDirection="column">
          <Text type="h2">Privacy Policy</Text>
          <Text type="s3b" color="--neutral-color-800">
            Last updated April 11, 2023
          </Text>
          <Text type="s2r" color="--neutral-color-800">
            This privacy notice for Serpa Cloud, Inc (<strong>"Company,"</strong>
            <strong>"we,"</strong> <strong>"us,"</strong> or
            <strong>"our"</strong>), describes how and why we might collect, store, use, and/or
            share (<strong>"process"</strong>) your information when you use our services (
            <strong>"Services"</strong>), such as when you:
          </Text>

          <ul>
            <li>
              Visit our website at beta.serpa.cloud, or any website of ours that links to this
              privacy notice
            </li>
            <li>Engage with us in other related ways, including any sales, marketing, or events</li>
          </ul>

          <Text type="s2r" color="--neutral-color-800">
            <strong>Questions or concerns?</strong> Reading this privacy notice will help you
            understand your privacy rights and choices. If you do not agree with our policies and
            practices, please do not use our Services. If you still have any questions or concerns,
            please contact us at Serpa Cloud, Inc.
          </Text>

          <Text type="s3b" color="--neutral-color-800">
            SUMMARY OF KEY POINTS
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <strong>
              <em>
                This summary provides key points from our privacy notice, but you can find out more
                details about any of these topics by clicking the link following each key point or
                by using our
                <a
                  href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#toc"
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}
                  table of contents{' '}
                </a>
                below to find the section you are looking for.
              </em>
            </strong>
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <strong>What personal information do we process?</strong> When you visit, use, or
            navigate our Services, we may process personal information depending on how you interact
            with Serpa Cloud, Inc and the Services, the choices you make, and the products and
            features you use. Learn more about{' '}
            <a
              href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#personalinfo"
              target="_blank"
              rel="noreferrer"
            >
              personal information you disclose to us.
            </a>
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <strong>Do we process any sensitive personal information?</strong> We may process
            sensitive personal information when necessary with your consent or as otherwise
            permitted by applicable law. Learn more about{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#sensitiveinfo"
            >
              sensitive information we process.
            </a>
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <strong>Do we receive any information from third parties?</strong> We do not receive any
            information from third parties.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <strong>How do we process your information?</strong> We process your information to
            provide, improve, and administer our Services, communicate with you, for security and
            fraud prevention, and to comply with law. We may also process your information for other
            purposes with your consent. We process your information only when we have a valid legal
            reason to do so. Learn more about{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#infouse"
            >
              how we process your information.
            </a>
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <strong>
              In what situations and with which parties do we share personal information?
            </strong>{' '}
            We may share information in specific situations and with specific third parties. Learn
            more about
            <a
              target="_blank"
              rel="noreferrer"
              href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#whoshare"
            >
              when and with whom we share your personal information.
            </a>
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <strong>How do we keep your information safe?</strong> We have organizational and
            technical processes and procedures in place to protect your personal information.
            However, no electronic transmission over the internet or information storage technology
            can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
            cybercriminals, or other unauthorized third parties will not be able to defeat our
            security and improperly collect, access, steal, or modify your information. Learn more
            about{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#infosafe"
            >
              how we keep your information safe.
            </a>
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <strong>What are your rights?</strong> Depending on where you are located
            geographically, the applicable privacy law may mean you have certain rights regarding
            your personal information. Learn more about{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#privacyrights"
            >
              your privacy rights.
            </a>
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <strong>How do you exercise your rights?</strong> The easiest way to exercise your
            rights is by submitting a{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://app.termly.io/notify/c796e57c-7ab8-40e3-8dc1-c14559537a5b"
            >
              data subject access request
            </a>
            , or by contacting us. We will consider and act upon any request in accordance with
            applicable data protection laws.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            Want to learn more about what Serpa Cloud, Inc does with any information we collect?{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#toc"
            >
              Review the privacy notice in full.
            </a>
          </Text>

          <Text type="s3b" color="--neutral-color-800">
            TABLE OF CONTENTS
          </Text>

          <ol>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#infocollect"
                >
                  WHAT INFORMATION DO WE COLLECT?
                </a>
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#infouse"
                >
                  HOW DO WE PROCESS YOUR INFORMATION?
                </a>
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#legalbases"
                >
                  WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?
                </a>
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#whoshare"
                >
                  WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </a>
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#3pwebsites"
                >
                  WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
                </a>
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#sociallogins"
                >
                  HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                </a>
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#intltransfers"
                >
                  IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
                </a>
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#inforetain"
                >
                  HOW LONG DO WE KEEP YOUR INFORMATION?
                </a>
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#infosafe"
                >
                  HOW DO WE KEEP YOUR INFORMATION SAFE?
                </a>
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#infominors"
                >
                  DO WE COLLECT INFORMATION FROM MINORS?
                </a>
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#privacyrights"
                >
                  WHAT ARE YOUR PRIVACY RIGHTS?
                </a>
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#DNT"
                >
                  CONTROLS FOR DO-NOT-TRACK FEATURES
                </a>
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#caresidents"
                >
                  DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </a>
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#virginia"
                >
                  DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </a>
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#policyupdates"
                >
                  DO WE MAKE UPDATES TO THIS NOTICE?
                </a>
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#contact"
                >
                  HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </a>
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#request"
                >
                  HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                </a>
              </Text>
            </li>
          </ol>

          <Text type="s3b" color="--neutral-color-800">
            1. WHAT INFORMATION DO WE COLLECT?
          </Text>

          <Text type="s3b" color="--neutral-color-800">
            Personal information you disclose to us
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <em>
              <strong>In Short:</strong> We collect personal information that you provide to us.
            </em>
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            We collect personal information that you voluntarily provide to us when you register on
            the Services, express an interest in obtaining information about us or our products and
            Services, when you participate in activities on the Services, or otherwise when you
            contact us.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <strong>Personal Information Provided by You.</strong> The personal information that we
            collect depends on the context of your interactions with us and the Services, the
            choices you make, and the products and features you use. The personal information we
            collect may include the following:
          </Text>

          <ul>
            <li>names</li>
            <li>phone numbers</li>
            <li>email addresses</li>
            <li>mailing addresses</li>
            <li>job titles</li>
            <li>usernames</li>
            <li>passwords</li>
            <li>contact preferences</li>
            <li>contact or authentication data</li>
            <li>billing addresses</li>
            <li>debit/credit card numbers</li>
          </ul>

          <Text type="s2r" color="--neutral-color-800">
            <strong>Sensitive Information.</strong> When necessary, with your consent or as
            otherwise permitted by applicable law, we process the following categories of sensitive
            information:
          </Text>

          <ul>
            <li>gender</li>
          </ul>

          <Text type="s2r" color="--neutral-color-800">
            Data. We may collect data necessary to process your payment if you make purchases, such
            as your payment instrument number, and the security code associated with your payment
            instrument. All payment data is stored by Stripe. You may find their privacy notice
            link(s) here:{' '}
            <a target="_blank" rel="noreferrer" href="https://stripe.com/es-us/privacy">
              https://stripe.com/es-us/privacy
            </a>
            .
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <strong>Social Media Login Data.</strong> We may provide you with the option to register
            with us using your existing social media account details, like your Facebook, Twitter,
            or other social media account. If you choose to register in this way, we will collect
            the information described in the section called "
            <a
              target="_blank"
              rel="noreferrer"
              href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#sociallogins"
            >
              HOW DO WE HANDLE YOUR SOCIAL LOGINS?
            </a>
            " below.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            All personal information that you provide to us must be true, complete, and accurate,
            and you must notify us of any changes to such personal information.
          </Text>

          <Text type="s3b" color="--neutral-color-800">
            Information automatically collected
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <em>
              <strong>In Short:</strong> Some information — such as your Internet Protocol (IP)
              address and/or browser and device characteristics — is collected automatically when
              you visit our Services.
            </em>
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            We automatically collect certain information when you visit, use, or navigate the
            Services. This information does not reveal your specific identity (like your name or
            contact information) but may include device and usage information, such as your IP
            address, browser and device characteristics, operating system, language preferences,
            referring URLs, device name, country, location, information about how and when you use
            our Services, and other technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our internal analytics and
            reporting purposes.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            The information we collect includes:
          </Text>

          <ul>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <em>Log and Usage Data.</em> Log and usage data is service-related, diagnostic,
                usage, and performance information our servers automatically collect when you access
                or use our Services and which we record in log files. Depending on how you interact
                with us, this log data may include your IP address, device information, browser
                type, and settings and information about your activity in the Services (such as the
                date/time stamps associated with your usage, pages and files viewed, searches, and
                other actions you take such as which features you use), device event information
                (such as system activity, error reports (sometimes called "crash dumps"), and
                hardware settings).
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <em>Device Data.</em> We collect device data such as information about your
                computer, phone, tablet, or other device you use to access the Services. Depending
                on the device used, this device data may include information such as your IP address
                (or proxy server), device and application identification numbers, location, browser
                type, hardware model, Internet service provider and/or mobile carrier, operating
                system, and system configuration information.
              </Text>
            </li>
            <li>
              <em>Location Data.</em> We collect location data such as information about your
              device's location, which can be either precise or imprecise. How much information we
              collect depends on the type and settings of the device you use to access the Services.
              For example, we may use GPS and other technologies to collect geolocation data that
              tells us your current location (based on your IP address). You can opt out of allowing
              us to collect this information either by refusing access to the information or by
              disabling your Location setting on your device. However, if you choose to opt out, you
              may not be able to use certain aspects of the Services.
            </li>
          </ul>

          <Text type="s3b" color="--neutral-color-800">
            2. HOW DO WE PROCESS YOUR INFORMATION?
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <em>
              <strong>In Short:</strong> We process your information to provide, improve, and
              administer our Services, communicate with you, for security and fraud prevention, and
              to comply with law. We may also process your information for other purposes with your
              consent.
            </em>
          </Text>

          <Text type="s2b" color="--neutral-color-800">
            We process your personal information for a variety of reasons, depending on how you
            interact with our Services, including:
          </Text>

          <ul>
            <li className={stylex(styles.li)}>
              <strong>
                To facilitate account creation and authentication and otherwise manage user
                accounts.
              </strong>
              We may process your information so you can create and log in to your account, as well
              as keep your account in working order.
            </li>
            <li className={stylex(styles.li)}>
              <strong>To deliver and facilitate delivery of services to the user.</strong>
              We may process your information to provide you with the requested service.
            </li>
            <li className={stylex(styles.li)}>
              <strong>To send administrative information to you.</strong>
              We may process your information to send you details about our products and services,
              changes to our terms and policies, and other similar information.
            </li>
            <li className={stylex(styles.li)}>
              <strong>To enable user-to-user communications.</strong>
              We may process your information if you choose to use any of our offerings that allow
              for communication with another user.
            </li>
            <li className={stylex(styles.li)}>
              <strong>To save or protect an individual's vital interest.</strong>
              We may process your information when necessary to save or protect an individual's
              vital interest, such as to prevent harm.
            </li>
          </ul>

          <Text type="s3b" color="--neutral-color-800">
            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <em>
              <strong>In Short:</strong> We only process your personal information when we believe
              it is necessary and we have a valid legal reason (i.e., legal basis) to do so under
              applicable law, like with your consent, to comply with laws, to provide you with
              services to enter into or fulfill our contractual obligations, to protect your rights,
              or to fulfill our legitimate business interests.
            </em>
          </Text>

          <Text type="s2b" color="--neutral-color-800">
            If you are located in the EU or UK, this section applies to you.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the
            valid legal bases we rely on in order to process your personal information. As such, we
            may rely on the following legal bases to process your personal information:
          </Text>

          <ul>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <strong>Consent.</strong> We may process your information if you have given us
                permission (i.e., consent) to use your personal information for a specific purpose.
                You can withdraw your consent at any time. Learn more about{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#withdrawconsent"
                >
                  withdrawing your consent.
                </a>
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <strong>Performance of a Contract.</strong> We may process your personal information
                when we believe it is necessary to fulfill our contractual obligations to you,
                including providing our Services or at your request prior to entering into a
                contract with you.
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <strong>Legal Obligations.</strong> We may process your information where we believe
                it is necessary for compliance with our legal obligations, such as to cooperate with
                a law enforcement body or regulatory agency, exercise or defend our legal rights, or
                disclose your information as evidence in litigation in which we are involved.
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <strong>Vital Interests.</strong> We may process your information where we believe
                it is necessary to protect your vital interests or the vital interests of a third
                party, such as situations involving potential threats to the safety of any person.
              </Text>
            </li>
          </ul>

          <Text type="s2b" color="--neutral-color-800">
            If you are located in Canada, this section applies to you.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            We may process your information if you have given us specific permission (i.e., express
            consent) to use your personal information for a specific purpose, or in situations where
            your permission can be inferred (i.e., implied consent). You can{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#withdrawconsent"
            >
              withdraw your consent
            </a>
            at any time.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            In some exceptional cases, we may be legally permitted under applicable law to process
            your information without your consent, including, for example:
          </Text>

          <ul>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                If collection is clearly in the interests of an individual and consent cannot be
                obtained in a timely way
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                For investigations and fraud detection and prevention
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                For business transactions provided certain conditions are met
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                If it is contained in a witness statement and the collection is necessary to assess,
                process, or settle an insurance claim
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                For identifying injured, ill, or deceased persons and communicating with next of kin
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                If we have reasonable grounds to believe an individual has been, is, or may be
                victim of financial abuse
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                If it is reasonable to expect collection and use with consent would compromise the
                availability or the accuracy of the information and the collection is reasonable for
                purposes related to investigating a breach of an agreement or a contravention of the
                laws of Canada or a province
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                If disclosure is required to comply with a subpoena, warrant, court order, or rules
                of the court relating to the production of records
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                If it was produced by an individual in the course of their employment, business, or
                profession and the collection is consistent with the purposes for which the
                information was produced
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                If the collection is solely for journalistic, artistic, or literary purposes
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                If the information is publicly available and is specified by the regulations
              </Text>
            </li>
          </ul>

          <Text type="s3b" color="--neutral-color-800">
            4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <em>
              <strong>In Short:</strong> We may share information in specific situations described
              in this section and/or with the following third parties.
            </em>
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            We may need to share your personal information in the following situations:
          </Text>

          <ul>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <strong>Business Transfers.</strong> We may share or transfer your information in
                connection with, or during negotiations of, any merger, sale of company assets,
                financing, or acquisition of all or a portion of our business to another company.
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <strong>Affiliates.</strong> We may share your information with our affiliates, in
                which case we will require those affiliates to honor this privacy notice. Affiliates
                include our parent company and any subsidiaries, joint venture partners, or other
                companies that we control or that are under common control with us.
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <strong>Business Partners.</strong> We may share your information with our business
                partners to offer you certain products, services, or promotions.
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <strong>Other Users.</strong> When you share personal information (for example, by
                posting comments, contributions, or other content to the Services) or otherwise
                interact with public areas of the Services, such personal information may be viewed
                by all users and may be publicly made available outside the Services in perpetuity.
                If you interact with other users of our Services and register for our Services
                through a social network (such as Facebook), your contacts on the social network
                will see your name, profile photo, and descriptions of your activity. Similarly,
                other users will be able to view descriptions of your activity, communicate with you
                within our Services, and view your profile.
              </Text>
            </li>
          </ul>

          <Text type="s3b" color="--neutral-color-800">
            5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <em>
              <strong>In Short:</strong> We are not responsible for the safety of any information
              that you share with third parties that we may link to or who advertise on our
              Services, but are not affiliated with, our Services.
            </em>
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            The Services may link to third-party websites, online services, or mobile applications
            and/or contain advertisements from third parties that are not affiliated with us and
            which may link to other websites, services, or applications. Accordingly, we do not make
            any guarantee regarding any such third parties, and we will not be liable for any loss
            or damage caused by the use of such third-party websites, services, or applications. The
            inclusion of a link towards a third-party website, service, or application does not
            imply an endorsement by us. We cannot guarantee the safety and privacy of data you
            provide to any third parties. Any data collected by third parties is not covered by this
            privacy notice. We are not responsible for the content or privacy and security practices
            and policies of any third parties, including other websites, services, or applications
            that may be linked to or from the Services. You should review the policies of such third
            parties and contact them directly to respond to your questions.
          </Text>

          <Text type="s3b" color="--neutral-color-800">
            6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <em>
              <strong>In Short:</strong> If you choose to register or log in to our Services using a
              social media account, we may have access to certain information about you.
            </em>
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            Our Services offer you the ability to register and log in using your third-party social
            media account details (like your Facebook or Twitter logins). Where you choose to do
            this, we will receive certain profile information about you from your social media
            provider. The profile information we receive may vary depending on the social media
            provider concerned, but will often include your name, email address, friends list, and
            profile picture, as well as other information you choose to make public on such a social
            media platform.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            We will use the information we receive only for the purposes that are described in this
            privacy notice or that are otherwise made clear to you on the relevant Services. Please
            note that we do not control, and are not responsible for, other uses of your personal
            information by your third-party social media provider. We recommend that you review
            their privacy notice to understand how they collect, use, and share your personal
            information, and how you can set your privacy preferences on their sites and apps.
          </Text>

          <Text type="s3b" color="--neutral-color-800">
            7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <em>
              <strong>In Short:</strong> We may transfer, store, and process your information in
              countries other than your own.
            </em>
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            Our servers are located in the United States. If you are accessing our Services from
            outside the United States, please be aware that your information may be transferred to,
            stored, and processed by us in our facilities and by those third parties with whom we
            may share your personal information (see "
            <a
              target="_blank"
              rel="noreferrer"
              href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#whoshare"
            >
              WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </a>
            " above), in the United States, Mexico, and other countries.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            If you are a resident in the European Economic Area (EEA) or United Kingdom (UK), then
            these countries may not necessarily have data protection laws or other similar laws as
            comprehensive as those in your country. However, we will take all necessary measures to
            protect your personal information in accordance with this privacy notice and applicable
            law.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <em>
              <strong>In Short:</strong> We keep your information for as long as necessary to
              fulfill the purposes outlined in this privacy notice unless otherwise required by law.
            </em>
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            We will only keep your personal information for as long as it is necessary for the
            purposes set out in this privacy notice, unless a longer retention period is required or
            permitted by law (such as tax, accounting, or other legal requirements). No purpose in
            this notice will require us keeping your personal information for longer than twelve
            (12) months past the termination of the user's account.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            When we have no ongoing legitimate business need to process your personal information,
            we will either delete or anonymize such information, or, if this is not possible (for
            example, because your personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from any further processing
            until deletion is possible.
          </Text>

          <Text type="s3b" color="--neutral-color-800">
            9. HOW DO WE KEEP YOUR INFORMATION SAFE?
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <em>
              <strong>In Short:</strong> We aim to protect your personal information through a
              system of organizational and technical security measures.
            </em>
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            We have implemented appropriate and reasonable technical and organizational security
            measures designed to protect the security of any personal information we process.
            However, despite our safeguards and efforts to secure your information, no electronic
            transmission over the Internet or information storage technology can be guaranteed to be
            100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other
            unauthorized third parties will not be able to defeat our security and improperly
            collect, access, steal, or modify your information. Although we will do our best to
            protect your personal information, transmission of personal information to and from our
            Services is at your own risk. You should only access the Services within a secure
            environment.
          </Text>

          <Text type="s3b" color="--neutral-color-800">
            10. DO WE COLLECT INFORMATION FROM MINORS?
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <em>
              <strong>In Short:</strong> We do not knowingly collect data from or market to children
              under 18 years of age.
            </em>
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            We do not knowingly solicit data from or market to children under 18 years of age. By
            using the Services, you represent that you are at least 18 or that you are the parent or
            guardian of such a minor and consent to such minor dependent’s use of the Services. If
            we learn that personal information from users less than 18 years of age has been
            collected, we will deactivate the account and take reasonable measures to promptly
            delete such data from our records. If you become aware of any data we may have collected
            from children under age 18, please contact us at emilia@serpa.cloud.
          </Text>

          <Text type="s3b" color="--neutral-color-800">
            11. WHAT ARE YOUR PRIVACY RIGHTS?
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <em>
              <strong>In Short:</strong> In some regions, such as the European Economic Area (EEA),
              United Kingdom (UK), and Canada, you have rights that allow you greater access to and
              control over your personal information. You may review, change, or terminate your
              account at any time.
            </em>
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            In some regions (like the EEA, UK, and Canada), you have certain rights under applicable
            data protection laws. These may include the right (i) to request access and obtain a
            copy of your personal information, (ii) to request rectification or erasure; (iii) to
            restrict the processing of your personal information; and (iv) if applicable, to data
            portability. In certain circumstances, you may also have the right to object to the
            processing of your personal information. You can make such a request by contacting us by
            using the contact details provided in the section "
            <a
              target="_blanck"
              rel="noreferrer"
              href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#contact"
            >
              HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </a>
            " below.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            We will consider and act upon any request in accordance with applicable data protection
            laws.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            If you are located in the EEA or UK and you believe we are unlawfully processing your
            personal information, you also have the right to complain to your{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
            >
              Member State data protection authority
            </a>
            or{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
            >
              UK data protection authority.
            </a>
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            If you are located in Switzerland, you may contact the{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.edoeb.admin.ch/edoeb/en/home.html"
            >
              Federal Data Protection and Information Commissioner
            </a>
            .
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <strong>Withdrawing your consent:</strong> If we are relying on your consent to process
            your personal information, which may be express and/or implied consent depending on the
            applicable law, you have the right to withdraw your consent at any time. You can
            withdraw your consent at any time by contacting us by using the contact details provided
            in the section "
            <a
              target="_blank"
              rel="noreferrer"
              href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#contact"
            >
              HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </a>
            " below or updating your preferences.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            However, please note that this will not affect the lawfulness of the processing before
            its withdrawal nor, when applicable law allows, will it affect the processing of your
            personal information conducted in reliance on lawful processing grounds other than
            consent.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <strong>Opting out of marketing and promotional communications:</strong> You can
            unsubscribe from our marketing and promotional communications at any time by clicking on
            the unsubscribe link in the emails that we send, or by contacting us using the details
            provided in the section "
            <a
              target="_blank"
              rel="noreferrer"
              href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#contact"
            >
              HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </a>
            " below. You will then be removed from the marketing lists. However, we may still
            communicate with you — for example, to send you service-related messages that are
            necessary for the administration and use of your account, to respond to service
            requests, or for other non-marketing purposes.
          </Text>

          <Text type="s2b" color="--neutral-color-800">
            Account Information
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            If you would at any time like to review or change the information in your account or
            terminate your account, you can:
          </Text>

          <ul>
            <li>
              <Text type="s2r" color="--neutral-color-800">
                Contact us using the contact information provided.
              </Text>
            </li>
          </ul>

          <Text type="s2r" color="--neutral-color-800">
            Upon your request to terminate your account, we will deactivate or delete your account
            and information from our active databases. However, we may retain some information in
            our files to prevent fraud, troubleshoot problems, assist with any investigations,
            enforce our legal terms and/or comply with applicable legal requirements.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            If you have questions or comments about your privacy rights, you may email us at
            emilia@serpa.cloud.
          </Text>

          <Text type="s3b" color="--neutral-color-800">
            12. CONTROLS FOR DO-NOT-TRACK FEATURES
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            Most web browsers and some mobile operating systems and mobile applications include a
            Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy
            preference not to have data about your online browsing activities monitored and
            collected. At this stage no uniform technology standard for recognizing and implementing
            DNT signals has been finalized. As such, we do not currently respond to DNT browser
            signals or any other mechanism that automatically communicates your choice not to be
            tracked online. If a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version of this privacy
            notice.
          </Text>

          <Text type="s3b" color="--neutral-color-800">
            13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <em>
              <strong>In Short:</strong> Yes, if you are a resident of California, you are granted
              specific rights regarding access to your personal information.
            </em>
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits
            our users who are California residents to request and obtain from us, once a year and
            free of charge, information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the names and addresses of
            all third parties with which we shared personal information in the immediately preceding
            calendar year. If you are a California resident and would like to make such a request,
            please submit your request in writing to us using the contact information provided
            below.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            If you are under 18 years of age, reside in California, and have a registered account
            with Services, you have the right to request removal of unwanted data that you publicly
            post on the Services. To request removal of such data, please contact us using the
            contact information provided below and include the email address associated with your
            account and a statement that you reside in California. We will make sure the data is not
            publicly displayed on the Services, but please be aware that the data may not be
            completely or comprehensively removed from all our systems (e.g., backups, etc.).
          </Text>

          <Text type="s2b" color="--neutral-color-800">
            CCPA Privacy Notice
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            The California Code of Regulations defines a "resident" as:
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            (1) every individual who is in the State of California for other than a temporary or
            transitory purpose and
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            (2) every individual who is domiciled in the State of California who is outside the
            State of California for a temporary or transitory purpose
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            All other individuals are defined as "non-residents."
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            If this definition of "resident" applies to you, we must adhere to certain rights and
            obligations regarding your personal information.
          </Text>

          <Text type="s2b" color="--neutral-color-800">
            What categories of personal information do we collect?
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            We have collected the following categories of personal information in the past twelve
            (12) months:
          </Text>

          <table className={stylex(styles.table)}>
            <tr>
              <th className={stylex(styles.tableColumn, styles.columnCenter)}>Category</th>
              <th className={stylex(styles.tableColumn, styles.columnCenter)}>Examples</th>
              <th className={stylex(styles.tableColumn, styles.columnCenter)}>Collected</th>
            </tr>
            <tr>
              <td className={stylex(styles.tableColumn)}>A. Identifiers</td>
              <td className={stylex(styles.tableColumn)}>
                Contact details, such as real name, alias, postal address, telephone or mobile
                contact number, unique personal identifier, online identifier, Internet Protocol
                address, email address, and account name
              </td>
              <td className={stylex(styles.tableColumn, styles.columnCenter)}>YES</td>
            </tr>
            <tr>
              <td className={stylex(styles.tableColumn)}>
                B. Personal information categories listed in the California Customer Records statute
              </td>
              <td className={stylex(styles.tableColumn)}>
                Name, contact information, education, employment, employment history, and financial
                information
              </td>
              <td className={stylex(styles.tableColumn, styles.columnCenter)}>YES</td>
            </tr>
            <tr>
              <td className={stylex(styles.tableColumn)}>
                C. Protected classification characteristics under California or federal law
              </td>
              <td className={stylex(styles.tableColumn)}>Gender and date of birth</td>
              <td className={stylex(styles.tableColumn, styles.columnCenter)}>YES</td>
            </tr>
            <tr>
              <td className={stylex(styles.tableColumn)}>D. Commercial information</td>
              <td className={stylex(styles.tableColumn)}>
                Transaction information, purchase history, financial details, and payment
                information
              </td>
              <td className={stylex(styles.tableColumn, styles.columnCenter)}>NO</td>
            </tr>
            <tr>
              <td className={stylex(styles.tableColumn)}>E. Biometric information</td>
              <td className={stylex(styles.tableColumn)}>Fingerprints and voiceprints</td>
              <td className={stylex(styles.tableColumn, styles.columnCenter)}>NO</td>
            </tr>
            <tr>
              <td className={stylex(styles.tableColumn)}>
                F. Internet or other similar network activity
              </td>
              <td className={stylex(styles.tableColumn)}>
                Browsing history, search history, online behavior, interest data, and interactions
                with our and other websites, applications, systems, and advertisements
              </td>
              <td className={stylex(styles.tableColumn, styles.columnCenter)}>YES</td>
            </tr>
            <tr>
              <td className={stylex(styles.tableColumn)}>G. Geolocation data</td>
              <td className={stylex(styles.tableColumn)}>Device location</td>
              <td className={stylex(styles.tableColumn, styles.columnCenter)}>NO</td>
            </tr>
            <tr>
              <td className={stylex(styles.tableColumn)}>
                H. Audio, electronic, visual, thermal, olfactory, or similar information
              </td>
              <td className={stylex(styles.tableColumn)}>
                Images and audio, video or call recordings created in connection with our business
                activities
              </td>
              <td className={stylex(styles.tableColumn, styles.columnCenter)}>NO</td>
            </tr>
            <tr>
              <td className={stylex(styles.tableColumn)}>
                I. Professional or employment-related information
              </td>
              <td className={stylex(styles.tableColumn)}>
                Business contact details in order to provide you our Services at a business level or
                job title, work history, and professional qualifications if you apply for a job with
                us
              </td>
              <td className={stylex(styles.tableColumn, styles.columnCenter)}>NO</td>
            </tr>
            <tr>
              <td className={stylex(styles.tableColumn)}>J. Education Information</td>
              <td className={stylex(styles.tableColumn)}>
                Student records and directory information
              </td>
              <td className={stylex(styles.tableColumn, styles.columnCenter)}>NO</td>
            </tr>
            <tr>
              <td className={stylex(styles.tableColumn)}>
                K. Inferences drawn from other personal information
              </td>
              <td className={stylex(styles.tableColumn)}>
                Inferences drawn from any of the collected personal information listed above to
                create a profile or summary about, for example, an individual’s preferences and
                characteristics
              </td>
              <td className={stylex(styles.tableColumn, styles.columnCenter)}>NO</td>
            </tr>
            <tr>
              <td className={stylex(styles.tableColumn)}>L. Sensitive Personal Information</td>
              <td className={stylex(styles.tableColumn)}>
                Account login information and debit or credit card numbers
              </td>
              <td className={stylex(styles.tableColumn, styles.columnCenter)}>YES</td>
            </tr>
          </table>

          <Text type="s2r" color="--neutral-color-800">
            We will use and retain the collected personal information as needed to provide the
            Services or for:
          </Text>

          <ul>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                Category A - As long as the user has an account with us
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                Category B - As long as the user has an account with us
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                Category C - As long as the user has an account with us
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                Category F - As long as the user has an account with us
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                Category L - As long as the user has an account with us
              </Text>
            </li>
          </ul>

          <Text type="s2r" color="--neutral-color-800">
            Category L information may be used, or disclosed to a service provider or contractor,
            for additional, specified purposes. You have the right to limit the use or disclosure of
            your sensitive personal information.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            We may also collect other personal information outside of these categories through
            instances where you interact with us in person, online, or by phone or mail in the
            context of:
          </Text>

          <ul>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                Receiving help through our customer support channels;
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                Participation in customer surveys or contests; and
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                Facilitation in the delivery of our Services and to respond to your inquiries.
              </Text>
            </li>
          </ul>

          <Text type="s2b" color="--neutral-color-800">
            How do we use and share your personal information?
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            More information about our data collection and sharing practices can be found in this
            privacy notice.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            You may contact us by email at emilia@serpa.cloud, or by referring to the contact
            details at the bottom of this document.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            If you are using an authorized agent to exercise your right to opt out we may deny a
            request if the authorized agent does not submit proof that they have been validly
            authorized to act on your behalf.
          </Text>

          <Text type="s2b" color="--neutral-color-800">
            Will your information be shared with anyone else?
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            We may disclose your personal information with our service providers pursuant to a
            written contract between us and each service provider. Each service provider is a
            for-profit entity that processes the information on our behalf, following the same
            strict privacy protection obligations mandated by the CCPA.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            We may use your personal information for our own business purposes, such as for
            undertaking internal research for technological development and demonstration. This is
            not considered to be "selling" of your personal information.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            Serpa Cloud, Inc has not disclosed, sold, or shared any personal information to third
            parties for a business or commercial purpose in the preceding twelve (12) months. Serpa
            Cloud, Inc will not sell or share personal information in the future belonging to
            website visitors, users, and other consumers.
          </Text>

          <Text type="s2b" color="--neutral-color-800">
            Your rights with respect to your personal data
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            Right to request deletion of the data — Request to delete
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            You can ask for the deletion of your personal information. If you ask us to delete your
            personal information, we will respect your request and delete your personal information,
            subject to certain exceptions provided by law, such as (but not limited to) the exercise
            by another consumer of his or her right to free speech, our compliance requirements
            resulting from a legal obligation, or any processing that may be required to protect
            against illegal activities.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            Right to be informed — Request to know
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            Depending on the circumstances, you have a right to know:
          </Text>

          <ul>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                whether we collect and use your personal information;
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                the categories of personal information that we collect;
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                the purposes for which the collected personal information is used;
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                whether we sell or share personal information to third parties;
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                the categories of personal information that we sold, shared, or disclosed for a
                business purpose;
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                the categories of third parties to whom the personal information was sold, shared,
                or disclosed for a business purpose;
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                the business or commercial purpose for collecting, selling, or sharing personal
                information; and
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                the specific pieces of personal information we collected about you.
              </Text>
            </li>
          </ul>

          <Text type="s2r" color="--neutral-color-800">
            In accordance with applicable law, we are not obligated to provide or delete consumer
            information that is de-identified in response to a consumer request or to re-identify
            individual data to verify a consumer request.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            We will not discriminate against you if you exercise your privacy rights.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            Right to Limit Use and Disclosure of Sensitive Personal Information
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            If the business collects any of the following:
          </Text>

          <ul>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                social security information, drivers' licenses, state ID cards, passport numbers
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                account login information
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                credit card numbers, financial account information, or credentials allowing access
                to such accounts
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                precise geolocation
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                racial or ethnic origin, religious or philosophical beliefs, union membership
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                the contents of email and text, unless the business is the intended recipient of the
                communication
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                genetic data, biometric data, and health data
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                data concerning sexual orientation and sex life
              </Text>
            </li>
          </ul>

          <Text type="s2r" color="--neutral-color-800">
            you have the right to direct that business to limit its use of your sensitive personal
            information to that use which is necessary to perform the Services.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            Once a business receives your request, they are no longer allowed to use or disclose
            your sensitive personal information for any other purpose unless you provide consent for
            the use or disclosure of sensitive personal information for additional purposes.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            Please note that sensitive personal information that is collected or processed without
            the purpose of inferring characteristics about a consumer is not covered by this right,
            as well as the publicly available information.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            To exercise your right to limit use and disclosure of sensitive personal information,
            please email emilia@serpa.cloud or submit a{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://app.termly.io/notify/c796e57c-7ab8-40e3-8dc1-c14559537a5b"
            >
              data subject access request.
            </a>
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            Verification process
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            Upon receiving your request, we will need to verify your identity to determine you are
            the same person about whom we have the information in our system. These verification
            efforts require us to ask you to provide information so that we can match it with
            information you have previously provided us. For instance, depending on the type of
            request you submit, we may ask you to provide certain information so that we can match
            the information you provide with the information we already have on file, or we may
            contact you through a communication method (e.g., phone or email) that you have
            previously provided to us. We may also use other verification methods as the
            circumstances dictate.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            We will only use personal information provided in your request to verify your identity
            or authority to make the request. To the extent possible, we will avoid requesting
            additional information from you for the purposes of verification. However, if we cannot
            verify your identity from the information already maintained by us, we may request that
            you provide additional information for the purposes of verifying your identity and for
            security or fraud-prevention purposes. We will delete such additionally provided
            information as soon as we finish verifying you.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            Other privacy rights
          </Text>

          <ul>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                You may object to the processing of your personal information.
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                You may request correction of your personal data if it is incorrect or no longer
                relevant, or ask to restrict the processing of the information.
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                You can designate an authorized agent to make a request under the CCPA on your
                behalf. We may deny a request from an authorized agent that does not submit proof
                that they have been validly authorized to act on your behalf in accordance with the
                CCPA.
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                You may request to opt out from future selling or sharing of your personal
                information to third parties. Upon receiving an opt-out request, we will act upon
                the request as soon as feasibly possible, but no later than fifteen (15) days from
                the date of the request submission.
              </Text>
            </li>
          </ul>

          <Text type="s2r" color="--neutral-color-800">
            To exercise these rights, you can contact us by email at emilia@serpa.cloud, or by
            referring to the contact details at the bottom of this document. If you have a complaint
            about how we handle your data, we would like to hear from you.
          </Text>

          <Text type="s3b" color="--neutral-color-800">
            14. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <em>
              <strong>In Short:</strong> Yes, if you are a resident of Virginia, you may be granted
              specific rights regarding access to and use of your personal information.
            </em>
          </Text>

          <Text type="s2b" color="--neutral-color-800">
            Virginia CDPA Privacy Notice
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            Under the Virginia Consumer Data Protection Act (CDPA):
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            "Consumer" means a natural person who is a resident of the Commonwealth acting only in
            an individual or household context. It does not include a natural person acting in a
            commercial or employment context.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            "Personal data" means any information that is linked or reasonably linkable to an
            identified or identifiable natural person. "Personal data" does not include
            de-identified data or publicly available information.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            "Sale of personal data" means the exchange of personal data for monetary consideration.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            If this definition "consumer" applies to you, we must adhere to certain rights and
            obligations regarding your personal data.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            The information we collect, use, and disclose about you will vary depending on how you
            interact with Serpa Cloud, Inc and our Services. To find out more, please visit the
            following links:
          </Text>

          <ul>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#infocollect"
                >
                  Personal data we collect
                </a>
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#infouse"
                >
                  How we use your personal data
                </a>
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.termly.io/dashboard/website/6033dc4c-e212-42c8-b230-db81232d80bb/privacy-policy#whoshare"
                >
                  When and with whom we share your personal data
                </a>
              </Text>
            </li>
          </ul>

          <Text type="s2r" color="--neutral-color-800">
            Your rights with respect to your personal data
          </Text>

          <ul>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                Right to be informed whether or not we are processing your personal data
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                Right to access your personal data
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                Right to correct inaccuracies in your personal data
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                Right to request deletion of your personal data
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                Right to obtain a copy of the personal data you previously shared with us
              </Text>
            </li>
            <li className={stylex(styles.li)}>
              <Text type="s2r" color="--neutral-color-800">
                Right to opt out of the processing of your personal data if it is used for targeted
                advertising, the sale of personal data, or profiling in furtherance of decisions
                that produce legal or similarly significant effects ("profiling")
              </Text>
            </li>
          </ul>

          <Text type="s2r" color="--neutral-color-800">
            Serpa Cloud, Inc has not sold any personal data to third parties for business or
            commercial purposes. Serpa Cloud, Inc will not sell personal data in the future
            belonging to website visitors, users, and other consumers.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            Exercise your rights provided under the Virginia CDPA
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            More information about our data collection and sharing practices can be found in this
            privacy notice.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            If you are using an authorized agent to exercise your rights, we may deny a request if
            the authorized agent does not submit proof that they have been validly authorized to act
            on your behalf.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            Verification process
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            We may request that you provide additional information reasonably necessary to verify
            you and your consumer's request. If you submit the request through an authorized agent,
            we may need to collect additional information to verify your identity before processing
            your request.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            Upon receiving your request, we will respond without undue delay, but in all cases,
            within forty-five (45) days of receipt. The response period may be extended once by
            forty-five (45) additional days when reasonably necessary. We will inform you of any
            such extension within the initial 45-day response period, together with the reason for
            the extension.
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            Right to appeal
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            If we decline to take action regarding your request, we will inform you of our decision
            and reasoning behind it. If you wish to appeal our decision, please email us at
            emilia@serpa.cloud. Within sixty (60) days of receipt of an appeal, we will inform you
            in writing of any action taken or not taken in response to the appeal, including a
            written explanation of the reasons for the decisions. If your appeal if denied, you may
            contact the{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint"
            >
              Attorney General to submit a complaint.
            </a>
          </Text>

          <Text type="s3b" color="--neutral-color-800">
            15. DO WE MAKE UPDATES TO THIS NOTICE?
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            <em>
              <strong>In Short:</strong> Yes, we will update this notice as necessary to stay
              compliant with relevant laws.
            </em>
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            We may update this privacy notice from time to time. The updated version will be
            indicated by an updated "Revised" date and the updated version will be effective as soon
            as it is accessible. If we make material changes to this privacy notice, we may notify
            you either by prominently posting a notice of such changes or by directly sending you a
            notification. We encourage you to review this privacy notice frequently to be informed
            of how we are protecting your information.
          </Text>

          <Text type="s3b" color="--neutral-color-800">
            16. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            If you have questions or comments about this notice, you may email us at
            emilia@serpa.cloud or by post to:
          </Text>
          <Text type="s2r" color="--neutral-color-800">
            Serpa Cloud, Inc
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            2810 North Church Street
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            PMB 90150
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            Wilmington, DE 19802
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            United States
          </Text>

          <Text type="s3b" color="--neutral-color-800">
            17. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
          </Text>

          <Text type="s2r" color="--neutral-color-800">
            Based on the applicable laws of your country, you may have the right to request access
            to the personal information we collect from you, change that information, or delete it.
            To request to review, update, or delete your personal information, please fill out and
            submit a{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://app.termly.io/notify/c796e57c-7ab8-40e3-8dc1-c14559537a5b"
            >
              data subject access request.
            </a>
          </Text>
        </Flexbox>
      </Padding>
    </div>
  );
}
