// @flow
import stylex from '@serpa-cloud/stylex';
import { Navigate } from 'react-router-dom';
import { useContext, Suspense } from 'react';

import { Icon, Text, Padding, Flexbox } from '../../../shared';

import CompilationSetup from './CompilationSetup';

import { Context } from '../Provider';

import useDevice from '../../../shared/hooks/useDevice';

const styles = stylex.create({
  builderPlaceholder: {
    height: 56,
    backgroundColor: 'var(--neutral-color-200)',
  },
});

type Props = {|
  +onUpdate?: ?() => void,
  +callToActionLabel?: ?string,
|};

export default function AppCompilation({ onUpdate, callToActionLabel }: Props): React$Node {
  const { width } = useDevice();
  const { state } = useContext(Context);

  if (!onUpdate) {
    if (!state.method) return <Navigate replace to="/app/apps/create/catalog" />;

    if (state.method === 'GIT' && !(state.gitRepo && state.gitProvider))
      return <Navigate replace to="/app/apps/create/git" />;

    if (state.method === 'GIT' && !state.appName)
      return <Navigate replace to="/app/apps/create/name" />;
  }

  return (
    <Padding bottom={24}>
      <Padding bottom={32}>
        <Flexbox flexDirection="column" rowGap={24}>
          <Flexbox alignItems="center" columnGap={8}>
            <Icon
              fill
              icon="token"
              gradient="linear-gradient(265.7deg, var(--orange-solid-color) -2.24%, var(--red-solid-color) 102.98%)"
            />
            <Flexbox flexDirection="column">
              <Text
                type={width <= 1000 ? 'h6' : 'h5'}
                component="h1"
                id="appCreator.builderTitle"
              />
            </Flexbox>
          </Flexbox>
          <Text
            id="appCreator.builderDescription"
            type={width <= 1000 ? 'bs' : 'bd'}
            values={{
              l(s) {
                return (
                  // eslint-disable-next-line react/jsx-no-target-blank
                  <a href="https://docs.serpa.cloud/introduction" target="_blank">
                    <span
                      style={{
                        fontWeight: '600',
                      }}
                    >
                      {s}
                    </span>
                  </a>
                );
              },
              b(s) {
                return (
                  <span
                    style={{
                      fontWeight: '600',
                      color: 'var(--neutral-color-800)',
                    }}
                  >
                    {s}
                  </span>
                );
              },
            }}
          />
        </Flexbox>
      </Padding>

      <Suspense fallback={<div className={stylex(styles.builderPlaceholder)} />}>
        <CompilationSetup
          onUpdate={onUpdate}
          // $FlowFixMe
          callToActionLabel={callToActionLabel ?? ''}
        />
      </Suspense>
    </Padding>
  );
}

AppCompilation.defaultProps = {
  onUpdate: null,
  callToActionLabel: '',
};
