/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// @flow
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import * as amplitude from '@amplitude/analytics-browser';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay';

import {
  Text,
  Margin,
  Input,
  Button,
  CascaderOption,
  useInput,
  validateData,
} from '../../../shared';

import type { BuildModalMutation } from './__generated__/BuildModalMutation.graphql';

type Props = {|
  +appId: string,
  +onBuild: (buildId: string, gitRef: string) => void,
|};

export default function BuildModal({ appId, onBuild }: Props): React$Node {
  const intl = useIntl();

  const [createBuild, commitPending] = useMutation<BuildModalMutation>(graphql`
    mutation BuildModalMutation($input: BuildInput!) {
      createBuild(input: $input) {
        id
      }
    }
  `);

  const buildTarget = useInput({
    name: 'buildTarget',
    value: '',
    autoCloseSelect: true,
    label: intl.formatMessage({ id: 'buildModal.input.label' }),
    required: true,
    errors: {
      requiredError: intl.formatMessage({ id: 'input.requiredError' }),
    },
  });

  const handleOnSubmit = useCallback(() => {
    if (!commitPending) {
      const { data, errors } = validateData([buildTarget]);

      if (!errors) {
        amplitude.track('Set Build Source');

        const [sourceType, repoRef, sha] = data.buildTarget.split('@');
        const [, , refName] = repoRef.split('/');
        const refValue = `refs/${sourceType === 'Branch' ? 'heads' : 'tags'}/${refName}`;

        createBuild({
          variables: {
            input: {
              sha,
              app: appId,
              ref: `refs/${sourceType === 'Branch' ? 'heads' : 'tags'}/${refName}`,
            },
          },
          onCompleted(response) {
            if (response?.createBuild) {
              amplitude.track('Build Triggered');
              onBuild(response.createBuild.id, refValue);
            }
          },
          onError(error) {
            amplitude.track('Build Trigger Error');

            // eslint-disable-next-line no-console
            console.trace(error);
          },
        });
      } else {
        amplitude.track('Set Build Source Error');
      }
    }
  }, [appId, buildTarget, commitPending, createBuild, onBuild]);

  const data = useLazyLoadQuery(
    graphql`
      query BuildModalQuery($appId: String!) {
        gitBranchesByApp(appId: $appId) {
          id
          name
          sha
        }
        gitTagsByApp(appId: $appId) {
          id
          name
          sha
        }
      }
    `,
    {
      appId,
    },
    {
      fetchPolicy: 'store-and-network',
    },
  );

  return (
    <>
      <Input input={buildTarget.input}>
        <CascaderOption
          selectable={false}
          value="BRANCH"
          label={<Text type="s1m" color="--neutral-color-600" id="buildModal.input.BRANCH" />}
        >
          {data.gitBranchesByApp?.map((el) => {
            const [, name] = el.id.split(':');
            const [, , ...alias] = name.split('/');

            return (
              <CascaderOption
                key={el.id}
                value={`Branch@${name}@${el.sha}`}
                label={
                  <Text type="s0m" color="--neutral-color-800">
                    {alias.join('/')}
                  </Text>
                }
              />
            );
          })}
        </CascaderOption>
        <CascaderOption
          selectable={false}
          value="TAG"
          label={<Text type="s1m" color="--neutral-color-600" id="buildModal.input.TAG" />}
        >
          {data.gitTagsByApp?.map((el) => {
            const [, name] = el.id.split(':');
            const [, , ...alias] = name.split('/');

            return (
              <CascaderOption
                key={el.id}
                value={`Tag@${name}@${el.sha}`}
                label={
                  <Text type="s0m" color="--neutral-color-800">
                    {alias.join('/')}
                  </Text>
                }
              />
            );
          })}
        </CascaderOption>
      </Input>

      <Margin top={24}>
        <Button
          loading={commitPending}
          intlId="build.runBuild"
          onClick={handleOnSubmit}
          disabled={commitPending || !buildTarget.input.value}
        />
      </Margin>
    </>
  );
}
