/**
 * @generated SignedSource<<765d2f1bc6ce6a866b36d9c61c371e81>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type NodeQuery$variables = {|
  id: string,
|};
export type NodeQuery$data = {|
  +node: ?{|
    +id: string,
  |},
|};
export type NodeQuery = {|
  variables: NodeQuery$variables,
  response: NodeQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NodeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NodeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6fca7bbebe06dc26ee1b7767236cc80d",
    "id": null,
    "metadata": {},
    "name": "NodeQuery",
    "operationKind": "query",
    "text": "query NodeQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    id\n    ... on App {\n      id\n    }\n    ... on Environment {\n      id\n    }\n    ... on Release {\n      id\n    }\n    ... on ReleaseChannel {\n      id\n    }\n    ... on Implementation {\n      id\n    }\n    ... on Build {\n      id\n    }\n    ... on Deployment {\n      id\n    }\n    ... on Site {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "438746078ad50967e7be5215724a5cd6";

module.exports = ((node/*: any*/)/*: Query<
  NodeQuery$variables,
  NodeQuery$data,
>*/);
