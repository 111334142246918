// @flow
import { useEffect } from 'react';
import stylex from '@serpa-cloud/stylex';
import { useMatch } from 'react-router-dom';

import { graphql, useMutation } from 'react-relay';

import { Spinner, Flexbox } from '../../shared';

import type { GitValidationConnectToGitlabMutation } from './__generated__/GitValidationConnectToGitlabMutation.graphql';
import type { GitValidationConnectToGithubMutation } from './__generated__/GitValidationConnectToGithubMutation.graphql';

const styles = stylex.create({
  loading: {
    width: '100vw',
    height: '100vh',
    backgroundColor: 'var(--surface-background)',
  },
});

export default function GitValidation(): React$Node {
  const match = useMatch('/git/:provider/validation');

  const [connectToGithub] = useMutation<GitValidationConnectToGithubMutation>(graphql`
    mutation GitValidationConnectToGithubMutation($code: String!) {
      saveGithubToken(code: $code)
    }
  `);

  const [connectToGilab] = useMutation<GitValidationConnectToGitlabMutation>(graphql`
    mutation GitValidationConnectToGitlabMutation($code: String!) {
      saveGitlabToken(code: $code)
    }
  `);

  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get('code');

    if (code) {
      if (match?.params?.provider?.toLowerCase() === 'github')
        connectToGithub({
          variables: { code },
          onCompleted(response) {
            if (response.saveGithubToken) window.close();
          },
          onError(error) {
            // eslint-disable-next-line no-console
            console.trace(error);
          },
        });
      else
        connectToGilab({
          variables: { code },
          onCompleted(response) {
            if (response.saveGitlabToken) window.close();
          },
          onError(error) {
            // eslint-disable-next-line no-console
            console.trace(error);
          },
        });
    }
  }, [connectToGithub, connectToGilab, match?.params?.provider]);

  return (
    <Flexbox className={stylex(styles.loading)} alignItems="center" justifyContent="center">
      <Spinner size={40} />
    </Flexbox>
  );
}
