// @flow
import { useIntl } from 'react-intl';
import stylex from '@serpa-cloud/stylex';
import { useNavigate } from 'react-router-dom';
import { graphql, useMutation } from 'react-relay';
import * as amplitude from '@amplitude/analytics-browser';
import { useMemo, useState, useCallback, Suspense } from 'react';

import { Text, Card, Margin, Padding, Flexbox, Divider, FastSelect } from '../../shared';

import CompilationSourceAppList from './CompilationSourceAppList';
import CompilationSourceGitList from './CompilationSourceGitList';

import type { CompilationSourceMutation } from './__generated__/CompilationSourceMutation.graphql';

const styles = stylex.create({
  fastSelectPlaceholder: {
    height: 40,
    borderRadius: 8,
    backgroundColor: 'var(--border-color)',
  },
  step: {
    width: 20,
    height: 20,
    borderRadius: 12,
    boxSizing: 'border-box',
  },
  stepInactive: {
    border: '1px solid var(--neutral-color-500)',
  },
  stepActive: {
    border: '1px solid var(--green-300)',
  },
  number: {
    fontSize: 10,
    fontWeight: 800,
    fontFamily: 'var(--font-family-default)',
  },
  numberInactive: {
    color: 'var(--neutral-color-500)',
  },
  numberActive: {
    color: 'var(--green-300)',
  },
});

type Props = {| +defaultAppId?: ?string |};

export default function CompilationSource({ defaultAppId }: Props): React$Node {
  const intl = useIntl();
  const navigate = useNavigate();
  const [appId, setAppId] = useState(defaultAppId ? [defaultAppId] : []);

  const onSelectApp = useCallback((value: Array<string>) => {
    setAppId(value);
  }, []);

  const [createBuild, buildPending] = useMutation<CompilationSourceMutation>(graphql`
    mutation CompilationSourceMutation($input: BuildInput!) {
      createBuild(input: $input) {
        id
      }
    }
  `);

  const handleOnSubmit = useCallback(
    (source: [string]) => {
      if (!buildPending) {
        if (source?.[0]) {
          amplitude.track('Send Build Order');

          const [sourceType, repoRef, sha] = source?.[0].split('@');
          const [, , refName] = repoRef.split('/');

          createBuild({
            variables: {
              input: {
                sha,
                app: appId[0],
                ref: `refs/${sourceType === 'Branch' ? 'heads' : 'tags'}/${refName}`,
              },
            },
            onCompleted(response) {
              if (response?.createBuild) {
                amplitude.track('Build Triggered');
                navigate(`/app/apps/${appId[0]}/compilations/${response.createBuild.id}`);
              }
            },
          });
        }
      }
    },
    [buildPending, createBuild, appId, navigate],
  );

  const steps = useMemo(() => {
    return [
      defaultAppId
        ? null
        : {
            title: 'compilationSource.selectApp',
            active: !!appId[0],
            children: (
              <Padding bottom={8}>
                <Suspense fallback={<div className={stylex(styles.fastSelectPlaceholder)} />}>
                  <CompilationSourceAppList
                    appId={appId}
                    onChange={onSelectApp}
                    disabled={!!defaultAppId}
                  />
                </Suspense>
              </Padding>
            ),
          },
      {
        title: defaultAppId ? '' : 'compilationSource.selectGit',
        active: true,
        children: (
          <Padding bottom={8}>
            <Suspense fallback={<div className={stylex(styles.fastSelectPlaceholder)} />}>
              {appId?.[0] ? (
                <CompilationSourceGitList appId={appId?.[0]} handleOnSubmit={handleOnSubmit} />
              ) : (
                <FastSelect
                  placeholder={intl.formatMessage({
                    id: 'compilationSource.gitPlaceholder',
                  })}
                  value={[]}
                  disabled={!appId[0]}
                  onChange={() => {}}
                  label={null}
                >
                  {[]}
                </FastSelect>
              )}
            </Suspense>
          </Padding>
        ),
      },
    ].filter(Boolean);
  }, [defaultAppId, appId, onSelectApp, handleOnSubmit, intl]);

  return (
    <Card>
      <Padding vertical={16} horizontal={16}>
        <Text id="compilationSource.title" type="s0b" color="--neutral-color-800" />
      </Padding>
      <Divider />
      <Padding vertical={16} horizontal={16}>
        <Flexbox flexDirection="column" rowGap={12}>
          {steps.map((step, stepIndex) => {
            return (
              <div key={step.title}>
                {step.title ? (
                  <Margin bottom={12}>
                    <Flexbox alignItems="center" columnGap={8}>
                      <Flexbox
                        justifyContent="center"
                        alignItems="center"
                        className={stylex(
                          styles.step,
                          step.active ? styles.stepActive : styles.stepInactive,
                        )}
                      >
                        <span
                          className={stylex(
                            styles.number,
                            step.active ? styles.numberActive : styles.numberInactive,
                          )}
                        >
                          {stepIndex + 1}
                        </span>
                      </Flexbox>
                      <Text
                        type="s0m"
                        id={step.title}
                        color={step.active ? '--green-300' : '--neutral-color-500'}
                      />
                    </Flexbox>
                  </Margin>
                ) : null}
                <div>{step.children || null}</div>
              </div>
            );
          })}
        </Flexbox>
      </Padding>
    </Card>
  );
}

CompilationSource.defaultProps = {
  defaultAppId: null,
};
