/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-target-blank */
// @flow
import { useIntl } from 'react-intl';
import { createPortal } from 'react-dom';
import stylex from '@serpa-cloud/stylex';
import { ResponsivePie } from '@nivo/pie';
import { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { graphql, useFragment, fetchQuery, useMutation, useRelayEnvironment } from 'react-relay';
import {
  Icon,
  Grid,
  Text,
  Card,
  Padding,
  Flexbox,
  useInterval,
  ContextualMenu,
  useNotification,
  InteractiveElement,
  Spinner,
  Margin,
} from '../../shared';

import useDevice from '../../shared/hooks/useDevice';

import DeleteDeploymentModal from './DeleteDeploymentModal';

import type { DeploymentElementData$key } from './__generated__/DeploymentElementData.graphql';
import type { DeploymentElementPauseMutation } from './__generated__/DeploymentElementPauseMutation.graphql';

const styles = stylex.create({
  topContainer: {
    borderBottomWidth: 1,
    borderBottomColor: 'var(--border-color)',
    borderBottomStyle: 'solid',
  },
  graphContainer: {
    width: 99,
    flexShrink: 0,
  },
  pieContainer: { height: 99, width: 99 },
  menuContainer: {
    position: 'relative',
  },
  contextualMenu: {
    width: 240,
  },
  dataGridContainer: {
    width: '100%',
  },
  gitInfo: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 16,
    display: 'inline-block',
    border: '1px solid var(--border-color)',
    boxShadow: 'var(--shadow-1)',
  },
});

type Props = {|
  +node: DeploymentElementData$key,
|};

export default function DeploymentElement({ node }: Props): React$Node {
  const { screenSize, width } = useDevice();
  const [menuOpen, setMenuOpen] = useState(false);
  const [deleteModalMode, setDeleteModalMode] = useState('DELETE');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const intl = useIntl();
  const [, , setNotification] = useNotification();

  const environment = useRelayEnvironment();

  const data = useFragment(
    graphql`
      fragment DeploymentElementData on Deployment {
        id
        name
        pause
        createdAtFormatted
        dockerImageUrl
        app {
          id
          name
          namespace
          appMethod
        }
        implementation {
          serviceName
        }
        environment {
          id
          name
        }
        status {
          replicas
          readyReplicas
          updatedReplicas
          availableReplicas
          unavailableReplicas
          observedGeneration
        }
        publicEndpoint
        pods {
          status {
            phase
          }
        }
        source {
          ... on Artifact {
            id
            commitShaShort
            commitUrl
            gitBaseIsTag
            gitTag
            gitTagUrl
            commitDescription
          }
          ... on Release {
            id
            artifact {
              id
              commitShaShort
              commitUrl
              gitBaseIsTag
              gitTag
              gitTagUrl
              commitDescription
            }
          }
          ... on ReleaseChannel {
            id
            artifact {
              id
              commitShaShort
              commitUrl
              gitBaseIsTag
              gitTag
              gitTagUrl
              commitDescription
            }
          }
        }
      }
    `,
    node,
  );

  const [restartDeployment, restartPending] = useMutation<DeploymentElementPauseMutation>(graphql`
    mutation DeploymentElementPauseMutation($id: ID!) {
      restartDeployment(id: $id) {
        id
        pause
        status {
          replicas
          readyReplicas
          updatedReplicas
          availableReplicas
          unavailableReplicas
          observedGeneration
        }
        pods {
          status {
            phase
          }
        }
      }
    }
  `);

  const handleOnRestart = useCallback(() => {
    if (!restartPending)
      restartDeployment({
        variables: {
          id: data.id,
        },

        onCompleted(response) {
          if (response?.restartDeployment) {
            setNotification({
              id: new Date().getTime().toString(),
              type: 'SUCCESS',
              message: intl.formatMessage({ id: 'restartDeployment.success' }),
            });

            setMenuOpen(false);

            setShowDeleteModal(false);
          }
        },

        onError(error) {
          // eslint-disable-next-line no-console
          console.log(error);

          setNotification({
            id: new Date().getTime().toString(),
            type: 'ERROR',
            // $FlowIgnore
            message: error.source.errors[0].message,
          });
        },
      });
  }, [data.id, intl, restartDeployment, restartPending, setNotification]);

  const podsPending = data.pods.filter((pod) => pod?.status?.phase === 'Pending').length;
  const artifact = data?.source?.artifact ? data?.source?.artifact : data?.source;

  useInterval(() => {
    fetchQuery(
      environment,
      graphql`
        query DeploymentElementStatusQuery($id: ID!) {
          node(id: $id) {
            id
            ... on Deployment {
              id
              status {
                replicas
                readyReplicas
                updatedReplicas
                availableReplicas
                unavailableReplicas
                observedGeneration
              }
              pods {
                status {
                  phase
                }
              }
            }
          }
        }
      `,
      {
        id: data?.id ?? '',
      },
    ).subscribe({});
  }, 15000);

  return (
    <>
      {showDeleteModal
        ? createPortal(
            // eslint-disable-next-line react/jsx-indent
            <DeleteDeploymentModal
              mode={deleteModalMode}
              deploymentId={data.id}
              onClose={() => setShowDeleteModal(false)}
            />,
            document.getElementById('modal-portal'),
          )
        : null}
      <Card>
        <Padding horizontal={16} vertical={16} className={stylex(styles.topContainer)}>
          <Flexbox justifyContent="space-between">
            <Link to={`/app/apps/${data.app.id}/deployments/${data.id}/dashboard`}>
              <Text type="s0b" color="--primary-color-1">
                {data.name}
              </Text>
            </Link>
            <div className={stylex(styles.menuContainer)}>
              <InteractiveElement
                onClick={() => {
                  setTimeout(() => {
                    setMenuOpen((x) => !x);
                  }, 50);
                }}
              >
                <Icon icon="more_horiz" color="--neutral-color-500" weight={600} size={16} />
              </InteractiveElement>

              <ContextualMenu
                open={menuOpen}
                containerHeight={0}
                anchor="RIGHT"
                onClose={() => {
                  setMenuOpen(false);
                }}
                className={stylex(styles.contextualMenu)}
              >
                <Padding horizontal={16} vertical={16}>
                  <Flexbox rowGap={16} flexDirection="column">
                    {!data.pause && (
                      <InteractiveElement
                        onClick={() => {
                          setShowDeleteModal(true);
                          setMenuOpen(false);
                          setDeleteModalMode('PAUSE');
                        }}
                      >
                        <Flexbox columnGap={4} alignItems="center">
                          <Icon icon="pause" color="--neutral-color-500" fill size={16} />
                          <Text
                            type="s0b"
                            color="--neutral-color-500"
                            id="deployment.pauseDeployment"
                          />
                        </Flexbox>
                      </InteractiveElement>
                    )}
                    {data.pause && (
                      <InteractiveElement onClick={handleOnRestart}>
                        <Flexbox columnGap={4} alignItems="center">
                          {restartPending ? (
                            <Spinner size={16} />
                          ) : (
                            <Icon icon="play_arrow" color="--neutral-color-500" fill size={16} />
                          )}
                          <Text
                            type="s0b"
                            color="--neutral-color-500"
                            id="deployment.playDeployment"
                          />
                        </Flexbox>
                      </InteractiveElement>
                    )}

                    <InteractiveElement
                      onClick={() => {
                        setShowDeleteModal(true);
                        setMenuOpen(false);
                        setDeleteModalMode('DELETE');
                      }}
                    >
                      <Flexbox columnGap={4} alignItems="center">
                        <Icon icon="delete" color="--red-300" fill size={16} />
                        <Text type="s0b" color="--red-300" id="deployment.deleteDeployment" />
                      </Flexbox>
                    </InteractiveElement>
                  </Flexbox>
                </Padding>
              </ContextualMenu>
            </div>
          </Flexbox>
        </Padding>

        <Padding vertical={24} horizontal={16}>
          <Flexbox
            alignItems="center"
            columnGap={24}
            rowGap={16}
            flexDirection={screenSize === 'phone' ? 'column' : 'row'}
          >
            <Flexbox className={stylex(styles.graphContainer)} justifyContent="center">
              <div className={stylex(styles.pieContainer)}>
                <ResponsivePie
                  width={96}
                  height={96}
                  data={
                    data.status && data.status.replicas !== 0
                      ? [
                          {
                            id: 'SUCCESS',
                            label: 'Success',
                            value: data.status.availableReplicas,
                            color: '#05C168',
                          },
                          {
                            id: 'PENDING',
                            label: 'Pending',
                            value: podsPending,
                            color: '#FF9E2C',
                          },
                          {
                            id: 'Error',
                            label: 'Error',
                            value: data.status.unavailableReplicas - podsPending,
                            color: '#FF5A65',
                          },
                        ]
                      : [
                          {
                            id: 'NO_AVAILABLE',
                            label: 'No available',
                            value: 1,
                            color: '#dcddeb',
                          },
                        ]
                  }
                  innerRadius={0.85}
                  enableArcLabels={false}
                  enableArcLinkLabels={false}
                  activeInnerRadiusOffset={1}
                  activeOuterRadiusOffset={1}
                  colors={{ datum: 'data.color' }}
                  tooltip={() => null}
                  layers={[
                    'arcs',
                    ({ centerX, centerY }) => (
                      <text
                        x={centerX}
                        y={centerY}
                        textAnchor="middle"
                        dominantBaseline="central"
                        style={{
                          fill: 'var(--neutral-color-800)',
                          fontSize: '20px',
                          lineHeight: '20px',
                          fontWeight: 600,
                          fontFamily: 'var(--font-family-default)',
                        }}
                      >
                        {data?.status?.replicas ?? '0'}
                      </text>
                    ),
                  ]}
                />
              </div>
            </Flexbox>
            <div style={{ width: screenSize === 'phone' ? '100%' : 'auto' }}>
              <Grid columns={width <= 580 ? '1fr' : '200px 1fr'} columnGap={24} rowGap={24}>
                <Flexbox flexDirection="column" rowGap={12}>
                  <Text type="s0m" color="--neutral-color-800" id="deployment.environment" />
                  <Link to={`/app/environments/${data?.environment?.id ?? ''}`}>
                    <Text type="s0m" color="--primary-color-1">
                      {data?.environment?.name}
                    </Text>
                  </Link>
                </Flexbox>

                <Flexbox flexDirection="column" rowGap={12}>
                  <Text type="s0m" color="--neutral-color-800" id="deployment.created" />
                  <Text type="s0m" color="--neutral-color-600">
                    {data?.createdAtFormatted}
                  </Text>
                </Flexbox>

                <Flexbox flexDirection="column" rowGap={12}>
                  <Text type="s0m" color="--neutral-color-800" id="deployment.privateEndpoint" />
                  <Text type="s0m" color="--neutral-color-600">
                    {`http://${data?.implementation?.serviceName}`}
                  </Text>
                </Flexbox>
                {data.publicEndpoint ? (
                  <Flexbox flexDirection="column" rowGap={12}>
                    <Text type="s0m" color="--neutral-color-800" id="deployment.publicEndpoint" />
                    <a target="_blank" href={`https://${data.publicEndpoint}`}>
                      <Text type="s0m" color="--primary-color-1">
                        {`https://${data.publicEndpoint}`}
                      </Text>
                    </a>
                  </Flexbox>
                ) : width <= 580 ? null : (
                  <div />
                )}
              </Grid>
              {artifact ? (
                <Margin top={32}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className={stylex(styles.gitInfo)}
                    href={artifact.gitBaseIsTag ? artifact.gitTagUrl : artifact.commitUrl}
                  >
                    <Text type="s0m" color="--primary-color-1">
                      {`${artifact?.gitTag ?? artifact.commitShaShort ?? ''}${
                        artifact.commitDescription ? ` - ${artifact.commitDescription}` : ''
                      }`}
                    </Text>
                  </a>
                </Margin>
              ) : null}
            </div>
          </Flexbox>
        </Padding>
      </Card>
    </>
  );
}
