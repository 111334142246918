// @flow
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

export default function usePageView(pageTitle: string | {| +id: string |}): void {
  const intl = useIntl();

  const r = typeof pageTitle === 'string' ? pageTitle : intl.formatMessage({ id: pageTitle.id });

  useEffect(() => {
    // document.title = `${r} | Serpa Cloud`;
    // window.dataLayer = window.dataLayer || [];
    // window.dataLayer.push({ event: 'page_view' });
  }, [r]);
}
