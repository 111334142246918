import { graphql } from 'react-relay';

export default graphql`
  query SessionQuery {
    me {
      id
      username
      name
      lastname
      fullname
      email
      gender
      pronoun
      description
      uiTheme
      hasBasicProfile
      profileAvatar: media(width: 352, height: 352) {
        ...Avatar
      }
      composerAvatar: media(width: 80, height: 80) {
        ...Avatar
      }
      badgeAvatar: media(width: 64, height: 64) {
        ...Avatar
      }
    }
    paymentProfile {
      id
    }
  }
`;
