/**
 * @generated SignedSource<<4cff5c53d5151b737658e54d2d6abc2b>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type UITheme = "DARK" | "LIGHT" | "%future added value";
export type UserBadgeChangeUIThemeMutation$variables = {|
  uiTheme: UITheme,
|};
export type UserBadgeChangeUIThemeMutation$data = {|
  +setUITheme: {|
    +id: string,
    +uiTheme: ?UITheme,
  |},
|};
export type UserBadgeChangeUIThemeMutation = {|
  variables: UserBadgeChangeUIThemeMutation$variables,
  response: UserBadgeChangeUIThemeMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uiTheme"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "uiTheme",
        "variableName": "uiTheme"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "setUITheme",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uiTheme",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserBadgeChangeUIThemeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserBadgeChangeUIThemeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2e1cc120ecf6c3a028d37da9255a7938",
    "id": null,
    "metadata": {},
    "name": "UserBadgeChangeUIThemeMutation",
    "operationKind": "mutation",
    "text": "mutation UserBadgeChangeUIThemeMutation(\n  $uiTheme: UITheme!\n) {\n  setUITheme(uiTheme: $uiTheme) {\n    id\n    uiTheme\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "c54432a418ebc8519e59677bfd6530ef";

module.exports = ((node/*: any*/)/*: Mutation<
  UserBadgeChangeUIThemeMutation$variables,
  UserBadgeChangeUIThemeMutation$data,
>*/);
