// @flow
import stylex from '@serpa-cloud/stylex';
import { createPortal } from 'react-dom';
import { Suspense, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { graphql, useLazyLoadQuery } from 'react-relay';
import * as amplitude from '@amplitude/analytics-browser';

import Header from '../Header';

import LayoutPlaceholder from './LayoutPlaceholder';

import { LoaderForSuspense } from '../../shared';

import noiseUrl from './noise.png';
import noiseWhiteUrl from './noise_white.png';

import Home from '../Home';
import Profile from '../Profile';
import AppCreator from '../AppCreator';
import CreateProfile from '../CreateProfile';
import CompilationDetail from '../Compilations/CompilationDetail';

import Environments from '../Environments';
import EnvironmentsForm from '../Environments/Form';
import CompilationsList from '../Compilations/CompilationsList';

import DeploymentSource from '../Deployments/DeploymentSource';
import Onboarding from '../Onboarding';
import OnboardingSubscription from '../Onboarding/OnboardingSubscription';

const styles = stylex.create({
  body: {
    width: '100vw',
  },
  content: {
    boxSizing: 'border-box',
  },
  frame: {
    boxSizing: 'border-box',
    paddingTop: '1px',
  },
  frameViewport: {
    position: 'relative',
  },
  frameInnerContent: {
    borderRadius: 0,
    paddingLeft: 'env(safe-area-inset-left)',
    paddingRight: 'env(safe-area-inset-right)',
  },
});

export default function Layout(): React$Node {
  const userData = useLazyLoadQuery(
    graphql`
      query LayoutQuery {
        me {
          id
          uiTheme
          username
          email
          hasBasicProfile
        }
        paymentProfile {
          id
        }
      }
    `,
    {},
    {
      fetchPolicy: 'store-or-network',
    },
  );

  const me = userData?.me;
  const id = me?.id;
  const email = me?.email;
  const username = me?.username;

  useEffect(() => {
    if (id && email && username) {
      amplitude.setUserId(username);

      datadogRum.setUser({
        id,
        email,
        name: username,
      });
    }
  }, [email, id, username]);

  const theme = me?.uiTheme;
  const isLight = theme === 'LIGHT';

  useEffect(() => {
    if (document.body) document.body.className = theme ?? '';
  }, [theme]);

  return (
    <div id="dashboard" className={theme}>
      {createPortal(
        <>
          <meta name="theme-color" content={isLight ? '#ffffff' : '#080033'} />
          <meta name="msapplication-TileColor" content={isLight ? '#ffffff' : '#080033'} />
        </>,
        document.head,
      )}
      <div className={stylex(styles.body)}>
        <div className={stylex(styles.content)}>
          <Header />
          <section className={stylex(styles.frame)}>
            <div className={stylex(styles.frameViewport)}>
              <div
                className={stylex(styles.frameInnerContent)}
                style={{
                  backgroundImage: `url("${isLight ? noiseWhiteUrl : noiseUrl}")`,
                }}
              >
                <Routes>
                  <Route
                    path="/subscription/*"
                    element={
                      <Suspense fallback={<LoaderForSuspense spinnerSize={40} />}>
                        <OnboardingSubscription />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/u/:user"
                    element={
                      <Suspense fallback={<LoaderForSuspense spinnerSize={40} />}>
                        <Profile />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/create-profile"
                    element={
                      <Suspense fallback={<LoaderForSuspense spinnerSize={40} />}>
                        <CreateProfile />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/apps/create/*"
                    element={
                      <Suspense fallback={<LoaderForSuspense spinnerSize={40} />}>
                        <AppCreator />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/deployments/select-source"
                    element={
                      <Suspense fallback={<LayoutPlaceholder />}>
                        <DeploymentSource />
                      </Suspense>
                    }
                  />

                  <Route
                    path="/environments"
                    element={
                      <Suspense fallback={<LayoutPlaceholder />}>
                        <Environments />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/environments/create"
                    element={
                      <Suspense fallback={<LayoutPlaceholder />}>
                        <EnvironmentsForm />
                      </Suspense>
                    }
                  />

                  <Route
                    path="/build/:id"
                    element={
                      <Suspense fallback={<LayoutPlaceholder />}>
                        <CompilationDetail />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/builds"
                    element={
                      <Suspense fallback={<LayoutPlaceholder />}>
                        <CompilationsList />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/onboarding/*"
                    element={
                      <Suspense fallback={<LayoutPlaceholder />}>
                        <Onboarding />
                      </Suspense>
                    }
                  />
                  <Route
                    path="*"
                    element={
                      <Suspense fallback={<LayoutPlaceholder />}>
                        <Home />
                      </Suspense>
                    }
                  />
                </Routes>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
