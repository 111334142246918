/**
 * @generated SignedSource<<380da33fa38a0b3d1c77b9719bc3f90f>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type GitValidationConnectToGithubMutation$variables = {|
  code: string,
|};
export type GitValidationConnectToGithubMutation$data = {|
  +saveGithubToken: boolean,
|};
export type GitValidationConnectToGithubMutation = {|
  variables: GitValidationConnectToGithubMutation$variables,
  response: GitValidationConnectToGithubMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "code"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "code",
        "variableName": "code"
      }
    ],
    "kind": "ScalarField",
    "name": "saveGithubToken",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GitValidationConnectToGithubMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GitValidationConnectToGithubMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5da61c07fed7bf00b9394c494aec8992",
    "id": null,
    "metadata": {},
    "name": "GitValidationConnectToGithubMutation",
    "operationKind": "mutation",
    "text": "mutation GitValidationConnectToGithubMutation(\n  $code: String!\n) {\n  saveGithubToken(code: $code)\n}\n"
  }
};
})();

(node/*: any*/).hash = "40f79320094a65ab110d2cccc9a8ecd4";

module.exports = ((node/*: any*/)/*: Mutation<
  GitValidationConnectToGithubMutation$variables,
  GitValidationConnectToGithubMutation$data,
>*/);
