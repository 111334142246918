// @flow
import { Link } from 'react-router-dom';
import stylex from '@serpa-cloud/stylex';

import Icon from './Icon';
import Text from './Text';
import Flexbox from './Flexbox';
import Padding from './Padding';
import Divider from './Divider';

type Section = {|
  +link: string,
  +text?: ?string,
  +icon?: ?string,
  +textId?: ?string,
|};

type Props = {|
  +sections: Array<Section>,
|};

const styles = stylex.create({
  sticky: {
    top: 'var(--header-height)',
    zIndex: 10,
    position: 'sticky',
    background: 'var(--surface)',
  },
  root: {
    overflowX: 'auto',
    '::-webkit-scrollbar': {
      display: 'none',
    },
  },
  container: {
    width: '100%',
  },
  breadContainer: {
    flexShrink: 0,
  },
});

export default function Breadcrumb({ sections }: Props): React$Node {
  return (
    <div className={stylex(styles.sticky)}>
      <Divider />
      <div className={stylex(styles.root)}>
        <Padding vertical={8} horizontal={16} className={stylex(styles.container)}>
          <Flexbox alignItems="center" columnGap={12}>
            <Link to="/app" className={stylex(styles.breadContainer)}>
              <Flexbox alignItems="center" columnGap={4}>
                <Icon icon="home" size={16} fill color="--neutral-color-400" />
                <Text id="home" type="s0m" />
              </Flexbox>
            </Link>
            {sections.map((section) => (
              <Flexbox
                alignItems="center"
                columnGap={12}
                key={section.link}
                className={stylex(styles.breadContainer)}
              >
                <Text type="s0m" color="--neutral-color-400">
                  /
                </Text>
                <Link to={section.link}>
                  <Flexbox alignItems="center" columnGap={4}>
                    {section?.icon ? (
                      <Icon icon={section.icon} size={16} fill color="--neutral-color-400" />
                    ) : null}
                    <Text id={section.textId ?? null} type="s0m">
                      {section?.text ?? null}
                    </Text>
                  </Flexbox>
                </Link>
              </Flexbox>
            ))}
          </Flexbox>
        </Padding>
      </div>
      <Divider />
    </div>
  );
}
