// @flow
import { useIntl } from 'react-intl';
import stylex from '@serpa-cloud/stylex';
import { Suspense, useCallback, useState } from 'react';
import { Navigate, useMatch, useNavigate } from 'react-router-dom';
import { graphql, useMutation, useLazyLoadQuery } from 'react-relay';

import {
  Grid,
  Text,
  Input,
  Margin,
  Button,
  Padding,
  Flexbox,
  CascaderOption,
  useInput,
  useDevice,
  validateData,
  LoaderForSuspense,
  useNotification,
} from '../../shared';

import Confirmation from './Confirmation';
import SubscriptionTableElement from './SubscriptionTableElement';

import type { OnboardingSubscriptionMutation } from './__generated__/OnboardingSubscriptionMutation.graphql';

const styles = stylex.create({
  inputContainer: {
    flex: 1,
  },
  buttonContainer: {
    '@media (max-width: 680px)': {
      width: '100%',
    },
    '@media (min-width: 680px)': {
      width: 160,
    },
  },
  content: {
    width: '100vw',
    maxWidth: 680,
  },
  tableContent: {
    width: '100vw',
    maxWidth: 1200,
  },
});

type PlanType = 'HOBBY' | 'BASIC' | 'PRO';

export default function OnboardingSubscription(): React$Node {
  const { width } = useDevice();
  const [selectedPlan, setSelectedPlan] = useState<?PlanType>();
  const match = useMatch('/app/subscription/:section');
  const intl = useIntl();
  const navigate = useNavigate();
  const [, , setNotification] = useNotification();

  const userData = useLazyLoadQuery(
    graphql`
      query OnboardingSubscriptionQuery {
        getCurrentNameSpace {
          id
          __typename
        }
        paymentProfile {
          id
        }
      }
    `,
    {},
    {
      fetchPolicy: 'network-only',
    },
  );

  // eslint-disable-next-line no-underscore-dangle
  const nameSpaceType = userData.getCurrentNameSpace?.__typename;
  const namespaceIsOrg = nameSpaceType === 'Org';

  const name = useInput({
    name: 'name',
    required: true,
    value: '',
    label: { id: 'onboarding.teamName' },
    errors: {
      requiredError: { id: 'input.requiredError' },
    },
  });

  const orgSize = useInput({
    name: 'orgSize',
    required: true,
    label: { id: 'onboarding.teamSize' },
    value: '1',
    hideSearch: true,
    replacer(v) {
      if (!v) return orgSize.input.value;
      return v;
    },
    autoCloseSelect: true,
    errors: {
      requiredError: { id: 'input.requiredError' },
    },
  });

  const market = useInput({
    name: 'market',
    required: true,
    label: { id: 'onboarding.market' },
    value: '0',
    hideSearch: true,
    replacer(v) {
      if (!v || v === '0') return market.input.value;
      return v;
    },
    autoCloseSelect: true,
    errors: {
      requiredError: { id: 'input.requiredError' },
    },
  });

  const goal = useInput({
    name: 'goal',
    required: true,
    label: { id: 'onboarding.goal' },
    value: '0',
    hideSearch: true,
    replacer(v) {
      if (!v || v === '0') return goal.input.value;
      return v;
    },
    autoCloseSelect: true,
    errors: {
      requiredError: { id: 'input.requiredError' },
    },
  });

  const section = match?.params?.section;
  const projectName = name.input.value;

  const [subscribe, subscribePending] = useMutation<OnboardingSubscriptionMutation>(graphql`
    mutation OnboardingSubscriptionMutation($input: SubscriptionInput!) {
      subscribe(input: $input)
    }
  `);

  const handleSubscription = useCallback(
    (plan) => {
      if (namespaceIsOrg || plan === 'HOBBY') {
        subscribe({
          variables: {
            input: {
              plan,
              name: '',
              goal: '',
              market: '',
              orgSize: '1',
            },
          },
          onCompleted(response) {
            if (response.subscribe) {
              window.location.href = response.subscribe;
            }
          },
          onError(error) {
            // eslint-disable-next-line no-console
            console.trace(error);

            setNotification({
              id: new Date().getTime().toString(),
              type: 'ERROR',
              // $FlowIgnore
              message: intl.formatMessage({ id: error.source.errors[0].message }),
            });
          },
        });
      } else {
        const { errors, data: formData } = validateData<{
          name: string,
          goal: string,
          orgSize: string,
          market: string,
        }>([name, orgSize, market, goal]);
        if (!errors) {
          subscribe({
            variables: {
              input: {
                plan,
                ...formData,
              },
            },
            onCompleted(response) {
              if (response.subscribe) {
                window.location.href = response.subscribe;
              }
            },
            onError(error) {
              // eslint-disable-next-line no-console
              console.trace(error);

              setNotification({
                id: new Date().getTime().toString(),
                type: 'ERROR',
                // $FlowIgnore
                message: intl.formatMessage({ id: error.source.errors[0].message }),
              });
            },
          });
        }
      }
    },
    [goal, intl, market, name, namespaceIsOrg, orgSize, setNotification, subscribe],
  );

  const handleOnSubmit = useCallback(() => {
    const { errors } = validateData([name, orgSize, market, goal]);
    if (!errors) {
      if (selectedPlan) handleSubscription(selectedPlan);
    }
  }, [name, orgSize, market, goal, handleSubscription, selectedPlan]);

  const handleOnSelectPlan = useCallback(
    (plan: PlanType) => {
      if (namespaceIsOrg) handleSubscription(plan);
      else {
        setSelectedPlan(plan);
        navigate('/app/subscription/info');
      }
    },
    [handleSubscription, namespaceIsOrg, navigate],
  );

  if (section === 'info' && !selectedPlan) {
    return <Navigate to="/app/subscription/billing" />;
  }

  return (
    <Padding vertical={40}>
      <Flexbox justifyContent="center">
        <div>
          {section === 'confirmation' && (
            <Suspense fallback={<LoaderForSuspense />}>
              <Confirmation />
            </Suspense>
          )}
          {section === 'info' && (
            <div className={stylex(styles.content)}>
              <Padding horizontal={16}>
                <Flexbox flexDirection="column" rowGap={16}>
                  <Text id="onboarding.subscriptionProjectSizeTitle" type="h4" />
                  <Text id="onboarding.subscriptionProjectSizeDescription" type="bs" />
                </Flexbox>

                <Margin top={32}>
                  <div className={stylex(styles.inputContainer)}>
                    <Input input={name.input} />
                  </div>
                </Margin>

                <Margin top={16}>
                  <Flexbox columnGap={16} rowGap={16} flexDirection="row" flexDirectionM="column">
                    <div className={stylex(styles.inputContainer)}>
                      <Input input={orgSize.input}>
                        <CascaderOption
                          value="1"
                          label={
                            <Text type="s0m" color="--neutral-color-800" id="teamSize.justYou" />
                          }
                        />
                        <CascaderOption
                          value="2-9"
                          label={<Text type="s0m" color="--neutral-color-800" id="teamSize.2+" />}
                        />
                        <CascaderOption
                          value="10-99"
                          label={<Text type="s0m" color="--neutral-color-800" id="teamSize.10+" />}
                        />
                        <CascaderOption
                          value="100-299"
                          label={<Text type="s0m" color="--neutral-color-800" id="teamSize.100+" />}
                        />
                        <CascaderOption
                          value="+300"
                          label={<Text type="s0m" color="--neutral-color-800" id="teamSize.300+" />}
                        />
                      </Input>
                    </div>
                  </Flexbox>
                </Margin>

                <Margin top={16}>
                  <Flexbox columnGap={16} rowGap={16} flexDirection="row" flexDirectionM="column">
                    <div className={stylex(styles.inputContainer)}>
                      <Input input={market.input}>
                        <CascaderOption
                          value="0"
                          label={
                            <Text
                              type="s0m"
                              color="--neutral-color-800"
                              id="input.selectAnOption"
                            />
                          }
                        />
                        <CascaderOption
                          value="FINANCE"
                          label={
                            <Text type="s0m" color="--neutral-color-800" id="market.FINANCE" />
                          }
                        />
                        <CascaderOption
                          value="TRAVEL"
                          label={<Text type="s0m" color="--neutral-color-800" id="market.TRAVEL" />}
                        />
                        <CascaderOption
                          value="FOOD"
                          label={<Text type="s0m" color="--neutral-color-800" id="market.FOOD" />}
                        />
                        <CascaderOption
                          value="AUTOMOTIVE"
                          label={
                            <Text type="s0m" color="--neutral-color-800" id="market.AUTOMOTIVE" />
                          }
                        />
                        <CascaderOption
                          value="EDUCATION"
                          label={
                            <Text type="s0m" color="--neutral-color-800" id="market.EDUCATION" />
                          }
                        />
                        <CascaderOption
                          value="GOVERNMENT"
                          label={
                            <Text type="s0m" color="--neutral-color-800" id="market.GOVERNMENT" />
                          }
                        />
                        <CascaderOption
                          value="IT"
                          label={<Text type="s0m" color="--neutral-color-800" id="market.IT" />}
                        />
                        <CascaderOption
                          value="HEALTHCARE"
                          label={
                            <Text type="s0m" color="--neutral-color-800" id="market.HEALTHCARE" />
                          }
                        />
                        <CascaderOption
                          value="RETAIL"
                          label={<Text type="s0m" color="--neutral-color-800" id="market.RETAIL" />}
                        />
                        <CascaderOption
                          value="ECOMMERCE"
                          label={
                            <Text type="s0m" color="--neutral-color-800" id="market.ECOMMERCE" />
                          }
                        />
                        <CascaderOption
                          value="MARKETING"
                          label={
                            <Text type="s0m" color="--neutral-color-800" id="market.MARKETING" />
                          }
                        />
                        <CascaderOption
                          value="DESIGN"
                          label={<Text type="s0m" color="--neutral-color-800" id="market.DESIGN" />}
                        />
                        <CascaderOption
                          value="GAMING"
                          label={<Text type="s0m" color="--neutral-color-800" id="market.GAMING" />}
                        />
                        <CascaderOption
                          value="ADVANCED"
                          label={
                            <Text type="s0m" color="--neutral-color-800" id="market.ADVANCED" />
                          }
                        />
                        <CascaderOption
                          value="NONPROFIT"
                          label={
                            <Text type="s0m" color="--neutral-color-800" id="market.NONPROFIT" />
                          }
                        />
                        <CascaderOption
                          value="OTHER"
                          label={<Text type="s0m" color="--neutral-color-800" id="market.OTHER" />}
                        />
                      </Input>
                    </div>

                    <div className={stylex(styles.inputContainer)}>
                      <Input input={goal.input}>
                        <CascaderOption
                          value="0"
                          label={
                            <Text
                              type="s0m"
                              color="--neutral-color-800"
                              id="input.selectAnOption"
                            />
                          }
                        />
                        <CascaderOption
                          value="LEARNING"
                          label={<Text type="s0m" color="--neutral-color-800" id="goal.LEARNING" />}
                        />
                        <CascaderOption
                          value="PERSONAL"
                          label={<Text type="s0m" color="--neutral-color-800" id="goal.PERSONAL" />}
                        />
                        <CascaderOption
                          value="NEW_APP"
                          label={<Text type="s0m" color="--neutral-color-800" id="goal.NEW_APP" />}
                        />
                        <CascaderOption
                          value="MIGRATE_APP"
                          label={
                            <Text type="s0m" color="--neutral-color-800" id="goal.MIGRATE_APP" />
                          }
                        />
                      </Input>
                    </div>
                  </Flexbox>
                </Margin>

                <Margin top={40}>
                  <div className={stylex(styles.buttonContainer)}>
                    <Button intlId="continue" onClick={handleOnSubmit} />
                  </div>
                </Margin>
              </Padding>
            </div>
          )}

          {section === 'billing' && (
            <div className={stylex(styles.tableContent)}>
              <Padding horizontal={16}>
                <Flexbox flexDirection="column" rowGap={16}>
                  <Text id="onboarding.subscriptionTitle" type="h4" />
                  <Text
                    id="onboarding.subscriptionDescription"
                    type="bs"
                    values={{ name: projectName }}
                  />
                </Flexbox>

                <Margin top={32}>
                  <Grid
                    // eslint-disable-next-line no-nested-ternary
                    columns={width > 1024 ? (!namespaceIsOrg ? '1fr 1fr 1fr' : '1fr 1fr') : '1fr'}
                    columnGap={24}
                    rowGap={24}
                  >
                    {!namespaceIsOrg && (
                      <SubscriptionTableElement
                        plan="HOBBY"
                        pending={subscribePending}
                        callToAction={() => {
                          handleSubscription('HOBBY');
                        }}
                      />
                    )}
                    <SubscriptionTableElement
                      plan="BASIC"
                      isFavorite
                      pending={subscribePending}
                      callToAction={() => {
                        handleOnSelectPlan('BASIC');
                      }}
                    />
                    <SubscriptionTableElement
                      plan="PRO"
                      pending={subscribePending}
                      callToAction={() => {
                        handleOnSelectPlan('PRO');
                      }}
                    />
                  </Grid>
                </Margin>
              </Padding>
            </div>
          )}
        </div>
      </Flexbox>
    </Padding>
  );
}
