// @flow
import stylex from '@serpa-cloud/stylex';
import { NavLink } from 'react-router-dom';

import Text from './Text';
import Card from './Card';
import Margin from './Margin';
import Padding from './Padding';
import Flexbox from './Flexbox';

const styles = stylex.create({
  container: {
    minHeight: 200,
  },
  titlePlaceholder: {
    width: 240,
    height: 13,
    borderRadius: 8,
    backgroundColor: 'var(--neutral-color-300)',
  },
  subtitlePlaceholder: {
    width: 120,
    height: 11,
    borderRadius: 8,
    backgroundColor: 'var(--neutral-color-300)',
  },
});

type Props = {|
  +name: string,
  +serviceURL: string,
  +footer: React$Node,
  +firstCount: number,
  +firstCountLabel: string,
  +secondCount: number,
  +secondCountLabel: string,
  +createdAtFormatted: string,
|};

export function ServiceCardPlaceholder(): React$Node {
  return (
    <Card className={stylex(styles.container)}>
      <Padding horizontal={16} vertical={16}>
        <Flexbox flexDirection="column">
          <Flexbox flexDirection="column" rowGap={12}>
            <div className={stylex(styles.titlePlaceholder)} />
            <div className={stylex(styles.subtitlePlaceholder)} />
          </Flexbox>
        </Flexbox>
      </Padding>
    </Card>
  );
}

export default function ServiceCard({
  name,
  footer,
  serviceURL,
  createdAtFormatted,
  firstCount,
  firstCountLabel,
  secondCount,
  secondCountLabel,
}: Props): React$Node {
  return (
    <Card className={stylex(styles.container)}>
      <Padding horizontal={16} vertical={16}>
        <Flexbox flexDirection="column">
          <Flexbox flexDirection="column" rowGap={12}>
            <NavLink to={serviceURL}>
              {/* $FlowIssue */}
              {() => (
                <Text type="s1b" color="--primary-color-1">
                  {name}
                </Text>
              )}
            </NavLink>

            <Text
              type="s0r"
              color="--neutral-color-600"
              id="microapp.created"
              values={{ days: createdAtFormatted }}
            />
          </Flexbox>
          <Margin vertical={32}>
            <Flexbox flexDirection="column" rowGap={12}>
              <Flexbox columnGap={8}>
                <Text type="s1b" color="--neutral-color-800">
                  {firstCount}
                </Text>
                <Text type="s0r" color="--neutral-color-600" id={firstCountLabel} />
              </Flexbox>

              <Flexbox columnGap={8}>
                <Text type="s1b" color="--neutral-color-800">
                  {secondCount}
                </Text>
                <Text type="s0r" color="--neutral-color-600" id={secondCountLabel} />
              </Flexbox>
            </Flexbox>
          </Margin>
          <Flexbox columnGap={8} alignItems="center">
            {footer}
          </Flexbox>
        </Flexbox>
      </Padding>
    </Card>
  );
}
