// @flow
import { Link } from 'react-router-dom';
import stylex from '@serpa-cloud/stylex';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';

import { Text, Flexbox, Card, Padding, Divider } from '../../shared';

import GithubLogo from '../../shared/images/github-logo.png';
import GithubLogoWhite from '../../shared/images/github-logo-white.png';
import GitlabLogo from '../../shared/images/gitlab-logo.png';

import useDevice from '../../shared/hooks/useDevice';

import type { CompilationElement$key } from './__generated__/CompilationElement.graphql';

type Props = {|
  +node: CompilationElement$key,
|};

const styles = stylex.create({
  repoAvatar: {
    width: 16,
    height: 16,
    borderRadius: 12,
  },
  titleContainer: {
    height: 16,
  },
});

const statusMapping: { [string]: { icon: string, color: string } } = {
  UNKNOWN: { color: '--neutral-color-600', icon: 'help' },
  QUEUED: { color: '--neutral-color-600', icon: '' },
  WORKING: { color: '--neutral-color-600', icon: '' },
  SUCCESS: { color: '--green-300', icon: 'check_circle' },
  FAILURE: { color: '--red-300', icon: 'error' },
  ERROR: { color: '--red-300', icon: 'error' },
  TIMEOUT: { color: '--red-300', icon: 'timelapse' },
  CANCELLED: { color: '--red-300', icon: 'stop_circle' },
};

export default function CompilationElement({ node }: Props): React$Node {
  const { screenSize } = useDevice();

  const userData = useLazyLoadQuery(
    graphql`
      query CompilationElementQuery {
        me {
          id
          uiTheme
        }
      }
    `,
    {},
    {
      fetchPolicy: 'store-or-network',
    },
  );

  const data = useFragment(
    graphql`
      fragment CompilationElement on Build {
        id
        createdAtFormatted
        buildStatus: status
        commitShaShort
        commitUrl
        gitBaseIsTag
        gitTag
        gitTagUrl
        commitDescription
        createdByAlias: createdBy {
          id
          name
        }
        app {
          id
          name
          appType
          namespace
          gitProvider
        }
        artifact {
          id
          commitShaShort
          commitUrl
          gitBaseIsTag
          gitTag
          gitTagUrl
          commitDescription
        }
      }
    `,
    node,
  );

  const { artifact, buildStatus } = data;

  const gitHubImage = {
    LIGHT: GithubLogo,
    DARK: GithubLogoWhite,
  };

  const gitTag = artifact?.gitTag || data?.gitTag;
  const commitShaShort = artifact?.commitShaShort || data?.commitShaShort;
  const commitDescription = artifact?.commitDescription || data?.commitDescription;
  const message = commitDescription ? ` - ${commitDescription}` : '';
  const gitBaseIsTag = artifact?.gitBaseIsTag || data?.gitBaseIsTag;
  const uidSha = gitTag ?? commitShaShort ?? '';
  const gitTagUrl = artifact?.gitTagUrl || data?.gitTagUrl;
  const commitUrl = artifact?.commitUrl || data?.commitUrl;
  const gitUrl = (gitBaseIsTag ? gitTagUrl : commitUrl) || '';

  return (
    <Card>
      <Padding horizontal={16} vertical={16} className={stylex(styles.topContainer)}>
        <Flexbox justifyContent="space-between">
          <Link to={`/app/apps/${data.app.id}/compilations/${data.id}`}>
            <Text
              type="s0b"
              color="--primary-color-1"
              id="buildElement.title"
              values={{ id: data.id.substring(6, 11) }}
            />
          </Link>
          <Flexbox columnGap={8}>
            <Flexbox columnGap={8} alignItems="center">
              <Text
                type="s0b"
                color={statusMapping[buildStatus ?? 'UNKNOW']?.color}
                id={`appCreator.buildStatus.${buildStatus ?? 'UNKNOW'}`}
              />
            </Flexbox>
          </Flexbox>
        </Flexbox>
      </Padding>
      <Divider />

      <Padding horizontal={16} top={16} bottom={24} columnGap={16}>
        <Flexbox
          columnGap={32}
          rowGap={16}
          flexDirection={screenSize === 'phone' ? 'column' : 'row'}
        >
          <Flexbox rowGap={16} flexDirection="column">
            <Flexbox alignItems="center" className={stylex(styles.titleContainer)}>
              <Text type="s0r" color="--neutral-color-600" id="microapp.createdTitle" />
            </Flexbox>
            <Text
              type="s0m"
              color="--neutral-color-600"
              id="microapp.createdBy"
              values={{ days: data?.createdAtFormatted, author: data?.createdByAlias?.name }}
            />
          </Flexbox>
          <Flexbox rowGap={16} flexDirection="column">
            <Flexbox columnGap={8}>
              <img
                alt="repo logo"
                src={
                  // eslint-disable-next-line no-nested-ternary
                  data?.app?.gitProvider === 'GITHUB'
                    ? gitHubImage[userData?.me?.uiTheme === 'LIGHT' ? 'LIGHT' : 'DARK']
                    : GitlabLogo
                }
                className={stylex(styles.repoAvatar)}
              />
              <Text type="s0r" id="microapp.gitRepo" />
            </Flexbox>
            {gitUrl && uidSha ? (
              <a target="_blank" rel="noreferrer" href={gitUrl}>
                <Text type="s0m" color="--primary-color-1">
                  {`${uidSha}${message}`}
                </Text>
              </a>
            ) : null}
          </Flexbox>
        </Flexbox>
      </Padding>
    </Card>
  );
}
