// @flow
import React, { useState } from 'react';
import stylex from '@serpa-cloud/stylex';

import Icon from './Icon';
import Text from './Text';
import Grid from './Grid';
import Padding from './Padding';
import Flexbox from './Flexbox';
import Checkbox from './Checkbox';
import LiteButton from './LiteButton';
import ContextualMenu from './ContextualMenu';
import InteractiveElement from './InteractiveElement';

import invariant from './utils/invariant';

import useDevice from './hooks/useDevice';

const styles = stylex.create({
  container: {
    position: 'relative',
  },
  content: {
    minWidth: 280,
    '@media (max-width: 680px)': {
      width: '100vw',
      maxWidth: '100%',
    },
  },
  row: {
    width: '100%',
    minHeight: 40,
    display: 'flex',
    cursor: 'pointer',
    borderRadius: 8,
    alignItems: 'center',
    transitionProperty: 'all',
    outline: 'none',
    transitionDuration: 'var(--fds-duration-short-in)',
    transitionTimingFunction: 'var(--fds-animation-fade-in)',
    ':hover': {
      backgroundColor: 'var(--neutral-color-300)',
      transitionDuration: 'var(--fds-duration-short-out)',
      transitionTimingFunction: 'var(--fds-animation-fade-out)',
    },
  },
  rowSelected: {
    backgroundColor: 'var(--neutral-color-300)',
    transitionDuration: 'var(--fds-duration-short-out)',
    transitionTimingFunction: 'var(--fds-animation-fade-out)',
  },
  rowContent: {
    width: '100%',
  },
  resultsContainer: {
    overflow: 'auto',
    maxHeight: 216,
    '@media (max-width: 680px)': {
      maxHeight: '36vh',
      minHeight: '25vh',
    },
    overscrollBehavior: 'contain',
  },
});

type CascaderOptionProps = {|
  +value: string,
  +label: React$Node,
  +selectable?: ?boolean,
  +children?: ?React$Node,
  +callback?: ?() => void,
  +placeholderLabel?: ?React$Node,
|};

// eslint-disable-next-line no-unused-vars
export function CascaderOption(_: CascaderOptionProps): React$Node {
  invariant(
    false,
    `A <CascaderOption> is only ever to be used as the child of <Cascader> element, ` +
      `never rendered directly. Please wrap your <CascaderOption> in a <Cascader>.`,
  );
}

type OptionObject = {|
  +value: string,
  +label: React$Node,
  +selectable?: ?boolean,
  +callback?: ?() => void,
  +placeholderLabel?: ?React$Node,
  +children?: ?Array<OptionObject>,
|};

export function createNodesFromChildren(children: React$Node): OptionObject[] {
  const nodes: OptionObject[] = [];

  React.Children.forEach(children, (element) => {
    if (React.isValidElement(element)) {
      if (element.type === React.Fragment) {
        nodes.push(...createNodesFromChildren(element.props.children));
      } else {
        invariant(
          element.type === CascaderOption,
          `[${
            typeof element.type === 'string' ? element.type : element.type.name
          }] is not a <CascaderOption> component. All component children of <Cascader> must be a <CascaderOption> or <React.Fragment>`,
        );

        const { props } = element;
        const node: OptionObject = {
          ...props,
          selectable: props.selectable ?? true,
          children: createNodesFromChildren(props.children),
        };
        nodes.push(node);
      }
    }
  });

  return nodes;
}

export function createNodesFromChildrenFlatten(children: React$Node): OptionObject[] {
  const nodes: OptionObject[] = [];

  React.Children.forEach(children, (element) => {
    if (React.isValidElement(element)) {
      if (element.type === React.Fragment) {
        nodes.push(...createNodesFromChildrenFlatten(element.props.children));
      } else {
        invariant(
          element.type === CascaderOption,
          `[${
            typeof element.type === 'string' ? element.type : element.type.name
          }] is not a <CascaderOption> component. All component children of <Cascader> must be a <CascaderOption> or <React.Fragment>`,
        );

        const { props } = element;
        const node: OptionObject = {
          ...props,
          selectable: props.selectable ?? true,
        };

        nodes.push(node);
        nodes.push(...createNodesFromChildrenFlatten(props.children));
      }
    }
  });

  return nodes;
}

type CascaderPayload = {| nodesIndex?: ?number, selectedId: string |};

type CascaderColumnProps = {|
  +selected?: ?string,
  +value: Array<string>,
  +nodes: Array<OptionObject>,
  +onChange: (Array<string>) => void,
  +showNextlevel: (CascaderPayload) => void,
|};

function CascaderColumn({
  nodes,
  value,
  selected,
  onChange,
  showNextlevel,
}: CascaderColumnProps): React$Node {
  return (
    <div className={stylex(styles.content)}>
      <Padding vertical={8} horizontal={8}>
        <Flexbox flexDirection="column" rowGap={8} className={stylex(styles.resultsContainer)}>
          {nodes.map((node, index) => {
            const isSelected = value.includes(node.value);

            return (
              <InteractiveElement
                key={node.value}
                className={stylex(
                  styles.row,
                  selected === node.value || isSelected ? styles.rowSelected : null,
                )}
                onClick={() => {
                  if (node?.children?.length) {
                    showNextlevel({ nodesIndex: index, selectedId: node.value });
                  } else if (node.selectable) {
                    showNextlevel({ nodesIndex: null, selectedId: node.value });
                    if (isSelected) {
                      onChange(value.filter((x) => x !== node.value));
                    } else onChange([node.value]);
                  }

                  if (node.callback) node.callback();
                }}
              >
                <Padding vertical={8} horizontal={8} className={stylex(styles.rowContent)}>
                  <Flexbox alignItems="center" justifyContent="space-between">
                    <Flexbox alignItems="center" columnGap={16}>
                      {node.selectable && (
                        <Checkbox
                          disableFocus
                          onChange={(_, e) => {
                            e.stopPropagation();
                            if (node.children && node.selectable) {
                              if (isSelected) {
                                onChange(value.filter((x) => x !== node.value));
                              } else onChange([node.value]);
                            }
                          }}
                          checked={isSelected}
                        />
                      )}
                      <div>
                        {typeof node.label === 'string' ? (
                          <Text type="s1r">{node.label}</Text>
                        ) : (
                          node.label
                        )}
                      </div>
                    </Flexbox>
                    {node?.children?.length ? (
                      <Icon icon="chevron_right" color="--primary-color-1" />
                    ) : null}
                  </Flexbox>
                </Padding>
              </InteractiveElement>
            );
          })}
        </Flexbox>
      </Padding>
    </div>
  );
}

CascaderColumn.defaultProps = {
  selected: null,
};

type Props = {|
  +value: Array<string>,
  +open: boolean,
  +onClose: () => void,
  +children: React$Node,
  +containerHeight?: ?number,
  +onChange: (Array<string>) => void,
  +forceInlineRender?: ?boolean,
|};

export default function Cascader({
  open,
  value,
  onClose,
  children,
  onChange,
  containerHeight,
  forceInlineRender,
}: Props): React$Node {
  const { width } = useDevice();
  const isMobile = width <= 680;

  const nodes = createNodesFromChildren(children);
  const [cols, setCols] = useState<number[]>([]);
  const [selected, setSelected] = useState([]);

  return (
    <div className={stylex(styles.container)}>
      <ContextualMenu
        anchor="LEFT"
        open={open}
        containerHeight={containerHeight ?? 0}
        onClose={onClose}
        className={stylex(styles.modal)}
        forceInlineRender={forceInlineRender}
      >
        <Grid
          columns={`repeat(${(isMobile ? 0 : cols.length) + 1}, ${isMobile ? '1fr' : '280px'})`}
          columnGap={0}
        >
          {!isMobile || !cols.length ? (
            <CascaderColumn
              nodes={nodes}
              value={value}
              onChange={onChange}
              selected={selected[0] ?? ''}
              showNextlevel={({ nodesIndex, selectedId }) => {
                setSelected((s) => {
                  const els = [...s];
                  els[0] = selectedId;
                  return els.slice(0, 1).filter(Boolean);
                });
                setCols((oldCols) => {
                  const els = [...oldCols];
                  els[0] = nodesIndex;
                  // $FlowIssue
                  return els.slice(0, 1).filter((n) => !!n || n === 0);
                });
              }}
            />
          ) : null}

          {cols.map((col, colIndex) => {
            if (isMobile && colIndex !== cols.length - 1) {
              return null;
            }

            let currentNodes: Array<OptionObject> = nodes;

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i <= colIndex; i++) {
              if (currentNodes?.[cols[i]]?.children) currentNodes = currentNodes[cols[i]].children;
            }

            if (currentNodes)
              return (
                <CascaderColumn
                  key={selected[colIndex]}
                  nodes={currentNodes}
                  value={value}
                  selected={selected[colIndex + 1]}
                  onChange={onChange}
                  showNextlevel={({ nodesIndex, selectedId }) => {
                    setSelected((s) => {
                      const els = [...s];
                      els[colIndex + 1] = selectedId;
                      return els.slice(0, colIndex + 2).filter(Boolean);
                    });
                    setCols((oldCols) => {
                      const els: Array<?number> = [...oldCols];
                      els[colIndex + 1] = nodesIndex;
                      // $FlowIssue
                      return els.slice(0, colIndex + 2).filter((n) => !!n || n === 0);
                    });
                  }}
                />
              );

            return null;
          })}
        </Grid>

        {isMobile && cols.length ? (
          <Padding horizontal={8} bottom={16}>
            <LiteButton
              intlId="back"
              icon="arrow_back"
              onClick={() => {
                if (cols.length === 0) {
                  onClose();
                } else {
                  setSelected((s) => {
                    const els = [...s];
                    return els.slice(0, els.length - 1).filter(Boolean);
                  });
                  setCols((oldCols) => {
                    const els = [...oldCols];
                    // $FlowIssue
                    return els.slice(0, els.length - 1).filter((n) => !!n || n === 0);
                  });
                }
              }}
            />
          </Padding>
        ) : null}
      </ContextualMenu>
    </div>
  );
}

Cascader.defaultProps = {
  containerHeight: 0,
  forceInlineRender: false,
};
