/* eslint-disable no-nested-ternary */
// @flow
import stylex from '@serpa-cloud/stylex';
import { Suspense, useCallback } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import {
  NavLink,
  Routes,
  Route,
  useMatch,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';

import Settings from '../Settings';
import Dashboard from '../Dashboard';

import { ReactComponent as Docker } from '../../MicroApps/MicroappsDetail/docker.svg';
import { ReactComponent as Gitlab } from '../../MicroApps/MicroappsDetail/gitlab.svg';
import { ReactComponent as Octomark } from '../../MicroApps/MicroappsDetail/octomark.svg';
import { ReactComponent as OctomarkDark } from '../../MicroApps/MicroappsDetail/octomark_white.svg';

import EnvironmentDetail from '../../Environments/EnvironmentDetail';

import {
  Text,
  Flexbox,
  Padding,
  TapIcon,
  ElementModal,
  LoaderForSuspense,
  useDevice,
} from '../../../shared';
import DiscountAvailableUntil from './DiscountAvailableUntil';

const styles = stylex.create({
  container: {
    minHeight: 'calc(100vh - var(--content-top-padding))',
  },
  tabsContainer: {
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
  },
  activeElement: {
    borderBottomColor: 'var(--primary-color-1)',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
  },
});

export default function DeploymentsDetail(): React$Node {
  const { width } = useDevice();
  const navigate = useNavigate();
  const location = useLocation();
  const matchMain = useMatch('/app/apps/:appId/deployments/:deploymentId/*');
  const match = useMatch('/app/apps/:appId/deployments/:deploymentId/:section/*');

  const section = match?.params?.section ?? '';
  const deploymentId = matchMain?.params?.deploymentId ?? '';

  const userData = useLazyLoadQuery(
    graphql`
      query DeploymentsDetailsUIQuery {
        me {
          id
          uiTheme
        }
      }
    `,
    {},
    {
      fetchPolicy: 'store-or-network',
    },
  );

  const data = useLazyLoadQuery(
    graphql`
      query DeploymentsDetailQuery($id: ID!) {
        node(id: $id) {
          id
          ... on Deployment {
            id
            name
            app {
              id
              name
              namespace
              appMethod
              gitProvider
            }
            environment {
              id
              name
            }
          }
        }
      }
    `,
    {
      id: deploymentId,
    },
    {
      fetchPolicy: 'store-and-network',
    },
  );

  const handleOnClose = useCallback(() => {
    navigate(`/app/apps/${data?.node?.app?.id ?? ''}/deployments`);
  }, [data?.node?.app?.id, navigate]);

  if (!section)
    return (
      <Navigate
        to={`/app/apps/${data?.node?.app?.id ?? ''}/deployments/${deploymentId}/dashboard`}
      />
    );

  if (!data?.node?.app) return null;

  const node = data?.node;
  const horizontalPadding = width <= 1000 ? (width <= 400 ? 0 : 16) : 24;

  return (
    <>
      <ElementModal
        id={data?.node?.id ?? 'DeploymentDetail'}
        header={
          <header>
            <Padding horizontal={width <= 1000 ? (width <= 400 ? 8 : 16) : 24} top={24} bottom={24}>
              <Flexbox alignItems="center" justifyContent="space-between">
                <Flexbox alignItems="center" columnGap={16}>
                  {node.app?.appMethod === 'GIT' && node?.app?.gitProvider === 'GITLAB' ? (
                    <Gitlab width={24} height={24} />
                  ) : null}
                  {node.app?.appMethod === 'GIT' &&
                  node.app?.gitProvider === 'GITHUB' &&
                  userData?.me?.uiTheme === 'LIGHT' ? (
                    <Octomark width={24} height={24} />
                  ) : null}

                  {node.app?.appMethod === 'GIT' &&
                  node.app?.gitProvider === 'GITHUB' &&
                  userData?.me?.uiTheme === 'DARK' ? (
                    <OctomarkDark width={24} height={24} />
                  ) : null}

                  {node?.app?.appMethod === 'DOCKER' ? <Docker width={24} height={24} /> : null}
                  <Text type="h5">{node?.name}</Text>
                </Flexbox>
                <TapIcon icon="close" iconColor="--primary-color-1" onClick={handleOnClose} />
              </Flexbox>
            </Padding>
            <Padding horizontal={horizontalPadding} className={stylex(styles.tabsContainer)}>
              <Flexbox columnGap={16}>
                <NavLink to={`dashboard${location.search}`} style={{ textDecoration: 'none' }}>
                  {/* $FlowIssue */}
                  {({ isActive }) => (
                    <Padding
                      top={12}
                      bottom={12}
                      horizontal={12}
                      className={stylex(isActive ? styles.activeElement : styles.inactiveElement)}
                    >
                      <Text
                        type="s0b"
                        color={isActive ? '--primary-color-1' : '--neutral-color-800'}
                        id="deployment.menuDashboard"
                      />
                    </Padding>
                  )}
                </NavLink>

                <NavLink to={`settings${location.search}`} style={{ textDecoration: 'none' }}>
                  {/* $FlowIssue */}
                  {({ isActive }) => (
                    <Padding
                      top={12}
                      bottom={12}
                      horizontal={12}
                      className={stylex(isActive ? styles.activeElement : styles.inactiveElement)}
                    >
                      <Text
                        type="s0b"
                        color={isActive ? '--primary-color-1' : '--neutral-color-800'}
                        id="deployment.menuSettings"
                      />
                    </Padding>
                  )}
                </NavLink>

                <NavLink
                  style={{ textDecoration: 'none' }}
                  to={`environment/${node?.environment?.id ?? ''}/domains${location.search}`}
                >
                  {/* $FlowIssue */}
                  {({ isActive }) => (
                    <Padding
                      top={12}
                      bottom={12}
                      horizontal={12}
                      className={stylex(isActive ? styles.activeElement : styles.inactiveElement)}
                    >
                      <Text
                        type="s0b"
                        color={isActive ? '--primary-color-1' : '--neutral-color-800'}
                        id="deployment.menuNetworking"
                      />
                    </Padding>
                  )}
                </NavLink>
              </Flexbox>
            </Padding>

            <Padding horizontal={horizontalPadding}>
              <DiscountAvailableUntil />
            </Padding>
          </header>
        }
      >
        <div className={stylex(styles.container)}>
          <Padding top={24} horizontal={horizontalPadding}>
            <Routes>
              <Route
                path="settings"
                element={
                  <Suspense fallback={<LoaderForSuspense />}>
                    <Settings />
                  </Suspense>
                }
              />
              <Route
                path="dashboard"
                element={
                  <Suspense fallback={<LoaderForSuspense />}>
                    <Dashboard deploymentId={deploymentId} />
                  </Suspense>
                }
              />
            </Routes>
          </Padding>
        </div>
      </ElementModal>
      <Routes>
        <Route
          path="environment/:envId/*"
          element={
            <Suspense fallback={<LoaderForSuspense spinnerSize={40} />}>
              <EnvironmentDetail />
            </Suspense>
          }
        />
      </Routes>
    </>
  );
}
