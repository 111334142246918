// @flow
import Lottie from 'lottie-react';
import { useCallback } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import Text from './Text';
import Flexbox from './Flexbox';
import LiteButton from './LiteButton';
import emptyStateAnimationData from './emptyStateAnimationDataWhite.json';
import emptyStateAnimationDataDark from './emptyStateAnimationDataDark.json';

const emptyImage = {
  LIGHT: emptyStateAnimationData,
  DARK: emptyStateAnimationDataDark,
};

export type Props = {|
  +textButton?: ?string,
  +callback?: ?() => void,
|};

export default function EmptyState({ textButton, callback }: Props): React$Node {
  const userData = useLazyLoadQuery(
    graphql`
      query EmptyStateQuery {
        me {
          id
          uiTheme
        }
      }
    `,
    {},
    {
      fetchPolicy: 'store-or-network',
    },
  );

  const onClick = useCallback(() => {
    if (callback) callback();
  }, [callback]);

  return (
    <Flexbox flexDirection="column" alignItems="center" rowGap={24}>
      <Lottie
        animationData={userData?.me?.uiTheme === 'LIGHT' ? emptyImage.LIGHT : emptyImage.DARK}
        style={{ width: 240 }}
        loop={false}
      />
      <Flexbox flexDirection="column" rowGap={16} alignItems="center">
        <Text type="bd" id="emptyState.emptyText" />
        {!!textButton && !!callback && (
          <LiteButton
            showShadow
            size="hero"
            onClick={onClick}
            intlId={textButton}
            textColor="--primary-color-1"
          />
        )}
      </Flexbox>
    </Flexbox>
  );
}

EmptyState.defaultProps = {
  callback: null,
  textButton: null,
};
