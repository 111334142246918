// @flow
import { Suspense } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import { Card, Flexbox, Padding, Spinner } from '../../../shared';

import DeploymentElement from '../DeploymentElement';

type Props = {|
  +deploymentId: string,
|};

export default function DeploymentResume({ deploymentId }: Props): React$Node {
  const data = useLazyLoadQuery(
    graphql`
      query DeploymentResumeQuery($id: ID!) {
        node(id: $id) {
          id
          ... on Deployment {
            id
            ...DeploymentElementData
          }
        }
      }
    `,
    {
      id: deploymentId,
    },
    {
      fetchPolicy: 'store-and-network',
    },
  );

  if (data.node)
    return (
      <Suspense
        fallback={
          <Card>
            <Padding vertical={40}>
              <Flexbox alignItems="center" justifyContent="center">
                <Spinner />
              </Flexbox>
            </Padding>
          </Card>
        }
      >
        <DeploymentElement node={data?.node} />
      </Suspense>
    );

  return null;
}
