/**
 * @generated SignedSource<<306456336281f313884d642243e26546>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
export type AppType = "CONTAINER" | "STATIC" | "%future added value";
export type BuildStatus = "UNKNOWN" | "QUEUED" | "WORKING" | "SUCCESS" | "FAILURE" | "ERROR" | "TIMEOUT" | "CANCELLED" | "%future added value";
export type GitProvider = "GITHUB" | "GITLAB" | "%future added value";
import type { FragmentType } from "relay-runtime";
declare export opaque type CompilationElement$fragmentType: FragmentType;
export type CompilationElement$data = {|
  +id: string,
  +createdAtFormatted: string,
  +buildStatus: BuildStatus,
  +commitShaShort: ?string,
  +commitUrl: ?string,
  +gitBaseIsTag: ?boolean,
  +gitTag: ?string,
  +gitTagUrl: ?string,
  +commitDescription: ?string,
  +createdByAlias: {|
    +id: string,
    +name: ?string,
  |},
  +app: {|
    +id: string,
    +name: string,
    +appType: AppType,
    +namespace: string,
    +gitProvider: ?GitProvider,
  |},
  +artifact: ?{|
    +id: string,
    +commitShaShort: string,
    +commitUrl: string,
    +gitBaseIsTag: boolean,
    +gitTag: ?string,
    +gitTagUrl: ?string,
    +commitDescription: string,
  |},
  +$fragmentType: CompilationElement$fragmentType,
|};
export type CompilationElement$key = {
  +$data?: CompilationElement$data,
  +$fragmentSpreads: CompilationElement$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commitShaShort",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commitUrl",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gitBaseIsTag",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gitTag",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gitTagUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commitDescription",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompilationElement",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAtFormatted",
      "storageKey": null
    },
    {
      "alias": "buildStatus",
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    {
      "alias": "createdByAlias",
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v7/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "App",
      "kind": "LinkedField",
      "name": "app",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "appType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "namespace",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gitProvider",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Artifact",
      "kind": "LinkedField",
      "name": "artifact",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Build",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "2052eecf1ef3151061fcc4169625a6fa";

module.exports = ((node/*: any*/)/*: Fragment<
  CompilationElement$fragmentType,
  CompilationElement$data,
>*/);
