// @flow

import stylex from '@serpa-cloud/stylex';
import { useTransition, useCallback, useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import { Text, Margin, Flexbox, Spinner } from '../../../../shared';

import { ReactComponent as Gitlab } from './gitlab.svg';
import { ReactComponent as Github } from './octomark.svg';
import { ReactComponent as GithubWhite } from './octomark_white.svg';

const styles = stylex.create({
  tabsContainer: {
    position: 'relative',
    display: 'flex',
    textAlignLast: 'center',
  },
  tabNav: {
    height: 24,
    padding: '1rem',
    textAlign: 'center',
    flexGrow: 1,
    cursor: 'pointer',
  },

  active: {
    borderBottom: '3px solid var(--primary-color-1)',
    zIndex: 2,
  },

  tabContainerLine: {
    zIndex: 1,
    position: 'absolute',
    insetBlockEnd: 0,
    blockSize: '3px',
    inlineSize: '100%',
    backgroundColor: 'var(--neutral-color-400)',
  },

  pending: {
    opacity: 0.4,
    position: 'relative',
    pointerEvents: 'none',
  },

  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

type ITabsConponent = {
  activeTab: number,
  children: React$Node,
  setCurrentTab: Function,
};

export default function TabsConponent({
  children,
  activeTab,
  setCurrentTab,
}: ITabsConponent): React$Node {
  const userData = useLazyLoadQuery(
    graphql`
      query TabsConponentQuery {
        me {
          id
          uiTheme
        }
      }
    `,
    {},
    {
      fetchPolicy: 'store-or-network',
    },
  );

  const [transitionPending, startTransition] = useTransition();
  const [visibleTab, setVisibleTab] = useState(activeTab);
  const onClick = useCallback(
    (tab) => {
      setVisibleTab(tab);

      startTransition(() => {
        setCurrentTab(tab);
      });
    },
    [setCurrentTab],
  );

  return (
    <div>
      <div className={stylex(styles.tabsContainer)}>
        <div
          role="button"
          aria-hidden="true"
          className={`${stylex(styles.tabNav)} ${visibleTab === 0 ? stylex(styles.active) : ''}`}
          onClick={() => onClick(0)}
        >
          <Flexbox flexDirection="row" justifyContent="center" alignItems="center" columnGap={8}>
            {userData?.me?.uiTheme === 'DARK' ? (
              <GithubWhite width={24} height={24} />
            ) : (
              <Github width={24} height={24} />
            )}
            <Text type="s2m" color="--neutral-color-500" id="appCreator.githubLabel" />
          </Flexbox>
        </div>
        <div
          role="button"
          aria-hidden="true"
          className={`${stylex(styles.tabNav)} ${visibleTab === 1 ? stylex(styles.active) : ''}`}
          onClick={() => onClick(1)}
        >
          <Flexbox flexDirection="row" justifyContent="center">
            <Margin right={8}>
              <Gitlab width={24} height={24} />
            </Margin>
            <Text type="s2m" color="--neutral-color-500" id="appCreator.gitlabLabel" />
          </Flexbox>
        </div>
        <div className={stylex(styles.tabContainerLine)} />
      </div>

      <>
        <div className={stylex(transitionPending ? styles.pending : null)}>{children}</div>
        {transitionPending ? (
          <div className={stylex(styles.overlay)}>
            <Spinner />
          </div>
        ) : null}
      </>
    </div>
  );
}
