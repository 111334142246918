// @flow
import { useIntl } from 'react-intl';
import stylex from '@serpa-cloud/stylex';
import { Suspense, useCallback, useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import { Card, Spinner, Padding, Flexbox, usePageView } from '../../../shared';

import LogsViewer from './LogsViewer';

const styles = stylex.create({
  mainContainer: {
    flex: 1,
  },
  selectContainer: {
    maxWidth: 560,
  },
  loaderContainer: {
    height: '100%',
  },
});

type Props = {
  deploymentId: string,
};

export default function Logs({ deploymentId }: Props): React$Node {
  const intl = useIntl();
  const [podIndex, setPodIndex] = useState(0);

  usePageView(intl.formatMessage({ id: 'pageView.deploymentsLogs' }));

  const data = useLazyLoadQuery(
    graphql`
      query LogsQuery($id: ID!) {
        podsByDeployment(deploymentId: $id) {
          id
          name
          status {
            phase
            conditions {
              type
              status
            }
          }
        }
      }
    `,
    {
      id: deploymentId ?? '',
    },
    {
      fetchPolicy: 'store-and-network',
    },
  );

  const pods = data?.podsByDeployment;
  const selectedPod = pods?.[podIndex];

  const handleOnChangePod = useCallback(
    (direction: 1 | -1) => {
      setPodIndex((pods.length + podIndex + direction) % pods.length);
    },
    [podIndex, pods.length],
  );

  return (
    <Padding vertical={24} className={stylex(styles.mainContainer)}>
      <Suspense
        fallback={
          <Card>
            <Padding vertical={40}>
              <Flexbox
                justifyContent="center"
                alignItems="center"
                className={stylex(styles.loaderContainer)}
              >
                <Spinner size={40} />
              </Flexbox>
            </Padding>
          </Card>
        }
      >
        <LogsViewer
          deploymentId={deploymentId}
          name={selectedPod?.name ?? ''}
          podIndex={selectedPod ? podIndex : -1}
          podsLenght={pods.length}
          onChangePod={handleOnChangePod}
        />
      </Suspense>
    </Padding>
  );
}
