/**
 * @generated SignedSource<<abd7a3763342254259195cfb391e2208>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
type Avatar$fragmentType = any;
export type Gender = "MALE" | "FEMALE" | "NONBINARY" | "TRANSGENDERMALE" | "TRANSGENDERFEMALE" | "QUEER" | "OTHER" | "%future added value";
export type Pronoun = "HE" | "SHE" | "THEY" | "OTHER" | "%future added value";
export type ProfileQuery$variables = {|
  id: string,
|};
export type ProfileQuery$data = {|
  +node: ?{|
    +id: string,
    +__typename: "User",
    +name?: ?string,
    +lastname?: ?string,
    +fullname?: ?string,
    +owned?: boolean,
    +username?: string,
    +gender?: ?Gender,
    +pronoun?: ?Pronoun,
    +description?: string,
    +media?: ?{|
      +$fragmentSpreads: Avatar$fragmentType,
    |},
    +cover?: ?{|
      +$fragmentSpreads: Avatar$fragmentType,
    |},
    +bluredCover?: ?{|
      +id: string,
      +url: string,
    |},
  |},
|};
export type ProfileQuery = {|
  variables: ProfileQuery$variables,
  response: ProfileQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullname",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "owned",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gender",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pronoun",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v12 = [
  {
    "kind": "Literal",
    "name": "height",
    "value": 352
  },
  {
    "kind": "Literal",
    "name": "width",
    "value": 352
  }
],
v13 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "Avatar"
  }
],
v14 = [
  {
    "kind": "Literal",
    "name": "height",
    "value": 480
  },
  {
    "kind": "Literal",
    "name": "width",
    "value": 2400
  }
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v16 = {
  "alias": "bluredCover",
  "args": [
    {
      "kind": "Literal",
      "name": "blur",
      "value": 2
    },
    {
      "kind": "Literal",
      "name": "height",
      "value": 15
    },
    {
      "kind": "Literal",
      "name": "width",
      "value": 30
    }
  ],
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "cover",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v15/*: any*/)
  ],
  "storageKey": "cover(blur:2,height:15,width:30)"
},
v17 = [
  (v2/*: any*/),
  (v15/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "alt",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "width",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "height",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "media",
                "plural": false,
                "selections": (v13/*: any*/),
                "storageKey": "media(height:352,width:352)"
              },
              {
                "alias": null,
                "args": (v14/*: any*/),
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "cover",
                "plural": false,
                "selections": (v13/*: any*/),
                "storageKey": "cover(height:480,width:2400)"
              },
              (v16/*: any*/)
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "media",
                "plural": false,
                "selections": (v17/*: any*/),
                "storageKey": "media(height:352,width:352)"
              },
              {
                "alias": null,
                "args": (v14/*: any*/),
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "cover",
                "plural": false,
                "selections": (v17/*: any*/),
                "storageKey": "cover(height:480,width:2400)"
              },
              (v16/*: any*/)
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2ea04ac2a6cd7f01aded1d252edab91d",
    "id": null,
    "metadata": {},
    "name": "ProfileQuery",
    "operationKind": "query",
    "text": "query ProfileQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    id\n    ... on User {\n      __typename\n      name\n      lastname\n      fullname\n      owned\n      username\n      gender\n      pronoun\n      description\n      media(width: 352, height: 352) {\n        ...Avatar\n        id\n      }\n      cover(width: 2400, height: 480) {\n        ...Avatar\n        id\n      }\n      bluredCover: cover(width: 30, height: 15, blur: 2) {\n        id\n        url\n      }\n    }\n  }\n}\n\nfragment Avatar on Image {\n  id\n  url\n  alt\n  width\n  height\n}\n"
  }
};
})();

(node/*: any*/).hash = "308d1d7b2cb01bbf2f7b5833169aead3";

module.exports = ((node/*: any*/)/*: Query<
  ProfileQuery$variables,
  ProfileQuery$data,
>*/);
