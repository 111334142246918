// @flow
import stylex from '@serpa-cloud/stylex';
import { useCallback, Suspense } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';

import useDevice from '../../shared/hooks/useDevice';

import {
  Icon,
  Text,
  Grid,
  Margin,
  Flexbox,
  Breadcrumb,
  EmptyState,
  LiteButton,
  ScrolledList,
  useFetchPolicy,
  usePageView,
  ServiceCardPlaceholder,
} from '../../shared';

// import Form from './Form';
import EnvironmentElement from './EnvironmentElement';

const styles = stylex.create({
  container: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 40,
    boxSizing: 'border-box',
    paddingTop: 32,
  },
});

export default function Environments(): React$Node {
  const { width } = useDevice();
  const intl = useIntl();
  usePageView(intl.formatMessage({ id: 'pageView.environment' }));
  const navigate = useNavigate();
  const baseFetchPolicy = useFetchPolicy();
  const match = useMatch('/app/environments/:section');

  const renderElement = useCallback(
    (node, key) => <EnvironmentElement node={node} key={key} />,
    [],
  );

  const fallbackElement = useCallback((key) => <ServiceCardPlaceholder key={key} />, []);

  return (
    <div>
      <Breadcrumb
        sections={[
          { link: '/app/environments', textId: 'home.environment', icon: 'dynamic_form' },
          match?.params?.section === 'create'
            ? {
                link: '/app/environments/create',
                textId: 'emptyState.environmentsTextButton',
                icon: 'dynamic_form',
              }
            : null,
        ].filter(Boolean)}
      />
      <div className={stylex(styles.container)}>
        <Suspense fallback={<div />}>
          <ScrolledList
            first={8}
            next={8}
            index="ENVIRONMENTS"
            fetchPolicy={baseFetchPolicy}
            renderElement={renderElement}
            queryFields={['name', 'runtime', 'host', 'selfDomain', 'domains', 'shortDescription']}
            fallbackElement={fallbackElement}
            header={
              <Margin bottom={40}>
                <Flexbox flexDirection="column" rowGap={24}>
                  <Flexbox alignItems="center" columnGap={8}>
                    <Icon
                      fill
                      icon="dynamic_form"
                      gradient="linear-gradient(265.7deg, var(--cyan-solid-color) -2.24%, var(--blue-solid-color) 102.98%)"
                    />
                    <Flexbox flexDirection="column">
                      <Text type="h5" component="h1" id="emptyState.environmentsTitle" />
                    </Flexbox>
                  </Flexbox>
                  <Text id="emptyState.environmentsText" type={width <= 815 ? 'bs' : 'bd'} />
                </Flexbox>

                <Margin top={24}>
                  <Flexbox flexDirection={width <= 815 ? 'column' : 'row'}>
                    <LiteButton
                      icon="add"
                      intlId="emptyState.environmentsTextButton"
                      onClick={() => navigate('/app/environments/create')}
                      showBorder
                      showShadow
                    />
                  </Flexbox>
                </Margin>
              </Margin>
            }
            emptyElement={
              <EmptyState
                textButton="emptyState.environmentsTextButton"
                callback={() => navigate('/app/environments/create')}
              />
            }
            container={
              <Grid
                columns={
                  (width <= 815 && '1fr') ||
                  (width > 815 && width <= 1080 && '1fr 1fr') ||
                  (width > 1080 && width <= 1320 && '1fr 1fr 1fr') ||
                  '1fr 1fr 1fr 1fr'
                }
                columnGap={16}
                rowGap={24}
              />
            }
          />
        </Suspense>
      </div>
    </div>
  );
}
