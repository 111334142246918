/* eslint-disable react/jsx-props-no-spreading */
// @flow
import { useIntl } from 'react-intl';
import { animated } from 'react-spring';
import stylex from '@serpa-cloud/stylex';
import { Suspense, useState, useCallback } from 'react';

import {
  Card,
  Icon,
  Text,
  Flexbox,
  Padding,
  Spinner,
  SideModal,
  InteractiveElement,
} from '../../../shared';

import useDevice from '../../../shared/hooks/useDevice';

import ConditionsEditor from './ConditionsEditor';

export type HTTPMatchRequestMatchType = 'EXACT' | 'PREFIX' | 'REGEX' | '%future added value';
export type HTTPMatchRequestParameter =
  | 'URI'
  | 'SCHEME'
  | 'METHOD'
  | 'AUTHORITY'
  | 'HEADERS'
  | 'QUERY_PARAMS'
  | '%future added value';

export type HTTPRoute$data = {
  +match: Array<{|
    parameter: HTTPMatchRequestParameter,
    parameterName: ?string,
    matchType: HTTPMatchRequestMatchType,
    matchValue: string,
  |}>,
  +route: Array<{|
    weight: number,
    destination: {|
      id: string,
      name: string,
    |},
  |}>,
};

type Props = {|
  +index: number,
  +target: string,
  +ruleNumber: number,
  +data: HTTPRoute$data,
  +bind: (index: number) => void,
  +onChange: ({ index: number, data: HTTPRoute$data | null }) => void,
|};

const styles = stylex.create({
  card: {
    height: '100%',
  },
  routeCard: {
    borderRadius: 3,
    height: '100%',
  },
  container: {
    height: '100%',
  },
  conditionContainer: {
    height: 38,
    borderRadius: 4,
    boxSizing: 'border-box',
    boxShadow: 'var(--shadow-1)',
    border: '1px solid var(--border-color)',
    backgroundColor: 'var(--neutral-color-100)',
    '@media (max-width: 815px)': {
      width: '100%',
    },
    '@media (min-width: 815px)': {
      width: '65%',
    },
  },
  destinationContainer: {
    height: 38,
    boxSizing: 'border-box',
    borderRadius: 4,
    boxShadow: 'var(--shadow-1)',
    border: '1px solid var(--border-color)',
    backgroundColor: 'var(--neutral-color-100)',
    '@media (max-width: 815px)': {
      width: '100%',
    },
    '@media (min-width: 815px)': {
      width: '30%',
    },
  },

  content: {
    flex: 1,
  },
  menu: {
    width: 24,
    height: 24,
    cursor: 'pointer',
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: 'var(--purple)',
  },
  strong: {
    color: 'var(--primary-font-color)',
  },

  pointer: {
    cursor: 'pointer',
  },
  loadingContainer: {
    width: '100%',
    height: '100%',
  },
  modifier: {
    cursor: 'pointer',
  },
  moveIcon: {
    cursor: 'grab',
    userSelect: 'none',
    ':active': {
      cursor: 'grabbing',
    },
  },
  optionButton: {
    borderRadius: 3,
  },
  optionButtonActive: {
    background: 'var(--neutral-color-300)',
  },
  defineRule: {
    borderRadius: 3,
    background: 'var(--neutral-color-100)',
    border: '1px solid var(--border-color)',
    '@media (max-width: 815px)': {
      height: 112,
    },
    '@media (min-width: 815px)': {
      height: 38,
    },
  },
});

export default function HTTPRoute({
  data,
  bind,
  index,
  target,
  onChange,
  ruleNumber,
}: Props): React$Node {
  const { screenSize } = useDevice();
  const intl = useIntl();
  const { match = [], route = [] } = data ?? {};

  const [editIsHover, setEditIsHover] = useState(false);
  const [deleteIsHover, setDeleteIsHover] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleOnChange = useCallback(
    ({ routes, conditions }) => {
      onChange({ index, data: { route: routes, match: conditions } });
    },
    [index, onChange],
  );

  const handleOnClose = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  const handleOnDelete = useCallback(() => {
    onChange({ index, data: null });
  }, [index, onChange]);

  const mainCondition = match[0];

  const { parameter, matchType, matchValue } = mainCondition;
  const conditionIsEmpty = !(matchValue && matchType && parameter);

  const { destination } = route[0];
  const { name: destinationName } = destination ?? {};

  const destinationIsEmpty = !destinationName;

  return (
    <>
      <SideModal open={modalIsOpen} onClose={handleOnClose}>
        <Suspense
          fallback={
            <Padding vertical={80}>
              <Flexbox
                className={stylex(styles.loadingContainer)}
                justifyContent="center"
                alignItems="center"
              >
                <Spinner size={48} />
              </Flexbox>
            </Padding>
          }
        >
          <ConditionsEditor
            target={target}
            conditions={match}
            onSave={handleOnChange}
            onClose={handleOnClose}
            destinationId={destination?.id ?? ''}
          />
        </Suspense>
      </SideModal>
      <Card className={stylex(styles.card)}>
        <div className={stylex(styles.routeCard)}>
          <Padding vertical={0} className={stylex(styles.container)}>
            <Flexbox alignItems="center" className={stylex(styles.container)}>
              <Padding left={8} right={8}>
                <animated.div {...bind(index)} className={stylex(styles.moveIcon)}>
                  <Icon icon="drag_indicator" color="--neutral-color-600" />
                </animated.div>
              </Padding>
              <Padding top={12} bottom={12} right={16} className={stylex(styles.content)}>
                <div className={stylex(styles.content)}>
                  <Flexbox flexDirection="column" rowGap={12} j>
                    <Flexbox justifyContent="space-between">
                      <Text
                        type="s0b"
                        id="routeRuleNumber"
                        color="--primary-color-1"
                        values={{ rule: ruleNumber + 1 }}
                      />
                      <Flexbox alignItems="center" columnGap={8}>
                        <InteractiveElement onClick={() => setModalIsOpen(true)}>
                          <div
                            onMouseEnter={() => setEditIsHover(true)}
                            onMouseLeave={() => setEditIsHover(false)}
                            className={stylex(
                              styles.optionButton,
                              editIsHover ? styles.optionButtonActive : null,
                            )}
                          >
                            <Padding vertical={4} horizontal={4}>
                              <Icon
                                size={20}
                                icon="edit"
                                color={editIsHover ? '--primary-color-1' : '--neutral-color-400'}
                              />
                            </Padding>
                          </div>
                        </InteractiveElement>

                        <InteractiveElement onClick={handleOnDelete}>
                          <div
                            onMouseEnter={() => setDeleteIsHover(true)}
                            onMouseLeave={() => setDeleteIsHover(false)}
                            className={stylex(
                              styles.optionButton,
                              deleteIsHover ? styles.optionButtonActive : null,
                            )}
                          >
                            <Padding vertical={4} horizontal={4}>
                              <Icon
                                size={20}
                                icon="delete"
                                color={deleteIsHover ? '--red-300' : '--neutral-color-400'}
                              />
                            </Padding>
                          </div>
                        </InteractiveElement>
                      </Flexbox>
                    </Flexbox>

                    <Padding top={4} bottom={4}>
                      {conditionIsEmpty && destinationIsEmpty ? (
                        <InteractiveElement onClick={() => setModalIsOpen(true)}>
                          <Flexbox
                            columnGap={8}
                            alignItems="center"
                            justifyContent="center"
                            className={stylex(styles.defineRule)}
                          >
                            <Icon icon="tune" size={16} opticalSize={20} weight={300} />
                            <Text
                              id="router.defineRouterRule"
                              type="s0m"
                              color="--neutral-color-600"
                            />
                          </Flexbox>
                        </InteractiveElement>
                      ) : (
                        <Flexbox
                          justifyContent="space-between"
                          alignItems="center"
                          rowGap={8}
                          flexDirection={screenSize === 'phone' ? 'column' : 'row'}
                        >
                          <Padding
                            vertical={12}
                            horizontal={16}
                            className={stylex(styles.conditionContainer)}
                          >
                            {conditionIsEmpty ? (
                              <div>
                                <Text type="s0r" id="router.undefinedCondition" />
                              </div>
                            ) : (
                              <Flexbox
                                justifyContent={screenSize === 'phone' ? 'center' : 'flex-start'}
                              >
                                <Text type="s0r" color="--neutral-color-800">
                                  <span>{intl.formatMessage({ id: 'router.if' })}</span>
                                  <span>
                                    <i>
                                      <strong className={stylex(styles.strong)}>
                                        {intl.formatMessage({
                                          id: `router.parameterLabel.${parameter}`,
                                        })}
                                      </strong>
                                    </i>
                                  </span>
                                  {mainCondition.parameterName ? (
                                    <span>
                                      <strong className={stylex(styles.strong)}>
                                        {`${mainCondition?.parameterName ?? ''} `}
                                      </strong>
                                    </span>
                                  ) : null}
                                  <span>
                                    {intl.formatMessage({
                                      id: `router.relationLabel.${matchType}`,
                                    })}
                                  </span>
                                  <span>
                                    <strong className={stylex(styles.strong)}>{matchValue}</strong>
                                  </span>
                                </Text>
                              </Flexbox>
                            )}
                          </Padding>
                          <Padding>
                            <Icon icon="arrow_right_alt" size={20} />
                          </Padding>
                          <Padding
                            vertical={12}
                            horizontal={16}
                            className={stylex(
                              styles.destinationContainer,
                              screenSize === 'phone' ? { width: '100%' } : null,
                            )}
                          >
                            <Flexbox justifyContent="center">
                              {destinationIsEmpty ? (
                                <div>
                                  <Text type="s0r" id="router.undefinedDestination" />
                                </div>
                              ) : (
                                <Text type="s0b" color="--neutral-color-800">
                                  {destinationName}
                                </Text>
                              )}
                            </Flexbox>
                          </Padding>
                        </Flexbox>
                      )}
                    </Padding>
                  </Flexbox>
                </div>
              </Padding>
            </Flexbox>
          </Padding>
        </div>
      </Card>
    </>
  );
}
