// @flow

import { Suspense } from 'react';
import stylex from '@serpa-cloud/stylex';

import { Card, Padding, Flexbox, Spinner } from '../../../shared';

import DeploymentSettings from './DeploymentSettings';
import DeploymentDangerZone from './DeploymentDangerZone';

const styles = stylex.create({
  body: {
    position: 'relative',
    boxSizing: 'border-box',
  },
  compilationPlaceholder: {
    minHeight: 360,
  },
  compilationPlaceholderContent: {
    flex: 1,
  },
});

export default function Settings(): React$Node {
  return (
    <Padding bottom={40} className={stylex(styles.body)}>
      <Flexbox flexDirection="column" rowGap={64}>
        <Suspense
          fallback={
            <Card>
              <Padding vertical={80}>
                <Flexbox justifyContent="center" alignItems="center">
                  <Spinner size={24} />
                </Flexbox>
              </Padding>
            </Card>
          }
        >
          <DeploymentSettings />
        </Suspense>

        <Card>
          <DeploymentDangerZone />
        </Card>
      </Flexbox>
    </Padding>
  );
}
