// @flow
import stylex from '@serpa-cloud/stylex';
import { useEffect, useRef } from 'react';
import { useTransition, animated, config } from 'react-spring';

import Card from './Card';

type Props = {|
  +open: boolean,
  +onClose: () => void,
  +className?: ?string,
  +children: React$Node,
|};

const styles = stylex.create({
  container: {
    padding: 0,
    border: 'none',
    outline: 'none',
    '::backdrop': {
      opacity: 0.8,
      backgroundColor: 'var(--neutral-color-800)',
    },
    '@media (max-width: 840px)': {
      margin: 0,
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      maxHeight: '100vh',
    },
  },
  card: {
    borderRadius: 0,
    '@media (max-width: 840px)': {
      width: '100vw',
      height: '100vh',
    },
    '@media (min-width: 840px)': {
      width: 720,
      maxHeight: 'calc(100vh - 40px)',
    },
  },
});

export default function SideModal({ open, onClose, children, className }: Props): React$Node {
  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.stiff,
  });

  const ref = useRef<?HTMLDialogElement>();

  useEffect(() => {
    if (open) ref.current?.showModal();
    else ref.current?.close();
  }, [open]);

  return (
    // $FlowIssue
    <dialog className={stylex(styles.container)} ref={ref} onClose={onClose}>
      {transitions(
        (animatedStyles, item) =>
          item && (
            <Card className={`${className ?? ''} ${stylex(styles.card)}`}>
              <animated.div style={{ ...animatedStyles }}>{children}</animated.div>
            </Card>
          ),
      )}
    </dialog>
  );
}

SideModal.defaultProps = {
  className: '',
};
