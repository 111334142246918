// @flow

// Libs
import stylex from '@serpa-cloud/stylex';

// Components
import Text from './Text';
import Icon from './Icon';
import Margin from './Margin';
import TapIcon from './TapIcon';
import Flexbox from './Flexbox';

const styles = stylex.create({
  container: {
    position: 'relative',
    backgroundColor: 'var(--neutral-color-200)',
    borderRadius: 24,
    height: 240,
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
    zIndex: 10,
  },
  content: {
    height: '100%',
    width: '100%',
  },
  inputFile: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    height: '100%',
    width: '100%',
    cursor: 'pointer',
  },
  previewPhoto: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: 24,
    border: '1px solid var(--neutral-color-300)',
  },
});

type Props = {
  onClose: Function,
  onUploadImage: Function,
  previewUrl: ArrayBuffer | string | null,
};

export default function CardImageUploader({
  onClose,
  onUploadImage,
  previewUrl,
}: Props): React$Node {
  return (
    <Flexbox justifyContent="center" alignItems="center" className={stylex(styles.container)}>
      <input
        type="file"
        className={stylex(styles.inputFile)}
        accept="image/*"
        name="upload"
        onChange={onUploadImage}
      />
      <div className={stylex(styles.closeButton)}>
        <TapIcon icon="delete" label="Cerrar" onClick={onClose} />
      </div>
      <Flexbox
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        className={stylex(styles.content)}
      >
        {previewUrl ? (
          <img alt="" className={stylex(styles.previewPhoto)} src={previewUrl} />
        ) : (
          <>
            <Margin bottom={16}>
              <Icon icon="file_upload" size={32} fill />
            </Margin>
            <Flexbox flexDirection="column" alignItems="center" rowGap={8}>
              <Text type="s1b" id="imageUploader.addPhoto" />
              <Text type="s0r" id="imageUploader.dragAndDrop" />
            </Flexbox>
          </>
        )}
      </Flexbox>
    </Flexbox>
  );
}
