/**
 * @generated SignedSource<<7539e692adc7216dc5d6d2dbb445942d>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type UITheme = "DARK" | "LIGHT" | "%future added value";
export type ContextualMenuQuery$variables = {||};
export type ContextualMenuQuery$data = {|
  +me: ?{|
    +id: string,
    +uiTheme: ?UITheme,
  |},
|};
export type ContextualMenuQuery = {|
  variables: ContextualMenuQuery$variables,
  response: ContextualMenuQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uiTheme",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ContextualMenuQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ContextualMenuQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "3dd6ddbcdd3f19f81bd8312ab7b5b086",
    "id": null,
    "metadata": {},
    "name": "ContextualMenuQuery",
    "operationKind": "query",
    "text": "query ContextualMenuQuery {\n  me {\n    id\n    uiTheme\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "378e63e5a3061d41922adc58a8a00665";

module.exports = ((node/*: any*/)/*: Query<
  ContextualMenuQuery$variables,
  ContextualMenuQuery$data,
>*/);
