/* eslint-disable no-nested-ternary */
// @flow
import stylex from '@serpa-cloud/stylex';
import { Suspense, useCallback } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import {
  NavLink,
  Routes,
  Route,
  Navigate,
  useMatch,
  useNavigate,
  useLocation,
} from 'react-router-dom';

import { TapIcon, Flexbox, Padding, Text, ElementModal, LoaderForSuspense } from '../../shared';

import DNS from './DNS';
import Networking from './Networking';

import useDevice from '../../shared/hooks/useDevice';

const styles = stylex.create({
  tabsContainer: {
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
  },
  navLink: {
    flexShrink: 0,
  },
  navElement: {
    height: 44,
    boxSizing: 'border-box',
  },
  activeElement: {
    borderBottomColor: 'var(--primary-color-1)',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
  },
});

export default function EnvironmentDetail(): React$Node {
  const { width } = useDevice();
  const location = useLocation();
  const navigate = useNavigate();
  const mainMatch = useMatch('/app/apps/:appId/deployments/:deploymentId/environment/:envId/*');
  const match = useMatch(
    '/app/apps/:appId/deployments/:deploymentId/environment/:envId/:section/*',
  );

  const envId = mainMatch?.params?.envId ?? '';
  const section = match?.params?.section ?? '';

  const data = useLazyLoadQuery(
    graphql`
      query EnvironmentDetailQuery($id: ID!) {
        node(id: $id) {
          id
          ... on Environment {
            id
            name
            namespace
            ...DNS
            ...Networking
          }
        }
      }
    `,
    {
      id: envId,
    },
  );

  const handleOnClose = useCallback(() => {
    navigate(
      `/app/apps/${match?.params?.appId ?? ''}/deployments/${match?.params?.deploymentId ?? ''}${
        location.search
      }`,
    );
  }, [location.search, match?.params?.appId, match?.params?.deploymentId, navigate]);

  if (!data.node)
    return (
      <Navigate
        replace
        to={`/app/apps/${match?.params?.appId ?? ''}/deployments/${match?.params?.deploymentId ??
          ''}`}
      />
    );
  if (!section)
    return (
      <Navigate
        replace
        to={`/app/apps/${match?.params?.appId ?? ''}/deployments/${match?.params?.deploymentId ??
          ''}/environment/${envId}/domains`}
      />
    );

  const node = data?.node;
  const horizontalPadding = width <= 1000 ? (width <= 400 ? 0 : 16) : 24;

  return (
    <ElementModal
      id={data?.node?.id ?? 'EnvironmentDetail'}
      header={
        <header>
          <Padding horizontal={width <= 1000 ? (width <= 400 ? 8 : 16) : 24} top={24} bottom={24}>
            <Flexbox alignItems="center" justifyContent="space-between">
              <Text type="h5">{node?.name}</Text>

              <TapIcon icon="close" iconColor="--primary-color-1" onClick={handleOnClose} />
            </Flexbox>
          </Padding>
          <Padding horizontal={horizontalPadding} className={stylex(styles.tabsContainer)}>
            <Flexbox columnGap={16}>
              <NavLink
                to="domains"
                style={{ textDecoration: 'none' }}
                className={stylex(styles.navLink)}
              >
                {/* $FlowIssue */}
                {({ isActive }) => (
                  <Padding
                    top={12}
                    bottom={12}
                    horizontal={12}
                    className={stylex(
                      styles.navElement,
                      isActive ? styles.activeElement : styles.inactiveElement,
                    )}
                  >
                    <Text
                      type="s0b"
                      id="environment.dns"
                      color={isActive ? '--primary-color-1' : '--neutral-color-800'}
                    />
                  </Padding>
                )}
              </NavLink>
              <NavLink
                to="router"
                style={{ textDecoration: 'none' }}
                className={stylex(styles.navLink)}
              >
                {/* $FlowIssue */}
                {({ isActive }) => (
                  <Padding
                    top={12}
                    bottom={12}
                    horizontal={12}
                    className={stylex(
                      styles.navElement,
                      isActive ? styles.activeElement : styles.inactiveElement,
                    )}
                  >
                    <Text
                      type="s0b"
                      id="environment.menuRouter"
                      color={isActive ? '--primary-color-1' : '--neutral-color-800'}
                    />
                  </Padding>
                )}
              </NavLink>
            </Flexbox>
          </Padding>
        </header>
      }
    >
      <Padding top={24} horizontal={horizontalPadding} bottom={80}>
        <Routes>
          <Route
            path="domains/*"
            element={
              <Suspense fallback={<LoaderForSuspense />}>
                <DNS node={data?.node} />
              </Suspense>
            }
          />

          <Route
            path="router"
            element={
              <Suspense fallback={<LoaderForSuspense />}>
                <Networking node={data?.node} />
              </Suspense>
            }
          />
        </Routes>
      </Padding>
    </ElementModal>
  );
}
