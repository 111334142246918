// @flow
import { useIntl } from 'react-intl';
import { createPortal } from 'react-dom';
import stylex from '@serpa-cloud/stylex';
import * as amplitude from '@amplitude/analytics-browser';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay';
import { useState, useContext, useRef, useCallback, Suspense, lazy } from 'react';

import {
  Icon,
  Text,
  Input,
  Card,
  Button,
  Flexbox,
  Padding,
  useInput,
  Checkbox,
  RadioButton,
  validateData,
  CascaderOption,
  EnvironmentVariablesForm,
  useNotification,
} from '../../../shared';

import awsLogo from './icon_aws.png';
import gcloudLogo from './icon_gcloud.png';

import { ReactComponent as Logo } from './sierra-negra.svg';

import { Context } from '../Provider';

import useDevice from '../../../shared/hooks/useDevice';

import type { DeploymentConfiguratorMutation } from './__generated__/DeploymentConfiguratorMutation.graphql';
import type { DeploymentConfiguratorUpdateMutation } from './__generated__/DeploymentConfiguratorUpdateMutation.graphql';

const DeployStatusModal = lazy(() => import('./DeploymentStatusModal'));

const styles = stylex.create({
  mainContainer: {
    maxWidth: 600,
  },
  appInput: {
    flex: 1,
  },
  providerContainer: {
    width: 24,
    height: 24,
    flexShrink: 0,
  },
  logoProvider: {
    flexShrink: 0,
  },
  labelNoWrap: {
    whiteSpace: 'nowrap',
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dockerLabelContainer: {
    height: 32,
  },
});

type Props = {|
  +editable?: ?boolean,
  +onUpdate?: ?() => void,
  +deploymentId?: ?string,
  +callToActionLabel?: ?string,
  +disableEnvironment?: ?boolean,
  +disableDeploymentName?: ?boolean,
  +avoidConvertToPrivate?: ?boolean,
|};

export default function DeploymentConfigurator({
  editable,
  onUpdate,
  deploymentId,
  callToActionLabel,
  disableEnvironment,
  disableDeploymentName,
  avoidConvertToPrivate,
}: Props): React$Node {
  const [, , setNotification] = useNotification();
  const intl = useIntl();
  const { screenSize, width } = useDevice();

  const envsRef = useRef<any>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const enableDeploymentSelector = searchParams.get('enable_deployment');
  const { state, dispatch } = useContext(Context);
  const [enableCors, setEnableCors] = useState(state?.deploymentConfig?.enableCors ?? false);

  const [deploy, deployPending] = useMutation<DeploymentConfiguratorMutation>(graphql`
    mutation DeploymentConfiguratorMutation($input: DeploymentInputFlow!) {
      createDeploymentFlow(input: $input) {
        id
        port
        app {
          id
          name
          namespace
          appMethod
        }
        dockerImageUrl
        name
        replicas
        namespace
        serviceName
        publicEndpoint
        publicVirtualService
        createdBy {
          id
          fullname
        }
        environment {
          id
          name
        }
        createdAtFormatted
        continousDeployment
        ...DeploymentElementData
      }
    }
  `);

  const [
    updateDeploy,
    updateDeployPending,
  ] = useMutation<DeploymentConfiguratorUpdateMutation>(graphql`
    mutation DeploymentConfiguratorUpdateMutation($input: PatchDeploymentInput!) {
      updateDeployment(input: $input) {
        id
        port
        name
        namespace
        dockerImageUrl
        app {
          id
          name
          appMethod
          namespace
        }
        environment {
          id
          name
        }
        replicas
        serviceName
        publicEndpoint
        publicVirtualService
        createdBy {
          id
          fullname
        }
        createdAtFormatted
        continousDeployment
        ...DeploymentElementData
      }
    }
  `);

  const data = useLazyLoadQuery(
    graphql`
      query DeploymentConfiguratorQuery(
        $first: Int
        $after: Cursor
        $appId: String!
        $sort: SortInput
        $index: ElasticIndex
        $skipGit: Boolean!
        $skipArtifacts: Boolean!
        $sortArtifacts: SortInput
        $filterMatrix: [[FilterInput]]
        $filterMatrixArtifacts: [[FilterInput]]
      ) {
        gitBranchesByApp(appId: $appId) @skip(if: $skipGit) {
          id
          name
          sha
        }
        gitTagsByApp(appId: $appId) @skip(if: $skipGit) {
          id
          name
          sha
        }
        entities(
          sort: $sort
          first: $first
          after: $after
          index: $index
          filterMatrix: $filterMatrix
        ) {
          pageInfo {
            hasNextPage
            endCursor
            finalCursor
          }
          edges {
            id
            cursor
            node {
              __typename
              ... on Environment {
                id
                name
                namespace
                kubernetesCluster {
                  id
                  provider
                }
              }
            }
          }
        }
        artifactsEntities: entities(
          sort: $sortArtifacts
          first: 200
          index: ARTIFACTS
          filterMatrix: $filterMatrixArtifacts
        ) @skip(if: $skipArtifacts) {
          pageInfo {
            hasNextPage
            endCursor
            finalCursor
          }
          edges {
            id
            cursor
            node {
              __typename
              ... on Artifact {
                id
                dockerName
                createdAtFormatted
              }
            }
          }
        }
      }
    `,
    {
      sortArtifacts: {
        value: 'desc',
        property: 'createdAt',
      },
      filterMatrixArtifacts: [
        [{ property: 'app.keyword', type: 'term', value: state?.appId ?? '' }],
      ],
      first: 99,
      index: 'ENVIRONMENTS',
      appId: state?.appId ?? '',
      skipGit: state.method === 'DOCKER',
      skipArtifacts: (!editable && !enableDeploymentSelector) || state.method === 'DOCKER',
      filterMatrix: [[{ property: 'defaultEnvironment', type: 'term', valueBoolean: false }]],
    },
    { fetchPolicy: 'network-only', networkCacheConfig: { force: false } },
  );

  const [privacy, setPrivacy] = useState(
    state?.deploymentConfig?.privacy === 'PRIVATE' ? 'Private' : 'Public',
  );

  const deploymentName = useInput({
    name: 'deploymentName',
    required: true,
    toLowerCase: true,
    disabled: disableDeploymentName ?? false,
    // eslint-disable-next-line no-useless-escape
    regexpOverwrite: /^[a-z0-9\-]*/,
    label: intl.formatMessage({ id: 'appCreator.deploymentName' }),
    value: state?.deploymentConfig?.name ?? `${state.appName}-default`,
    errors: {
      requiredError: intl.formatMessage({ id: 'input.requiredError' }),
    },
  });

  const dockerImageUrl = useInput({
    name: 'dockerImageUrl',
    required: state.method === 'DOCKER',
    toLowerCase: true,
    label: intl.formatMessage({ id: 'appCreator.dockerImageUrl' }),
    value: state?.deploymentConfig?.dockerImageUrl ?? '',
    errors: {
      requiredError: intl.formatMessage({ id: 'input.requiredError' }),
    },
  });

  const source = useInput({
    name: 'source',
    required: (!!editable || !!enableDeploymentSelector) && state.method === 'GIT',
    label: intl.formatMessage({ id: 'appCreator.artifactSource' }),
    value: state?.deploymentConfig?.source ?? '',
    hideSearch: true,
    replacer(v) {
      if (!v) return source.input.value;
      return v;
    },
    autoCloseSelect: true,
    errors: {
      requiredError: intl.formatMessage({ id: 'input.requiredError' }),
    },
  });

  const environment = useInput({
    name: 'environment',
    required: true,
    disabled: disableEnvironment ?? false,
    label: intl.formatMessage({ id: 'appCreator.environment' }),
    value: state?.deploymentConfig?.environment ?? 'DEFAULT',
    autoCloseSelect: true,
  });

  const allowOrigin = useInput({
    name: 'allowOrigin',
    required: enableCors,
    toLowerCase: true,
    label: 'Allow Origin',
    value: state?.deploymentConfig?.allowOrigin?.join(', ') ?? '*',
    disabled: !enableCors,
    errors: {
      requiredError: intl.formatMessage({ id: 'input.requiredError' }),
    },
  });

  const allowHeaders = useInput({
    name: 'allowHeaders',
    required: enableCors,
    label: 'Allow Headers',
    value: state?.deploymentConfig?.allowHeaders?.join(', ') ?? 'content-type, accept-type',
    disabled: !enableCors,
    errors: {
      requiredError: intl.formatMessage({ id: 'input.requiredError' }),
    },
  });

  const allowMethods = useInput({
    name: 'allowMethods',
    required: enableCors,
    toUpperCase: true,
    label: 'Allow Methods',
    value: state?.deploymentConfig?.allowMethods?.join(', ') ?? 'OPTIONS, GET',
    disabled: !enableCors,
    errors: {
      requiredError: intl.formatMessage({ id: 'input.requiredError' }),
    },
  });

  const defaultSource = state?.gitRef ?? '';
  const isTag = defaultSource.includes('refs/tags');
  const branchValue = defaultSource.replace('refs/heads/', '');

  const continousDeployment = useInput({
    name: 'continousDeployment',
    required: state?.method !== 'DOCKER',
    label: intl.formatMessage({ id: 'appCreator.deploymentConfiguratorCDLabel' }),
    value: state?.deploymentConfig?.continousDeployment ?? (isTag ? 'TAG' : branchValue),
    autoCloseSelect: true,
    errors: {
      requiredError: intl.formatMessage({ id: 'input.requiredError' }),
    },
  });

  const continousDeploymentIsTag = continousDeployment.input.value === 'TAG';

  const patternTag = useInput({
    name: 'patternTag',
    required: continousDeploymentIsTag && state?.method !== 'DOCKER',
    disabled: !continousDeploymentIsTag,
    label: intl.formatMessage({ id: 'appCreator.deploymentConfiguratorCDTag' }),
    value: state?.deploymentConfig?.patternTag ?? '/.*/',
    errors: {
      requiredError: intl.formatMessage({ id: 'input.requiredError' }),
    },
  });

  const replicas = useInput({
    name: 'replicas',
    label: intl.formatMessage({ id: 'appCreator.replica' }),
    value: state?.deploymentConfig?.replicas?.toString() ?? '1',
    required: true,
    replacer(value) {
      return value.replace(/[^0-9]/g, '');
    },
    errors: {
      requiredError: intl.formatMessage({ id: 'input.requiredError' }),
    },
  });

  const port = useInput({
    name: 'port',
    label: intl.formatMessage({ id: 'appCreator.port' }),
    value: state?.deploymentConfig?.port?.toString() ?? '',
    required: true,
    replacer(value) {
      return value.replace(/[^0-9]/g, '');
    },
    errors: {
      requiredError: intl.formatMessage({ id: 'input.requiredError' }),
    },
  });

  const handleOnSubmit = useCallback(() => {
    const envs = envsRef?.current?.submit?.();
    const envsData = (envs ?? []).map((el) => el.data);
    const envsError = (envs ?? []).reduce((old, el) => [...old, ...(el?.errors ?? [])], []);
    const { data: formData, errors } = validateData<{
      port: number,
      source: string,
      replicas: number,
      patternTag: string,
      environment: string,
      allowOrigin: string,
      allowHeaders: string,
      allowMethods: string,
      deploymentName: string,
      dockerImageUrl: string,
      continousDeployment: string,
    }>([
      port,
      source,
      replicas,
      patternTag,
      environment,
      dockerImageUrl,
      deploymentName,
      allowOrigin,
      allowHeaders,
      allowMethods,
      continousDeployment,
    ]);

    dispatch({
      type: 'SET_DEPLOYMENT_CONFIG',
      deploymentConfig: {
        port: Number(formData?.port ?? 80).toString(),
        replicas: formData.replicas,
        name: formData.deploymentName,
        patternTag: formData.patternTag,
        dockerImageUrl: formData.dockerImageUrl,
        continousDeployment: formData.continousDeployment,
        environment: formData.environment,
        privacy: privacy === 'Public' ? 'PUBLIC' : 'PRIVATE',
        enableCors,
        allowOrigin: formData?.allowOrigin?.split(',').map((e) => e.trim()) ?? [],
        allowHeaders: formData?.allowHeaders?.split(',').map((e) => e.trim()) ?? [],
        allowMethods: formData?.allowMethods?.split(',').map((e) => e.trim()) ?? [],
        env: envsData,
      },
    });

    if (!(errors?.length || envsError?.length)) {
      if (editable && deploymentId) {
        amplitude.track('Set Update Deployment');

        updateDeploy({
          variables: {
            input: {
              id: deploymentId,
              env: envsData,
              port: Number(formData?.port),
              source: formData?.source,
              // postStartExecCommand: [],
              replicas: Number(formData.replicas),
              // volumeMounts: [],
              // volumes: [],
              dockerImageUrl: formData?.dockerImageUrl ?? null,
              privacy: privacy === 'Public' ? 'PUBLIC' : 'PRIVATE',
              enableCors,
              allowOrigin: formData?.allowOrigin?.split(',').map((e) => e.trim()) ?? [],
              allowHeaders: formData?.allowHeaders?.split(',').map((e) => e.trim()) ?? [],
              allowMethods: formData?.allowMethods?.split(',').map((e) => e.trim()) ?? [],
              continousDeployment:
                state.method === 'DOCKER' ? 'MANUAL' : formData.continousDeployment,
              patternTag: formData.patternTag,
            },
          },
          onCompleted(response) {
            if (response.updateDeployment) {
              amplitude.track('Deployment updated');

              if (onUpdate) onUpdate();
            } else {
              amplitude.track('Deployment update error');
            }
          },
        });
      } else {
        amplitude.track('Set Create Deployment');

        deploy({
          variables: {
            input: {
              port: Number(formData?.port ?? 80),
              app: state?.appId ?? '',
              build: formData?.source,
              name: formData.deploymentName,
              environment: formData.environment,
              env: envsData,
              postStartExecCommand: [],
              replicas: Number(formData.replicas),
              volumeMounts: [],
              volumes: [],
              privacy: privacy === 'Public' ? 'PUBLIC' : 'PRIVATE',
              enableCors,
              dockerImageUrl: formData?.dockerImageUrl ?? null,
              allowOrigin: formData?.allowOrigin?.split(',').map((e) => e.trim()) ?? [],
              allowHeaders: formData?.allowHeaders?.split(',').map((e) => e.trim()) ?? [],
              allowMethods: formData?.allowMethods?.split(',').map((e) => e.trim()) ?? [],
              continousDeployment:
                state.method === 'DOCKER' ? 'MANUAL' : formData.continousDeployment,
              patternTag: formData.patternTag,
            },
          },
          onCompleted(response) {
            if (response.createDeploymentFlow) {
              amplitude.track('Deployment created');

              window.dataLayer = window.dataLayer || [];

              window.dataLayer.push({
                event: 'deployEvent',
              });

              setNotification({
                id: new Date().getTime().toString(),
                type: 'SUCCESS',
                // $FlowIgnore
                message: intl.formatMessage({ id: 'createDeploySuccess' }),
              });

              navigate(
                `/app/apps/${state?.appId ?? ''}/deployments/${
                  response.createDeploymentFlow.id
                }/dashboard?newDeployment=true`,
              );
            } else {
              amplitude.track('Deployment create error');
            }
          },
        });
      }
    }
  }, [
    port,
    source,
    replicas,
    patternTag,
    environment,
    dockerImageUrl,
    deploymentName,
    allowOrigin,
    allowHeaders,
    allowMethods,
    continousDeployment,
    dispatch,
    privacy,
    enableCors,
    editable,
    deploymentId,
    updateDeploy,
    state.method,
    state?.appId,
    onUpdate,
    deploy,
    setNotification,
    intl,
    navigate,
  ]);

  const onRedirectToEnvironment = useCallback(() => {
    navigate(
      `/app/environments/create?success_redirect=${encodeURIComponent(
        '/app/apps/create/catalog?recreate=true',
      )}`,
    );
  }, [navigate]);

  return (
    <>
      {state.deploymentId
        ? createPortal(
            // eslint-disable-next-line react/jsx-indent
            <Suspense fallback={<div />}>
              <DeployStatusModal
                deploymentId={state.deploymentId}
                deploymentName={state?.deploymentName ?? ''}
                publicEndpoint={state.publicEndpoint}
              />
            </Suspense>,
            document.getElementById('modal-portal'),
          )
        : null}
      <Flexbox flexDirection="column" className={editable ? '' : stylex(styles.mainContainer)}>
        <Flexbox flexDirection="column" rowGap={24}>
          <Card>
            <Padding horizontal={16} vertical={16}>
              <Text
                type="s1b"
                color="--neutral-color-800"
                id="appCreator.deploymentConfiguratorGeneral"
              />
              <Padding top={24}>
                <Flexbox flexDirection="column" rowGap={16}>
                  <div className={stylex(styles.appInput)}>
                    <Input input={deploymentName.input} />
                  </div>
                  <Flexbox
                    columnGap={16}
                    rowGap={16}
                    flexDirection={screenSize === 'phone' ? 'column' : 'row'}
                  >
                    <div className={stylex(styles.appInput)}>
                      <Input input={environment.input}>
                        {false && (
                          <CascaderOption
                            selectable={false}
                            value="CREATE"
                            callback={onRedirectToEnvironment}
                            placeholderLabel={
                              <Text type="s0m" color="--primary-color-1" id="createEnvironment" />
                            }
                            label={
                              <Flexbox alignItems="center" columnGap={8}>
                                <Icon icon="add" size={20} />
                                <Text type="s0b" color="--primary-color-1" id="createEnvironment" />
                              </Flexbox>
                            }
                          />
                        )}
                        <CascaderOption
                          value="DEFAULT"
                          placeholderLabel={
                            <Flexbox alignItems="center" columnGap={8}>
                              <div className={stylex(styles.providerContainer)}>
                                <Logo width={24} height={24} />
                              </div>
                              <Text
                                type="s0m"
                                color="--neutral-color-800"
                                id="sierraNegraManaged"
                              />
                            </Flexbox>
                          }
                          label={
                            <Flexbox alignItems="center" columnGap={8}>
                              <div className={stylex(styles.providerContainer)}>
                                <Logo width={24} height={24} />
                              </div>
                              <Text
                                type="s0m"
                                color="--neutral-color-800"
                                id="sierraNegraManaged"
                              />
                            </Flexbox>
                          }
                        />
                        {data?.entities?.edges?.map((edge) => {
                          if (!edge) return null;
                          const { node } = edge;

                          // eslint-disable-next-line no-underscore-dangle
                          if (node?.__typename === 'Environment') {
                            const { kubernetesCluster } = node;

                            return (
                              <CascaderOption
                                key={node.id}
                                value={node.id}
                                placeholderLabel={
                                  <Flexbox alignItems="center" columnGap={8}>
                                    <img
                                      width={20}
                                      className={stylex(styles.logoProvider)}
                                      alt={
                                        kubernetesCluster?.provider === 'AWS'
                                          ? 'AWS'
                                          : 'Google Cloud'
                                      }
                                      src={
                                        kubernetesCluster?.provider === 'AWS' ? awsLogo : gcloudLogo
                                      }
                                    />

                                    <Text type="s0m" color="--neutral-color-800">
                                      {`@${node.namespace}/${node.name}`}
                                    </Text>
                                  </Flexbox>
                                }
                                label={
                                  <Flexbox alignItems="center" columnGap={8}>
                                    <img
                                      width={20}
                                      className={stylex(styles.logoProvider)}
                                      alt={
                                        kubernetesCluster?.provider === 'AWS'
                                          ? 'AWS'
                                          : 'Google Cloud'
                                      }
                                      src={
                                        kubernetesCluster?.provider === 'AWS' ? awsLogo : gcloudLogo
                                      }
                                    />

                                    <Text type="s0m" color="--neutral-color-800">
                                      {`@${node.namespace}/${node.name}`}
                                    </Text>
                                  </Flexbox>
                                }
                              />
                            );
                          }

                          return null;
                        })}
                      </Input>
                    </div>
                    <div className={stylex(styles.appInput)}>
                      {!!data?.artifactsEntities && (
                        <Input input={source.input}>
                          {!data?.artifactsEntities?.edges?.length && (
                            <CascaderOption
                              selectable={false}
                              value="NEW_BUILD"
                              callback={() => {
                                navigate(`/app/apps/${state?.appId ?? ''}/compilations`);
                              }}
                              placeholderLabel={
                                <Text type="s0m" color="--primary-color-1" id="createNewBuild" />
                              }
                              label={
                                <Flexbox alignItems="center" columnGap={8}>
                                  <Icon icon="open_in_new" size={20} />
                                  <Text type="s0m" color="--primary-color-1" id="createNewBuild" />
                                </Flexbox>
                              }
                            />
                          )}
                          {data?.artifactsEntities?.edges?.map((edge) => {
                            if (!edge) return null;
                            const { node } = edge;

                            // eslint-disable-next-line no-underscore-dangle
                            if (node?.__typename === 'Artifact') {
                              return (
                                <CascaderOption
                                  key={node.id}
                                  value={node.id}
                                  placeholderLabel={
                                    <Text type="s0m" color="--neutral-color-800">
                                      {node.dockerName ?? ''}
                                    </Text>
                                  }
                                  label={
                                    <Flexbox flexDirection="column" rowGap={8}>
                                      <Text type="s0m" color="--neutral-color-800">
                                        {node.dockerName ?? ''}
                                      </Text>
                                      <Text type="s0r" color="--neutral-color-600">
                                        {node.createdAtFormatted}
                                      </Text>
                                    </Flexbox>
                                  }
                                />
                              );
                            }

                            return null;
                          })}
                        </Input>
                      )}

                      {state?.method === 'DOCKER' && <Input input={dockerImageUrl.input} />}
                    </div>
                  </Flexbox>

                  <Flexbox
                    columnGap={16}
                    rowGap={16}
                    flexDirection={screenSize === 'phone' ? 'column' : 'row'}
                  >
                    <div className={stylex(styles.appInput)}>
                      <Input input={replicas.input} />
                    </div>

                    <div className={stylex(styles.appInput)}>
                      <Input input={port.input} />
                    </div>
                  </Flexbox>
                </Flexbox>
              </Padding>
            </Padding>
          </Card>

          <Card>
            <Padding horizontal={16} vertical={16}>
              <EnvironmentVariablesForm
                ref={envsRef}
                defaultValues={state?.deploymentConfig?.env ?? [{ name: 'PORT', value: '8080' }]}
              />
            </Padding>
          </Card>

          {state.method === 'GIT' && (
            <Card>
              <Padding horizontal={16} vertical={16}>
                <Text
                  type="s1b"
                  color="--neutral-color-800"
                  id="appCreator.deploymentConfiguratorCD"
                />

                <Padding vertical={24}>
                  <Text
                    type={width <= 815 ? 'bs' : 'bd'}
                    id="appCreator.deploymentConfiguratorCDDescription"
                    color="--neutral-color-600"
                  />
                </Padding>

                <Flexbox flexDirection="column" rowGap={16}>
                  <div>
                    <Input input={continousDeployment.input}>
                      <CascaderOption
                        value="AUTOMATIC"
                        selectable={false}
                        placeholderLabel={
                          <Text
                            type="s0m"
                            color="--neutral-color-800"
                            id="appCreator.deploymentConfiguratorCDAutomatic"
                          />
                        }
                        label={
                          <Text
                            type="s0m"
                            color="--neutral-color-800"
                            id="appCreator.deploymentConfiguratorCDAutomatic"
                          />
                        }
                      >
                        <CascaderOption
                          value="BRANCH"
                          selectable={false}
                          placeholderLabel={
                            <Text
                              type="s0m"
                              color="--neutral-color-800"
                              id="appCreator.deploymentConfiguratorCDBranchPlaceholder"
                            />
                          }
                          label={
                            <Text
                              type="s0m"
                              color="--neutral-color-800"
                              id="appCreator.deploymentConfiguratorCDBranch"
                            />
                          }
                        >
                          {data.gitBranchesByApp?.map((el) => {
                            const [, name] = el.id.split(':');
                            const [, , ...alias] = name.split('/');

                            return (
                              <CascaderOption
                                key={el.id}
                                value={name?.split('/')?.[2] ?? ''}
                                placeholderLabel={
                                  <Text type="s0m" color="--neutral-color-800">
                                    <span className={stylex(styles.labelNoWrap)}>
                                      {`${intl.formatMessage({
                                        id: 'appCreator.deploymentConfiguratorCDAutomatic',
                                      })} / ${alias.join('/')}`}
                                    </span>
                                  </Text>
                                }
                                label={
                                  <Text type="s0m" color="--neutral-color-800">
                                    <span className={stylex(styles.labelNoWrap)}>
                                      {alias.join('/')}
                                    </span>
                                  </Text>
                                }
                              />
                            );
                          })}
                        </CascaderOption>
                        <CascaderOption
                          value="TAG"
                          placeholderLabel={
                            <Text type="s0m" color="--neutral-color-800">
                              <span className={stylex(styles.labelNoWrap)}>
                                {intl.formatMessage({
                                  id: 'appCreator.deploymentConfiguratorCDTagPlaceholder',
                                })}
                              </span>
                            </Text>
                          }
                          label={
                            <Text
                              type="s0m"
                              color="--neutral-color-800"
                              id="appCreator.deploymentConfiguratorCDTag"
                            />
                          }
                        />
                      </CascaderOption>
                      <CascaderOption
                        value="MANUAL"
                        placeholderLabel={
                          <Text
                            type="s0m"
                            color="--neutral-color-800"
                            id="appCreator.deploymentConfiguratorCDManual"
                          />
                        }
                        label={
                          <Text
                            type="s0m"
                            color="--neutral-color-800"
                            id="appCreator.deploymentConfiguratorCDManual"
                          />
                        }
                      />
                    </Input>
                  </div>

                  <Flexbox columnGap={16}>
                    <div className={stylex(styles.appInput)}>
                      <Input input={patternTag.input} />
                    </div>
                    <div className={stylex(styles.appInput)} />
                  </Flexbox>
                </Flexbox>
              </Padding>
            </Card>
          )}

          <Card>
            <Padding horizontal={16} top={16} bottom={24}>
              <Text
                type="s1b"
                color="--neutral-color-800"
                id="appCreator.deploymentConfiguratorEndpointPrivacy"
              />
              <Padding top={24} bottom={32}>
                <Text
                  type={width <= 815 ? 'bs' : 'bd'}
                  id="appCreator.deploymentConfiguratorEndpointPrivacyDescription"
                />
              </Padding>

              <Flexbox flexDirection="column" rowGap={32}>
                <Flexbox flexDirection="column" rowGap={16}>
                  <Flexbox columnGap={16} alignItems="center">
                    <RadioButton
                      checked={privacy === 'Public'}
                      onChange={() => setPrivacy('Public')}
                    />
                    <Text
                      type="s0b"
                      color="--neutral-color-800"
                      id="appCreator.deploymentConfiguratorPublic"
                    />
                  </Flexbox>
                  <Padding left={40}>
                    <Text
                      type={width <= 815 ? 'bs' : 'bd'}
                      id="appCreator.deploymentConfiguratorPublicDescription"
                    />
                  </Padding>
                </Flexbox>

                {state?.deploymentConfig?.privacy === 'PUBLIC' && !!avoidConvertToPrivate ? null : (
                  <Flexbox flexDirection="column" rowGap={16}>
                    <Flexbox columnGap={16} alignItems="center">
                      <RadioButton
                        disabled={
                          state?.deploymentConfig?.privacy === 'PUBLIC' && !!avoidConvertToPrivate
                        }
                        checked={privacy === 'Private'}
                        onChange={() => {
                          setEnableCors(false);
                          setPrivacy('Private');
                        }}
                      />
                      <Text
                        type="s0b"
                        color="--neutral-color-800"
                        id="appCreator.deploymentConfiguratorPrivate"
                      />
                    </Flexbox>
                    <Padding left={40}>
                      <Text
                        type={width <= 815 ? 'bs' : 'bd'}
                        id="appCreator.deploymentConfiguratorPrivateDescription"
                      />
                    </Padding>
                  </Flexbox>
                )}
              </Flexbox>
            </Padding>
          </Card>

          {privacy === 'Public' && (
            <Card>
              <Padding horizontal={16} vertical={16}>
                <Text
                  type="s1b"
                  color="--neutral-color-800"
                  id="appCreator.deploymentConfiguratorCorsTitle"
                />
                <Padding top={24}>
                  <Text
                    type={width <= 815 ? 'bs' : 'bd'}
                    id="appCreator.deploymentConfiguratorCorsDescription"
                  />
                </Padding>

                <Padding vertical={32}>
                  <Flexbox columnGap={16}>
                    <Checkbox checked={enableCors} onChange={setEnableCors} />
                    <Text
                      type="s0b"
                      id="appCreator.deploymentConfiguratorEnableCors"
                      color={enableCors ? '--primary-color-1' : '--neutral-color-600'}
                    />
                  </Flexbox>
                </Padding>

                {enableCors ? (
                  <Flexbox flexDirection="column" rowGap={16}>
                    <Input input={allowOrigin.input} />
                    <Input input={allowHeaders.input} />
                    <Input input={allowMethods.input} />
                  </Flexbox>
                ) : null}
              </Padding>
            </Card>
          )}

          <Padding top={0}>
            <Button
              loading={deployPending || updateDeployPending}
              onClick={handleOnSubmit}
              intlId={callToActionLabel || 'deploy'}
              disabled={deployPending || updateDeployPending}
            />
          </Padding>
        </Flexbox>
      </Flexbox>
    </>
  );
}

DeploymentConfigurator.defaultProps = {
  onUpdate: null,
  editable: false,
  deploymentId: null,
  callToActionLabel: '',
  disableEnvironment: false,
  avoidConvertToPrivate: false,
  disableDeploymentName: false,
};
