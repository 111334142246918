/**
 * @generated SignedSource<<f5d3a5e8e05c79a351024592b67d4d01>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type DNSQueryRecordInput = {|
  name: string,
  type: string,
  value: string,
|};
export type RegisterDNSValidationQuery$variables = {|
  records: $ReadOnlyArray<DNSQueryRecordInput>,
|};
export type RegisterDNSValidationQuery$data = {|
  +lookupDNSRecords: ?boolean,
|};
export type RegisterDNSValidationQuery = {|
  variables: RegisterDNSValidationQuery$variables,
  response: RegisterDNSValidationQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "records"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "records",
        "variableName": "records"
      }
    ],
    "kind": "ScalarField",
    "name": "lookupDNSRecords",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RegisterDNSValidationQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RegisterDNSValidationQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "80fa51b28cac2869a942b1638f276195",
    "id": null,
    "metadata": {},
    "name": "RegisterDNSValidationQuery",
    "operationKind": "query",
    "text": "query RegisterDNSValidationQuery(\n  $records: [DNSQueryRecordInput!]!\n) {\n  lookupDNSRecords(records: $records)\n}\n"
  }
};
})();

(node/*: any*/).hash = "fd451bcd1dff88ab8f3880e5196adf34";

module.exports = ((node/*: any*/)/*: Query<
  RegisterDNSValidationQuery$variables,
  RegisterDNSValidationQuery$data,
>*/);
