/**
 * @generated SignedSource<<eeb953dc57052aba74d9db50f2f6b725>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
type Avatar$fragmentType = any;
export type UITheme = "DARK" | "LIGHT" | "%future added value";
export type UserBadgeQuery$variables = {||};
export type UserBadgeQuery$data = {|
  +me: ?{|
    +id: string,
    +username: string,
    +name: ?string,
    +uiTheme: ?UITheme,
    +media: ?{|
      +$fragmentSpreads: Avatar$fragmentType,
    |},
  |},
|};
export type UserBadgeQuery = {|
  variables: UserBadgeQuery$variables,
  response: UserBadgeQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uiTheme",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "height",
    "value": 72
  },
  {
    "kind": "Literal",
    "name": "width",
    "value": 72
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserBadgeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "media",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Avatar"
              }
            ],
            "storageKey": "media(height:72,width:72)"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserBadgeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "media",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "width",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "height",
                "storageKey": null
              }
            ],
            "storageKey": "media(height:72,width:72)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0c19d6e53c49b1ce55dcd997a98d46a3",
    "id": null,
    "metadata": {},
    "name": "UserBadgeQuery",
    "operationKind": "query",
    "text": "query UserBadgeQuery {\n  me {\n    id\n    username\n    name\n    uiTheme\n    media(width: 72, height: 72) {\n      ...Avatar\n      id\n    }\n  }\n}\n\nfragment Avatar on Image {\n  id\n  url\n  alt\n  width\n  height\n}\n"
  }
};
})();

(node/*: any*/).hash = "46ab96ce26894a36ad80421e7978cbd8";

module.exports = ((node/*: any*/)/*: Query<
  UserBadgeQuery$variables,
  UserBadgeQuery$data,
>*/);
