/**
 * @generated SignedSource<<a4550e7389a1fc8f53f977987866873c>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserBadgeLogoutMutation$variables = {||};
export type UserBadgeLogoutMutation$data = {|
  +logout: ?string,
|};
export type UserBadgeLogoutMutation = {|
  variables: UserBadgeLogoutMutation$variables,
  response: UserBadgeLogoutMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "logout",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserBadgeLogoutMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserBadgeLogoutMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "f1fd751afc72c58480fb3ae07a662ab3",
    "id": null,
    "metadata": {},
    "name": "UserBadgeLogoutMutation",
    "operationKind": "mutation",
    "text": "mutation UserBadgeLogoutMutation {\n  logout\n}\n"
  }
};
})();

(node/*: any*/).hash = "3073bce0591821c3e00c05b698fda8a1";

module.exports = ((node/*: any*/)/*: Mutation<
  UserBadgeLogoutMutation$variables,
  UserBadgeLogoutMutation$data,
>*/);
