// @flow
import stylex from '@serpa-cloud/stylex';
import { useCallback, Suspense } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';

import {
  Text,
  Icon,
  Card,
  Margin,
  Padding,
  Flexbox,
  ScrolledList,
  useFetchPolicy,
} from '../../shared';

import CompilationSource from './CompilationSource';
import CompilationElement from './CompilationElement';
import BuildModal from '../MicroApps/BuildModal';

const styles = stylex.create({
  container: {
    position: 'relative',
    '@media (max-width: 815px)': {
      width: '100%',
    },
    '@media (min-width: 815px)': {
      width: 680,
    },
  },
  component: {
    display: 'flex',
    flexDirection: 'column',
  },
  toolContainer: {
    zIndex: 9,
    position: 'relative',
  },
  descriptorContainer: {
    top: 24,
    position: 'sticky',
  },
  emptyDescriptor: {
    maxWidth: 480,
    width: '100%',
  },
});

type ContainerProps = { +children: React$Node, +empty: boolean };

function CompilationsContainer({ children, empty }: ContainerProps): React$Node {
  const matchBase = useMatch('/app/apps/:appId/compilations/*');

  return (
    <div>
      <div className={stylex(styles.component)}>
        {!empty ? (
          <Padding className={stylex(styles.toolContainer)}>
            <div className={stylex(styles.descriptorContainer)}>
              <CompilationSource defaultAppId={matchBase?.params?.appId ?? null} />
            </div>
          </Padding>
        ) : null}
        {empty ? (
          <Flexbox justifyContent="center">
            <Padding top={32} className={stylex(styles.container)}>
              {children}
            </Padding>
          </Flexbox>
        ) : null}
        {!empty ? (
          <Padding top={24} bottom={40}>
            {children}
          </Padding>
        ) : null}
      </div>
    </div>
  );
}

export default function Compilations(): React$Node {
  const navigate = useNavigate();
  const baseFetchPolicy = useFetchPolicy();
  const matchBase = useMatch('/app/apps/:appId/compilations/*');
  const appId = matchBase?.params?.appId ?? '';

  const renderElement = useCallback(
    (node, key) => <CompilationElement node={node} key={key} />,
    [],
  );

  const handleOnBuildSubmit = useCallback(
    (buildId) => {
      navigate(`/app/apps/${appId}/compilations/${buildId}`);
    },
    [appId, navigate],
  );

  return (
    <Suspense fallback={<div />}>
      <ScrolledList
        first={4}
        index="BUILDS"
        fetchPolicy={baseFetchPolicy}
        renderElement={renderElement}
        filterMatrix={[
          [
            {
              type: 'term',
              property: 'app.keyword',
              value: appId,
            },
          ],
        ]}
        emptyElement={
          <Flexbox className={stylex(styles.emptyDescriptor)}>
            <div>
              <Margin top={24}>
                <Card>
                  <Padding vertical={24} horizontal={24}>
                    <Flexbox flexDirection="column" alignItems="center">
                      <Padding horizontal={8} bottom={24}>
                        <Icon
                          fill
                          size={56}
                          weight={100}
                          icon="build_circle"
                          color="--neutral-color-100"
                          gradient="linear-gradient(265.7deg, var(--yellow-solid-color) -2.24%, var(--red-solid-color) 102.98%"
                        />
                      </Padding>
                      <Text
                        id="build.description"
                        type="bs"
                        values={{
                          b: (s) => (
                            <strong style={{ color: 'var(--neutral-color-800)' }}>{s}</strong>
                          ),
                          g: (s) => <strong style={{ color: 'var(--green-300)' }}>{s}</strong>,
                        }}
                      />
                    </Flexbox>
                    <Margin top={32}>
                      <BuildModal appId={appId} onBuild={handleOnBuildSubmit} />
                    </Margin>
                  </Padding>
                </Card>
              </Margin>
            </div>
          </Flexbox>
        }
        container={<Flexbox rowGap={24} flexDirection="column" />}
        parent={CompilationsContainer}
      />
    </Suspense>
  );
}
