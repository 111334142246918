/**
 * @generated SignedSource<<b808471903ddc2f5f805fd1f084b41aa>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type AIChatMessageInput = {|
  author: string,
  content: string,
|};
export type SerpaAIFormMutation$variables = {|
  messages: $ReadOnlyArray<AIChatMessageInput>,
  initialContent?: ?string,
  locale?: ?string,
|};
export type SerpaAIFormMutation$data = {|
  +askToSerpaAIDocker: string,
|};
export type SerpaAIFormMutation = {|
  variables: SerpaAIFormMutation$variables,
  response: SerpaAIFormMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "initialContent"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "locale"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "messages"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "initialContent",
        "variableName": "initialContent"
      },
      {
        "kind": "Variable",
        "name": "locale",
        "variableName": "locale"
      },
      {
        "kind": "Variable",
        "name": "messages",
        "variableName": "messages"
      }
    ],
    "kind": "ScalarField",
    "name": "askToSerpaAIDocker",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SerpaAIFormMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "SerpaAIFormMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "e0c6c44b3acb1c9f37a3502d93cab6f8",
    "id": null,
    "metadata": {},
    "name": "SerpaAIFormMutation",
    "operationKind": "mutation",
    "text": "mutation SerpaAIFormMutation(\n  $messages: [AIChatMessageInput!]!\n  $initialContent: String\n  $locale: String\n) {\n  askToSerpaAIDocker(messages: $messages, initialContent: $initialContent, locale: $locale)\n}\n"
  }
};
})();

(node/*: any*/).hash = "96d3249cda69163c45386f04292d7361";

module.exports = ((node/*: any*/)/*: Mutation<
  SerpaAIFormMutation$variables,
  SerpaAIFormMutation$data,
>*/);
