/**
 * @generated SignedSource<<1048d9b9b1bae89e9f21a229e6684b07>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type AppMethod = "GIT" | "DOCKER" | "%future added value";
export type AppType = "CONTAINER" | "STATIC" | "%future added value";
export type ContainerBuilder = "DOCKER" | "BUILDPACKS" | "KANIKO" | "%future added value";
export type EventTypeTrigger = "BRANCH" | "TAG" | "%future added value";
export type GitProvider = "GITHUB" | "GITLAB" | "%future added value";
export type StaticRuntime = "NODEJS" | "%future added value";
export type AppInput = {|
  name?: ?string,
  media?: ?string,
  source?: ?string,
  gitProvider?: ?GitProvider,
  useDockerfileTemplate?: ?boolean,
  dockerfile?: ?string,
  dockerfileContent?: ?string,
  continousIntegrationEnabled?: ?boolean,
  eventTypeTrigger?: ?EventTypeTrigger,
  eventNameTrigger?: ?string,
  env?: ?$ReadOnlyArray<EnvInput>,
  runtime?: ?StaticRuntime,
  buildCommand?: ?string,
  preInstallCommand?: ?string,
  appType?: ?AppType,
  appMethod: AppMethod,
  buildPath?: ?string,
  builder?: ?ContainerBuilder,
|};
export type EnvInput = {|
  name: string,
  value: string,
|};
export type AppNameCreateDockerAppMutation$variables = {|
  input: AppInput,
  triggerCompilation?: ?boolean,
|};
export type AppNameCreateDockerAppMutation$data = {|
  +createApp: {|
    +id: string,
    +namespace: string,
    +name: string,
    +createdAtFormatted: string,
    +createdBy: {|
      +fullname: ?string,
    |},
  |},
|};
export type AppNameCreateDockerAppMutation = {|
  variables: AppNameCreateDockerAppMutation$variables,
  response: AppNameCreateDockerAppMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "triggerCompilation"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  },
  {
    "kind": "Variable",
    "name": "triggerCompilation",
    "variableName": "triggerCompilation"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "namespace",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAtFormatted",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullname",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppNameCreateDockerAppMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "App",
        "kind": "LinkedField",
        "name": "createApp",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppNameCreateDockerAppMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "App",
        "kind": "LinkedField",
        "name": "createApp",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "211dbffa21a914ffc7358eae979cc39d",
    "id": null,
    "metadata": {},
    "name": "AppNameCreateDockerAppMutation",
    "operationKind": "mutation",
    "text": "mutation AppNameCreateDockerAppMutation(\n  $input: AppInput!\n  $triggerCompilation: Boolean\n) {\n  createApp(input: $input, triggerCompilation: $triggerCompilation) {\n    id\n    namespace\n    name\n    createdAtFormatted\n    createdBy {\n      fullname\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "bca87fcaab7b89a37c64dfae93918b3c";

module.exports = ((node/*: any*/)/*: Mutation<
  AppNameCreateDockerAppMutation$variables,
  AppNameCreateDockerAppMutation$data,
>*/);
