// @flow
import React, { useReducer } from 'react';

type Dispatch<A> = (value: A) => void;

export type Notification = {|
  +id: string,
  +message: string | {| +id: string |},
  +type: 'SUCCESS' | 'WARNING' | 'ERROR',
  cancel?: ?(any) => any,
|};

type StateType = {|
  notifications: Notification[],
|};

export const initialState: StateType = {
  notifications: [],
};

type ReducerAction =
  | {|
      +type: 'SET_NOTIFICATION',
      +notification: Notification,
    |}
  | {|
      +type: 'REMOVE_NOTIFICATION',
      +notificationId: string,
    |};

type ContextType = {|
  state: StateType,
  dispatch: Dispatch<ReducerAction>,
|};

const initialContext: ContextType = {
  state: initialState,
  dispatch: () => {},
};

export const AppContext: React$Context<{|
  dispatch: Dispatch<ReducerAction>,
  state: StateType,
|}> = React.createContext(initialContext);

function reducer(state: StateType, action: ReducerAction) {
  const { type, notification, notificationId } = action;

  if (type === 'SET_NOTIFICATION' && notification) {
    return {
      ...state,
      notifications: [notification, ...state.notifications],
    };
  }

  if (type === 'REMOVE_NOTIFICATION' && notificationId) {
    return {
      ...state,
      notifications: [...state.notifications.filter((el) => el.id !== notificationId)],
    };
  }

  return { ...state };
}

type Props = {
  children: React$Node,
};

export default function AppProvider({ children }: Props): React$Node {
  const [state, dispatch]: [StateType, Dispatch<ReducerAction>] = useReducer(reducer, initialState);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
}
