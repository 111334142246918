/**
 * @generated SignedSource<<cbd3635758766c1cd75804557eba02a2>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type ElasticIndex = "ALL" | "APPS" | "BUILDS" | "RELEASES" | "ORGS" | "ARTIFACTS" | "ACCOUNTS" | "DOCKERFILES" | "DEPLOYMENTS" | "CHANNELS" | "ENVIRONMENTS" | "IMPLEMENTATIONS" | "KUBERNETES" | "SITES" | "POSTS" | "%future added value";
export type SortInput = {|
  value: string,
  property: string,
|};
export type FilterInput = {|
  property: string,
  type: string,
  value?: ?string,
  valueNumber?: ?number,
  valueBoolean?: ?boolean,
  gt?: ?number,
  lte?: ?number,
|};
export type DeploymentConfiguratorQuery$variables = {|
  first?: ?number,
  after?: ?any,
  appId: string,
  sort?: ?SortInput,
  index?: ?ElasticIndex,
  skipGit: boolean,
  skipArtifacts: boolean,
  sortArtifacts?: ?SortInput,
  filterMatrix?: ?$ReadOnlyArray<?$ReadOnlyArray<?FilterInput>>,
  filterMatrixArtifacts?: ?$ReadOnlyArray<?$ReadOnlyArray<?FilterInput>>,
|};
export type DeploymentConfiguratorQuery$data = {|
  +gitBranchesByApp?: ?$ReadOnlyArray<{|
    +id: string,
    +name: string,
    +sha: string,
  |}>,
  +gitTagsByApp?: ?$ReadOnlyArray<{|
    +id: string,
    +name: string,
    +sha: string,
  |}>,
  +entities: ?{|
    +pageInfo: ?{|
      +hasNextPage: ?boolean,
      +endCursor: ?any,
      +finalCursor: ?any,
    |},
    +edges: ?$ReadOnlyArray<?{|
      +id: string,
      +cursor: any,
      +node: {|
        +__typename: "Environment",
        +id: string,
        +name: string,
        +namespace: string,
        +kubernetesCluster: ?{|
          +id: string,
          +provider: string,
        |},
      |} | {|
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        +__typename: "%other",
      |},
    |}>,
  |},
  +artifactsEntities?: ?{|
    +pageInfo: ?{|
      +hasNextPage: ?boolean,
      +endCursor: ?any,
      +finalCursor: ?any,
    |},
    +edges: ?$ReadOnlyArray<?{|
      +id: string,
      +cursor: any,
      +node: {|
        +__typename: "Artifact",
        +id: string,
        +dockerName: ?string,
        +createdAtFormatted: string,
      |} | {|
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        +__typename: "%other",
      |},
    |}>,
  |},
|};
export type DeploymentConfiguratorQuery = {|
  variables: DeploymentConfiguratorQuery$variables,
  response: DeploymentConfiguratorQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "appId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterMatrix"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterMatrixArtifacts"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "index"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipArtifacts"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipGit"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortArtifacts"
},
v10 = [
  {
    "kind": "Variable",
    "name": "appId",
    "variableName": "appId"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v13 = [
  (v11/*: any*/),
  (v12/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sha",
    "storageKey": null
  }
],
v14 = {
  "condition": "skipGit",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "alias": null,
      "args": (v10/*: any*/),
      "concreteType": "GitBranch",
      "kind": "LinkedField",
      "name": "gitBranchesByApp",
      "plural": true,
      "selections": (v13/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v10/*: any*/),
      "concreteType": "GitTag",
      "kind": "LinkedField",
      "name": "gitTagsByApp",
      "plural": true,
      "selections": (v13/*: any*/),
      "storageKey": null
    }
  ]
},
v15 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filterMatrix",
    "variableName": "filterMatrix"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "index",
    "variableName": "index"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort"
  }
],
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalCursor",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v19 = {
  "kind": "InlineFragment",
  "selections": [
    (v11/*: any*/),
    (v12/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "namespace",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "KubernetesCluster",
      "kind": "LinkedField",
      "name": "kubernetesCluster",
      "plural": false,
      "selections": [
        (v11/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "provider",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Environment",
  "abstractKey": null
},
v20 = [
  {
    "kind": "Variable",
    "name": "filterMatrix",
    "variableName": "filterMatrixArtifacts"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 200
  },
  {
    "kind": "Literal",
    "name": "index",
    "value": "ARTIFACTS"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sortArtifacts"
  }
],
v21 = {
  "kind": "InlineFragment",
  "selections": [
    (v11/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dockerName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAtFormatted",
      "storageKey": null
    }
  ],
  "type": "Artifact",
  "abstractKey": null
},
v22 = {
  "kind": "InlineFragment",
  "selections": [
    (v11/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeploymentConfiguratorQuery",
    "selections": [
      (v14/*: any*/),
      {
        "alias": null,
        "args": (v15/*: any*/),
        "concreteType": "EntitiesConnection",
        "kind": "LinkedField",
        "name": "entities",
        "plural": false,
        "selections": [
          (v16/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Edge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v11/*: any*/),
              (v17/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v18/*: any*/),
                  (v19/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "condition": "skipArtifacts",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "artifactsEntities",
            "args": (v20/*: any*/),
            "concreteType": "EntitiesConnection",
            "kind": "LinkedField",
            "name": "entities",
            "plural": false,
            "selections": [
              (v16/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Edge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  (v17/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v18/*: any*/),
                      (v21/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v8/*: any*/),
      (v5/*: any*/),
      (v7/*: any*/),
      (v6/*: any*/),
      (v9/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "DeploymentConfiguratorQuery",
    "selections": [
      (v14/*: any*/),
      {
        "alias": null,
        "args": (v15/*: any*/),
        "concreteType": "EntitiesConnection",
        "kind": "LinkedField",
        "name": "entities",
        "plural": false,
        "selections": [
          (v16/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Edge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v11/*: any*/),
              (v17/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v22/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "condition": "skipArtifacts",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "artifactsEntities",
            "args": (v20/*: any*/),
            "concreteType": "EntitiesConnection",
            "kind": "LinkedField",
            "name": "entities",
            "plural": false,
            "selections": [
              (v16/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Edge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  (v17/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v18/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "8f8d3b3fcf6c2e626f1088f55feeaddf",
    "id": null,
    "metadata": {},
    "name": "DeploymentConfiguratorQuery",
    "operationKind": "query",
    "text": "query DeploymentConfiguratorQuery(\n  $first: Int\n  $after: Cursor\n  $appId: String!\n  $sort: SortInput\n  $index: ElasticIndex\n  $skipGit: Boolean!\n  $skipArtifacts: Boolean!\n  $sortArtifacts: SortInput\n  $filterMatrix: [[FilterInput]]\n  $filterMatrixArtifacts: [[FilterInput]]\n) {\n  gitBranchesByApp(appId: $appId) @skip(if: $skipGit) {\n    id\n    name\n    sha\n  }\n  gitTagsByApp(appId: $appId) @skip(if: $skipGit) {\n    id\n    name\n    sha\n  }\n  entities(sort: $sort, first: $first, after: $after, index: $index, filterMatrix: $filterMatrix) {\n    pageInfo {\n      hasNextPage\n      endCursor\n      finalCursor\n    }\n    edges {\n      id\n      cursor\n      node {\n        __typename\n        ... on Environment {\n          id\n          name\n          namespace\n          kubernetesCluster {\n            id\n            provider\n          }\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n  }\n  artifactsEntities: entities(sort: $sortArtifacts, first: 200, index: ARTIFACTS, filterMatrix: $filterMatrixArtifacts) @skip(if: $skipArtifacts) {\n    pageInfo {\n      hasNextPage\n      endCursor\n      finalCursor\n    }\n    edges {\n      id\n      cursor\n      node {\n        __typename\n        ... on Artifact {\n          id\n          dockerName\n          createdAtFormatted\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "48c6767338cd39b7f487a4cc8e920511";

module.exports = ((node/*: any*/)/*: Query<
  DeploymentConfiguratorQuery$variables,
  DeploymentConfiguratorQuery$data,
>*/);
