/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
// @flow
import stylex from '@serpa-cloud/stylex';
import { useState } from 'react';

import Padding from './Padding';

type VarElement = {| +name: string, +value: string |};

type Props = {|
  +environmentsRow: $ReadOnlyArray<[number, VarElement]>,
  +setRows: (Array<[number, VarElement]>) => void,
|};

const styles = stylex.create({
  editor: {
    padding: 16,
    fontSize: 16,
    width: '100%',
    lineHeight: 2,
    minHeight: 356,
    borderRadius: 0,
    boxSizing: 'border-box',
    backgroundColor: 'var(--logs-background)',
    color: 'var(--logs-text-color)',
    border: '1px solid var(--border-color)',
  },
});

export default function EnvironmentVariablesEditor({
  environmentsRow,
  setRows,
}: Props): React$Node {
  const [textAreaString, setTextAreaString] = useState(() => {
    let tmpString = '';
    environmentsRow.forEach(([, environmentVar]) => {
      if (environmentVar?.name && environmentVar?.value) {
        tmpString += `${environmentVar?.name}=${environmentVar?.value}`;
      }
    });

    return tmpString;
  });

  function handleChange(e) {
    let tmpArray = e.target.value;
    tmpArray = tmpArray.split('\n');
    setTextAreaString(e.target.value);

    const newArray: Array<[number, VarElement]> = tmpArray
      .map((envVariables, key: number) => {
        if (!envVariables) return null;
        const variableValues = envVariables.split(/=(.*)/s);

        return [
          key,
          {
            name: variableValues?.[0]?.trim() ?? '',
            value: variableValues?.[1]?.trim() ?? '',
          },
        ];
      })
      .filter(Boolean);

    setRows(newArray);
  }

  return (
    <Padding top={16}>
      <textarea className={stylex(styles.editor)} value={textAreaString} onChange={handleChange} />
    </Padding>
  );
}
