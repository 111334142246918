/**
 * @generated SignedSource<<297e33458cdad25e127d3a063bfb6d16>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type GitProvider = "GITHUB" | "GITLAB" | "%future added value";
export type GitSelectorQuery$variables = {|
  provider: GitProvider,
|};
export type GitSelectorQuery$data = {|
  +me: ?{|
    +id: string,
    +canFetchFromGithub: boolean,
    +canFetchFromGitlab: boolean,
  |},
  +gitRepos: ?$ReadOnlyArray<{|
    +id: string,
    +name: string,
    +url: string,
    +ownerAvatar: ?string,
  |}>,
|};
export type GitSelectorQuery = {|
  variables: GitSelectorQuery$variables,
  response: GitSelectorQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "provider"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "me",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "canFetchFromGithub",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "canFetchFromGitlab",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "provider",
        "variableName": "provider"
      }
    ],
    "concreteType": "GitRepo",
    "kind": "LinkedField",
    "name": "gitRepos",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ownerAvatar",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GitSelectorQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GitSelectorQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "0b8863506cd031d57bc8a715d48acf9d",
    "id": null,
    "metadata": {},
    "name": "GitSelectorQuery",
    "operationKind": "query",
    "text": "query GitSelectorQuery(\n  $provider: GitProvider!\n) {\n  me {\n    id\n    canFetchFromGithub\n    canFetchFromGitlab\n  }\n  gitRepos(provider: $provider) {\n    id\n    name\n    url\n    ownerAvatar\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "0a96260cde087bc50db8c8f8a190049d";

module.exports = ((node/*: any*/)/*: Query<
  GitSelectorQuery$variables,
  GitSelectorQuery$data,
>*/);
