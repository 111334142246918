/**
 * @generated SignedSource<<62cb08215efb4202c9459b4d8ff02776>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
export type AppType = "CONTAINER" | "STATIC" | "%future added value";
export type GitProvider = "GITHUB" | "GITLAB" | "%future added value";
import type { FragmentType } from "relay-runtime";
declare export opaque type MicroAppElement$fragmentType: FragmentType;
export type MicroAppElement$data = {|
  +id: string,
  +name: string,
  +appType: AppType,
  +createdAt: any,
  +siteCount: ?number,
  +gitProvider: ?GitProvider,
  +deploymentCount: ?number,
  +compilationCount: ?number,
  +createdAtFormatted: string,
  +staticGitSourceName: ?string,
  +$fragmentType: MicroAppElement$fragmentType,
|};
export type MicroAppElement$key = {
  +$data?: MicroAppElement$data,
  +$fragmentSpreads: MicroAppElement$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MicroAppElement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "siteCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gitProvider",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deploymentCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "compilationCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAtFormatted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "staticGitSourceName",
      "storageKey": null
    }
  ],
  "type": "App",
  "abstractKey": null
};

(node/*: any*/).hash = "8394a0c237e0f2b4c01b9d3cdac1dc0c";

module.exports = ((node/*: any*/)/*: Fragment<
  MicroAppElement$fragmentType,
  MicroAppElement$data,
>*/);
