/**
 * @generated SignedSource<<f75e44d6c4a215006b86c4036a189b58>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type AppMethod = "GIT" | "DOCKER" | "%future added value";
export type DeploymentFormQuery$variables = {|
  id: string,
|};
export type DeploymentFormQuery$data = {|
  +node: ?{|
    +id?: string,
    +name?: string,
    +appMethod?: AppMethod,
    +namespace?: string,
  |},
|};
export type DeploymentFormQuery = {|
  variables: DeploymentFormQuery$variables,
  response: DeploymentFormQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appMethod",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "namespace",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeploymentFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "type": "App",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeploymentFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "type": "App",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "96cb19576383cb534034ed60c538fe03",
    "id": null,
    "metadata": {},
    "name": "DeploymentFormQuery",
    "operationKind": "query",
    "text": "query DeploymentFormQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on App {\n      id\n      name\n      appMethod\n      namespace\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "2524a0d3744154b89ca0027c3adc64c8";

module.exports = ((node/*: any*/)/*: Query<
  DeploymentFormQuery$variables,
  DeploymentFormQuery$data,
>*/);
