/**
 * @generated SignedSource<<20bcc3026326f2e5e2680fc5983db06e>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type DiscountAvailableUntilQuery$variables = {||};
export type DiscountAvailableUntilQuery$data = {|
  +getCurrentNameSpace: ?{|
    +id: string,
    +freeTrialUntil: any,
    +discountAvailableUntil: ?any,
  |},
  +paymentProfile: ?{|
    +id: string,
  |},
|};
export type DiscountAvailableUntilQuery = {|
  variables: DiscountAvailableUntilQuery$variables,
  response: DiscountAvailableUntilQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "freeTrialUntil",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountAvailableUntil",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "PaymentProfile",
  "kind": "LinkedField",
  "name": "paymentProfile",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DiscountAvailableUntilQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "getCurrentNameSpace",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DiscountAvailableUntilQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "getCurrentNameSpace",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ]
  },
  "params": {
    "cacheID": "51579d957f5b01cf6aba848f7cd34d84",
    "id": null,
    "metadata": {},
    "name": "DiscountAvailableUntilQuery",
    "operationKind": "query",
    "text": "query DiscountAvailableUntilQuery {\n  getCurrentNameSpace {\n    __typename\n    id\n    freeTrialUntil\n    discountAvailableUntil\n  }\n  paymentProfile {\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "8d6eb9852925d8ea3a9b9d982d0abb66";

module.exports = ((node/*: any*/)/*: Query<
  DiscountAvailableUntilQuery$variables,
  DiscountAvailableUntilQuery$data,
>*/);
