// @flow
import { useState } from 'react';
import stylex from '@serpa-cloud/stylex';

import { Card, Icon, Text, Flexbox, Padding, Margin, Button } from '../../shared';

const styles = stylex.create({
  flagContainer: {
    height: 24,
  },
  flag: {
    height: 24,
    backgroundColor: 'var(--neutral-color-300)',
  },
  descriptionContainer: {
    height: 90,
  },
  tableContainer: {
    maxWidth: 480,
    justifySelf: 'center',
  },
  priceHighligh: {
    color: 'var(--neutral-color-800)',
  },
});

type Props = {|
  +plan: 'HOBBY' | 'BASIC' | 'PRO',
  +pending?: ?boolean,
  +isFavorite?: ?boolean,
  +callToAction: () => void,
|};

const mappings = {
  HOBBY: {
    title: 'pricing.hobby.title',
    description: 'pricing.hobby.description',
    pricing: 'pricing.hobby.pricing',
    callToAction: 'pricing.hobby.cta',
    features: [
      'pricing.feature.hobby.seat',
      'pricing.feature.hobby.activeDeployments',
      'pricing.feature.hobby.replicas',
      'pricing.feature.hobby.compute',
      'pricing.feature.hobby.infra',
      'pricing.feature.hobby.env',
      'pricing.feature.hobby.github',
      'pricing.feature.hobby.gitlab',
      'pricing.feature.hobby.buildMinutes',
      'pricing.feature.hobby.buildPacks',
    ],
  },
  BASIC: {
    title: 'pricing.basic.title',
    description: 'pricing.basic.description',
    pricing: 'pricing.basic.pricing',
    callToAction: 'pricing.basic.cta',
    features: [
      'pricing.feature.basic.inherit',
      'pricing.feature.basic.seat',
      'pricing.feature.basic.activeDeployments',
      'pricing.feature.basic.replicas',
      'pricing.feature.basic.compute',
      'pricing.feature.basic.logs',
      'pricing.feature.hobby.env',
      'pricing.feature.basic.buildMinutes',
      'pricing.feature.basic.docker',
      'pricing.feature.basic.domains',
      'pricing.feature.basic.ssl',
      'pricing.feature.basic.support',
    ],
  },
  PRO: {
    title: 'pricing.pro.title',
    description: 'pricing.pro.description',
    pricing: 'pricing.pro.pricing',
    callToAction: 'pricing.pro.cta',
    features: [
      'pricing.feature.pro.inherit',
      'pricing.feature.pro.seat',
      'pricing.feature.pro.activeDeployments',
      'pricing.feature.pro.replicas',
      'pricing.feature.pro.compute',
      'pricing.feature.pro.dockerhub',
      'pricing.feature.pro.infraSerpa',
      'pricing.feature.pro.infra',
      'pricing.feature.pro.env',
      'pricing.feature.pro.buildMinutes',
      'pricing.feature.pro.loadBalancer',
      'pricing.feature.pro.support',
    ],
  },
};

export default function SubscriptionTableElement({
  plan,
  pending,
  isFavorite,
  callToAction,
}: Props): React$Node {
  const [isSelected, setIsSelected] = useState(false);

  return (
    <Card className={stylex(styles.tableContainer)}>
      <Padding horizontal={24} vertical={24}>
        <Flexbox className={stylex(styles.flagContainer)}>
          {isFavorite && (
            <Flexbox className={stylex(styles.flag)} justifyContent="center" alignItems="center">
              <Padding horizontal={8}>
                <Text type="s0r" color="--neutral-color-800" id="pricing.favorite" />
              </Padding>
            </Flexbox>
          )}
        </Flexbox>

        <Margin top={20}>
          <div className={stylex(styles.descriptionContainer)}>
            <Flexbox flexDirection="column" rowGap={16}>
              <Text id={mappings[plan].title} type="h4" />
              <Text
                id={mappings[plan].description}
                type="bs"
                values={{
                  strong: (str) => <strong className={stylex(styles.priceHighligh)}>{str}</strong>,
                }}
              />
            </Flexbox>
          </div>
        </Margin>

        <Margin top={32}>
          <Flexbox alignItems="flex-start" columnGap={8}>
            <Text id={mappings[plan].pricing} type="h2" />
            <Padding top={4}>
              <Text id="pricing.monthly" type="s0r" />
            </Padding>
          </Flexbox>
        </Margin>

        <Margin top={32}>
          <Button
            loading={isSelected && pending}
            onClick={() => {
              setIsSelected(true);
              callToAction();
            }}
            disabled={pending || false}
            intlId={mappings[plan].callToAction}
            buttonType={isFavorite ? 'primary' : 'secondary'}
          />
        </Margin>

        <Margin top={16}>
          {mappings[plan].features.map((feature) => {
            return (
              <Padding key={feature} vertical={8}>
                <Flexbox alignItems="center" columnGap={8}>
                  <Icon icon="check_circle" size={20} />
                  <Text id={feature} type="bs" />
                </Flexbox>
              </Padding>
            );
          })}
        </Margin>
      </Padding>
    </Card>
  );
}

SubscriptionTableElement.defaultProps = {
  pending: false,
  isFavorite: false,
};
