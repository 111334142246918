// @flow
import { graphql, useFragment } from 'react-relay';

import { Text, Icon, ServiceCard } from '../../shared';

import type { EnvironmentElementDetail$key } from './__generated__/EnvironmentElementDetail.graphql';

type Props = {|
  +node: EnvironmentElementDetail$key,
|};

export default function EnvironmentElement({ node }: Props): React$Node {
  const data = useFragment(
    graphql`
      fragment EnvironmentElementDetail on Environment {
        id
        name
        namespace
        host
        createdAtFormatted
        deploymentCount
        secretsCount
      }
    `,
    node,
  );

  if (!data) return null;

  return (
    <ServiceCard
      name={data.name}
      createdAtFormatted={data.createdAtFormatted}
      firstCount={data?.deploymentCount ?? 0}
      firstCountLabel="environments.deployments"
      secondCount={data?.secretsCount ?? 0}
      secondCountLabel="environments.secrets"
      footer={
        <>
          <Icon icon="launch" size={24} color="--neutral-color-500" />
          <a href={data.host} target="_blank" rel="noreferrer">
            <Text type="s0r" color="--primary-color-1">
              {data.host}
            </Text>
          </a>
        </>
      }
      serviceURL={`/app/environments/${data?.id}/deployments`}
    />
  );
}
