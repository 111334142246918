/**
 * @generated SignedSource<<ed65490b4f5b160cc5c60ae0f242b322>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type Register$fragmentType: FragmentType;
export type Register$data = {|
  +id: string,
  +domain: string,
  +cert: {|
    +id: string,
    +domainName: string,
    +provider: string,
    +arn: ?string,
    +dnsRecords: $ReadOnlyArray<{|
      +domainName: string,
      +name: string,
      +type: string,
      +value: string,
    |}>,
  |},
  +$fragmentType: Register$fragmentType,
|};
export type Register$key = {
  +$data?: Register$data,
  +$fragmentSpreads: Register$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domainName",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Register",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domain",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Certificate",
      "kind": "LinkedField",
      "name": "cert",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "provider",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "arn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DNSRecord",
          "kind": "LinkedField",
          "name": "dnsRecords",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DomainMapping",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "aea670305fa13bc2bdfe939025d99666";

module.exports = ((node/*: any*/)/*: Fragment<
  Register$fragmentType,
  Register$data,
>*/);
