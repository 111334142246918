// @flow
import stylex from '@serpa-cloud/stylex';
import { Spinner, Text, Icon, Flexbox, InteractiveElement, Padding } from '../../shared';

const styles = stylex.create({
  row: {
    borderRadius: 8,
    backgroundColor: 'var(--card-background)',
    ':hover': {
      backgroundColor: 'var(--neutral-color-200)',
    },
  },
  iconContainer: {
    width: 36,
    height: 36,
    borderRadius: 18,
    backgroundColor: 'var(--neutral-color-400)',
  },
});

type ItemProps = {
  icon: string,
  title: string,
  pending?: ?boolean,
  callback: () => void,
};

export default function ContextualMenuItem({
  icon,
  title,
  callback,
  pending,
}: ItemProps): React$Node {
  return (
    <InteractiveElement onClick={callback} className={stylex(styles.row)}>
      <Padding left={8} vertical={8} right={16}>
        <Flexbox alignItems="center" justifyContent="space-between">
          <Flexbox alignItems="center" columnGap={8}>
            <Flexbox
              alignItems="center"
              justifyContent="center"
              className={stylex(styles.iconContainer)}
            >
              <Icon fill icon={icon} size={20} color="--neutral-color-800" />
            </Flexbox>

            <Text type="s0m" id={title} color="--neutral-color-800" />
          </Flexbox>
          {pending && <Spinner size={16} />}
        </Flexbox>
      </Padding>
    </InteractiveElement>
  );
}

ContextualMenuItem.defaultProps = {
  pending: false,
};
