// @flow
import { createPortal } from 'react-dom';
import stylex from '@serpa-cloud/stylex';
import { useParams } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import { useState, useEffect, lazy, Suspense } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';

import Composer from '../Composer';
import ProfileCover from './ProfileCover';
import ProfilePicture from './ProfilePicture';

import { Text, Card, Grid, Button, Padding, Flexbox, Margin, LiteButton } from '../../shared';

const ProfileEditor = lazy(() => import(/* webpackPrefetch: true */ './ProfileEditor'));

const styles = stylex.create({
  container: {
    paddingTop: 'calc(var(--header-height) + 48px)',
    maxWidth: 1200,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 'calc(100vw - 32px)',
  },
  card: {
    overflow: 'hidden',
  },
  coverBlured: {
    position: 'fixed',
    zIndex: 0,
    top: 0,
    left: 0,
    right: 0,
    height: 352,
    backgroundColor: 'var(--primary-color-1)',
  },
  coverContainer: {
    height: 240,
    position: 'relative',
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    backgroundColor: 'var(--neutral-color-300)',
  },
  editCoverContainer: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
  headData: {
    flex: 1,
  },
  inputFile: {
    width: 0,
    height: 0,
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  bluredImage: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  profilePicture: {
    position: 'relative',
    top: -92,
  },
});

export default function Profile(): React$Node {
  const [showModal, setShowModal] = useState(false);
  const params = useParams();

  const [coverSpringProps, coverSpringAPI] = useSpring(
    () => ({
      from: { opacity: 0 },
      to: { opacity: 1 },
    }),
    [],
  );

  useEffect(() => {
    coverSpringAPI.start({ opacity: 1 });

    return () => {
      coverSpringAPI.stop();
      coverSpringAPI.start({ opacity: 0 });
    };
  });

  const userData = useLazyLoadQuery(
    graphql`
      query ProfileQuery($id: ID!) {
        node(id: $id) {
          id
          ... on User {
            __typename
            name
            lastname
            fullname
            owned
            username
            gender
            pronoun
            description
            media(width: 352, height: 352) {
              ...Avatar
            }
            cover(width: 2400, height: 480) {
              ...Avatar
            }
            bluredCover: cover(width: 30, height: 15, blur: 2) {
              id
              url
            }
          }
        }
      }
    `,
    {
      id: `User:${params?.user ?? ''}`,
    },
    {
      fetchPolicy: 'store-or-network',
    },
  );

  const user = userData.node;

  // eslint-disable-next-line no-underscore-dangle
  if (user?.__typename === 'User') {
    return (
      <>
        {showModal && user?.owned
          ? createPortal(
              // eslint-disable-next-line react/jsx-indent
              <Suspense fallback={<div />}>
                <ProfileEditor
                  name={user?.name ?? ''}
                  lastname={user?.lastname ?? ''}
                  gender={user?.gender ?? ''}
                  pronoun={user?.pronoun ?? ''}
                  description={user?.description ?? ''}
                  onClose={() => setShowModal(false)}
                />
              </Suspense>,
              document.getElementById('modal-portal'),
            )
          : null}

        <div className={stylex(styles.container)}>
          <animated.div className={stylex(styles.coverBlured)} style={coverSpringProps}>
            {user?.bluredCover ? (
              <img alt="" src={user?.bluredCover.url} className={stylex(styles.bluredImage)} />
            ) : null}
          </animated.div>
          <Card className={stylex(styles.card)} hideBorder>
            <ProfileCover
              fullname={user?.fullname ?? ''}
              node={user?.cover ?? null}
              owned={Boolean(user?.owned)}
            />
            <Flexbox columnGap={24}>
              <Padding left={24}>
                <div className={stylex(styles.profilePicture)}>
                  <ProfilePicture
                    fullname={user?.fullname ?? ''}
                    node={user?.media ?? null}
                    owned={Boolean(user?.owned)}
                  />
                </div>
              </Padding>
              <Padding right={24} top={24} className={stylex(styles.headData)}>
                <Flexbox flexDirection="column" rowGap={32}>
                  <Flexbox alignItems="flex-start" justifyContent="space-between">
                    <Flexbox rowGap={8} flexDirection="column">
                      <Text type="h5" color="--neutral-color-800">
                        {user.fullname}
                      </Text>

                      <Text
                        type="s1r"
                        id={`profile.pronoun.${user?.pronoun ?? ''}`}
                        values={{ username: user?.username ?? '' }}
                        color="--neutral-color-700"
                      />
                    </Flexbox>

                    <Flexbox alignItems="center" columnGap={8}>
                      {!user?.owned && (
                        <>
                          <Button intlId="connect" onClick={() => {}} />
                          <LiteButton icon="chat" intlId="profile.chat" onClick={() => {}} />
                        </>
                      )}
                      {user.owned && (
                        <LiteButton
                          icon="edit"
                          intlId="profile.editProfile"
                          onClick={() => setShowModal(true)}
                        />
                      )}
                    </Flexbox>
                  </Flexbox>

                  {user?.description ? (
                    <Flexbox flexDirection="column" rowGap={12}>
                      {user?.description?.split('\n').map((el, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Text type="s0r" key={index}>
                          {el}
                        </Text>
                      ))}
                    </Flexbox>
                  ) : null}
                </Flexbox>
              </Padding>
            </Flexbox>
          </Card>
          <Margin top={24}>
            <Padding bottom={24}>
              <Grid columns="680px 1fr" columnGap={24}>
                <div>
                  <Composer />
                </div>
                <div />
              </Grid>
            </Padding>
          </Margin>
        </div>
      </>
    );
  }

  return null;
}
