/**
 * @generated SignedSource<<660625e571ae37b85984149e517af170>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type EnvironmentElementDetail$fragmentType: FragmentType;
export type EnvironmentElementDetail$data = {|
  +id: string,
  +name: string,
  +namespace: string,
  +host: string,
  +createdAtFormatted: string,
  +deploymentCount: ?number,
  +secretsCount: ?number,
  +$fragmentType: EnvironmentElementDetail$fragmentType,
|};
export type EnvironmentElementDetail$key = {
  +$data?: EnvironmentElementDetail$data,
  +$fragmentSpreads: EnvironmentElementDetail$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EnvironmentElementDetail",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "namespace",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "host",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAtFormatted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deploymentCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "secretsCount",
      "storageKey": null
    }
  ],
  "type": "Environment",
  "abstractKey": null
};

(node/*: any*/).hash = "8e55fe1136fc1d8f206639eb2a39915e";

module.exports = ((node/*: any*/)/*: Fragment<
  EnvironmentElementDetail$fragmentType,
  EnvironmentElementDetail$data,
>*/);
