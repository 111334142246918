// @flow
import { animated } from 'react-spring';
import { createPortal } from 'react-dom';
import stylex from '@serpa-cloud/stylex';

import Notification from './Notification';

import useNotification from './hooks/useNotification';

const styles = stylex.create({
  container: {
    top: 8,
    right: 16,
    zIndex: 99,
    position: 'fixed',
  },
  element: {
    overflow: 'hidden',
  },
});

export default function Notifications(): React$Node {
  const [notifications, transitions] = useNotification();

  if (notifications.length)
    return createPortal(
      // eslint-disable-next-line react/jsx-indent
      <div className={stylex(styles.container)}>
        {transitions((style, item) => {
          return (
            <animated.div style={style} className={stylex(styles.element)}>
              <Notification
                id={item.id}
                life={style.life}
                type={item.type}
                cancel={item.cancel}
                message={item.message}
              />
            </animated.div>
          );
        })}
      </div>,
      document.getElementById('notification-portal'),
    );

  return null;
}
