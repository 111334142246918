/* eslint-disable camelcase */
// @flow
import { useIntl } from 'react-intl';
import stylex from '@serpa-cloud/stylex';
import { ConnectionHandler } from 'relay-runtime';
import { Navigate, useNavigate } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';
import { useState, useCallback, useMemo, useContext, Suspense, useEffect } from 'react';

import {
  graphql,
  fetchQuery,
  useMutation,
  useLazyLoadQuery,
  requestSubscription,
  useRelayEnvironment,
} from 'react-relay';

import {
  Icon,
  Text,
  Margin,
  Button,
  Spinner,
  Padding,
  Divider,
  Flexbox,
  FastSelect,
  CascaderOption,
  FastSearchInput,
  EmptyState,
  InteractiveElement,
  useNotification,
} from '../../../shared';

import { Context } from '../Provider';

import TabsConponent from './components/Tabs';

import GitSelectorQuery from './__generated__/GitSelectorQuery.graphql';
import type { GitSelectorSubscription } from './__generated__/GitSelectorSubscription.graphql';
import type { GitSelectorCreateAppMutation } from './__generated__/GitSelectorCreateAppMutation.graphql';

import useDevice from '../../../shared/hooks/useDevice';

const styles = stylex.create({
  repoRowContent: {
    height: 28,
  },
  repoAvatar: {
    width: 24,
    height: 24,
    borderRadius: 12,
  },
  loadingContainer: {
    width: '100%',
    height: '100%',
    minHeight: 320,
  },
  selectContainer: {
    width: 280,
  },
  selectContainerphone: {
    width: '100%',
  },
  searchContainer: {
    flex: 1,
  },
  searchContainerPhone: {
    width: '100%',
  },
  repoItem: {
    backgroundColor: 'var(--surface)',
    border: '1px solid var(--border-color)',
    borderBottom: '0px',
    ':last-child': {
      borderBottom: '1px solid var(--border-color)',
    },
  },
  radioButton: {
    width: '24px',
    height: '24px',
    borderRadius: '12px',
  },
  repoLogo: {
    width: '24px',
    height: '24px',
    borderRadius: '12px',
    backgroundColor: 'var(--neutral-color-100)',
  },
  repoImage: {
    width: '24px',
    height: '24px',
    borderRadius: '12px',
  },
  importbutton: {
    boxShadow: 'var(--shadow-1)',
    borderRadius: 24,
    border: '1px solid var(--border-color)',
    transitionProperty: 'all',
    transitionDuration: 'var(--fds-duration-short-in)',
    transitionTimingFunction: 'var(--fds-animation-fade-in)',
    flexShrink: 0,
    width: 99,
    height: 28,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ':hover': {
      backgroundColor: 'var(--neutral-color-300)',
      transitionDuration: 'var(--fds-duration-short-out)',
      transitionTimingFunction: 'var(--fds-animation-fade-out)',
    },
  },
  filterContainer: {
    position: 'sticky',
    zIndex: 9,
    top: 0,
    paddingTop: 16,
    boxSizing: 'border-box',
    '@media (max-width: 815px) and (min-width: 541px)': {
      width: 'calc(100% + 32px)',
      transform: 'translateX(-16px)',
      paddingRight: 16,
      paddingLeft: 16,
    },
    '@media (max-width: 540px)': {
      width: 'calc(100% + 16px)',
      transform: 'translateX(-8px)',
      paddingRight: 8,
      paddingLeft: 8,
    },
  },
  spinnerContainer: {
    width: 99,
  },
  repoName: {
    flex: 1,
  },
});

const subscription = graphql`
  subscription GitSelectorSubscription {
    gitValidation {
      provider
      available
    }
  }
`;

function RepoRow({
  repo,
  currentTab,
  selectedRepo,
  setSelectedRepo,
}: {
  selectedRepo: ?string,
  setSelectedRepo: (?string) => void,
  currentTab: 0 | 1,
  repo: {|
    +id: string,
    +name: string,
    +ownerAvatar: ?string,
    +url: string,
  |},
}) {
  const [isHover, setIsHover] = useState(false);
  const intl = useIntl();
  const navigate = useNavigate();
  const [, , setNotification] = useNotification();

  const { screenSize } = useDevice();

  const [createApp, createPending] = useMutation<GitSelectorCreateAppMutation>(graphql`
    mutation GitSelectorCreateAppMutation($input: AppInput!, $triggerCompilation: Boolean) {
      createApp(input: $input, triggerCompilation: $triggerCompilation) {
        id
        namespace
        name
        createdAtFormatted
        lastCompilation
        media(width: 80, height: 80) {
          ...Avatar
        }
        createdBy {
          fullname
        }
      }
    }
  `);

  const handleOnMouseEnter = useCallback(() => {
    setIsHover(true);
  }, []);

  const handleOnMouseLeave = useCallback(() => {
    setIsHover(false);
  }, []);

  const handleCreateApp = useCallback(() => {
    setSelectedRepo(repo.id);

    createApp({
      variables: {
        triggerCompilation: true,
        input: {
          appMethod: 'GIT',
          gitProvider: currentTab === 0 ? 'GITHUB' : 'GITLAB',
          source: repo.id ?? '',
        },
      },
      updater(store) {
        const root = store.getRoot();

        const edges = ConnectionHandler.getConnection(root, 'ScrolledList_root_entities', {
          index: 'APPS',
          filterMatrix: [
            [
              {
                property: 'deleted',
                type: 'term',
                valueBoolean: false,
              },
            ],
          ],
        });

        const payload = store.getRootField('createApp');

        if (!edges || !payload) return;

        const newEdge = ConnectionHandler.createEdge(store, edges, payload, 'Edge');

        ConnectionHandler.insertEdgeBefore(edges, newEdge);
      },
      onCompleted(response) {
        const appResponse = response?.createApp;

        if (appResponse) {
          amplitude.track('App Created');

          if (appResponse.lastCompilation) {
            navigate(
              `/app/apps/${appResponse.id}/compilations/${appResponse.lastCompilation}?autodeploy=true`,
            );
          } else navigate(`/app/apps/${appResponse.id}/compilations`);

          setNotification({
            id: new Date().getTime().toString(),
            type: 'SUCCESS',
            // $FlowIgnore
            message: intl.formatMessage({ id: 'app.SucessCreated' }),
          });
        } else {
          setSelectedRepo(null);
        }
      },
      onError(error) {
        setSelectedRepo(null);

        amplitude.track('Create App Error');

        // eslint-disable-next-line no-console
        console.trace(error);

        setNotification({
          id: new Date().getTime().toString(),
          type: 'ERROR',
          message: intl.formatMessage({
            // $FlowIgnore
            id: error.source.errors[0].message,
          }),
        });
      },
    });
  }, [createApp, currentTab, intl, navigate, repo.id, setNotification, setSelectedRepo]);

  return (
    <div
      key={repo.id}
      className={stylex([styles.repoItem])}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <InteractiveElement disabled={createPending} onClick={handleCreateApp}>
        <Padding
          horizontal={screenSize === 'phone' ? 8 : 16}
          vertical={screenSize === 'phone' ? 8 : 16}
          key={repo.id}
        >
          <Flexbox
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            columnGap={8}
            className={stylex(styles.repoRowContent)}
          >
            <Flexbox flexDirection="row" alignItems="center" columnGap={8}>
              <div className={stylex(styles.repoLogo)}>
                {repo.ownerAvatar ? (
                  <img
                    alt={repo.name}
                    src={repo.ownerAvatar}
                    className={stylex(styles.repoImage)}
                  />
                ) : null}
              </div>

              <div className={stylex(styles.repoName)}>
                <Text type="s1m" color="--neutral-color-700">
                  {(repo.name ?? '')?.split('/')?.[1] ?? ''}
                </Text>
              </div>
            </Flexbox>
            {isHover && !createPending && !selectedRepo ? (
              <div className={stylex(styles.importbutton)}>
                <Padding horizontal={16} vertical={screenSize === 'phone' ? 16 : 8}>
                  <Text type="s0m" color="--neutral-color-800" id="gitSelector.createApp" />
                </Padding>
              </div>
            ) : null}
            {createPending && (
              <Flexbox
                justifyContent="center"
                alignItems="center"
                className={stylex(styles.importbutton)}
              >
                <Spinner size={16} />
              </Flexbox>
            )}
          </Flexbox>
        </Padding>
      </InteractiveElement>
    </div>
  );
}

function GitSelector(): React$Node {
  const { screenSize } = useDevice();
  const intl = useIntl();
  const environment = useRelayEnvironment();
  const [selectedRepo, setSelectedRepo] = useState(null);

  const [currentTab, setCurrentTab] = useState(0);
  const [refreshedQueryOptions, setRefreshedQueryOptions] = useState({
    fetchPolicy: 'store-or-network',
  });

  const data = useLazyLoadQuery(
    graphql`
      query GitSelectorQuery($provider: GitProvider!) {
        me {
          id
          canFetchFromGithub
          canFetchFromGitlab
        }
        gitRepos(provider: $provider) {
          id
          name
          url
          ownerAvatar
        }
      }
    `,
    {
      provider: currentTab === 0 ? 'GITHUB' : 'GITLAB',
    },
    refreshedQueryOptions,
  );

  const [searchValue, setSearchValue] = useState<string>('');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [loading, setLoading] = useState(false);

  const organizations = useMemo(() => {
    return (data?.gitRepos ?? []).reduce((accumulator, repo) => {
      const orgString = (repo?.name ?? '').split('/')[0];
      const newArray = [...accumulator];

      const existingNode = newArray.find((el) => el.id === orgString);
      if (!existingNode) return [...newArray, { id: orgString, avatar: repo.ownerAvatar }];
      return newArray;
    }, []);
  }, [data]);

  const [selectedOrganizations, setSelectedOrganizations] = useState(
    organizations?.[0]?.id ? [organizations[0].id] : [],
  );

  useEffect(() => {
    setSelectedOrganizations(organizations?.[0]?.id ? [organizations[0].id] : []);
  }, [currentTab, organizations]);

  const refresh = useCallback(() => {
    if (isRefreshing) {
      return;
    }
    setIsRefreshing(true);

    fetchQuery(environment, GitSelectorQuery, {
      provider: currentTab === 0 ? 'GITHUB' : 'GITLAB',
    }).subscribe({
      complete: () => {
        setIsRefreshing(false);
        setLoading(false);

        setRefreshedQueryOptions((prev) => ({
          fetchKey: (prev?.fetchKey ?? 0) + 1,
          fetchPolicy: 'store-only',
        }));
      },
      error: () => {
        setIsRefreshing(false);
      },
    });
  }, [environment, isRefreshing, currentTab]);

  const handleConnectToGitlab = useCallback(() => {
    setLoading(true);

    const disposeSubscription = requestSubscription<GitSelectorSubscription>(environment, {
      subscription,
      onNext(payload) {
        // $FlowIgnore
        if (payload?.gitValidation?.available) {
          setTimeout(() => {
            setLoading(false);
          }, 5000);

          refresh();
        }
        // $FlowIgnore
        disposeSubscription.dispose();
      },
      onError(e) {
        // eslint-disable-next-line no-console
        console.trace(e);
      },
      variables: {},
    });
    window.open(
      `https://gitlab.com/oauth/authorize?client_id=${process.env.REACT_APP_GITLAB_API_CLIENT ??
        ''}&redirect_uri=${encodeURIComponent(
        `${process.env.REACT_APP_GITLAB_REDIRECT_URI ?? ''}/git/gitlab/validation`,
      )}&response_type=code&scope=api+read_api+read_user+read_repository+write_repository`,
    );
  }, [environment, refresh]);

  const handleConnectToGithub = useCallback(() => {
    setLoading(true);

    const disposeSubscription = requestSubscription<GitSelectorSubscription>(environment, {
      subscription,
      onNext(payload) {
        // $FlowIgnore
        if (payload?.gitValidation?.available) {
          setTimeout(() => {
            setLoading(false);
          }, 5000);

          refresh();
        }
        // $FlowIgnore
        disposeSubscription.dispose();
      },
      onError(e) {
        // eslint-disable-next-line no-console
        console.trace(e);
      },
      variables: {},
    });

    window.open(
      `https://github.com/login/oauth/authorize?client_id=${process.env
        .REACT_APP_GITHUB_API_CLIENT ?? ''}&scope=repo,read:org&redirect_uri=${encodeURIComponent(
        `${window.location.origin}/git/github/validation`,
      )}`,
    );
  }, [environment, refresh]);

  const finalRepos = useMemo(() => {
    return (
      data?.gitRepos?.filter((repo) => {
        if (selectedOrganizations.length === 0 && !searchValue) return true;

        if (searchValue) {
          if (
            !repo.name
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .includes(
                searchValue
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, ''),
              )
          )
            return false;

          if (selectedOrganizations.length === 0) return true;
        }
        return selectedOrganizations.some((org) => repo.name.includes(org));
      }) ?? []
    );
  }, [data, selectedOrganizations, searchValue]);

  return (
    <TabsConponent setCurrentTab={setCurrentTab} activeTab={currentTab}>
      {!data?.me?.canFetchFromGithub && currentTab === 0 && (
        <Margin top={40}>
          <Flexbox flexDirection="column" rowGap={24}>
            <Text type="h6" id="appCreator.grantAccessGithub" />
            <Text type="bd" id="appCreator.grantAccessGithubDescription" />
          </Flexbox>

          <Padding vertical={24}>
            <Divider />
          </Padding>
          <Flexbox justifyContent="space-between" alignItems="center">
            <Flexbox columnGap={24} alignItems="center">
              <Button
                disabled={loading}
                onClick={handleConnectToGithub}
                intlId="appCreator.grantAccessGithubAction"
              />
              {loading && <Spinner size={24} />}
            </Flexbox>
          </Flexbox>
        </Margin>
      )}
      {!data?.me?.canFetchFromGitlab && currentTab === 1 && (
        <Margin top={40}>
          <Flexbox flexDirection="column" rowGap={24}>
            <Text type="h6" id="appCreator.grantAccessGitlab" />
            <Text type="bd" id="appCreator.grantAccessGithubDescription" />
          </Flexbox>

          <Padding vertical={24}>
            <Divider />
          </Padding>
          <Flexbox justifyContent="space-between" alignItems="center">
            <Flexbox columnGap={24} alignItems="center">
              <Button
                disabled={loading}
                onClick={handleConnectToGitlab}
                intlId="appCreator.grantAccessGithubAction"
              />
              {loading && <Spinner size={24} />}
            </Flexbox>
          </Flexbox>
        </Margin>
      )}
      {((data?.me?.canFetchFromGithub && currentTab === 0) ||
        (data?.me?.canFetchFromGitlab && currentTab === 1)) && (
        <>
          {!!data?.gitRepos?.length && (
            <>
              <Margin top={24}>
                <Text
                  type="s0m"
                  id="appCreator.gitRepoCounter"
                  color="--primary-color-1"
                  values={{ count: finalRepos.length, total: data?.gitRepos?.length ?? 0 }}
                />
              </Margin>

              <div className={stylex(styles.filterContainer)}>
                <Flexbox
                  alignItems="center"
                  flexDirection={screenSize === 'phone' ? 'column' : 'row'}
                  columnGap={16}
                  rowGap={16}
                >
                  <div
                    className={stylex(
                      screenSize === 'phone' ? styles.selectContainerphone : styles.selectContainer,
                    )}
                  >
                    <FastSelect
                      key={currentTab === 0 ? 'GITHUB' : 'GITLAB'}
                      value={selectedOrganizations}
                      onChange={(value: Array<string>) => setSelectedOrganizations(value)}
                      placeholder={intl.formatMessage({
                        id: 'appCreator.gitRepoSelectPlaceholder',
                      })}
                      label={selectedOrganizations.map((orgId) => {
                        const org = organizations.find((el) => el.id === orgId);
                        if (!org) return null;
                        return (
                          <Flexbox alignItems="center" columnGap={12} key={orgId}>
                            <img
                              alt={org.id}
                              src={org.avatar}
                              className={stylex(styles.repoAvatar)}
                            />
                            <Text type="s1m" color="--neutral-color-800">
                              {org.id}
                            </Text>
                          </Flexbox>
                        );
                      })}
                    >
                      {organizations.map((org) => (
                        <CascaderOption
                          key={org.id}
                          value={org.id}
                          label={
                            <Flexbox alignItems="center" columnGap={12}>
                              <img
                                alt={org.id}
                                src={org.avatar}
                                className={stylex(styles.repoAvatar)}
                              />
                              <Text type="s1m" color="--neutral-color-600">
                                {org.id}
                              </Text>
                            </Flexbox>
                          }
                        />
                      ))}
                    </FastSelect>
                  </div>
                  <div
                    className={stylex(
                      screenSize === 'phone' ? styles.searchContainerPhone : styles.searchContainer,
                    )}
                  >
                    <FastSearchInput
                      key={currentTab === 0 ? 'GITHUB' : 'GITLAB'}
                      onChange={setSearchValue}
                      placeholder={intl.formatMessage({
                        id: 'appCreator.gitRepoSearchPlaceholder',
                      })}
                    />
                  </div>
                </Flexbox>
                <Padding top={16}>
                  <Divider />
                </Padding>
              </div>
            </>
          )}

          <Margin top={16}>
            <Flexbox flexDirection="column" className={stylex(styles.listContainer)}>
              {!!finalRepos.length &&
                finalRepos.map((repo) => (
                  <RepoRow
                    currentTab={currentTab}
                    repo={repo}
                    selectedRepo={selectedRepo}
                    setSelectedRepo={setSelectedRepo}
                  />
                ))}
              {!finalRepos.length && (
                <Padding bottom={24}>
                  <EmptyState />
                </Padding>
              )}
            </Flexbox>
          </Margin>
        </>
      )}
    </TabsConponent>
  );
}

export default function GitSelectorInterface(): React$Node {
  const { width } = useDevice();
  const { state } = useContext(Context);

  if (!state.method) return <Navigate replace to="/app/apps/create/catalog" />;

  return (
    <>
      <Padding bottom={32}>
        <Flexbox flexDirection="column" rowGap={24}>
          <Flexbox alignItems="center" columnGap={8}>
            <Icon
              fill
              icon="token"
              gradient="linear-gradient(265.7deg, var(--orange-solid-color) -2.24%, var(--red-solid-color) 102.98%)"
            />
            <Flexbox flexDirection="column">
              <Text
                type={width < 1000 ? 'h6' : 'h5'}
                component="h1"
                id="appCreator.gitSelectorTitle"
              />
            </Flexbox>
          </Flexbox>
          <Text id="appCreator.gitRepoDescription" type={width > 1000 ? 'bd' : 'bs'} />
        </Flexbox>
      </Padding>

      <Padding bottom={80}>
        <Suspense
          fallback={
            <Padding top={200} bottom={200}>
              <Flexbox justifyContent="center" alignItems="center">
                <Spinner size={40} />
              </Flexbox>
            </Padding>
          }
        >
          <GitSelector />
        </Suspense>
      </Padding>
    </>
  );
}
