/**
 * @generated SignedSource<<e7bdb7510d2f72890258b9d693f6bda7>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type BuildRemediationsQuery$variables = {|
  buildId: string,
|};
export type BuildRemediationsQuery$data = {|
  +getBuildRemediations: ?string,
|};
export type BuildRemediationsQuery = {|
  variables: BuildRemediationsQuery$variables,
  response: BuildRemediationsQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "buildId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "buildId",
        "variableName": "buildId"
      }
    ],
    "kind": "ScalarField",
    "name": "getBuildRemediations",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BuildRemediationsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BuildRemediationsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "400a34c71b982898f39c5252f9978c80",
    "id": null,
    "metadata": {},
    "name": "BuildRemediationsQuery",
    "operationKind": "query",
    "text": "query BuildRemediationsQuery(\n  $buildId: ID!\n) {\n  getBuildRemediations(buildId: $buildId)\n}\n"
  }
};
})();

(node/*: any*/).hash = "e85651c666a81974a4ad78b2f053ff1b";

module.exports = ((node/*: any*/)/*: Query<
  BuildRemediationsQuery$variables,
  BuildRemediationsQuery$data,
>*/);
