/**
 * @generated SignedSource<<c2c29acab15332f65665651f8a8f3533>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
type DeploymentElementData$fragmentType = any;
export type AppMethod = "GIT" | "DOCKER" | "%future added value";
export type DeploymentPrivacy = "PUBLIC" | "PRIVATE" | "%future added value";
export type DeploymentInputFlow = {|
  name: string,
  app: string,
  build?: ?string,
  environment: string,
  env: $ReadOnlyArray<DeploymentEnvInput>,
  postStartExecCommand?: ?$ReadOnlyArray<string>,
  replicas?: ?number,
  volumeMounts?: ?$ReadOnlyArray<VolumeMountInput>,
  volumes?: ?$ReadOnlyArray<VolumeInput>,
  privacy: DeploymentPrivacy,
  enableCors: boolean,
  allowOrigin?: ?$ReadOnlyArray<string>,
  allowHeaders?: ?$ReadOnlyArray<string>,
  allowMethods?: ?$ReadOnlyArray<string>,
  continousDeployment?: ?string,
  patternTag?: ?string,
  dockerImageUrl?: ?string,
  port: number,
|};
export type DeploymentEnvInput = {|
  name: string,
  value?: ?string,
  valueFrom?: ?ValueFromInput,
|};
export type ValueFromInput = {|
  secretKeyRef?: ?ValueFromSecretKeyRefInput,
|};
export type ValueFromSecretKeyRefInput = {|
  name: string,
  key: string,
  optional: boolean,
|};
export type VolumeMountInput = {|
  name: string,
  mountPath: string,
  readOnly?: ?boolean,
|};
export type VolumeInput = {|
  name: string,
  secret?: ?SecretVolumeSourceInput,
|};
export type SecretVolumeSourceInput = {|
  secretName: string,
  defaultMode?: ?number,
|};
export type DeploymentConfiguratorMutation$variables = {|
  input: DeploymentInputFlow,
|};
export type DeploymentConfiguratorMutation$data = {|
  +createDeploymentFlow: {|
    +id: string,
    +port: number,
    +app: {|
      +id: string,
      +name: string,
      +namespace: string,
      +appMethod: AppMethod,
    |},
    +dockerImageUrl: ?string,
    +name: string,
    +replicas: number,
    +namespace: string,
    +serviceName: ?string,
    +publicEndpoint: ?string,
    +publicVirtualService: ?string,
    +createdBy: {|
      +id: string,
      +fullname: ?string,
    |},
    +environment: {|
      +id: string,
      +name: string,
    |},
    +createdAtFormatted: string,
    +continousDeployment: ?string,
    +$fragmentSpreads: DeploymentElementData$fragmentType,
  |},
|};
export type DeploymentConfiguratorMutation = {|
  variables: DeploymentConfiguratorMutation$variables,
  response: DeploymentConfiguratorMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "port",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "namespace",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "App",
  "kind": "LinkedField",
  "name": "app",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appMethod",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dockerImageUrl",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "replicas",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publicEndpoint",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publicVirtualService",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "createdBy",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullname",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Environment",
  "kind": "LinkedField",
  "name": "environment",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAtFormatted",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "continousDeployment",
  "storageKey": null
},
v16 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "commitShaShort",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "commitUrl",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "gitBaseIsTag",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "gitTag",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "gitTagUrl",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "commitDescription",
    "storageKey": null
  }
],
v17 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Artifact",
    "kind": "LinkedField",
    "name": "artifact",
    "plural": false,
    "selections": (v16/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeploymentConfiguratorMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Deployment",
        "kind": "LinkedField",
        "name": "createDeploymentFlow",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v4/*: any*/),
          (v8/*: any*/),
          (v5/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DeploymentElementData"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeploymentConfiguratorMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Deployment",
        "kind": "LinkedField",
        "name": "createDeploymentFlow",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v4/*: any*/),
          (v8/*: any*/),
          (v5/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pause",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Implementation",
            "kind": "LinkedField",
            "name": "implementation",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeploymentStatus",
            "kind": "LinkedField",
            "name": "status",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "readyReplicas",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedReplicas",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "availableReplicas",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "unavailableReplicas",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "observedGeneration",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Pod",
            "kind": "LinkedField",
            "name": "pods",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PodStatus",
                "kind": "LinkedField",
                "name": "status",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phase",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "source",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v16/*: any*/),
                "type": "Artifact",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v17/*: any*/),
                "type": "Release",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v17/*: any*/),
                "type": "ReleaseChannel",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1898e963c871511e70208f8403736d1c",
    "id": null,
    "metadata": {},
    "name": "DeploymentConfiguratorMutation",
    "operationKind": "mutation",
    "text": "mutation DeploymentConfiguratorMutation(\n  $input: DeploymentInputFlow!\n) {\n  createDeploymentFlow(input: $input) {\n    id\n    port\n    app {\n      id\n      name\n      namespace\n      appMethod\n    }\n    dockerImageUrl\n    name\n    replicas\n    namespace\n    serviceName\n    publicEndpoint\n    publicVirtualService\n    createdBy {\n      id\n      fullname\n    }\n    environment {\n      id\n      name\n    }\n    createdAtFormatted\n    continousDeployment\n    ...DeploymentElementData\n  }\n}\n\nfragment DeploymentElementData on Deployment {\n  id\n  name\n  pause\n  createdAtFormatted\n  dockerImageUrl\n  app {\n    id\n    name\n    namespace\n    appMethod\n  }\n  implementation {\n    serviceName\n    id\n  }\n  environment {\n    id\n    name\n  }\n  status {\n    replicas\n    readyReplicas\n    updatedReplicas\n    availableReplicas\n    unavailableReplicas\n    observedGeneration\n  }\n  publicEndpoint\n  pods {\n    status {\n      phase\n    }\n  }\n  source {\n    __typename\n    ... on Artifact {\n      id\n      commitShaShort\n      commitUrl\n      gitBaseIsTag\n      gitTag\n      gitTagUrl\n      commitDescription\n    }\n    ... on Release {\n      id\n      artifact {\n        id\n        commitShaShort\n        commitUrl\n        gitBaseIsTag\n        gitTag\n        gitTagUrl\n        commitDescription\n      }\n    }\n    ... on ReleaseChannel {\n      id\n      artifact {\n        id\n        commitShaShort\n        commitUrl\n        gitBaseIsTag\n        gitTag\n        gitTagUrl\n        commitDescription\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "936fc4ad64e0f2d825a38b2769330072";

module.exports = ((node/*: any*/)/*: Mutation<
  DeploymentConfiguratorMutation$variables,
  DeploymentConfiguratorMutation$data,
>*/);
