// @flow
import { memo } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import { Icon, Text, Flexbox } from '../../../shared';

import type { DNSQueryRecordInput } from './__generated__/RegisterDNSValidationQuery.graphql';

type Props = {|
  records: Array<DNSQueryRecordInput>,
|};

function RegisterDNSValidation({ records }: Props): React$Node {
  const validation = useLazyLoadQuery(
    graphql`
      query RegisterDNSValidationQuery($records: [DNSQueryRecordInput!]!) {
        lookupDNSRecords(records: $records)
      }
    `,
    {
      records,
    },
    {
      fetchPolicy: 'store-and-network',
    },
  );

  if (validation.lookupDNSRecords)
    return (
      <Flexbox alignItems="center" columnGap={8}>
        <Icon icon="check_circle" fill color="--green-300" size={24} />
        <Text id="domains.validRecords" type="s0r" color="--neutral-color-600" />
      </Flexbox>
    );

  return (
    <Flexbox alignItems="center" columnGap={8}>
      <Icon icon="error" fill color="--red-300" size={24} />
      <Text id="domains.invalidRecords" type="s0b" color="--red-300" />
    </Flexbox>
  );
}

export default (memo<Props>(RegisterDNSValidation): React$AbstractComponent<Props, mixed>);
