// @flow
import { useIntl } from 'react-intl';
import { graphql, useLazyLoadQuery } from 'react-relay';

import { Text, FastSelect, CascaderOption } from '../../shared';

type Props = {|
  +disabled?: ?boolean,
  +appId: Array<string>,
  onChange: (Array<string>) => void,
|};

export default function CompilationSourceAppList({ disabled, appId, onChange }: Props): React$Node {
  const intl = useIntl();

  const data = useLazyLoadQuery(
    graphql`
      query CompilationSourceAppListQuery($filterMatrix: [[FilterInput]]) {
        entities(first: 99, index: APPS, filterMatrix: $filterMatrix) {
          pageInfo {
            hasNextPage
            endCursor
            finalCursor
          }
          edges {
            id
            cursor
            node {
              __typename
              ... on App {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      filterMatrix: [
        [
          {
            property: 'deleted',
            type: 'term',
            valueBoolean: false,
          },
        ],
      ],
    },
    { fetchPolicy: 'network-only', networkCacheConfig: { force: false } },
  );

  const edges = data?.entities?.edges;
  const selectedEdge = edges?.find((e) => e?.node?.id === appId?.[0]);

  return (
    <FastSelect
      placeholder={intl.formatMessage({
        id: 'compilationSource.appPlaceholder',
      })}
      disabled={disabled}
      value={appId}
      onChange={onChange}
      label={
        selectedEdge ? (
          <Text type="s0m" color="--neutral-color-600">
            {selectedEdge.node.name}
          </Text>
        ) : null
      }
    >
      {edges?.map((edge) =>
        // eslint-disable-next-line no-underscore-dangle
        edge?.node?.__typename === 'App' ? (
          <CascaderOption
            key={edge.id}
            value={edge.node.id}
            label={
              <Text type="s0m" color="--neutral-color-600">
                {edge.node.name}
              </Text>
            }
          />
        ) : null,
      ) ?? []}
    </FastSelect>
  );
}

CompilationSourceAppList.defaultProps = {
  disabled: false,
};
