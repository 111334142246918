// @flow
import { useIntl } from 'react-intl';
import stylex from '@serpa-cloud/stylex';
import { ConnectionHandler } from 'relay-runtime';
import { graphql, useMutation } from 'react-relay/hooks';
import { Navigate, useNavigate } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';
import { useContext, useCallback, useState, startTransition } from 'react';

import { Context } from '../Provider';

import {
  Icon,
  Text,
  Input,
  Margin,
  Flexbox,
  Padding,
  useInput,
  validateData,
  useNotification,
} from '../../../shared';

import useDevice from '../../../shared/hooks/useDevice';

import NameSpaceAvailability from './AppNameSpaceAvailability';
import type { AppNameCreateDockerAppMutation } from './__generated__/AppNameCreateDockerAppMutation.graphql';

const styles = stylex.create({
  container: {
    width: '100%',
  },
  appInput: {
    flex: 1,
  },
  organizationInput: {
    width: 240,
    zIndex: 9,
  },
});

let timer;

export default function AppName(): React$Node {
  const { screenSize } = useDevice();
  const intl = useIntl();
  const [, , setNotification] = useNotification();

  const navigate = useNavigate();
  const { state, dispatch } = useContext(Context);

  const { gitRepo, method } = state;
  const gitRepoAppName = (state?.appName ?? gitRepo?.name?.split('/')?.[1] ?? '').toLowerCase();
  const [selectedName, setSelectedName] = useState(gitRepoAppName);

  const [createApp, createPending] = useMutation<AppNameCreateDockerAppMutation>(graphql`
    mutation AppNameCreateDockerAppMutation($input: AppInput!, $triggerCompilation: Boolean) {
      createApp(input: $input, triggerCompilation: $triggerCompilation) {
        id
        namespace
        name
        createdAtFormatted
        createdBy {
          fullname
        }
      }
    }
  `);

  const name = useInput({
    name: 'name',
    required: true,
    toLowerCase: true,
    // eslint-disable-next-line no-useless-escape
    regexpOverwrite: /^[a-z0-9\-]*/,
    label: intl.formatMessage({ id: 'appCreator.appNameInputLabel' }),
    value: gitRepoAppName,
    onChange: (e) => {
      clearTimeout(timer);

      timer = setTimeout(() => {
        startTransition(() => {
          setSelectedName(e.target.value);
        });
      }, 350);
    },
    errors: {
      requiredError: intl.formatMessage({ id: 'input.requiredError' }),
    },
  });

  const handleOnSubmit = useCallback(() => {
    const { errors, data } = validateData([name]);

    if (!errors) {
      amplitude.track('Set App Name');

      if (method === 'GIT') {
        dispatch({ type: 'SET_APP_NAME', appName: data.name.toLowerCase() });
        navigate('/app/apps/create/setup');
      }

      if (method === 'DOCKER') {
        createApp({
          variables: {
            input: {
              env: [],
              builder: 'DOCKER',
              name: data.name.toLowerCase(),
              dockerfileContent: '',
              appType: 'CONTAINER',
              appMethod: 'DOCKER',
            },
          },
          updater(store) {
            const root = store.getRoot();

            const edges = ConnectionHandler.getConnection(root, 'ScrolledList_root_entities', {
              index: 'APPS',
              filterMatrix: [
                [
                  {
                    property: 'deleted',
                    type: 'term',
                    valueBoolean: false,
                  },
                ],
              ],
            });

            const payload = store.getRootField('createApp');

            if (!edges || !payload) return;

            const newEdge = ConnectionHandler.createEdge(store, edges, payload, 'Edge');

            ConnectionHandler.insertEdgeBefore(edges, newEdge);
          },
          onCompleted(response) {
            const appResponse = response?.createApp;

            if (appResponse) {
              amplitude.track('App Created');

              dispatch({
                type: 'SET_DOCKER_APP_ID',
                appName: data.name.toLowerCase(),
                appId: appResponse?.id,
                appNamespaceValue: appResponse.namespace,
              });

              navigate(`/app/apps/${appResponse.id}/deployments`);

              setNotification({
                id: new Date().getTime().toString(),
                type: 'SUCCESS',
                // $FlowIgnore
                message: intl.formatMessage({ id: 'app.SucessCreated' }),
              });
            }
          },
        });
      }
    } else {
      amplitude.track('Set App Name Error');
    }
  }, [createApp, dispatch, intl, method, name, navigate, setNotification]);

  if (!state.method) return <Navigate replace to="/app/apps/create/catalog" />;

  if (state.method === 'GIT' && !(state.gitRepo && state.gitProvider))
    return <Navigate replace to="/app/apps/create/git" />;

  return (
    <Padding bottom={24} className={stylex(styles.container)}>
      <Padding bottom={32}>
        <Flexbox flexDirection="column" rowGap={24}>
          <Flexbox alignItems="center" columnGap={8}>
            <Icon
              fill
              icon="token"
              gradient="linear-gradient(265.7deg, var(--orange-solid-color) -2.24%, var(--red-solid-color) 102.98%)"
            />
            <Flexbox flexDirection="column">
              <Text type="h5" component="h1" id="appCreator.appNameTitle" />
            </Flexbox>
          </Flexbox>
          <Text id="appCreator.appNameDescription" type={screenSize === 'phone' ? 'bs' : 'bd'} />
        </Flexbox>
      </Padding>

      <Flexbox columnGap={8}>
        <div className={stylex(styles.appInput)}>
          <Input input={name.input} />
        </div>
      </Flexbox>
      <Margin top={8}>
        <NameSpaceAvailability
          appId={state.appId}
          name={selectedName}
          onSubmit={handleOnSubmit}
          pendingSubmit={createPending}
          currentName={state?.appName ?? ''}
        />
      </Margin>
    </Padding>
  );
}
