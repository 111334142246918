// @flow
import { Suspense } from 'react';
import stylex from '@serpa-cloud/stylex';
import * as amplitude from '@amplitude/analytics-browser';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Icon, Text, Breadcrumb, Padding, Flexbox, Button, useInput } from '../../shared';
import DeploymentAppList from './DeploymentAppList';

import useDevice from '../../shared/hooks/useDevice';

const styles = stylex.create({
  container: {
    maxWidth: 560,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  fakePlaceholder: {
    height: 56,
    background: 'var(--neutral-color-200)',
  },
});

export default function DeploymentSource(): React$Node {
  const { width } = useDevice();
  const [searchParams] = useSearchParams();
  const componentParam = searchParams.get('component');
  const componentIdParam = searchParams.get('componentId');
  const navigate = useNavigate();

  const app = useInput({
    name: 'app',
    hideSearch: true,
    autoCloseSelect: true,
    label: { id: 'selectAnApp' },
    replacer(v) {
      if (!v) return app.input.value;
      return v;
    },
  });

  return (
    <>
      <Breadcrumb
        sections={[
          { link: '/app/deployments', textId: 'home.deployments', icon: 'rocket_launch' },
          {
            link: '/app/deployments/select-source',
            textId: 'pageview.selectDeploymentSource',
            icon: 'frame_source',
          },
        ]}
      />

      <Padding
        className={stylex(styles.container)}
        top={32}
        horizontal={(width <= 540 && 8) || (width > 540 && width <= 1000 && 16) || 0}
      >
        <Padding bottom={24}>
          <Flexbox alignItems="center" columnGap={8}>
            <Icon
              fill
              weight={700}
              icon="rocket_launch"
              gradient="linear-gradient(265.7deg, var(--green-solid-color) -2.24%, var(--cyan-solid-color) 102.98%)"
            />
            <Flexbox flexDirection="column">
              <Text
                type={width <= 1000 ? 'h6' : 'h5'}
                component="h1"
                id="pageview.selectDeploymentSource"
              />
            </Flexbox>
          </Flexbox>
        </Padding>

        <Suspense fallback={<div className={stylex(styles.fakePlaceholder)} />}>
          <DeploymentAppList input={app.input} />
        </Suspense>

        <Padding top={24}>
          <Flexbox>
            <Button
              disabled={!app?.input?.value}
              intlId="continue"
              onClick={() => {
                amplitude.track('Go to Deployment Form');

                navigate(
                  `/app/deployments/create?appId=${
                    app?.input?.value
                  }&recreate=true&enable_deployment=true&component=${componentParam ??
                    ''}&componentId=${componentIdParam ?? ''}`,
                );
              }}
            />
          </Flexbox>
        </Padding>
      </Padding>
    </>
  );
}
