// @flow
import { useEffect } from 'react';
import stylex from '@serpa-cloud/stylex';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useSearchParams, useNavigate } from 'react-router-dom';

import {
  Icon,
  Grid,
  Card,
  Text,
  Padding,
  Flexbox,
  Margin,
  Divider,
  Button,
  useDevice,
} from '../../shared';

const styles = stylex.create({
  inputContainer: {
    flex: 1,
  },

  content: {
    width: '100vw',
    maxWidth: 680,
  },

  resourceLink: {
    backgroundColor: 'var(--neutral-color-100)',
  },
});

export default function Confirmation(): React$Node {
  const { width } = useDevice();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const session = searchParams?.get('session');

  const data = useLazyLoadQuery(
    graphql`
      query ConfirmationQuery($session: ID!) {
        getPaymentProfileByStripeCustomerSession(session: $session) {
          id
          product
          createdAtFormatted
        }
      }
    `,
    {
      session: session ?? '',
    },
    {
      fetchPolicy: 'network-only',
    },
  );

  const paymentProfile = data.getPaymentProfileByStripeCustomerSession;

  useEffect(() => {
    const values = {
      hobby: 10,
      basic: 30,
      pro: 50,
    };

    if (paymentProfile?.product) {
      window.dataLayer.push({
        event: 'subscription',
        subscriptionId: session,
        subscriptionValue: values[paymentProfile?.product],
      });
    }
  }, [session, paymentProfile?.product]);

  return (
    <div className={stylex(styles.content)}>
      <Padding horizontal={16}>
        <Flexbox flexDirection="column" rowGap={16}>
          <Text
            type="h4"
            color="--primary-color-1"
            id="onboarding.subscriptionConfirmartionTitle"
          />
          <Text id="onboarding.subscriptionConfirmartionDescription" type="bs" />
        </Flexbox>

        <Margin top={32}>
          <Card>
            <Grid columns="1fr 1fr">
              <Padding vertical={16} horizontal={16}>
                <Text
                  type="s0b"
                  id="onboarding.subscriptionConfirmartionPlanTitle"
                  color="--neutral-color-800"
                />
              </Padding>

              <Padding vertical={16} horizontal={16}>
                <Text
                  type="s0r"
                  id={`onboarding.subscriptionConfirmartionPlan.${paymentProfile?.product ?? ''}`}
                  color="--neutral-color-600"
                />
              </Padding>
            </Grid>
            <Divider />
            <Grid columns="1fr 1fr">
              <Padding vertical={16} horizontal={16}>
                <Text
                  type="s0b"
                  id="onboarding.subscriptionConfirmartionPlan.startDate"
                  color="--neutral-color-800"
                />
              </Padding>

              <Padding vertical={16} horizontal={16}>
                <Text type="s0r" color="--neutral-color-600">
                  {paymentProfile?.createdAtFormatted ?? ''}
                </Text>
              </Padding>
            </Grid>
            <Divider />
            <Grid columns="1fr 1fr">
              <Padding vertical={16} horizontal={16}>
                <Text
                  type="s0b"
                  id="onboarding.subscriptionConfirmartionPlan.paymentRecurrency"
                  color="--neutral-color-800"
                />
              </Padding>

              <Padding vertical={16} horizontal={16}>
                <Text
                  type="s0r"
                  color="--neutral-color-600"
                  id="onboarding.subscriptionConfirmartionPlan.paymentRecurrencyMonthly"
                />
              </Padding>
            </Grid>
          </Card>

          <Margin top={40}>
            <Flexbox flexDirection="column" rowGap={16}>
              <Text id="onboarding.subscriptionConfirmartionResourcesTitle" type="h5" />
              <Text id="onboarding.subscriptionConfirmartionResourcesDescription" type="bs" />
            </Flexbox>

            <Padding top={24} bottom={16}>
              <Flexbox rowGap={16} flexDirection={width > 800 ? 'row' : 'column'}>
                <Button
                  intlId="onboarding.subscriptionConfirmartionCreateAppTitle"
                  onClick={() => {
                    navigate('/app/apps/create/catalog');
                  }}
                />
              </Flexbox>
            </Padding>

            <Divider />

            <Margin top={24}>
              <Grid columns={width > 800 ? '1fr 1fr' : '1fr'} columnGap={16} rowGap={16}>
                <Padding vertical={8} horizontal={8} className={stylex(styles.resourceLink)}>
                  <Flexbox alignItems="center" columnGap={8}>
                    <Icon icon="description" />
                    <a
                      href="https://docs.serpa.cloud/introduction"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Text type="s0m" id="onboarding.documentation" color="--neutral-color-800" />
                    </a>
                  </Flexbox>
                </Padding>

                <Padding vertical={8} horizontal={8} className={stylex(styles.resourceLink)}>
                  <Flexbox alignItems="center" columnGap={8}>
                    <Icon icon="animated_images" />
                    <a
                      href="https://www.youtube.com/@SerpaCloud/videos"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Text type="s0m" id="onboarding.videos" color="--neutral-color-800" />
                    </a>
                  </Flexbox>
                </Padding>
              </Grid>
            </Margin>

            <Margin top={16}>
              <Grid columns={width > 800 ? '1fr 1fr' : '1fr'} columnGap={16} rowGap={16}>
                <Padding vertical={8} horizontal={8} className={stylex(styles.resourceLink)}>
                  <Flexbox alignItems="center" columnGap={8}>
                    <Icon icon="support_agent" />
                    <a
                      href="https://join.slack.com/t/serpacloudtechsupport/shared_invite/zt-2mtkez0tw-Yxy6bTOILZypZXDs2_jwCg"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Text type="s0m" id="onboarding.support" color="--neutral-color-800" />
                    </a>
                  </Flexbox>
                </Padding>
              </Grid>
            </Margin>
          </Margin>

          <Margin top={40}>
            <Flexbox flexDirection="column" rowGap={16}>
              <Text id="onboarding.subscriptionConfirmartionRefundTitle" type="h5" />
              <Text
                id="onboarding.subscriptionConfirmartionRefundDescription"
                type="bs"
                values={{
                  b: (str) => <strong>{str}</strong>,
                  a: (str) => (
                    <a href="mailto:billing-support@serpa.cloud" target="_blank" rel="noreferrer">
                      {str}
                    </a>
                  ),
                }}
              />
            </Flexbox>
          </Margin>
        </Margin>
      </Padding>
    </div>
  );
}
