// @flow

import { Suspense } from 'react';
import { useIntl } from 'react-intl';
import stylex from '@serpa-cloud/stylex';

import { Icon, Text, Card, Padding, Flexbox, Margin, usePageView } from '../../../shared';

import AppDangerZone from './AppDangerZone';
import CompilationSettings from './CompilationSettings';

import useDevice from '../../../shared/hooks/useDevice';

const styles = stylex.create({
  compilationPlaceholder: {
    paddingBottom: 24,
  },
  compilationPlaceholderContent: {
    flex: 1,
  },
  builderPlaceholder: {
    height: 56,
    backgroundColor: 'var(--neutral-color-200)',
  },
});

type Props = {|
  +showCompilationSettings?: ?boolean,
|};

export default function Settings({ showCompilationSettings }: Props): React$Node {
  const { width } = useDevice();
  const intl = useIntl();

  usePageView(intl.formatMessage({ id: 'pageView.microappsSettings' }));

  return (
    <Padding bottom={40}>
      <Flexbox flexDirection="column" rowGap={32}>
        {showCompilationSettings ? (
          <Card>
            <Padding top={24} bottom={8} horizontal={16}>
              <Flexbox flexDirection="column" rowGap={32}>
                <Suspense
                  fallback={
                    <div className={stylex(styles.compilationPlaceholder)}>
                      <Flexbox flexDirection="column" rowGap={24}>
                        <Flexbox alignItems="center" columnGap={8}>
                          <Icon
                            fill
                            icon="token"
                            gradient="linear-gradient(265.7deg, var(--orange-solid-color) -2.24%, var(--red-solid-color) 102.98%)"
                          />
                          <Flexbox flexDirection="column">
                            <Text
                              type={width <= 1000 ? 'h6' : 'h5'}
                              component="h1"
                              id="appCreator.builderTitle"
                            />
                          </Flexbox>
                        </Flexbox>
                        <Text
                          id="appCreator.builderDescription"
                          type={width <= 1000 ? 'bs' : 'bd'}
                          values={{
                            l(s) {
                              return (
                                // eslint-disable-next-line react/jsx-no-target-blank
                                <a href="https://docs.serpa.cloud/introduction" target="_blank">
                                  <span
                                    style={{
                                      fontWeight: '600',
                                    }}
                                  >
                                    {s}
                                  </span>
                                </a>
                              );
                            },
                            b(s) {
                              return (
                                <span
                                  style={{
                                    fontWeight: '600',
                                    color: 'var(--neutral-color-800)',
                                  }}
                                >
                                  {s}
                                </span>
                              );
                            },
                          }}
                        />
                      </Flexbox>
                      <Margin top={32}>
                        <div className={stylex(styles.builderPlaceholder)} />
                      </Margin>
                    </div>
                  }
                >
                  <CompilationSettings />
                </Suspense>
              </Flexbox>
            </Padding>
          </Card>
        ) : null}

        <Card>
          <AppDangerZone />
        </Card>
      </Flexbox>
    </Padding>
  );
}

Settings.defaultProps = {
  showCompilationSettings: true,
};
