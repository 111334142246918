// @flow
import { Suspense } from 'react';
import stylex from '@serpa-cloud/stylex';

import { Flexbox, Padding, LoaderForSuspense } from '../../shared';

import OnboardingSubscription from './OnboardingSubscription';

const styles = stylex.create({
  content: {
    width: '100vw',
  },
});

export default function Onboarding(): React$Node {
  return (
    <div className={stylex(styles.content)}>
      <Padding top={40}>
        <Flexbox justifyContent="center">
          <Suspense fallback={<LoaderForSuspense spinnerSize={40} />}>
            <OnboardingSubscription />
          </Suspense>
        </Flexbox>
      </Padding>
    </div>
  );
}
