/**
 * @generated SignedSource<<e256fb1210074aab17280dfee8786e15>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type DNSProvider = "AWS" | "%future added value";
export type DNSRecordType = "A" | "CNAME" | "%future added value";
export type DomainStatus = "PENDING" | "COMPLETE" | "%future added value";
export type DomainElementRequestCertificateMutation$variables = {|
  domain: string,
  environment: string,
  provider: DNSProvider,
  recordType: DNSRecordType,
|};
export type DomainElementRequestCertificateMutation$data = {|
  +requestCertificate: {|
    +id: string,
    +domain: string,
    +status: DomainStatus,
  |},
|};
export type DomainElementRequestCertificateMutation = {|
  variables: DomainElementRequestCertificateMutation$variables,
  response: DomainElementRequestCertificateMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "domain"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "environment"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "provider"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "recordType"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "domain",
        "variableName": "domain"
      },
      {
        "kind": "Variable",
        "name": "environment",
        "variableName": "environment"
      },
      {
        "kind": "Variable",
        "name": "provider",
        "variableName": "provider"
      },
      {
        "kind": "Variable",
        "name": "recordType",
        "variableName": "recordType"
      }
    ],
    "concreteType": "Domain",
    "kind": "LinkedField",
    "name": "requestCertificate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "domain",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DomainElementRequestCertificateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DomainElementRequestCertificateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "82eac98af221e5fccf8c20b536ffc7c4",
    "id": null,
    "metadata": {},
    "name": "DomainElementRequestCertificateMutation",
    "operationKind": "mutation",
    "text": "mutation DomainElementRequestCertificateMutation(\n  $domain: String!\n  $environment: ID!\n  $provider: DNSProvider!\n  $recordType: DNSRecordType!\n) {\n  requestCertificate(domain: $domain, environment: $environment, provider: $provider, recordType: $recordType) {\n    id\n    domain\n    status\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "ac54f430cfaf47c9756752a9e0640ade";

module.exports = ((node/*: any*/)/*: Mutation<
  DomainElementRequestCertificateMutation$variables,
  DomainElementRequestCertificateMutation$data,
>*/);
