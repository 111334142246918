// @flow
import { useIntl } from 'react-intl';
import stylex from '@serpa-cloud/stylex';
import { Link, Route, Routes } from 'react-router-dom';
import { useLazyLoadQuery, graphql } from 'react-relay';
import * as amplitude from '@amplitude/analytics-browser';
import { useCallback, useState, useTransition, Suspense, useRef, useEffect } from 'react';

import {
  Icon,
  Text,
  Grid,
  Margin,
  Flexbox,
  Padding,
  Spinner,
  ScrolledList,
  LoaderForSuspense,
  ServiceCardPlaceholder,
  useFetchPolicy,
} from '../../shared';

import { ReactComponent as GitIcon } from './gitHome.svg';
import { ReactComponent as DockerIcon } from './dockerHome.svg';

import MicroAppElement from '../MicroApps/MicroAppElement';
import MicroappsDetail from '../MicroApps/MicroappsDetail';

import noiseUrl from '../Layout/noise.png';
import noiseWhiteUrl from '../Layout/noise_white.png';

import useDevice from '../../shared/hooks/useDevice';

const styles = stylex.create({
  mainContent: {
    maxWidth: 1360,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  banner: {
    height: 160,
    backgroundColor: '#FAF8ED',
    borderRadius: 8,
    boxShadow: 'var(--shadow-1)',
    position: 'relative',
    zIndex: 0,
    border: '1px solid var(--neutral-color-300)',
  },
  bannerLink: {
    display: 'block',
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'none',
    },
  },
  bannerTextContainer: {
    height: '100%',
    boxSizing: 'border-box',
  },
  serpaMountains: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    borderBottomRightRadius: 8,
    borderTopRightRadius: 8,
  },
  bannerText: {
    width: 350,
    height: '100%',
  },
  adminIcon: {
    width: 56,
    height: 56,
    borderRadius: 8,
  },
  appsBackground: {
    backgroundImage:
      'linear-gradient(180deg, var(--orange-solid-color) 0%, var(--red-solid-color) 100%)',
  },
  environmentBackground: {
    backgroundImage:
      'linear-gradient(180deg, var(--cyan-solid-color) 0%, var(--blue-solid-color) 100%)',
  },
  deploymentBackground: {
    backgroundImage:
      'linear-gradient(180deg, var(--green-solid-color) 0%, var(--cyan-solid-color) 100%)',
  },
  buildBackground: {
    backgroundImage:
      'linear-gradient(180deg, var(--yellow-solid-color) 0%, var(--red-solid-color) 100%)',
  },
  deployType: {
    display: 'block',
    height: 160,
    borderRadius: 8,
    boxShadow: 'var(--shadow-1)',
    transitionProperty: 'transform',
    transitionDuration: 'var(--fds-duration-medium-in)',
    transitionTimingFunction: 'var(--fds-soft)',
    position: 'relative',
    zIndex: 1,
    textDecoration: 'none',

    ':hover': {
      textDecoration: 'none',
      transitionDuration: 'var(--fds-duration-medium-out)',
    },
  },
  deployTypeShadow: {
    top: 0,
    left: 0,
    height: 160,
    width: '100%',
    position: 'absolute',
    filter: 'blur(12px)',
    borderRadius: 8,
    opacity: 0,
    transform: 'translateZ(0px) translateX(0px) translateY(0px) scale3d(1, 1, 1)',
    transitionProperty: 'all',
    transitionDuration: 'var(--fds-duration-extra-long-in)',
    transitionTimingFunction: 'var(--fds-soft)',
  },
  deployTypeShadowHover: {
    opacity: 0.5,
    transitionDuration: 'var(--fds-duration-extra-long-out)',
    transform: 'translateZ(-100px) translateX(20px) translateY(20px) scale3d(1, 0.98, 0.98)',
  },
  deployContent: {
    boxSizing: 'border-box',
    height: 160,
    width: '100%',
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    pointerEvents: 'none',
  },
  deployInnerContent: {
    height: '100%',
  },
  dockerType: {
    backgroundImage:
      'linear-gradient(359deg,var(--blue-solid-color) 0%, var(--pink-solid-color) 100%)',
  },
  gitType: {
    backgroundImage:
      'linear-gradient(359deg,var(--red-solid-color) 0%, var(--yellow-solid-color) 100%)',
  },

  deployMethodContainer: {
    position: 'relative',
  },
  noise: {
    width: '100%',
    height: '100%',
  },
  container: {
    width: 560,
    '@media (max-width: 815px)': {
      width: '100%',
    },
    '@media (min-width: 815px)': {
      width: 680,
    },
  },
  appListContainer: {
    maxWidth: 1360,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  inputContainer: {
    position: 'relative',
  },
  inputIcon: {
    position: 'absolute',
    top: 12,
    left: 12,
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    width: '100%',
    height: 48,
    padding: 0,
    boxSizing: 'border-box',
    borderRadius: 8,
    background: 'var(--card-background)',
    border: '1px solid var(--border-color)',
    boxShadow: 'var(--shadow-1)',
    outline: 'none',
    textIndent: 48,
    lineHeight: 46,
    fontSize: 16,
    fontFamily: 'var(--font-family-apple)',
    fontWeight: '300',
    color: 'var(--neutral-color-800)',
    '::placeholder': {
      color: 'var(--neutral-color-500)',
    },
    ':focus': {
      border: '1px solid var(--primary-color-1)',
    },
    ':active': {
      border: '1px solid var(--primary-color-1)',
    },
  },
});

const deployTypeMapping = {
  GIT: {
    url: '/app/apps/create/git?method=GIT',
    className: styles.gitType,
    title: 'gitRepo',
    description: 'gitRepoDescription',
    icon: GitIcon,
  },
  DOCKER: {
    url: '/app/apps/create/name?method=DOCKER',
    className: styles.dockerType,
    title: 'dockerImage',
    description: 'dockerImageDescription',
    icon: DockerIcon,
  },
};

type ContainerProps = { +children: React$Node, +empty: boolean };

function MicroAppsContainer({ children, empty }: ContainerProps): React$Node {
  return (
    <div>
      {empty ? (
        <Flexbox justifyContent="center">
          <Padding top={24} className={stylex(styles.container)}>
            {children}
          </Padding>
        </Flexbox>
      ) : null}
      {!empty ? (
        <Padding top={24} bottom={40}>
          {children}
        </Padding>
      ) : null}
    </div>
  );
}

function DeployTypeElement({
  uiTheme,
  method,
}: {
  uiTheme: string,
  method: 'GIT' | 'DOCKER',
}): React$Node {
  const [hover, setHover] = useState(false);
  const [[x, y], setXY] = useState([0, 0]);

  const methodData = deployTypeMapping[method];
  const { icon: IconElement } = methodData;

  const handleOnMouseEnter = useCallback(() => setHover(true), []);
  const handleOnMouseLeave = useCallback(() => {
    setHover(false);
    setXY([0, 0]);
  }, []);
  const handleOnMouseMove = useCallback((e: SyntheticMouseEvent<HTMLLinkElement>) => {
    const { currentTarget: target, clientX, clientY } = e;
    const rect = target.getBoundingClientRect();

    setXY([
      ((clientY - rect.top) * 40) / rect.height - 20,
      ((clientX - rect.left) * -24) / rect.width + 12,
    ]);
  }, []);

  return (
    <div className={stylex(styles.deployMethodContainer)}>
      <div
        className={stylex(
          methodData.className,
          styles.deployTypeShadow,
          hover ? styles.deployTypeShadowHover : null,
        )}
      >
        <div
          className={stylex(styles.noise)}
          style={{
            backgroundImage: `url("${uiTheme === 'LIGHT' ? noiseWhiteUrl : noiseUrl}")`,
          }}
        />
      </div>
      <Link
        to={methodData.url}
        onClick={() => {
          amplitude.track(`${method} Deployment Process`);
        }}
        className={stylex(styles.deployType, methodData.className)}
        style={{
          transform: hover
            ? `scaleX(1.025) scaleY(1.075) perspective(2000px) rotateX(${x}deg) rotateY(${y}deg)`
            : 'scale(1) perspective(2000px) rotateX(0deg) rotateY(0deg)',
        }}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        onMouseMove={handleOnMouseMove}
      >
        <div
          className={stylex(styles.noise)}
          style={{
            backgroundImage: `url("${noiseWhiteUrl}")`,
          }}
        />
      </Link>
      <Padding horizontal={20} vertical={24} className={stylex(styles.deployContent)}>
        <Flexbox
          rowGap={12}
          flexDirection="column"
          justifyContent="flex-end"
          className={stylex(styles.deployInnerContent)}
        >
          <IconElement width={24} height={24} />
          <Flexbox flexDirection="column" rowGap={12}>
            <Text type="s1b" id={methodData.title} color="--always-white" />
            <Text type="s1r" id={methodData.description} color="--always-white" />
          </Flexbox>
        </Flexbox>
      </Padding>
    </div>
  );
}

let timer;

export default function Home(): React$Node {
  const { isMobileAgent, os, width } = useDevice();
  const [searchValue, setSearchValue] = useState('');
  const [query, setQuery] = useState('');
  const [transitionPending, startTransition] = useTransition();
  const inputRef = useRef();
  const isMacOS = os.includes('Mac OS ');

  const handleOnSearch = useCallback((e) => {
    const { value } = e.target;

    setSearchValue(value);

    clearTimeout(timer);

    timer = setTimeout(() => {
      startTransition(() => {
        setQuery(value);
      });
    }, 200);
  }, []);

  const intl = useIntl();
  const baseFetchPolicy = useFetchPolicy();

  const data = useLazyLoadQuery(
    graphql`
      query HomeV2Query {
        me {
          id
          uiTheme
        }
      }
    `,
    {},
    {
      fetchPolicy: 'store-or-network',
    },
  );

  useEffect(() => {
    const handleKeydown = (event) => {
      if (
        (isMacOS && event.metaKey && event.key === 'f') ||
        (!isMacOS && event.ctrlKey && event.key === 'f')
      ) {
        event.preventDefault();
        inputRef.current?.focus();
      }
    };

    window.addEventListener('keydown', handleKeydown);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [os, inputRef, isMacOS]);

  const renderElement = useCallback((node, key) => <MicroAppElement node={node} key={key} />, []);
  const fallbackElement = useCallback((key) => <ServiceCardPlaceholder key={key} />, []);

  if (!data?.me) return null;

  return (
    <Padding bottom={40}>
      <section>
        <Padding top={40} horizontal={width > 540 ? 16 : 8}>
          <div className={stylex(styles.mainContent)}>
            <Flexbox flexDirection="column" rowGap={16}>
              <Text
                type={width > 540 ? 'h3' : 'h4'}
                component="h1"
                gradient="var(--orange-pink-gradient, linear-gradient(270deg, var(--pink-solid-color) 0%, var(--orange-solid-color) 100%))"
              >
                Serpa Cloud OS Prosperity
              </Text>

              <Text id="home.subtitle" type={width > 540 ? 'd4' : 'h4'} component="h2" />
            </Flexbox>

            <Margin top={40}>
              <Text type="s2b" id="home.deployNow" color="--neutral-color-800" />
            </Margin>

            <Margin top={24}>
              <Grid
                columns={
                  (width <= 815 && '1fr') ||
                  (width > 815 && width <= 1200 && '1fr 1fr') ||
                  '1fr 1fr 1fr'
                }
                columnGap={24}
                rowGap={20}
              >
                <DeployTypeElement method="GIT" uiTheme={data?.me?.uiTheme ?? 'LIGHT'} />
                <DeployTypeElement method="DOCKER" uiTheme={data?.me?.uiTheme ?? 'LIGHT'} />
              </Grid>
            </Margin>
          </div>

          <div className={stylex(styles.appListContainer)}>
            <Margin top={56} bottom={0}>
              <Text type="s2b" id="home.manageResources" color="--neutral-color-800" />
            </Margin>
            <Margin top={24} bottom={0}>
              <div className={stylex(styles.inputContainer)}>
                <div className={stylex(styles.inputIcon)}>
                  {!transitionPending ? <Icon icon="search" /> : <Spinner size={16} />}
                </div>
                <input
                  ref={inputRef}
                  value={searchValue}
                  onChange={handleOnSearch}
                  className={stylex(styles.input)}
                  placeholder={intl.formatMessage(
                    { id: 'home.search' },
                    {
                      command: isMobileAgent ? '' : `[${isMacOS ? 'CMD' : 'Ctrl'} + F] `,
                    },
                  )}
                />
              </div>
            </Margin>
            <Suspense
              fallback={
                <Padding vertical={80}>
                  <LoaderForSuspense />
                </Padding>
              }
            >
              <ScrolledList
                next={9}
                first={9}
                index="APPS"
                fetchPolicy={baseFetchPolicy}
                search={query}
                renderElement={renderElement}
                queryFields={['gitProvider', 'name', 'namespace', 'source']}
                emptyElement={<div />}
                filterMatrix={[
                  [
                    {
                      property: 'deleted',
                      type: 'term',
                      valueBoolean: false,
                    },
                  ],
                ]}
                fallbackContainer={
                  <Padding vertical={80}>
                    <LoaderForSuspense />
                  </Padding>
                }
                container={
                  <Grid
                    columns={
                      (width <= 815 && '1fr') ||
                      (width > 815 && width <= 1200 && '1fr 1fr') ||
                      '1fr 1fr 1fr'
                    }
                    columnGap={24}
                    rowGap={20}
                  />
                }
                fallbackElement={fallbackElement}
                parent={MicroAppsContainer}
              />
            </Suspense>
          </div>
        </Padding>
      </section>
      <Routes>
        <Route
          path="/apps/:nodeId/*"
          element={
            <div>
              <Suspense fallback={<div />}>
                <MicroappsDetail />
              </Suspense>
            </div>
          }
        />
      </Routes>
    </Padding>
  );
}
