// @flow
import { Suspense } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';

import { Text, Icon, Flexbox, Padding, Divider, Button, Spinner } from '../../../shared';

type AppNamespaceAvailabilityLayoutProps = {|
  +name: string,
  +disabled?: ?boolean,
  +onSubmit: () => void,
  +pendingSubmit: boolean,
  +showAvailability?: ?boolean,
|};

function AppNamespaceAvailabilityLayout({
  name,
  disabled,
  onSubmit,
  pendingSubmit,
  showAvailability,
}: AppNamespaceAvailabilityLayoutProps): React$Node {
  return (
    <div>
      {!!showAvailability && (
        <Flexbox alignItems="center">
          <Icon
            size={24}
            weight={500}
            icon={disabled ? 'close' : 'done'}
            color={disabled ? '--red-300' : '--green-300'}
          />
          <Padding horizontal={4}>
            <Text
              type="s0r"
              id={disabled ? 'appCreator.appNameUnavailable' : 'appCreator.appNameAvailable'}
              color="--neutral-color-700"
              values={{ name: <strong>{name}</strong> }}
            />
          </Padding>
        </Flexbox>
      )}
      <Padding bottom={16} top={24}>
        <Divider />
      </Padding>
      <Flexbox columnGap={24} alignItems="center">
        <Button
          iconRight="east"
          intlId="continue"
          onClick={onSubmit}
          disabled={(disabled || pendingSubmit) ?? false}
        />
        {pendingSubmit && <Spinner size={24} />}
      </Flexbox>
    </div>
  );
}

AppNamespaceAvailabilityLayout.defaultProps = {
  disabled: false,
  showAvailability: false,
};

type AppNamespaceAvailabilityLayoutNetworkInterfaceProps = {|
  +name: string,
  +onSubmit: () => void,
  +pendingSubmit: boolean,
|};

function AppNamespaceAvailabilityLayoutNetworkInterface({
  name,
  onSubmit,
  pendingSubmit,
}: AppNamespaceAvailabilityLayoutNetworkInterfaceProps) {
  const availabilityData = useLazyLoadQuery(
    graphql`
      query AppNameSpaceAvailabilityQuery($name: String!) {
        appNameAvailability(name: $name)
      }
    `,
    { name },
    {
      fetchPolicy: 'network-only',
    },
  );

  return (
    <AppNamespaceAvailabilityLayout
      name={name}
      onSubmit={onSubmit}
      pendingSubmit={pendingSubmit}
      disabled={!availabilityData.appNameAvailability}
      showAvailability
    />
  );
}

type Props = {|
  +name: string,
  +appId?: ?string,
  +currentName: string,
  +onSubmit: () => void,
  +pendingSubmit: boolean,
|};

export default function AppNamespaceAvailabilityLayoutInterface({
  name,
  appId,
  onSubmit,
  currentName,
  pendingSubmit,
}: Props): React$Node {
  if (!name)
    return (
      <AppNamespaceAvailabilityLayout
        pendingSubmit={pendingSubmit}
        name={name}
        onSubmit={onSubmit}
        disabled
      />
    );

  if (name === currentName && !!appId)
    return (
      <AppNamespaceAvailabilityLayout
        pendingSubmit={pendingSubmit}
        showAvailability
        name={name}
        onSubmit={onSubmit}
      />
    );

  return (
    <Suspense
      fallback={
        <AppNamespaceAvailabilityLayout
          pendingSubmit={pendingSubmit}
          name={name}
          disabled
          onSubmit={onSubmit}
        />
      }
    >
      <AppNamespaceAvailabilityLayoutNetworkInterface
        pendingSubmit={pendingSubmit}
        name={name}
        onSubmit={onSubmit}
      />
    </Suspense>
  );
}

AppNamespaceAvailabilityLayoutInterface.defaultProps = {
  appId: null,
};
